import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Typography, Modal, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { getToken } from '../utils/common';

const VideoUpload = ({ sequenceId, onUploadComplete }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [availableCameras, setAvailableCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [startRecordingAfterCameraSelect, setStartRecordingAfterCameraSelect] = useState(false); // Track if recording should start after selecting a camera

    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunks = useRef([]);

    useEffect(() => {
        // Fetch available video input devices (cameras) on component mount
        const fetchCameras = async () => {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');

                // Sort the devices by their label
                videoDevices.sort((a, b) => a.label.localeCompare(b.label));

                setAvailableCameras(videoDevices);
                setSelectedCamera(videoDevices[0]?.deviceId || '');
            } catch (error) {
                console.error('Error fetching cameras:', error);
            }
        };
        fetchCameras();
    }, []);

    const handleCameraChange = (event) => {
        setSelectedCamera(event.target.value);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            console.log("No file selected");
            return;
        }

        const file = new File([selectedFile], 'recorded-video.mp4', { type: 'video/mp4' });
        const formData = new FormData();
        formData.append('video', file);
        formData.append('sequence_id', sequenceId);

        setUploading(true);
        setError(null);

        try {
            const backend = process.env.REACT_APP_API_URL;
            const api = '/products/sequence/upload-video/';
            const response = await axios.post(`${backend}${api}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${getToken()}`,
                },
            });

            setUploading(false);
            onUploadComplete(response.data.video_url); // Notify parent with the uploaded video URL
        } catch (error) {
            setUploading(false);
            setError('Video upload failed. Please try again.');
        }
    };

    const startRecording = async () => {
        try {
            setShowVideo(true);
            let stream;

            // If the user has selected a camera, use that camera
            if (selectedCamera) {
                stream = await navigator.mediaDevices.getUserMedia({
                    video: { deviceId: { exact: selectedCamera } }
                });
            } else {
                // Fallback to default camera
                stream = await navigator.mediaDevices.getUserMedia({ video: true });
            }

            videoRef.current.srcObject = stream;

            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = (e) => {
                if (e.data.size > 0) {
                    chunks.current.push(e.data);
                    saveRecording();
                }
            };

            mediaRecorderRef.current.start();
            setIsRecording(true);
        } catch (err) {
            console.error('Error accessing webcam:', err);
            setError('Unable to access webcam');
        }
    };

    const saveRecording = async () => {
        if (chunks.current.length > 0) {
            const blob = new Blob(chunks.current, { type: 'video/mp4' });
            const file = new File([blob], 'recorded-video.mp4', { type: 'video/mp4' });

            const formData = new FormData();
            formData.append('video', file);
            formData.append('sequence_id', sequenceId);

            try {
                const backend = process.env.REACT_APP_API_URL;
                const response = await axios.post(`${backend}/products/sequence/upload-video/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `${getToken()}`,
                    },
                });
                console.log('Upload successful:', response.data);
            } catch (error) {
                console.error('Video upload failed:', error);
                setError('Video upload failed. Please try again.');
            }

            chunks.current = []; // Clear chunks for the next recording
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
            videoRef.current.srcObject.getTracks().forEach(track => track.stop());
            mediaRecorderRef.current = null;
            videoRef.current.srcObject = null;
            setIsRecording(false);
        }
    };

    const openCameraSelectionModal = () => {
        setIsModalOpen(true);
        setStartRecordingAfterCameraSelect(true); // Mark that recording should start after camera selection
    };

    const closeModalAndStartRecording = () => {
        setIsModalOpen(false);
        if (startRecordingAfterCameraSelect) {
            startRecording(); // Start recording after camera selection
            setStartRecordingAfterCameraSelect(false); // Reset flag
        }
    };

    return (
        <Box>
            <input type="file" accept="video/*" capture="camcorder" onChange={handleFileChange} />
            <Button variant="contained" color="primary" onClick={handleUpload} disabled={uploading}>
                {uploading ? 'Uploading...' : 'Upload Video'}
            </Button>
            {error && <Typography color="error">{error}</Typography>}

            <Box mt={2}>
                {showVideo && <video ref={videoRef} width="100%" autoPlay muted></video>}
                {isRecording ? (
                    <Button variant="contained" color="secondary" onClick={stopRecording}>
                        Stop Recording
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={openCameraSelectionModal}>
                        Start Recording
                    </Button>
                )}
            </Box>

            {videoURL && (
                <Box mt={2}>
                    <Typography>Recorded Video:</Typography>
                    <video width="100%" controls src={videoURL}></video>
                </Box>
            )}

            {/* Camera Selection Modal */}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Box p={4} sx={{ bgcolor: 'background.paper', maxWidth: 400, margin: 'auto', mt: '20vh' }}>
                    <Typography variant="h6">Select a Camera</Typography>
                    <FormControl fullWidth>
                        <InputLabel id="camera-select-label">Camera</InputLabel>
                        <Select
                            labelId="camera-select-label"
                            value={selectedCamera}
                            onChange={handleCameraChange}
                        >
                            {availableCameras.map(camera => (
                                <MenuItem key={camera.deviceId} value={camera.deviceId}>
                                    {camera.label || `Camera ${camera.deviceId}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box mt={2}>
                        <Button variant="contained" onClick={closeModalAndStartRecording}>
                            Confirm and Start Recording
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default VideoUpload;
