import React, { useEffect, useState} from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faMinus, faSave, faCancel, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import { getUser, getToken } from '../utils/common';
import axios from 'axios';


const Insert = ({ProductID, ComponentID}) => {

    const [ID, setID] = useState('');
    const [Name, setName] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    const handleIDChange = (event) => {
      setID(event.target.value);
    };
  
    const handleNameChange = (event) => {
        setName(event.target.value);
      };
  
      
    useEffect(() => {
    }, [disabled]);
  
  
    
    const insert = async () => {
      var body =  { 
          ID: ID,
          Name: Name,
      };
    
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ComponentID+"/sequence/insert/", options);
      
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to insert component');
        }
        setDisabled(false);
        window.location.reload();
      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error inserting component: ' + error.message);
      }
  
  };
  
    const contentNotFocused = (<><tr>
        <td><>
            <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>  
    </tr></>
    );

    const contentFocused = (<><tr>
        <td><input type="number"  value={ID} onChange={handleIDChange} className="mx-1" disabled={disabled}/></td>
        <td><input type="text"  value={Name} onChange={handleNameChange} className="mx-1" disabled={disabled}/></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>  
        <td><>
        <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </></td>
    </tr></>
    );

    return (isFocused ? contentFocused : contentNotFocused);
  
}
  
const Delete = ({ProductID, ComponentID, SequenceID}) => {

    const handleClick = () => {

        const fetchData = async () => {

            try {
    
                var request = {'request': null};
    
                const Api = process.env.REACT_APP_API_URL;   
                const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('AccessToken'),
                },
                body: JSON.stringify(request),
                };
        
    
            const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ ComponentID +"/sequence/"+ SequenceID +"/delete/", options);

            const data = await response.json();
            window.location.reload();


            } catch (error) {
                console.log('Error fetching data: '+ error)
            } finally {
                //setIsLoading(false);
            }
    
        };
    


        if (confirm("Confirm delete sequence " + SequenceID + " of component " + ComponentID + " of product " + ProductID))
            console.log("deleting", SequenceID, ComponentID, ProductID);
            fetchData();
        };

    return(<><FontAwesomeIcon icon={faTrash} onClick={handleClick} style={{ cursor: 'pointer' }}></FontAwesomeIcon></>);

}

const UpdateField = ({ProductID, ComponentID, SequenceID, FieldName, FieldType, FieldValue}) => {

  const [parameterValue, setParameterValue] = useState(FieldValue);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  useEffect(() => {
    setInitialValue(parameterValue);
  }, []);

  useEffect(() => {
  }, [disabled]);

  var o;
  if (!isFocused) o = <span onClick = {() => {setIsFocused(true)}}>{FieldValue}</span>;
  else {
  o = <></>;
  if (FieldType === 'text') o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
  else if (FieldType === 'number') o = <input type="number"  step = "0.01" value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
  else if (FieldType === 'date') o =  <DatePicker
      selected={new Date(parameterValue)}
      onChange={(date) => {setParameterValue(date.toLocaleString('sv').split(' ')[0])}}
    dateFormat="dd/MM/yyyy"
    />;
  else if (FieldType == 'bool') o = (<select
        value={parameterValue}
        onChange={handleChange}>
        <option value={null}>--</option>
        <option value="1">SI</option>
        <option value="0">NO</option>
        </select>);
  }

  const updateParameter = async () => {
    var body =  { 
        ProductID: ProductID,
        ComponentID: ComponentID, 
        SequenceID: SequenceID, 
        FieldName: FieldName,
        FieldValue: parameterValue
      };

    try {
        setDisabled(true);

        const Api = process.env.REACT_APP_API_URL;   

        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(body),
        };
        
    const response = await fetch(Api+"/api/products/" + ProductID + "/components/" + ComponentID + "/sequence/"+ SequenceID+"/update/", options);
    
      const data = await response.json();
//      console.log(data);
      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }
      setInitialValue(parameterValue);
      setDisabled(false);
      setIsFocused(false);
      window.location.reload();

    } catch (error) {
        console.log(error.message);
      setErrorMessage('Error updating parameter: ' + error.message);
    }

};

return (<>
  {o}
  {((parameterValue !== initialValue)) && isFocused && (<>
      <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </>
  )}
  { isFocused && (<>
      <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </>
  )}          
  {errorMessage && <p>{errorMessage}</p>}
</>
);

}

const SkillSelect = ({ProductID, ComponentID, SequenceID, currentSkills}) => {

  const [isFocused, setIsFocused] = useState(false);
  const [possibleSkills, setPossibleSkills] = useState(['Banco', 'Macchina', 'Juki', 'Colore']);

  const fetchPossibleSkills = async () => {

    try {

      var request = {'id': null};

      const backend = process.env.REACT_APP_API_URL;   
      const api = `/api/skills/view/`
      const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
      },
      body: JSON.stringify(request),
      };

  const response = await fetch(`${backend}${api}`, options);
  const data = await response.json();
  //console.log("DATA", data.data);
  setPossibleSkills(data.data.map((item => item['Skill'])));

} catch (error) {
  console.log(error)
} finally {
}

  }

  useEffect(() => {fetchPossibleSkills()}, []);


  const handleModifySkillValue = (event, Skill, value) => {

    event.stopPropagation();
    console.log("Modify",  Skill, " of ", ProductID, ComponentID, SequenceID, " by ", value);

      const fetchData = async () => {

        try {

            var request = {
              'ProductID': ProductID,
              'ComponentID': ComponentID,
              'SequenceID': SequenceID,
              'Skill': Skill,
              'SkillChange' : value
            };


            const Api = process.env.REACT_APP_API_URL;   
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(request),
            };


            const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ ComponentID +"/sequence/" + SequenceID +"/skill_update/", options);

        const data = await response.json();
        window.location.reload();


        } catch (error) {
            console.log('Error fetching data: '+ error)
        } finally {
            //setIsLoading(false);
        }

      };

      fetchData();   

  };

  const addSkill = (event) => {

    console.log("Add required skill ", event.target.value, " to component ", ProductID, ComponentID, SequenceID);

    const fetchData = async () => {

      try {

          var request = {
            'ProductID': ProductID,
            'ComponentID': ComponentID,
            'SequenceID': SequenceID,
            'Skill': event.target.value,
            'SkillChange' : 1
          };

          const Api = process.env.REACT_APP_API_URL;   
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
          };


      const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ ComponentID +"/sequence/" + SequenceID +"/skill_update/", options);

      const data = await response.json();
      window.location.reload();


      } catch (error) {
          console.log('Error fetching data: '+ error)
      } finally {
          //setIsLoading(false);
      }

    };
 
    fetchData();   
 
  };
  
  const handleFocus = () => {
    setIsFocused(!isFocused);
  };

  /*
  const handleBlur = () => {
    setIsFocused(false);
  };
  */

  const contentNotFocused = (
    <td onClick = {handleFocus}>
      {currentSkills ? 
        currentSkills.map((skill) => {                      
          return (
            <>
              <span className = 'badge bg-secondary mx-1'>
                <span className = 'mx-1'>{skill.Skill}</span>
                <span className = 'mx-2'>{skill.SkillValue}</span> 
                <FontAwesomeIcon className = 'mx-1' icon={faCaretUp} onClick={(event) => {handleModifySkillValue(event, skill.Skill, 1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                <FontAwesomeIcon className = 'mx-1' icon={faCaretDown} onClick={(event) => {handleModifySkillValue(event, skill.Skill, -1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
              </span>
            </>);
          })
        : null }
    </td>
  );

  const contentFocused = (
    <>
      <td className = ''>
        <select 
          onBlur = {handleFocus}
          onChange = {(event) => addSkill(event)}
        >
          <option defaultValue>--</option>
          {possibleSkills.map((skill) => (<option value = {skill}>{skill}</option>))}
        </select>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </td>
    </>
  );

  return( isFocused ? contentFocused : contentNotFocused );

}

const SelectToolClass = ({ ProductID, ComponentID, SequenceID, currentValue, currentName }) => {

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const fetchList = async () => {
    try {
      var request = { 'request': null };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/tools/tool_classes/", postOptions);
      console.log(response);
      const data = await response.json();
      console.log(data);
      const array = data["data"].map((item) => ({ value: item.ToolClassID, label: '[' + item.ToolClassID + '] ' + item.ToolClassName }));
      setOptions(array);
      
      if (currentValue) {
        const preselectedOption = array.find(option => option.value === currentValue);
        setSelectedOption(preselectedOption);
      }
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  const saveSelection = async (reset) => {
    try {
      var request = { 
        'ProductID': ProductID, 
        'ComponentID': ComponentID, 
        'SequenceID': SequenceID, 
        'FieldName' : 'ToolClassID',
        'FieldValue': selectedOption.value,     
        'reset' : reset
      };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/products/" + ProductID +"/components/" + ComponentID + "/sequence/" + SequenceID + "/update/", postOptions);
      //const data = await response.json();
      window.location.reload();

    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };


  useEffect(() => { fetchList() }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSelectOption = (selectedOption) => {
    setSelectedOption(selectedOption);
    // console.log('Selected:', selectedOption);
    // You can perform further actions with the selected option
  };

  var contentWhenFocused = (
    <>
      <div>
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectOption}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          placeholder="Type to filter ..."
          isClearable
          isSearchable
        />
      </div>
      { selectedOption && (selectedOption.value !== currentValue) && 
      (<FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={() => {saveSelection(0)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)
      }
      {(<FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}          
      {(<FontAwesomeIcon className = 'mx-3' icon={faTrash} onClick={() => {if (confirm("Confirm reset?")) saveSelection(1);}} style={{ color: 'red', cursor: 'pointer' }}></FontAwesomeIcon>)}          

  </>
);

  var contentWhenNotFocused = (currentValue ? <span onClick = {() => {setIsFocused(true)}}>[{currentValue}] {currentName}</span> 
    : <span onClick = {() => {setIsFocused(true)}}>---</span> 
  );

  return (isFocused ? contentWhenFocused : contentWhenNotFocused);

}

const SelectMandatedTool = ({ ProductID, ComponentID, SequenceID, currentValue, currentName }) => {

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const fetchList = async () => {
    try {
      var request = { 'request': null };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/tools/", postOptions);
      const data = await response.json();
      const array = data["data"].map((item) => ({ value: item.ToolID, label: '[' + item.ToolID + '] ' + item.ToolName }));
      setOptions(array);
      
      if (currentValue) {
        const preselectedOption = array.find(option => option.value === currentValue);
        setSelectedOption(preselectedOption);
      }
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  const saveSelection = async (reset) => {
    try {
      var request = { 
        'ProductID': ProductID, 
        'ComponentID': ComponentID, 
        'SequenceID': SequenceID, 
        'FieldName' : 'MandatedToolID',
        'FieldValue': selectedOption.value,     
        'reset' : reset
      };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/products/" + ProductID +"/components/" + ComponentID + "/sequence/" + SequenceID + "/update/", postOptions);
      //const data = await response.json();
      window.location.reload();

    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };


  useEffect(() => { fetchList() }, []);

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSelectOption = (selectedOption) => {
    setSelectedOption(selectedOption);
    // console.log('Selected:', selectedOption);
    // You can perform further actions with the selected option
  };

  var contentWhenFocused = (
    <>
      <div>
        <Select
          options={options}
          value={selectedOption}
          onChange={handleSelectOption}
          onInputChange={handleInputChange}
          inputValue={inputValue}
          placeholder="Type to filter ..."
          isClearable
          isSearchable
        />
      </div>
      { selectedOption && (selectedOption.value !== currentValue) && 
      (<FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={() => {saveSelection(0)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)
      }
      {(<FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}          
      {(<FontAwesomeIcon className = 'mx-3' icon={faTrash} onClick={() => {if (confirm("Confirm reset?")) saveSelection(1);}} style={{ color: 'red', cursor: 'pointer' }}></FontAwesomeIcon>)}          

  </>
);

  var contentWhenNotFocused = (currentValue ? <span onClick = {() => {setIsFocused(true)}}>[{currentValue}] {currentName}</span> 
    : <span onClick = {() => {setIsFocused(true)}}>---</span> 
  );

  return (isFocused ? contentWhenFocused : contentWhenNotFocused);

}

const SelectPreferredOperators = ({ ProductID, ComponentID, SequenceID, currentOperators }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const fetchList = async () => {
    try {
      const request = { 'request': null };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/operators/", postOptions);
      const data = await response.json();
      const array = data["data"].map((item) => ({ value: item.OperatorID, label: item.OperatorName + ' [' + item.OperatorID + ']' }));
      setOptions(array);
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  useEffect(() => { fetchList() }, []);

  const changeOperator = async (event, OperatorID, action) => {

    event.stopPropagation();

    try {

      const request = {
        'ProductID': ProductID,
        'ComponentID': ComponentID,
        'SequenceID': SequenceID,
        'OperatorID': OperatorID,
        'action': action
      };

      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/products/" + ProductID + "/components/" + ComponentID + "/sequence/" + SequenceID + "/change_preferred_operator/", postOptions);
      window.location.reload();
      // Instead of reloading page, handle response and update state accordingly
      // const data = await response.json();
      // Update state or perform other necessary actions based on the response
      setIsFocused(false); // Close select when action is performed
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSelectOption = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      {isFocused ? (
        <div>
          <Select
            options={options}
            value={selectedOption}
            onChange={handleSelectOption}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            placeholder="Type to filter ..."
            isClearable
            isSearchable
          />
          {selectedOption && (<FontAwesomeIcon className='mx-1' icon={faSave} onClick={(event) => changeOperator(event, selectedOption.value, 'add')} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
          <FontAwesomeIcon className='mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          {currentOperators.map((operator) => (
            <span key={operator.OperatorID} className="badge bg-secondary mx-1">
              <FontAwesomeIcon
                className="mx-1"
                icon={faTrash}
                onClick={(event) => changeOperator(event, operator.OperatorID, 'remove')}
                style={{ cursor: 'pointer' }}
              />
              {operator.OperatorID} {operator.OperatorName}
            </span>
          ))}
        </div>
      ) : (
        <div onClick={() => setIsFocused(true)}>
          {currentOperators.length == 0 ? <span>---</span> :
            
            currentOperators.map((operator) => (
            <div key={operator.OperatorID} className="badge bg-secondary m-1">
              <span className="mx-1">{operator.OperatorName}</span>
              <span className="mx-1">[{operator.OperatorID}]</span>
              <span className="mx-1">
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faTrash}
                  onClick={(event) => changeOperator(event, operator.OperatorID, 'remove')}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const SelectQualifiedOperators = ({ ProductID, ComponentID, SequenceID, currentOperators }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const fetchList = async () => {
    try {
      const request = { 'request': null };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/operators/", postOptions);
      const data = await response.json();
      const array = data["data"].map((item) => ({ value: item.OperatorID, label: item.OperatorName + ' [' + item.OperatorID + ']' }));
      setOptions(array);
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  useEffect(() => { fetchList() }, []);

  const changeOperator = async (event, OperatorID, action) => {

    event.stopPropagation();

    try {

      const request = {
        'ProductID': ProductID,
        'ComponentID': ComponentID,
        'SequenceID': SequenceID,
        'OperatorID': OperatorID,
        'action': action
      };

      const backend = process.env.REACT_APP_API_URL;
      const api = `/api/products/${ProductID}/components/${ComponentID}/sequence/${SequenceID}/change_qualified_operator/`;

      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(`${backend}${api}`, postOptions);
      window.location.reload();
      // Instead of reloading page, handle response and update state accordingly
      // const data = await response.json();
      // Update state or perform other necessary actions based on the response
      setIsFocused(false); // Close select when action is performed
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSelectOption = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      {isFocused ? (
        <div>
          <Select
            options={options}
            value={selectedOption}
            onChange={handleSelectOption}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            placeholder="Type to filter ..."
            isClearable
            isSearchable
          />
          {selectedOption && (<FontAwesomeIcon className='mx-1' icon={faSave} onClick={(event) => changeOperator(event, selectedOption.value, 'add')} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
          <FontAwesomeIcon className='mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          {currentOperators.map((operator) => (
            <span key={operator.OperatorID} className="badge bg-secondary mx-1">
              <FontAwesomeIcon
                className="mx-1"
                icon={faTrash}
                onClick={(event) => changeOperator(event, operator.OperatorID, 'remove')}
                style={{ cursor: 'pointer' }}
              />
              {operator.OperatorID} {operator.OperatorName}
            </span>
          ))}
        </div>
      ) : (
        <div onClick={() => setIsFocused(true)}>
          {currentOperators.length == 0 ? <span>---</span> :
            
            currentOperators.map((operator) => (
            <div key={operator.OperatorID} className="badge bg-secondary m-1">
              <span className="mx-1">{operator.OperatorName}</span>
              <span className="mx-1">[{operator.OperatorID}]</span>
              <span className="mx-1">
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faTrash}
                  onClick={(event) => changeOperator(event, operator.OperatorID, 'remove')}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const SelectTrainingOperators = ({ ProductID, ComponentID, SequenceID, currentOperators }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const fetchList = async () => {
    try {
      const request = { 'request': null };
      const Api = process.env.REACT_APP_API_URL;
      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(Api + "/api/operators/", postOptions);
      const data = await response.json();
      const array = data["data"].map((item) => ({ value: item.OperatorID, label: item.OperatorName + ' [' + item.OperatorID + ']' }));
      setOptions(array);
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  useEffect(() => { fetchList() }, []);

  const changeOperator = async (event, OperatorID, action) => {

    event.stopPropagation();

    try {

      const request = {
        'ProductID': ProductID,
        'ComponentID': ComponentID,
        'SequenceID': SequenceID,
        'OperatorID': OperatorID,
        'action': action
      };

      const backend = process.env.REACT_APP_API_URL;
      const api = `/api/products/${ProductID}/components/${ComponentID}/sequence/${SequenceID}/change_training_operator/`;

      const postOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };
      const response = await fetch(`${backend}${api}`, postOptions);
      window.location.reload();
      // Instead of reloading page, handle response and update state accordingly
      // const data = await response.json();
      // Update state or perform other necessary actions based on the response
      setIsFocused(false); // Close select when action is performed
    } catch (error) {
      console.log('Error fetching data: ' + error)
    }
  };

  const handleInputChange = (inputValue) => {
    setInputValue(inputValue);
  };

  const handleSelectOption = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div>
      {isFocused ? (
        <div>
          <Select
            options={options}
            value={selectedOption}
            onChange={handleSelectOption}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            placeholder="Type to filter ..."
            isClearable
            isSearchable
          />
          {selectedOption && (<FontAwesomeIcon className='mx-1' icon={faSave} onClick={(event) => changeOperator(event, selectedOption.value, 'add')} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
          <FontAwesomeIcon className='mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          {currentOperators.map((operator) => (
            <span key={operator.OperatorID} className="badge bg-secondary mx-1">
              <FontAwesomeIcon
                className="mx-1"
                icon={faTrash}
                onClick={(event) => changeOperator(event, operator.OperatorID, 'remove')}
                style={{ cursor: 'pointer' }}
              />
              {operator.OperatorID} {operator.OperatorName}
            </span>
          ))}
        </div>
      ) : (
        <div onClick={() => setIsFocused(true)}>
          {currentOperators.length == 0 ? <span>---</span> :
            
            currentOperators.map((operator) => (
            <div key={operator.OperatorID} className="badge bg-secondary m-1">
              <span className="mx-1">{operator.OperatorName}</span>
              <span className="mx-1">[{operator.OperatorID}]</span>
              <span className="mx-1">
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faTrash}
                  onClick={(event) => changeOperator(event, operator.OperatorID, 'remove')}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

const SwitchButton = ({ item, FieldName }) => {

  const [isChecked, setIsChecked] = useState(item[FieldName]);
  const [errorMessage, setErrorMessage] = useState('');

  const updateParameter = async () => {
  
    /* eslint-disable no-restricted-globals */
//    if (!confirm('Confirm update?')) return;
    /* eslint-enable no-restricted-globals */
      try {
        const user = getUser();
        const auth = user.auth;
        if (!auth.hasOwnProperty('products')) return;
        if (!(auth.users.includes('a') || auth.users.includes('u'))) return;

        const request = {
          ProductID: item.ProductID,
          ComponentID: item.ComponentID,
          SequenceID: item.SequenceID,
          FieldName: FieldName,
          FieldValue: isChecked ? 0 : 1,
        };

        const backend = process.env.REACT_APP_BACKEND_URL;
//        const api = "/api/products/" + item.ProductID + "/components/" + item.ComponentID + "/sequence/"+ item.SequenceID+"/update/";
        const api = `/api/products/${item.ProductID}/components/${item.ComponentID}/sequence/${item.SequenceID}/update/`;

        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
        };

        const url = `${backend}${api}`;
//        console.log("request", request)
        const response = await axios.post(url, request, options);
//        console.log(response);
        window.location.reload();
      } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  }; // end update parameter  
  
/*
  useEffect(() => {
    setIsChecked(value);
  }, [value]);
*/

const handleChange = (checked) => {
    setIsChecked(checked);
    updateParameter();
  };

  return (
    <div>
      <Switch
        onChange={handleChange}
        checked={isChecked}
        onColor="#86d3ff"
        offColor="#dcdcdc"
        checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
        uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
        handleDiameter={24}
        height={26}
        width = {80}
      />
    </div>
  );
};


const ComponentSequence = () => {

  let { ProductID, ComponentID } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState(null);

  const fetchData = async () => {

    try {

      var request = {'request': null};
      const Api = process.env.REACT_APP_API_URL;   
      const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
      },
      body: JSON.stringify(request),
      };
  
      const response = await fetch(Api+"/api/products/" + ProductID +"/components/" + ComponentID +"/sequence/", options);
      const data = await response.json();
      console.log(data);
      var link_to_products = "/products/";
      var link_to_product = "/products/" + ProductID + "/components/";
      var link_to_sequence = "/products/" + ProductID + "/components/"+ ComponentID + "/sequence/";
      
      setContent(
        <>
          {/* Navigation bar */}
          <div className = 'd-flex flex-start p-3'>
            <Link className = 'mx-2' to={link_to_products}>Products</Link>
            {' >> '}
            <span className = 'mx-2'>{ProductID}</span>
            {' >> '}
            <Link className = 'mx-2'to={link_to_product}>Components</Link>
            {' >> '}
            <span className = 'mx-2'>{ComponentID}</span>
            {' >> '}
            <Link className = 'mx-2'to={link_to_sequence}>Sequence</Link>
          </div>
          {/* Content card */}
          <div className = 'card m-2'>
            {/* Header */}
            <div className = 'card-header'>
                Item: {ProductID} {data["ProductName"]}, Component: {ComponentID} {data["ComponentName"]}
            </div>
            {/* Body */}
            <div className = 'card-body'>
              {/* Table */}
              <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
                {/* Table Head*/}
                <thead>
                  <tr className="table-light" >
                  <th>ID</th>
                  <th>Name</th>
                  <th>ExpectedTime</th>
                  <th>DelayAfter</th>
                  <th>LineStation</th>
                  <th>ToolClass</th>
                  <th>MandatedTool</th>
                  <th>Skills</th>
                  <th>requiresQualification</th>
                  <th>QualifiedOperators</th>
                  <th>TrainingOperators</th>
                  <th>PreferredOperators</th>
                  <th>Performed</th>
                  <th>Actions</th>
                </tr>
                </thead>
                {/* Table Body*/}              
                <tbody>
                  {data["data"].map((item) => {
                    return(
                    <>
                      <tr className="table-light" >
                      <td>{item.SequenceID}</td>
                      <td><UpdateField ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}  FieldName = 'SequenceName' FieldType = 'text' FieldValue = {item.SequenceName}/></td>
                      <td><UpdateField ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}  FieldName = 'ExpectedTime' FieldType = 'number' FieldValue = {item.ExpectedTime == null ? 0.0 : item.ExpectedTime}/></td>
                      <td><UpdateField ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}  FieldName = 'DelayAfter' FieldType = 'number' FieldValue = {item.DelayAfter == null ? 0.0 : item.DelayAfter}/></td>
                      <td><UpdateField ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}  FieldName = 'LineStation' FieldType = 'number' FieldValue = {item.LineStation}/></td>
                      <td><SelectToolClass ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentValue={item.ToolClassID} currentName={item.ToolClassName}/></td>
                      <td><SelectMandatedTool ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentValue={item.MandatedToolID} currentName={item.MandatedToolName}/></td>
                      <SkillSelect ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentSkills={item.required_skills}/> 
                      <td><SwitchButton item = {item} FieldName = 'requiresQualification' /></td>
                      <td><SelectQualifiedOperators ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentOperators={item.qualified_operators}/></td>
                      <td><SelectTrainingOperators ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentOperators={item.training_operators}/></td>
                      <td><SelectPreferredOperators ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentOperators={item.preferred_operators}/></td>
                      <td>
                        <p className='my-0'>Completed: <Link className = 'mx-2' to={`/products/${ProductID}/components/${ComponentID}/sequence/${item.SequenceID}/batches/`}>{item.SequenceQuantity}</Link></p>
                        <p className='my-0'>AverageTime: {item.AverageActualTime}</p>
                        <p className='my-0'>Min: {item.MinActualTime}</p>
                        <p className='my-0'>Max: {item.MaxActualTime}</p>

                      </td>
                      <td><Delete ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}/></td>                
                    </tr></>);              

                    })}
                  <Insert ProductID = {ProductID} ComponentID = {ComponentID}/>
                </tbody>
              </table>
            </div> {/* End card body */}
          </div>  {/* End card */}
        </>       
      );

    } catch (error) {setContent('Error fetching data: '+ error)
    } finally {setIsLoading(false);}

  };
  
  // fetch data only on mount
  useEffect(() => {fetchData()}, []);

  var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

  return (<>{isLoading ? loading : content }</>);

}

export default ComponentSequence;
