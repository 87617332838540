import { useState, useEffect } from 'react';
//import { Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate, Link, useParams } from 'react-router-dom';


const SelectOperator = ({TaskID, KitID, operators}) => {

  const [parameterValue, setParameterValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target.checked);
  };

  useEffect(() => {
    setInitialValue(parameterValue);
  }, []);

  var options = operators.map((item) => {
    return (<option value = {item.OperatorID}>{item.OperatorName}</option>);
    })

    var o = <select
        value={parameterValue}
        onChange={handleChange}>
        {options}
        </select>;

  const updateParameter = async () => {

    var body =  { 
        TaskID: TaskID,
        OperatorID: parameterValue,
        KitID: KitID
    };

    try {
        
        console.log(body)

        setDisabled(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/assign_task/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }
      setInitialValue(parameterValue);
      setDisabled(false);
    } catch (error) {
        console.log(error.message);
      setErrorMessage('Error updating parameter: ' + error.message);
    }
  };

  return (<>
    {/*<div className="d-flex align-items-center">*/}
      {o}
      {((parameterValue !== initialValue)) && (
        <button onClick={updateParameter} className="btn btn-primary mx-1">
          Assign
        </button>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    {/*</div>*/}
    </>
  );

};

const OperatorRow = (props) =>  {

  var tasks = [];

  var skills = props.skills;
  //console.log(skills)
  var CurrentTask = <></>;

  for (var id in props.assigned_tasks)  {


    var TaskID = props.assigned_tasks[id]['TaskID'];
  //  var OrderID = props.assigned_tasks[id]['OrderID'];
    var ProductID = props.assigned_tasks[id]['ProductID'];
    var Quantity = props.assigned_tasks[id]['Quantity'];
    var isPossible = props.assigned_tasks[id]['isPossible'];
    var isWorkedOn = props.assigned_tasks[id]['isWorkedOn'];
    var ExpectedTime = props.assigned_tasks[id]['ExpectedTime'];
    var Started = props.assigned_tasks[id]['Started'];
    var ComponentName = props.assigned_tasks[id]['ComponentName'];
    var SequenceName = props.assigned_tasks[id]['SequenceName'];
    var KitName = props.assigned_tasks[id]['KitName'];
    var KitID = props.assigned_tasks[id]['KitID'];
    var KitBeingUsed = props.assigned_tasks[id]['KitBeingUsed'];
//    var TaskSkills = props.assigned_tasks[id]['Skills'];
    if (id === 0) CurrentTask = <>{Quantity} x {ProductID} {ComponentName} - {SequenceName} ({TaskID})</>;
    
    tasks.push(
      <tr>
      <td>{Quantity} x {ProductID} {ComponentName} - {SequenceName} ({TaskID})</td>
      <td>{KitName} {KitID} ({KitBeingUsed})</td>
      <td>{ExpectedTime}</td>
      <td>{isPossible}</td>
      <td>{isWorkedOn}</td>
      <td>{Started}</td>
    </tr>

      );
  }

  const SkillsComponent = ({skills}) => {
      
    var tags = [];

    for (var id in skills) {
      tags.push(<span className = "badge bg-primary mx-1">{id}: {skills[id]}</span>);
    }
    return(<>{tags}</>);
  }

  
  return (
    <Accordion.Item eventKey={props.OperatorID}>
    <Accordion.Header style ={{ width: "auto"}}>
    <div className="container m-0 p-0">
    <table className="table table-striped text-start table-fixed table-sm table-responsive-sm m-0 p-0">
    <thead><tr>
      <td className = "col-2 fst-italic fs-6"><small>Operatore</small></td>
      <td className = "col-3 fst-italic fs-6">Skills</td>
      <td className = "col-1 fst-italic fs-6">Assigned Tasks</td>
      <td className = "col-6 fst-italic fs-6">Current Task</td>
    </tr></thead>
    <tbody><tr>
      <td>{props.OperatorName} ({props.OperatorID})</td>
      <td><SkillsComponent skills={skills}/></td>
      <td>{props.AssignedTasks}</td>
      <td>{CurrentTask}</td>
    </tr></tbody>
    </table>
    </div>
    </Accordion.Header>
    <Accordion.Body style ={{ backgroundColor: "grey"}}>
    <div>
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
    <tr className="table-dark">
      <th className = "col-4">Task</th>
      <th className = "col-1">Kit</th>
      <th className = "col-1">ExpectedTime</th>
      <th className = "col-1">isPossible</th>
      <th className = "col-1">isWorkedOn</th>
      <th className = "col-1">Started</th>
    </tr></thead>
    <tbody>{tasks}</tbody>
    </table>
    </div>
    </Accordion.Body>
  </Accordion.Item>  
    );

};

const TasksTable = ({tasks, operators}) =>  {
    
    var rows = [];
    var counter = 0;

    const SkillsComponent = ({skills}) => {
      
      var tags = [];

      for (var id in skills) {
        tags.push(<span className = "badge bg-primary mx-1">{id}: {skills[id]}</span>);
      }
      return(<>{tags}</>);
    }

    for (var id in tasks)  {
      counter += 1;
      var TaskID = tasks[id]['TaskID'];
//      var OrderID = tasks[id]['OrderID'];
      var ProductID = tasks[id]['ProductID'];
      var Quantity = tasks[id]['Quantity'];
      var ExpectedTime = tasks[id]['ExpectedTime'];
      var ComponentName = tasks[id]['ComponentName'];
      var SequenceName = tasks[id]['SequenceName'];
      var KitName = tasks[id]['KitName'];
      var KitID = tasks[id]['KitID'];
      var KitBeingUsed = tasks[id]['KitBeingUsed'];
      var tskills =  tasks[id]['skills'];

      var kitbg = "";
      if (KitBeingUsed === 1) kitbg = "bg-danger text-white";
  
      rows.push(
      <tr>
        <td>{Quantity} x {ProductID} {ComponentName} - {SequenceName} ({TaskID})</td>
        <td className = {kitbg}>{KitName}</td>
        <td><SkillsComponent skills = {tskills}/></td>
        <td>{ExpectedTime}</td>
        <td>{!(KitBeingUsed === 1) && (<SelectOperator TaskID = {TaskID} KitID = {KitID} operators = {operators}/>)}</td>

      </tr>
  
        );
    }
  

    return (
    <div>
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
    <tr className="table-dark">
      <th className = "col-4">Task ({counter})</th>
      <th className = "col-1">Kit</th>
      <th className = "col-2">Skills</th>
      <th className = "col-1">ExpectedTime</th>
      <th className = "col-1">AssignTo</th>
    </tr></thead>
    <tbody>{rows}</tbody>
    </table>
    </div>
  );

};

const TasksToAssign = ({jobs}) => {
      
 // let { JobID } = useParams();

//  const url = `${process.env.REACT_APP_API_URL}/api/jobs/${JobID}/tasks-to-assign/`;
  const url = `${process.env.REACT_APP_API_URL}/api/jobs/tasks-to-assign/`;

  var request = {
    'AllJobs' : 0,
    'jobs' : jobs
  };

  const now = new Date();
  request["Now"] = now.toLocaleString('sv');
  request["UTCoffset"] = now.getTimezoneOffset();

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };


  const [data, setData] = useState([]);

  const update = () => {
    fetch(url, options)
    .then((res) => {
      console.log(res)
    return res.json();
    })
    .then((data) => {
    console.log(data);
    setData(data);
  })
    .catch(rejected => {
    console.log(rejected);
  });
  } 


  useEffect(() => {
    update();
    const intervalId = setInterval(update, 2500);
    return () => clearInterval(intervalId);
  }, []);

 
return (
  <div> 
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
      <tr className="table-light">
        <th className = "col-5">Task</th>
        <th className = "col-2">Tools</th>
        <th className = "col-2">Skills</th>
        <th className = "col-1">Kit</th>
        <th className = "col-2"><p className="my-0">Possible</p><p className="my-0">Operators</p></th>
      </tr>
    </thead>
    <tbody>
        {data.hasOwnProperty('tasks') && data.tasks.map((task) => {
        return (
          <>
            <tr key = {task.BatchID}>
              <td>
              <p className="my-0"><span className ="badge bg-secondary fst-italic mx-2" ><small>{task.BatchCode}-{task.ComponentID}-{task.SequenceID}</small></span>{task.ComponentName} - {task.SequenceName}</p>
              </td>
              <td>
              {task.tools.map((tool) => {
                var c = 'grey'
                if (!(tool.JobID === null)) {
                  if (tool.OperatorID === null) c = 'green';
                  else c = 'red';
                } 
                return <><span style = {{backgroundColor: c, color: 'white'}} className ="badge fst-italic mx-1" ><small>{tool.ToolID} {tool.ToolName}</small></span></>})}
              </td>
              <td>
              <p className="my-0">
                {task.Skills.map((skill) => <><span className ="badge bg-secondary fst-italic" ><small>{skill.Skill}:{skill.SkillValue}</small></span></>)}
                </p>
              </td>
              <td>
              <p className="my-0">
                <span 
                  className ="badge fst-italic" 
                  style = {{backgroundColor : `${task.KitIsBeingUsed == 1 ? 'red' : 'green'}`}}
                ><small>{task.BatchCode}/{task.KitID}</small></span></p>
              </td>
              <td>
              <p className="my-0">
                {task.possible_operators.map((operator) => <>
                <span 
                  className ="badge fst-italic mx-1 p-1" 
                  style = {{backgroundColor : `${operator.isSkilled === 0 ? 'grey' : operator.isBusy == 1 ? 'red' : 'green'}`}}
                ><small>{operator.OperatorName} ({operator.OperatorID})</small></span></>)}
                </p>
              </td>

            </tr>
          </>
        );
      })}
    </tbody>
    </table>
  </div>);
};

export default TasksToAssign;