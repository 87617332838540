import React from 'react';
import { Route, Routes } from 'react-router-dom';
import JobsList from './JobsList.js'
import TabsMenu from './TabsMenu.js';

const Jobs = () => {

    return (
        <>
            <TabsMenu />
            <div>
                <Routes>
                    <Route index element={<JobsList />} />
                    {/*<Route path="batches/*" element={<Batches jobs = {'*'}/>} />
                    <Route path="operators/*" element={<OperatorsTable operators = {'*'}/>} />
                    <Route path="tasks/" element={<PossibleTasks AllJobs = {true}/>} />  */}
                </Routes>
            </div>
        </>
    );
};

export default Jobs;
