import React, { useState, useEffect } from 'react';
import {
  IconButton, Box, Typography, Card, CardContent, Chip, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import axios from 'axios';
import { getToken } from '../utils/common';
import { useNavigate } from 'react-router-dom';
import ListIcon from '@mui/icons-material/List';
import { DictValue } from '../utils/common';
import { Update, SwitchButton } from '../utils/elements';

import CloseIcon from '@mui/icons-material/Close'; // Make sure to import the Close icon

const SubcomponentChip = ({ component, refetch, canChange }) => {
  
  const handleDelete = async (event) => {
    
    if (!canChange) return

    event.stopPropagation(); // Prevent the event from bubbling up

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/change-parent/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };

      // Remove the subcomponent by setting parent_id to null
      await axios.post(url, { id: component.id, parent_id: '' }, options);
      refetch(); // Refresh data after deletion

    } catch (error) {
      console.log('Error updating subcomponents: ', error);
    }
  };

  return (
    <Chip
      key={'chip' + component.id}
      label={
        <div 
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}
          onClick={(event) => event.stopPropagation()} // Prevent select from opening when clicking on chip
        >
          <span>
            {component.quantity} x {component.name} ({component.id})
          </span>
          {canChange && <CloseIcon
            onClick={(event) => {
              event.stopPropagation(); // Prevent click event from bubbling up
              handleDelete(event); // Call the delete handler when the icon is clicked
            }}
            style={{ cursor: 'pointer', marginLeft: '8px' }} // Style for better UX
          />}
        </div>
      }
      variant="outlined"
      style={{ padding: '4px 8px' }} // Optional: adjust padding if needed
      onClick={(event) => event.stopPropagation()} // Prevent select from opening when clicking on chip
      onMouseDown={(event) => event.stopPropagation()} // Prevent select from opening when clicking on chip
      />
  );
};

// CreateComponentModal Component
const CreateComponentModal = ({ product_id, components, api, open, onClose, onCreated }) => {
  const initialData = {
    name: '',
    product_id: product_id,
    quantity: 1,
    parent_id: null, // Start with no parent selected
  };

  const [formData, setFormData] = useState(initialData);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleParentChange = (e) => {
    setFormData({
      ...formData,
      parent_id: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}${api}`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };

      const dataToSend = { ...formData };

//      console.log(url, dataToSend, options)
      const response = await axios.post(url, dataToSend, options);
      console.log(response)
      const newComponent = response.data; // Assume response contains the new component data
      
      onClose();
      onCreated(newComponent); // Pass the new component data to onCreated
    } catch (error) {
      console.log('Error creating component: ', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Component</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            label="Name"
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
          
          <FormControl fullWidth margin="dense">
            <InputLabel>{"Dependent Component (Parent in tree structure)"}</InputLabel>
            <Select
              value={formData.parent_id}
              onChange={handleParentChange}
              label="Parent Component"
            >
              <MenuItem value={''}>None (Root Component)</MenuItem>
              {components.map((component) => (
                <MenuItem key={component.id} value={component.id}>
                  {component.name} (ID: {component.id})
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            margin="dense"
            label="Quantity"
            name="quantity"
            type="number"
            fullWidth
            value={formData.quantity}
            onChange={handleChange}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// Helper function to flatten the component structure
const flattenComponents = (components) => {
  const flatList = [];

  const extractSubcomponents = (component) => {
    flatList.push(component);
    if (component.subcomponents) {
      component.subcomponents.forEach(sub => extractSubcomponents(sub));
    }
  };

  components.forEach(component => extractSubcomponents(component));

  try {
    return flatList.sort((a, b) => a.name.localeCompare(b.name));
  }
  catch (err) {console.log(err); return flatList;}
  // Sort the flattened list by component name
};

// Helper function to gather all subcomponents recursively
const getAllSubcomponentIds = (component) => {
  let subcomponentIds = [];
  
  const gatherSubcomponents = (comp) => {
    if (comp.subcomponents && comp.subcomponents.length > 0) {
      comp.subcomponents.forEach(sub => {
        subcomponentIds.push(sub.id);
        gatherSubcomponents(sub);  // Recursively gather subcomponents of subcomponents
      });
    }
  };
  
  gatherSubcomponents(component);
  return subcomponentIds;
};

// Helper function to gather all ancestors recursively (parents, grandparents, etc.)
const getAllAncestorIds = (component, allComponents) => {
  let ancestorIds = [];
  
  const gatherAncestors = (comp) => {
    if (comp.parent_id) {
      const parent = allComponents.find(c => c.id === comp.parent_id);
      if (parent) {
        ancestorIds.push(parent.id);
        gatherAncestors(parent);  // Recursively gather ancestors of the parent
      }
    }
  };
  
  gatherAncestors(component);
  return ancestorIds;
};


const getComponentsThatAreSubcomponents = (components) => {
  const subcomponentSet = new Set();

  const gatherSubcomponents = (component) => {
    if (component.subcomponents && component.subcomponents.length > 0) {
      component.subcomponents.forEach(sub => {
        subcomponentSet.add(sub.id);  // Add the subcomponent's ID
        gatherSubcomponents(sub);      // Recursively gather subcomponents of subcomponents
      });
    }
  };

  components.forEach(component => gatherSubcomponents(component));

  return Array.from(subcomponentSet);
};


// ComponentCard to display each component
const ComponentCard = ({ component, prevData, setData, flattenedComponents, setDeleteComponentId, setConfirmDialogOpen, changePerm = true, refetch=null }) => {

  const [editParentId, setEditParentId] = useState(component.parent_id);
  const [selectedSubcomponent, setSelectedSubcomponent] = useState('');
  const [availableComponents, setAvailableComponents] = useState([]);
  
  const navigate = useNavigate()

  // Initialize available subcomponents list excluding the required components
  useEffect(() => {
    // Gather all subcomponents and ancestors to exclude them
    const subcomponentIds = getAllSubcomponentIds(component);
    const ancestorIds = getAllAncestorIds(component, flattenedComponents);
    const alreadyIds = getComponentsThatAreSubcomponents(prevData);
    const excludedIds = [component.id, ...subcomponentIds, ...ancestorIds, ...alreadyIds];

    // Filter the available components to exclude the necessary ones
    const available = flattenedComponents.filter((comp) => !excludedIds.includes(comp.id));
    setAvailableComponents(available);
  }, [component, flattenedComponents]);


  useEffect(()=>{}, [component])

  const handleSubcomponentChange = async (e) => {
    
    if (!changePerm) return

    const newSubcomponentId = e.target.value;
    setSelectedSubcomponent(newSubcomponentId);
  
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/change-parent/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      
      await axios.post(url, { id: newSubcomponentId, parent_id: component.id }, options);
  
      // Update the component's subcomponents in the state
      setData((prevData) => {
        return prevData.map((item) =>
          item.id === component.id 
            ? { ...item, subcomponents: [...item.subcomponents, availableComponents.find(c => c.id === newSubcomponentId)] }
            : item
        );
      });
  
      refetch();  // If you're using refetch to refresh data
  
    } catch (error) {
      console.log('Error updating subcomponents: ', error);
    }
  };

  const handleParentIdChange = async (e) => {

    if (!changePerm) return
    const newParentId = e.target.value;
    setEditParentId(newParentId);

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/change-parent/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      
//      console.log({ id: component.id, parent_id: newParentId })
      await axios.post(url, { id: component.id, parent_id: newParentId }, options);

      // Update component list with new parent ID
      setData((prevData) =>
        prevData.map((item) =>
          item.id === component.id ? { ...item, parent_id: newParentId } : item
        )
      );
      refetch();

    } catch (error) {
      console.log('Error updating parent_id: ', error);
    }
  };

  // Gather all subcomponents (including deep nested ones)
  const subcomponentIds = getAllSubcomponentIds(component);
  
  // Add the current component's ID to the exclusion list
  const excludedIds = [component.id, ...subcomponentIds];

  const handleConfirmDelete = async () => {

    if (!changePerm) return

    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/delete/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.delete(`${url}${component.id}/`, options);

      setData((prevData) => {
        const removeItemRecursively = (items) => {
          return items
            .filter(item => item.id !== component.id)
            .map(item => ({
              ...item,
              subcomponents: removeItemRecursively(item.subcomponents),
            }));
        };

        return [...removeItemRecursively(prevData)]; // Return new reference to trigger re-render
      });

      setConfirmDialogOpen(false);
    } catch (error) {
      console.log('Error deleting: ', error);
      setConfirmDialogOpen(false);
    }
  };




  return (
    <Card variant="outlined" sx={{ marginBottom: 2 }}>
      <CardContent>
      <Typography variant="body1" color="textSecondary">
          <Update api = {"/products/component/change/"} request ={{id: component.id}} field_name='quantity' field_type='number' field_value={component.quantity} can_change={changePerm}/>
          {' x '}
        </Typography>

        <IconButton onClick={(e) =>
          { 
            e.stopPropagation(); // Prevent toggling the node when clicking the button
            navigate('/products/component/'+ component.id)
            }} 
            color="primary">
            
            {component.name} [{component.id || 'None'}]
          </IconButton>


        {/* Parent component selection */}
        {!changePerm && !component.parent_id ? null : <FormControl fullWidth margin="dense">
          <InputLabel>Parent Component</InputLabel>
          <Select
            value={editParentId || ''}
            onChange={handleParentIdChange}
            label="Parent Component"
            disabled = {!changePerm}
          >
            <MenuItem value={''}>None (Root Component)</MenuItem>
            {flattenedComponents
              .filter((comp) => !excludedIds.includes(comp.id))  // Exclude the component itself and all its subcomponents
              .map((comp) => (
                <MenuItem key={comp.id} value={comp.id}>
                  {comp.name} (ID: {comp.id})
                </MenuItem>
              ))}
          </Select>
        </FormControl>}

  {(!changePerm && component.subcomponents.length == 0) ? null : <FormControl fullWidth margin="dense"  onMouseDown = {(event) => event.stopPropagation()}>
  <InputLabel>Subcomponents</InputLabel>
  <Select
    disabled={!changePerm || availableComponents.length === 0}
    value={component.subcomponents.length > 0 ? 'show' : ''}
    onChange={handleSubcomponentChange}
    label="Subcomponents"
    onMouseDown={(event) => event.stopPropagation()} // Prevent Select from opening
    renderValue={() => (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {component.subcomponents.length > 0 ? (
          component.subcomponents.map((subcomponent) => (
            <SubcomponentChip 
              key={'c' + component.id + 's' + subcomponent.id} component={subcomponent} refetch={refetch} 
              onClick = {(event) => event.stopPropagation()}
              onMouseDown = {(event) => event.stopPropagation()}
              canChange = {changePerm}
              />
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No subcomponents added yet
          </Typography>
        )}
      </Box>
    )}
  >
    {availableComponents.map((comp) => (
      <MenuItem key={comp.id} value={comp.id}>
        {comp.name} (ID: {comp.id})
      </MenuItem>
    ))}
  </Select>
</FormControl>}

      {/*component.subcomponents.map((subcomponent) => <SubcomponentChip key = {'c'+component.id+'s'+subcomponent.id} component={subcomponent}/>)*/}

      </CardContent>
      
      {changePerm && <DialogActions>
          <Button onClick={() => {setDeleteComponentId(component.id); setConfirmDialogOpen(true)}} color="error">
            Delete
          </Button>
        </DialogActions>}

    </Card>
  );
};

// Main FlatView Component
const FlatView = ({ product_id, components, apiUrl, refetch, canChange }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [componentList, setComponentList] = useState(components);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteComponentId, setDeleteComponentId] = useState(null);

  console.log("CANCHANGE", canChange)

  useEffect(() => {
    setComponentList(components);
  }, [components]); // Re-run when 'components' changes


  // Flattened list of all components (root and subcomponents)
  const flattenedComponents = flattenComponents(componentList);
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCreatedComponent = (newComponent) => {
    setComponentList([...componentList, { ...newComponent, subcomponents: [] }]);
    refetch();
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteComponentId(null);
  };

  const handleConfirmDelete = async () => {
    if (!canChange) return
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/delete/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.delete(`${url}${deleteComponentId}/`, options);

      setConfirmDialogOpen(false);
      refetch();
    } catch (error) {
      console.log('Error deleting: ', error);
      setConfirmDialogOpen(false);
    }
  };



  return (
    <Box sx={{ padding: 2 }}>

      {canChange && <Button variant="contained" onClick={handleOpenModal} sx={{ marginBottom: 2 }}>
        {DictValue("Add Component")}
      </Button>}

      {/* Render flattened components */}
      {flattenedComponents.map((component) => (
        <ComponentCard 
          key={'component'+component.id} 
          component={component} 
          prevData={componentList} 
          setData={setComponentList} 
          setConfirmDialogOpen={setConfirmDialogOpen}
          setDeleteComponentId={setDeleteComponentId}
          flattenedComponents={flattenedComponents}
          refetch={refetch}
          changePerm = {canChange}
          />
      ))}

      {/* Modal to create new component */}
      {canChange && <CreateComponentModal
        product_id={product_id}
        components={flattenedComponents} // Pass the flattened list of components
        api={apiUrl}
        open={modalOpen}
        onClose={handleCloseModal}
        onCreated={handleCreatedComponent}
      />}

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this component?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>


    </Box>
  );
};

export default FlatView;






