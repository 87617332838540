import {React, useState, useEffect} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getToken } from '../utils/common';
import {
  Container,
  Typography,
  Paper,
  Button,
  Box,
  Grid,
  Checkbox,
  FormControlLabel,
  Switch,
  Chip, IconButton, TextField, Autocomplete, CircularProgress 
} from "@mui/material";
//import Switch from 'react-switch';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCaretUp, faCaretDown, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';
import AssignedOperators from './AssignedOperators';
//import Select from 'react-select';
//import { Box, Button, Chip, IconButton, TextField, Autocomplete } from '@mui/material';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faTrash, faSave, faCancel } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import PropTypes from 'prop-types';

import BatchChips from './BatchChip';
import JobCloneModal from './JobClone';
import { KContainer, KText, KCheckbox, KSwitchIcon } from '../utils/elements';
import { PlayCircle, StopCircle } from '@mui/icons-material';


export const SwitchButton = ({ job_id, field_name, field_value }) => {
  const [isChecked, setIsChecked] = useState(field_value);

  useEffect(() => {
    setIsChecked(field_value);
  }, [field_value]);

  const updateParameter = async () => {
    const request = {
      job_id: job_id,
      field_name: field_name,
      field_value: !isChecked,
    };

    try {
      const backend = process.env.REACT_APP_API_URL;
      const api = "/jobs/job_update/";
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getToken(),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(`${backend}${api}`, options);
      const data = await response.json();

      if (!response.ok) {
        throw new Error("Failed to update parameter");
      }
      // window.location.reload();
    } catch (error) {
      console.log(error.message);
      //setErrorMessage('Error updating parameter: ' + error.message);
    }
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
    updateParameter();
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isChecked}
          onChange={handleChange}
          color="primary"
        />
      }
      label={isChecked ? "Yes" : "No"}
    />
  );
};

export const Update = ({ username, field_name, field_type, field_value }) => {
  const [parameterValue, setParameterValue] = useState(field_value || '');
  const [initialValue, setInitialValue] = useState(field_value || '');
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  const updateParameter = async () => {
    const request = {
      username: username,
      field_name: field_name,
      field_value: parameterValue,
    };

    try {
      const backend = process.env.REACT_APP_API_URL;
      const api = "/users/user_update/";
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(`${backend}${api}`, options);
      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }

      // Update the initial value to reflect the successful update
      setInitialValue(parameterValue);
      setIsFocused(false);
    } catch (error) {
      setErrorMessage('Error updating parameter: ' + error.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {!isFocused ? (
        <span style={{ color: 'blue' }} onClick={() => setIsFocused(true)}>
          {parameterValue === null || parameterValue === '' ? '---' : (field_type === 'date' ? (new Date(parameterValue)).toLocaleDateString('it', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }) : parameterValue)}
        </span>
      ) : (
        <>
          {field_type === 'text' && (
            <input
              type="text"
              value={parameterValue}
              onChange={handleChange}
              className="mx-1"
              disabled={disabled}
            />
          )}
          {field_type === 'number' && (
            <input
              type="number"
              step="0.001"
              value={parameterValue}
              onChange={handleChange}
              className="mx-1"
              disabled={disabled}
            />
          )}
          {field_type === 'date' && (
            <DatePicker
              selected={parameterValue ? new Date(parameterValue) : null}
              onChange={(date) => setParameterValue(date.toISOString().split('T')[0])}
              dateFormat="dd/MM/yyyy"
            />
          )}
          {field_type === 'bool' && (
            <select value={parameterValue} onChange={handleChange} disabled={disabled}>
              <option value="">--</option>
              <option value="1">SI</option>
              <option value="0">NO</option>
            </select>
          )}

          {parameterValue !== initialValue && (
            <FontAwesomeIcon
              className="mx-1"
              icon={faSave}
              onClick={updateParameter}
              style={{ cursor: 'pointer' }}
            />
          )}
          <FontAwesomeIcon
            className="mx-1"
            icon={faCancel}
            onClick={() => setIsFocused(false)}
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
      {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
    </>
  );
};


const fetchJobData = async (JobID, setData, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/jobs/job_info/";
        const request = { 'job_id': JobID };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data)
        setData(data.data);
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};


const SelectAssignedTools = ({ job_id, current_tools }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [tools, setTools] = useState(current_tools); // Local state for tools
  const [isLoading, setIsLoading] = useState(false); // Loading state for API calls

  // Fetch the list of available tools from the backend
  const fetchList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/tools/list/`,
        { request: null },
        {
          headers: { Authorization: getToken() },
        }
      );
      const array = response.data.data.map((item) => ({
        value: item.tool_id,
        label: `${item.tool_name} [${item.tool_id}]`,
      }));
      setOptions(array);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch tools list on component mount
  useEffect(() => {
    fetchList();
  }, []);

  // Update local tools state if current_tools prop changes
  useEffect(() => {
    setTools(current_tools);
  }, [current_tools]);

  // Handle adding or removing a tool
  const changeTool = async (event, tool_id, action) => {
    event.stopPropagation();
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/tools/job_actions/`,
        {
          job_id,
          tool_id,
          action,
        },
        {
          headers: { Authorization: getToken() },
        }
      );

      if (action === 'add') {
        // Find the added tool from options
        const addedTool = options.find((option) => option.value === tool_id);
        if (addedTool) {
          const [tool_name] = addedTool.label.split(' [');
          setTools((prevTools) => [
            ...prevTools,
            { tool_id, tool_name: tool_name.trim() },
          ]);
        }
      } else if (action === 'remove') {
        setTools((prevTools) =>
          prevTools.filter((tool) => tool.tool_id !== tool_id)
        );
      }

      // Reset selected option and close the autocomplete
      setSelectedOption(null);
      setIsFocused(false);
    } catch (error) {
      console.error('Error changing tool:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle adding a tool when clicking the save icon
  const handleAddTool = (event) => {
    if (selectedOption) {
      changeTool(event, selectedOption.value, 'add');
    }
  };

  return (
    <Box>
      {isFocused ? (
        <Box>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            value={selectedOption}
            onChange={(event, newValue) => setSelectedOption(newValue)}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            inputValue={inputValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type to filter..."
                variant="outlined"
                fullWidth
              />
            )}
            clearOnEscape
            disableClearable
          />
          <Box mt={1} display="flex" alignItems="center">
            {selectedOption && (
              <IconButton
                onClick={handleAddTool}
                disabled={isLoading}
                color="primary"
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <FontAwesomeIcon icon={faSave} />
                )}
              </IconButton>
            )}
            <IconButton
              onClick={() => setIsFocused(false)}
              disabled={isLoading}
              color="secondary"
            >
              <FontAwesomeIcon icon={faCancel} />
            </IconButton>
          </Box>
          <Box display="flex" flexWrap="wrap" mt={2}>
            {tools.map((tool) => (
              <Chip
                key={tool.tool_id}
                label={`${tool.tool_name} [${tool.tool_id}]`}
                onDelete={(event) => changeTool(event, tool.tool_id, 'remove')}
                deleteIcon={
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ fontSize: '0.75rem' }} // Smaller trash icon
                  />
                }
                style={{ margin: '0.5rem' }}
                color="default"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          onClick={() => setIsFocused(true)}
          sx={{
            cursor: 'pointer',
            minHeight: '40px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            border: '1px dashed #ccc',
            padding: '8px',
            borderRadius: '4px',
          }}
        >
          {tools.length === 0 ? (
            <span>---</span>
          ) : (
            tools.map((tool) => (
              <Chip
                key={tool.tool_id}
                label={`${tool.tool_name} [${tool.tool_id}]`}
                onDelete={(event) => changeTool(event, tool.tool_id, 'remove')}
                deleteIcon={
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ fontSize: '0.75rem' }} // Smaller trash icon
                  />
                }
                style={{ margin: '0.5rem' }}
                color="default"
                variant="outlined"
              />
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

SelectAssignedTools.propTypes = {
  job_id: PropTypes.number.isRequired,
  current_tools: PropTypes.arrayOf(
    PropTypes.shape({
      tool_id: PropTypes.number.isRequired,
      tool_name: PropTypes.string.isRequired,
    })
  ).isRequired,
};


const SelectAssignedOperators = ({ job_id, assigned_operators }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [operators, setOperators] = useState(assigned_operators); // Local state for tools
  const [isLoading, setIsLoading] = useState(false); // Loading state for API calls

  // Fetch the list of available tools from the backend
  const fetchList = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/operators/operators_list/`,
        { request: null },
        {
          headers: { Authorization: getToken() },
        }
      );
      console.log(response)
      const array = response.data.data.map((item) => ({
        value: item.operator_id,
        label: `${item.username} [${item.operator_id}]`,
      }));
      setOptions(array);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // Fetch list on component mount
  useEffect(() => {
    fetchList();
  }, []);

  // Update local state if prop changes
  useEffect(() => {
    setOperators(assigned_operators);
  }, [assigned_operators]);

  // Handle adding or removing an operator
  const changeOperator = async (event, operator_id, action) => {
    event.stopPropagation();
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/operators/job_actions/`,
        {
          job_id,
          operator_id,
          action,
        },
        {
          headers: { Authorization: getToken() },
        }
      );

      if (action === 'add') {
        // Find the added tool from options
        const addedOperator = options.find((option) => option.value === operator_id);
        if (addedOperator) {
          const [username] = addedOperator.label.split(' [');
          setOperators((prevOperators) => [
            ...prevOperators,
            { operator_id, username: username.trim() },
          ]);
        }
      } else if (action === 'remove') {
        setOperators((prevOperators) =>
          prevOperators.filter((operator) => operator.operator_id !== operator_id)
        );
      }

      // Reset selected option and close the autocomplete
      setSelectedOption(null);
      setIsFocused(false);
    } catch (error) {
      console.error('Error changing tool:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle adding a tool when clicking the save icon
  const handleAddOperator = (event) => {
    if (selectedOption) {
      changeOperator(event, selectedOption.value, 'add');
    }
  };

  return (
    <Box>
      {isFocused ? (
        <Box>
          <Autocomplete
            options={options}
            getOptionLabel={(option) => option.label}
            value={selectedOption}
            onChange={(event, newValue) => setSelectedOption(newValue)}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            inputValue={inputValue}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type to filter..."
                variant="outlined"
                fullWidth
              />
            )}
            clearOnEscape
            disableClearable
          />
          <Box mt={1} display="flex" alignItems="center">
            {selectedOption && (
              <IconButton
                onClick={handleAddOperator}
                disabled={isLoading}
                color="primary"
              >
                {isLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  <FontAwesomeIcon icon={faSave} />
                )}
              </IconButton>
            )}
            <IconButton
              onClick={() => setIsFocused(false)}
              disabled={isLoading}
              color="secondary"
            >
              <FontAwesomeIcon icon={faCancel} />
            </IconButton>
          </Box>
          <Box display="flex" flexWrap="wrap" mt={2}>
            {operators.map((operator) => (
              <Chip
                key={operator.operator_id}
                label={`${operator.username} [${operator.operator_id}]`}
                onDelete={(event) => changeOperator(event, operator.operator_id, 'remove')}
                deleteIcon={
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ fontSize: '0.75rem' }} // Smaller trash icon
                  />
                }
                style={{ margin: '0.5rem' }}
                color="default"
                variant="outlined"
              />
            ))}
          </Box>
        </Box>
      ) : (
        <Box
          onClick={() => setIsFocused(true)}
          sx={{
            cursor: 'pointer',
            minHeight: '40px',
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            border: '1px dashed #ccc',
            padding: '8px',
            borderRadius: '4px',
          }}
        >
          {operators.length === 0 ? (
            <span>---</span>
          ) : (
            operators.map((operator) => (
              <Chip
                key={operator.operator_id}
                label={`${operator.username} [${operator.operator_id}]`}
                onDelete={(event) => changeOperator(event, operator.operator_id, 'remove')}
                deleteIcon={
                  <FontAwesomeIcon
                    icon={faTrash}
                    style={{ fontSize: '0.75rem' }} // Smaller trash icon
                  />
                }
                style={{ margin: '0.5rem' }}
                color="default"
                variant="outlined"
              />
            ))
          )}
        </Box>
      )}
    </Box>
  );
};

SelectAssignedOperators.propTypes = {
  operator_id: PropTypes.number.isRequired,
  assigned_operators: PropTypes.arrayOf(
    PropTypes.shape({
      operator_id: PropTypes.number.isRequired,
      username: PropTypes.string.isRequired,
    })
  ).isRequired,
};


export const JobInfo = () => {
  
  const { JobID } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
//      console.log('Opening modal');
    setShowModal(true);
  };
  const handleCloseModal = () => {
//        console.log('Closing modal');
      setShowModal(false);
  };


  useEffect(() => {
    fetchJobData(JobID, setData, setIsLoading);
  }, [JobID]);

  const deleteJob = async () => {
    const backend = process.env.REACT_APP_API_URL;
    const api = `/jobs/job_delete/${JobID}/`;

    try {
      const response = await fetch(`${backend}${api}`, {
        method: "DELETE",
        headers: {
          Authorization: getToken(),
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete job");
      }

      navigate("/jobs"); // Redirect to the jobs list or another page after deletion
    } catch (error) {
      console.log("Error:", error.message);
    }
  };

  return (
    data && (
      <>
        <KContainer title="Job Details">
          <KText label="JobID" value={data.job_id} />
          <KText label="JobCode" value={data.job_code} />
          <KText label="Product" value={`${data.product_name} (${data.product_id})`} />
          <KText label="Quantity" value={data.quantity} />
          <KText label="ET per unit" value={data.expected_time_per_unit_hhmm} />
          <KText label="ET total" value={data.expected_time_hhmm} />
          <KText label="Created" value={data.dt_created_local} />

          <KCheckbox label="Started" value={data.is_started} />
          <KCheckbox label="Completed" value={data.is_completed} />
          <KCheckbox label="Assigned" value={data.is_assigned} />
          <KSwitchIcon 
              uid_name = 'job_id'
              uid_value = {data.job_id}
              field_name='is_active' 
              field_value={data.is_active}
              label='Active'
              api='/jobs/job_update/'
              icon_on={<PlayCircle sx={{ color: '#4CAF50' }} />}
              icon_off={<StopCircle sx={{ color: '#F44336' }} />}
            />

            {/*<Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" color="textSecondary">
                isActive
              </Typography>
              <SwitchButton job_id={data.job_id} field_name="is_active" value={data.is_active} />
            </Grid>*/}

            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Batches
              </Typography>
                <BatchChips data={data?.batches} />
                {/*data.batches?.map((item, index) => (
                  <Chip key={index} label={`${item.batch_code} (${item.quantity})`} style={{ marginRight: 4, marginBottom: 4, border: item?.is_active ? '2px solid green' : 'none',  }} />
                ))*/}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Assigned operators
              </Typography>
              <Box>
              <SelectAssignedOperators job_id={data.job_id} assigned_operators={data.assigned_operators}/>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Assigned tools
              </Typography>
              <Box>
                <SelectAssignedTools job_id={data.job_id} current_tools={data.assigned_tools}/>
              </Box>
            </Grid>

            {/* Tags Section */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Required skills
              </Typography>
              <Box>
                {data.required_skills?.map((skill, index) => (
                  <Chip key={index} label={`${skill.skill_tag} : ${skill.skill_value} `} style={{ marginRight: 4, marginBottom: 4, border: skill?.fulfilled ? '2px solid green' : 'none', }} />
                ))}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Required qualifications
              </Typography>
              <Box>
                {data.required_qualifications?.map((q, index) => (
                  <Chip key={index} label={`${q.component_name} (${q.component_id}) ${q.sequence_name} `} style={{ marginRight: 4, marginBottom: 4, border: q?.fulfilled ? '2px solid green' : 'none',  }} />
                ))}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                Required Toolclasses
              </Typography>
              <Box>
                {data.required_toolclasses?.map((item, index) => (
                  <Chip key={index} label={`${item.tool_class_name} (${item.tool_class_id})`} style={{ marginRight: 4, marginBottom: 4, border: item?.fulfilled ? '2px solid green' : 'none',  }} />
                ))}
              </Box>
            </Grid>              
            
            {/*<Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleShowModal}>
              Clone Job
              </Button>
              <JobCloneModal job={data} open={showModal} onClose={handleCloseModal} />
            </Grid>*/}

            {/* Delete Button */}
            {!data.is_started && (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={deleteJob}
                  fullWidth
                >
                  Delete Job
                </Button>
              </Grid>
            )}

      </KContainer>
      </>
    )
  );
};