import React from 'react'; 
import { Link, Route, Routes, useParams } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
//import axios from 'axios';
//import { getToken } from '../utils/common';

//import Dispatching from '../jobs/dispatching/Dispatching.js';
import Batches from '../batches/Batches.js';
import BatchTasks from '../tasks/BatchTasks.js';
import OperatorsTable from './dispatching/Operators'; // Import OperatorsTable component
import TasksToAssign from './dispatching/TasksToAssign.js'
import DictTag from '../dictionaries/DictTag.js';

const Job = () => {

    let { JobID } = useParams();

    return (
        <>
            <span>{' >> '}</span>
            <Link className='mx-2' to={`/jobs/job/${JobID}`}>{JobID}</Link>
            <div className="my-2">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/jobs/job/${JobID}/batches`} eventKey="batches"><DictTag tag='Batches'/></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/jobs/job/${JobID}/operators`} eventKey="operators"><DictTag tag='Operators'/></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/jobs/job/${JobID}/tasks`} eventKey="tasks"><DictTag tag='Tasks'/></Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div>
                <Routes>
                    <Route index element={<OperatorsTable />} />
                    <Route path="batches" element={<Batches />} />
                    <Route path="operators" element={<OperatorsTable />} />
                    <Route path="tasks" element={<TasksToAssign jobs = {[JobID]}/>} />
                    <Route path="batches/:BatchID/tasks/" element={<BatchTasks />} />
                </Routes>
            </div>
        </>
    );
};

export default Job;
