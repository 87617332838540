import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
//import axios from 'axios';

import { useNavigate, Link } from 'react-router-dom';

import SwitchButton from './SwitchButton';
import Delete from './Delete';
import Update from './Update';
import CreateBatchModal from './CreateBatchModal';
import SelectAssignedOperators from './SelectAssignedOperators';
import SelectAssignedTools from './SelectAssignedTools';
import SelectManager from './SelectManager';
import { getToken } from '../utils/common';
import FilterInput from '../utils/FilterInput';
import DictTag from '../dictionaries/DictTag';


const Jobs = () => {

    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [filters, setFilters] = useState({
        field1: '',
        field2: '',
        field3: '',    
      });
    
      const setFilter1 = (value) => {
        setFilters({ ...filters, field1: value });
      };
      
      const setFilter2 = (value) => {
        setFilters({ ...filters, field2: value });
      };
    
      const setFilter3 = (value) => {
        setFilters({ ...filters, field3: value });
      };


    const history = useNavigate();

    const fetchData = async () => {

        try {

            var request = {'ProductID': null};

            const backend = process.env.REACT_APP_API_URL;   
            const api = "/api/jobs/";   

            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
            };
    
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
//        console.log(data);

        setContent(<>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
                <tr className="table-light" >
                <th><DictTag tag = 'JobID'/></th>
                <th><DictTag tag = 'JobCode'/></th>
                <th><DictTag tag = 'Quantity'/>/ <DictTag tag = 'Product'/></th>
                <th><DictTag tag = 'isActive'/></th>
                <th><DictTag tag = 'isCompleted'/></th>
                <th><DictTag tag = 'Priority'/></th>
                <th><DictTag tag = 'Manager'/></th>
                <th><DictTag tag = 'AssignedOperators'/></th>
                <th><DictTag tag = 'AssignedTools'/></th>
                <th><DictTag tag = 'Batches'/></th>
                <th><DictTag tag = 'Created'/>/ <DictTag tag = 'Completed'/></th>
                <th><DictTag tag = 'Details'/></th>
                <th><DictTag tag = 'Actions'/></th>
              </tr>

              <tr className="table-light" >
                <th><FilterInput initialValue={''} setExternalValue={setFilter1}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter2}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter3}/></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>


              </thead>
              <tbody>
                {data["data"].map((job) => { 
//                    var link_to_batches = `/products/${job.ProductID}/jobs/${job.JobID}/batches/`;
                    var link_to_batches = `/jobs/job/${job.JobID}/batches/`;

                    var link_to_dispatching = `/jobs/job/${job.JobID}/operators/`;
                    var visible = true;
                    
                    if (!(filters.field1 == '')) if 
                      (!(
                        (job.JobID.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
                      )) 
                        visible = false;
  
                    if (!(filters.field2 == '')) if 
                      (job.JobCode && !(
                        (job.JobCode.toString().toUpperCase().includes(filters.field2.toUpperCase())) 
                          
                      ) || (job.JobCode == null)) 
                        visible = false;
  
                      if (!(filters.field3 == '')) if 
                      (!(
                        (job.ProductName.toString().toUpperCase().includes(filters.field3.toUpperCase())) 
                        || (job.ProductID.toString().toUpperCase().includes(filters.field3.toUpperCase())) 

                    )) 
                        visible = false;
  
                        if (!visible) return <></>;
  

                    return (
                        <>
                        <tr>
                    <td><Link to={`/jobs/job/${job.JobID}`}>{job.JobID}</Link></td>
                    <td><Update JobID = {job.JobID} FieldName = 'JobCode' FieldType = 'text' FieldValue = {job.JobCode} /></td>
                    <td>{job.JobQuantity} x {job.ProductName} ({job.ProductID})</td>
                    <td><SwitchButton JobID = {job.JobID} FieldName = 'isActive' value = {job.isActive}/></td>
                    <td><SwitchButton JobID = {job.JobID} FieldName = 'isCompleted' value = {job.isCompleted}/></td>
                    <td><Update JobID = {job.JobID} FieldName = 'Priority' FieldType = 'number' FieldValue = {job.Priority} /></td>
                    <td><SelectManager JobID = {job.JobID} currentManagerID = {job.ManagerID} currentManagerName = {job.ManagerName}/></td>
                    <td><SelectAssignedOperators JobID = {job.JobID} currentOperators = {job.operators}/></td>
                    <td><SelectAssignedTools JobID = {job.JobID} currentTools = {job.tools}/></td>
                    <td>{job.batches.map((batch) => (
                            <span 
                              key={batch.BatchID} 
                              className="badge bg-secondary mx-1"
                              onClick = {() => history('/products/'+job.ProductID+'/jobs/'+job.JobID+'/batches/')}
                              style={{ cursor: 'pointer' }}
                              >
                            {batch.BatchCode}{' => '}{batch.BatchQuantity} 
                            </span>
                        ))}</td>
                    <td>{job.Created}</td>
                    <td><Link to={link_to_batches}>batches</Link> <Link to={link_to_dispatching}>operators</Link></td>
                    <td>
                        <CreateBatchModal JobID = {job.JobID} JobQuantity = {job.JobQuantity}/>
                        <Delete JobID = {job.JobID}/>
                    </td>                
                    </tr>                        
                        </>
                    );

                }

                )}
              </tbody>
            </table>
        </>);

    } catch (error) {
        setContent('Error fetching data: '+ error)
      } finally {
        setIsLoading(false);
      }

    };
  
useEffect(() => {fetchData()}, [filters]);
//useEffect(() => {}, [filters]);

    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

return (<>{isLoading ? loading : content }</>);
};

export default Jobs;
