import React from 'react';
import { Route, Routes} from 'react-router-dom';
import OperatorsList from './OperatorsList';
import OperatorPanel from './OperatorPanel';

const Operators = () => {

    return (
    <>

        {/*<h3 className = "mb-3">Operators</h3> */}
    
        <div>    
        <Routes>
            <Route index element={<OperatorsList />} />
            <Route path="operator_panel" element={<OperatorPanel />} /> 
            <Route path="*" element={<OperatorsList />} /> 
        </Routes>
        </div>
    </>
    );
};

export default Operators;