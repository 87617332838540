import React, { useState , useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, IconButton, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import axios from 'axios';
import { getToken } from '../utils/common';
import CreateComponentModal from './CreateComponentModal';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ListIcon from '@mui/icons-material/List';

const Component = ({ item, onDelete }) => {
  return <></>
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      <Typography variant="body2">{`Code: ${item.code}`}</Typography>
      <Typography variant="body2">{`Parent_id: ${item.parent_id}`}</Typography>
    </Box>
  );
};

const MyTreeItem = ({ item, addSubComponent, deleteComponent, expandedNodes, setExpandedNodes, canChange, level = 0 }) => {

  const navigate = useNavigate();

  const hasSubcomponents = item.subcomponents && item.subcomponents.length > 0;

  const handleToggle = (nodeId) => {
    if (typeof setExpandedNodes !== 'function') {
      console.error('setExpandedNodes is not a function');
      return;
    }
    console.log(expandedNodes)
    setExpandedNodes((prevExpanded) =>
      prevExpanded.includes(nodeId)
        ? prevExpanded.filter((id) => id !== nodeId)
        : [...prevExpanded, nodeId]
    );
    console.log(expandedNodes)

  };

  const isExpanded = (expandedNodes || []).includes(item.id);  
  console.log(isExpanded, item)

  useEffect(() => {
    console.log('Expanded Nodes Updated:', expandedNodes);
  }, [expandedNodes]);

//  if (hasSubcomponents) 
  
  return (
    <TreeItem 
      itemId={item.id.toString()}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="body1">{`${item.quantity} x ${item.name} [${item.id}]`}</Typography>
          <IconButton onClick={(e) =>
          { 
            e.stopPropagation(); // Prevent toggling the node when clicking the button
            navigate('/products/component/'+ item.id)
            }} 
            color="primary">
            <ListIcon />
          </IconButton>
 
          {canChange && <IconButton 
            onClick={(e) => { 
            e.stopPropagation(); // Prevent toggling the node when clicking the button
            addSubComponent(item.id);
            }}
            color="primary">
            <AddCircleIcon />
          </IconButton>}
          
          {canChange && <IconButton onClick={(e) =>
          { 
            e.stopPropagation(); // Prevent toggling the node when clicking the button
            deleteComponent(item.id)
            }}           
          color="secondary">
            <DeleteIcon />
          </IconButton>}

        </Box>
      }
//      defaultCollapseIcon={hasSubcomponents ? <ExpandMoreIcon /> : <FiberManualRecordIcon />}
//      defaultExpandIcon={hasSubcomponents ? <ChevronRightIcon /> : <FiberManualRecordIcon />}
//      expanded={expandedNodes.includes(item.id)} // Control expansion here
      onClick={(e) => {
        e.stopPropagation(); // Prevent toggling the node when clicking the button
        handleToggle(item.id);
      }}
      sx={{ 
        '& .MuiTreeItem-content': {
          paddingLeft: `${level * 24}px`, // Adjust padding based on level
        },
        '& .MuiTreeItem-group': {
          marginLeft: `${level * 24}px`, // Adjust margin between parent and subcomponents
        },
        // Hide chevrons if no subcomponents
        '& .MuiTreeItem-group': {
          display: hasSubcomponents ? 'block' : 'none', // Hide the chevron when no subcomponents
        },
      '& .MuiTreeItem-iconContainer': {
      display: hasSubcomponents ? 'block' : 'none', // Show icons only if there are subcomponents
    },
      }}
    >
      <Component item={item} onDelete={deleteComponent} />
      
      {hasSubcomponents && isExpanded && item.subcomponents.map((subitem) => (
        <MyTreeItem
          key={subitem.id}
          item={subitem}
          addSubComponent={addSubComponent}
          deleteComponent={deleteComponent}
          expandedNodes={expandedNodes}    // Make sure this is passed
          setExpandedNodes={setExpandedNodes} // Make sure this is passed
          level={level + 1} // Increment level for child items
        />
      ))}
    </TreeItem>

);


};




const NEWBasicSimpleTreeView = ({ product_id, components }) => {
  const [data, setData] = useState([...components]); // Ensure it's a new reference
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(null);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);

  // Automatically expand all nodes on load
  useEffect(() => {
    const extractAllComponentIds = (components) => {
      let ids = [];
      const traverse = (component) => {
        ids.push(component.id);
        if (component.subcomponents && component.subcomponents.length > 0) {
          component.subcomponents.forEach(subcomponent => traverse(subcomponent));
        }
      };
      components.forEach(component => traverse(component));
      return ids;
    };

    if (components.length > 0) {
      const allComponentIds = extractAllComponentIds(components);
      setExpandedNodes(allComponentIds); // Set expandedNodes to all IDs
    }
  }, [components]);

  useEffect(() => {
    // Uncomment if you need to log expanded nodes updates
    // console.log('Expanded Nodes Updated:', expandedNodes);
  }, [expandedNodes]);

  const handleOpenModal = (parentId) => {
    setCurrentParentId(parentId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentParentId(null);
  };

  const handleCreated = (response) => {
    const newComponent = response.data.props;

    setData((prevData) => {
      const addSubItemRecursively = (items) => {
        return items.map((item) => {
          if (item.id === newComponent.parent_id) {
            // Check if the parent is not already in expandedNodes and update state if needed
            /*
            if (!expandedNodes.includes(item.id)) {
              setExpandedNodes((prevExpanded) => {
                if (!prevExpanded.includes(item.id)) {
                  return [...prevExpanded, item.id];
                }
                return prevExpanded;
              });
            }
            */
            return {
              ...item,
              subcomponents: [...item.subcomponents, newComponent], // Add new subcomponent
            };
          } else if (item.subcomponents && item.subcomponents.length > 0) {
            return {
              ...item,
              subcomponents: addSubItemRecursively(item.subcomponents),
            };
          }
          return item;
        });
      };

      return [...addSubItemRecursively(prevData)]; // Return new reference to trigger re-render
    });

    // Add the new component's parent to expandedNodes
    if (!expandedNodes.includes(newComponent.parent_id)) {
      setExpandedNodes((prevExpanded) => [...prevExpanded, newComponent.parent_id]);
    }
  };

  const handleDeleteComponent = (componentId) => {
    setDeleteComponentId(componentId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/delete/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.delete(`${url}${deleteComponentId}/`, options);

      setData((prevData) => {
        const removeItemRecursively = (items) => {
          return items
            .filter(item => item.id !== deleteComponentId)
            .map(item => ({
              ...item,
              subcomponents: removeItemRecursively(item.subcomponents),
            }));
        };

        return [...removeItemRecursively(prevData)]; // Return new reference to trigger re-render
      });

      setExpandedNodes((prevExpanded) =>
        prevExpanded.filter((id) => id !== deleteComponentId)
      );
      setConfirmDialogOpen(false);
    } catch (error) {
      console.log('Error deleting component: ', error);
      setConfirmDialogOpen(false);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteComponentId(null);
  };

  return (
    <Box>
      <CreateComponentModal
        product_id={product_id}
        parent_id={currentParentId}
        api="/products/component/create/"
        open={modalOpen}
        onClose={handleCloseModal}
        onCreated={handleCreated}
      />

      {data.length === 0 ? (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenModal(null)} // Create root component
        >
          Add Root Component
        </Button>
      ) : (
        <SimpleTreeView
          expanded={expandedNodes} // Expand all nodes by default
          onNodeToggle={(event, nodeIds) => setExpandedNodes(nodeIds)} // Keep track of expanded nodes
        >
          {data.map((item) => (
            <MyTreeItem
              key={item.id}
              item={item}
              addSubComponent={(parentId) => handleOpenModal(parentId)}
              deleteComponent={handleDeleteComponent}
            />
          ))}
        </SimpleTreeView>
      )}

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this component?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};




const BasicSimpleTreeView = ({ product_id, components, refetch, canChange }) => {
  
  const [data, setData] = useState(components);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentParentId, setCurrentParentId] = useState(null);
  const [deleteComponentId, setDeleteComponentId] = useState(null);
  const [expandedNodes, setExpandedNodes] = useState([]);

  useEffect(() => {
    setData(components);
  }, [components]); // Re-run when 'components' changes

  useEffect(() => {
//    console.log('Expanded Nodes Updated:', expandedNodes);
  }, [expandedNodes]);

  const handleOpenModal = (parentId) => {
    setCurrentParentId(parentId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentParentId(null);
  };

  const handleCreated = (response) => {
    const newComponent = response.data.props;
  
    setData((prevData) => {
      // Ensure that expandedNodes is updated after adding the new component
      const addSubItemRecursively = (items) => {
        return items.map((item) => {
          if (item.id === newComponent.parent_id) {
            // Check if the parent is not already in expandedNodes and update state if needed
            /*
            if (!expandedNodes.includes(item.id)) {
              // Use a callback to ensure expandedNodes is updated correctly
              setExpandedNodes((prevExpanded) => {
                if (!prevExpanded.includes(item.id)) {
                  return [...prevExpanded, item.id];
                }
                return prevExpanded;
              });
            }
            */
            return {
              ...item,
              subcomponents: [...item.subcomponents, newComponent], // Add new subcomponent
            };
          } else if (item.subcomponents && item.subcomponents.length > 0) {
            return {
              ...item,
              subcomponents: addSubItemRecursively(item.subcomponents),
            };
          }
          return item;
        });
      };
  
      // Return updated data which triggers re-render
      return addSubItemRecursively(prevData);
    });
  };
  
  
  const handleDeleteComponent = (componentId) => {
    setDeleteComponentId(componentId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/delete/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.delete(`${url}${deleteComponentId}/`, options);
  
      // Remove the component from the data
      setData((prevData) => {
        const removeItemRecursively = (items) => {
          return items
            .filter(item => item.id !== deleteComponentId)
            .map(item => ({
              ...item,
              subcomponents: removeItemRecursively(item.subcomponents),
            }));
        };
  
        return removeItemRecursively(prevData);
      });
  
      // Remove the deleted node from expandedNodes
      setExpandedNodes((prevExpanded) =>
        prevExpanded.filter((id) => id !== deleteComponentId)
      );
  
      setConfirmDialogOpen(false);
    } catch (error) {
      console.log('Error deleting component: ', error);
      setConfirmDialogOpen(false);
    }
  };
    
  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteComponentId(null);
  };

  return (
    <Box >
      <CreateComponentModal
        product_id={product_id}
        parent_id={currentParentId}
        api="/products/component/create/"
        open={modalOpen}
        onClose={handleCloseModal}
        onCreated={handleCreated}
      />

      {canChange && data.length === 0 ? (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleOpenModal(null)} // Create root component
        >
          Add Root Component
        </Button>
      ) : (
        <SimpleTreeView
        >
        {data.map((item) => (
          <MyTreeItem
            key={item.id}
            item={item}
            addSubComponent={(parentId) => handleOpenModal(parentId)}
            deleteComponent={handleDeleteComponent}
            expandedNodes={expandedNodes}    // Make sure this is passed
            setExpandedNodes={setExpandedNodes} // Make sure this is passed
            canChange={canChange}
          />
        ))}
        </SimpleTreeView>
      )}

      <Dialog
        open={confirmDialogOpen}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this component?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default BasicSimpleTreeView;
