
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StartButton, PauseButton, ResumeButton, CompleteButton, RejectButton, ToolBadge  } from './OperatorButtons'; 
import { getToken } from '../../utils/common';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

/*
function StartButton({OperatorID, BatchID, ComponentID, SequenceID, KitID, tools, ExpectedTime}) {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = {};
      const now = new Date();
      request["Started"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["KitID"] =KitID;
      request["tools"] =tools;
      request["ExpectedTime"] =ExpectedTime;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

//      console.log("REQUEST", request);

      const response = await fetch(Api+"/api/start_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
      //     console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Avvia'}
      </button>
    </div>
  );
};

const PauseButton = ({OperatorID, BatchID, ComponentID, SequenceID}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["Event"] ='pause';

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

//      console.log("REQUEST", request);

      const response = await fetch(Api+"/api/pause_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
      //     console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Pausa'}
      </button>
    </div>
  );
};

const ResumeButton = ({OperatorID, BatchID, ComponentID, SequenceID}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["Event"] ='resume';

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

//      console.log("REQUEST", request);

      const response = await fetch(Api+"/api/resume_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
      //     console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Riprendi'}
      </button>
    </div>
  );
};

function CompleteButton({OperatorID, BatchID, ComponentID, SequenceID, KitID, tools, ExpectedTime}) {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {

      setIsLoading(true);
      const request = {};
      const now = new Date();
      request["Completed"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["tools"] =tools;
      request["ExpectedTime"] =ExpectedTime;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/complete_task/", options);
//      console.log("COMPLETED", response)
      const data = await response.json();
      setResponseData(data);
      window.location.reload();

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Completa'}
      </button>

    </div>
  );
};

function RejectButton({OperatorID, BatchID, ComponentID, SequenceID, KitID, tools}) {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const request = {};
      const now = new Date();
      request["Rejected"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["KitID"] =KitID;
      request["tools"] =tools;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/reject_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Rifiuta'}
      </button>
    </div>
  );
};

function AssignToolBadge(props) {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = Object.create(Object.getPrototypeOf(props));
      Object.assign(request, props);
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();

      if (request == {}) return;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/assign_tool/", options);
      const data = await response.json();
      setResponseData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
      <>
      <span onClick={props.isBeingUsed == 0 ? fetchData : null} disabled={isLoading} className = "badge fst-italic" style = {{backgroundColor: `${props.isBeingUsed == 0 ? 'green' : 'red'}`}}>
        {props.ToolName} {props.ToolID}
      </span>
      </>
  );
}

const ToolBadge = ({tool, OperatorID}) => {
  
  var bgcolor = tool.AssignedOperator ? "red" : "green";
  var textcolor = tool.AssignedOperator ? "black" : "white";

  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const request = {};
      const now = new Date();

      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] = OperatorID;
      request["ToolID"] = tool.ToolID;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/assign_tool/", options);
      const data = await response.json();
      setResponseData(data);
      //console.log(data)
      window.location.reload();

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return <>
    <span 
      className ="badge fst-italic" 
      style = {{backgroundColor: bgcolor, color: textcolor}}
      onClick={tool.AssignedOperator ? null : fetchData}    
    >
      <small>{tool.ToolID} {tool.ToolName}</small>
    </span>
  </>
};
*/

const KitBadge = ({BatchID, BatchCode, KitID, OperatorID, AssignedOperator}) => {
  
  var bgcolor = (AssignedOperator !== OperatorID) && AssignedOperator !== null ? "red" : "green";
  var textcolor = (AssignedOperator !== OperatorID) && AssignedOperator !== null ? "black" : "white";


  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    if (!(AssignedOperator == null)) return
    try {
      setIsLoading(true);

      const request = {};
      const now = new Date();

      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] = OperatorID;
      request["BatchID"] = BatchID;
      request["KitID"] = KitID;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/assign_kit/", options);
      const data = await response.json();
      setResponseData(data);
      //console.log(data)
      window.location.reload();

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return <>
    <span 
      className ="badge fst-italic" 
      style = {{backgroundColor: bgcolor, color: textcolor}}
      onClick={fetchData}    
    >
      <small>{BatchCode}/{KitID}</small>
    </span>
  </>
};

const MyRow = ({operator}) =>  {

  //console.log(operator);

  var bg = "";
  var bgcolor = "white";
  var textcolor = "black";

  /*
  if(operator.OperatorStatus == "WorkingTask") {bgcolor = "green"; textcolor = "white";}
  else if(operator.OperatorStatus == "AssignedTasks") {bgcolor = "#ADD8E6"; textcolor = "black";}
  */

  var OperatorSkillsBadges =[]
  var os = operator.OperatorSkills;
  os = JSON.parse(os);
//  var os = JSON.parse("{".concat(operator.OperatorSkills, "}"))
  //console.log(os);
  for (var e in os) if (os[e] > 0) OperatorSkillsBadges.push(<><span className = "badge bg-secondary mx-1"><small>{e}: {os[e]}</small></span></>);

//  var _s1 = <><p className="my-0">{operator.JobID}</p><p className = "my-0">{operator.JobQuantity} x {operator.ProductID} {operator.ProductName}</p></>

  /*

  var lock_start = false;
  if (!(operator.ToolClassName == null) && operator.ToolID == null) lock_start = true;

  var AvailableTools = [];
  for (var _t in operator.AvailableTools) {
      AvailableTools.push(
        <>
      <AssignToolBadge OperatorID = {operator.OperatorID} TaskID = {operator.TaskID} ToolID = {operator.AvailableTools[_t]["ToolID"]} ToolName = {operator.AvailableTools[_t]["ToolName"]} isBeingUsed = {operator.AvailableTools[_t]["isBeingUsed"]}/>
      </>
      )
  }

  if (operator.OrderID == null) var _s1 =<></>;

  if (operator.ComponentName == null) var _s2 =<></>;
  else {

    var _s2 = []
    var badges = []
    var ts = operator.hasOwnProperty('TaskSkills') ? JSON.parse("{".concat(operator.TaskSkills, "}")) : null

    for (var e in ts) if (ts[e] > 0) badges.push(<><span className = "badge bg-secondary fst-italic"><small>{e}: {ts[e]}</small></span></>);

    var toolclassbadge = <></>;

    if(operator.isWorkedOn == 0) {

      if (!(operator.ToolClassName == null))
        {
        if ((operator.ToolID == null))
          toolclassbadge =<><p className="my-0">{AvailableTools}</p></>;
        }  

    }
    else {

  }

    _s2.push(<>
    <p className="my-0">{operator.ComponentName} {badges}</p>
    <p className="my-0">{operator.SequenceName} ({operator.TaskID})</p> 
    {toolclassbadge}
    <p className="my-0">Prossimo: {operator.NextTask}</p>
    </>);
  }

  var completion = (100 * operator.WorkedTime / operator.ExpectedTime).toFixed(0);

  var _s4 = <>{completion}%</>;
  if (operator.SequenceName == null) var _s4 =<></>;

  var t = operator.OperatorWorkedTime;
  var h = Math.floor(t/3600);
  var m = Math.floor((t - 3600 * h) / 60);
  var s = Math.floor(t -3600 * h - m * 60);
  if (h < 10) h = <>0{h}</>;
  if (m < 10) m = <>0{m}</>;
  if (s < 10) s = <>0{s}</>;

  var wt = <>{h}:{m}:{s}</>;

  var ct = <></>
  
  const _status = operator.OperatorStatus.split(";");


  var ss = [];

  if (_status.length == 1) ss = _status[0];
  else {
    var missingskillsbadges = []
    var _ss2 = JSON.parse(_status[1])
    for (var o in _ss2)  missingskillsbadges.push(<><span className="badge mx-1 bg-danger">{o}: {_ss2[o]}</span></>)
    ss.push(<><p className="my-1">{_status[0]} {missingskillsbadges}</p></>)
  }

  if (operator.ToolID == null) var toolbadge = <></>;
  else var toolbadge = <><span className ="badge bg-primary fst-italic"><small>{operator.ToolID} {operator.ToolName}</small></span></>;

*/



var hasTask = operator.hasOwnProperty('task') && operator.task;
if(hasTask && operator.task.isPaused == 1) {bgcolor = "yellow"; textcolor = "black";}
else if(hasTask && operator.task.isWorkedOn == 1) {bgcolor = "green"; textcolor = "white";}
else if(hasTask) {bgcolor = "#ADD8E6"; textcolor = "black";}


return (
    <tr key = {operator.id}>
    <td style = {{backgroundColor: bgcolor, color: textcolor}}>
      <p className="my-0">{operator.OperatorID} {operator.OperatorName}</p>
      <p className="my-0">{OperatorSkillsBadges}</p>
      {/*
      <p className="my-0 fst-italic fs-6"><small>{ss}</small></p>*/}
    </td>
    <td>
    <p className="my-0">{hasTask && <span>{operator.task.ProductName} ({operator.task.ProductID})</span> }</p>
    <p className="my-0"><b>{hasTask && operator.task.BatchCode}</b></p>
    <p className="my-0">{operator.assigned_tasks.map((item)=>{return (<>
      <span className ="badge fst-italic"
            style = {{backgroundColor : `${item.isWorkedOn == 1 ? 'green' : 'grey'}`}}
      >
        <small>{item.BatchCode}-{item.ComponentID}-{item.SequenceID}</small>
      </span></>);})}
    </p>

    </td>
    <td>
      <p className="my-0">{operator.kits.map((kit) => {return <><span className ="badge bg-secondary fst-italic" onClick = {() => {window.open('/kits', '_blank');}} style ={{cursor: 'pointer'}}><small>{kit.BatchCode}/{kit.KitID}</small></span></>} )}</p>
      <p className="my-0">{operator.tools.map((tool) => {return <><span className ="badge bg-primary fst-italic" onClick = {() => {window.open('/tools/tool_classes/'+tool.ToolClassID, '_blank');}} style ={{cursor: 'pointer'}}><small>{tool.ToolID} {tool.ToolName}</small></span></>} )}</p>
    </td>
    <td>
    <p className="my-0">{hasTask && <span>{operator.task.ComponentName} ({operator.task.ComponentID})</span>}</p>
    <p className="my-0"><b>{hasTask && <span>{operator.task.TaskQuantity} x {operator.task.SequenceName}</span>}</b></p>
    <p className="my-0">{hasTask && operator.task.skills.map((skill)=>{return <><><span className ="badge bg-secondary fst-italic"><small>{skill.Skill}: {skill.SkillValue}</small></span></></>})}</p>
    </td>

    <td>
    <p className={`mt-1 mb-2 ${hasTask && operator.task.isPossible && operator.task.tool_ok && operator.task.isWorkedOn == 0 && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      {hasTask ?  <StartButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      KitID = {operator.task.KitID}  
      tools = {operator.tools}
      ExpectedTime = {operator.task.ExpectedTime}
      /> : null }
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isWorkedOn == 1 && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      { hasTask ? <CompleteButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      KitID = {operator.task.KitID}  
      tools = {operator.tools}
      ExpectedTime = {operator.task.ExpectedTime}
      /> : null}     
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      {hasTask ? <RejectButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      KitID = {operator.task.KitID}  
      tools = {operator.tools}
      /> : null }     
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isPossible && operator.task.tool_ok && operator.task.isWorkedOn == 1 && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      {hasTask ?  <PauseButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      /> : null }
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isPossible && operator.task.tool_ok && operator.task.isWorkedOn == 1 && operator.task.isPaused == 1 ? 'd-all' : 'd-none'}`}>
      {hasTask ?  <ResumeButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      /> : null }
      </p>

    {hasTask && <p className={`mt-1 mb-2 ${!operator.task.tool_ok ? 'd-all' : 'd-none'}`}>{operator.task.available_tools.map((tool) => <ToolBadge tool = {tool} OperatorID={operator.OperatorID}/>)}</p> }

    </td>
    <td style={{backgroundColor: `${hasTask && operator.task.Completion > 100 ? 'yellow' : ''}` }}>
    <p className="my-0">{hasTask &&  operator.task.Started}</p>
    <p className="my-0">{hasTask &&  operator.task.hasOwnProperty('ActualTime_hhmm') ? operator.task.ActualTime_hhmm + ' / ' +operator.task.ExpectedTime_hhmm : null}</p>
    <p className="my-0">{hasTask &&  operator.task.hasOwnProperty('ActualTime_hhmm') ? operator.task.Completion + '%' : null}</p>

    </td>

    </tr>
    );

/*

    <td><p className="my-0">{operator.KitName}</p><p className="my-0">{toolbadge}</p></td>
    <td>{_s2}</td>
    <td>
      <p className={`mt-1 mb-2 ${!lock_start && operator.isWorkedOn == 0 ? 'd-all' : 'd-none'}`}><StartButton OperatorID = {operator.OperatorID} TaskID = {operator.TaskID}  KitID = {operator.KitID}  ToolID = {operator.ToolID}/></p>
<      <p className={`mb-2 ${operator.isWorkedOn == 1 ? 'd-all' : 'd-none'}`}><CompleteButton OperatorID = {operator.OperatorID} TaskID = {operator.TaskID}  KitID = {operator.KitID}  ToolID = {operator.ToolID}/></p>      
      <p className={`mb-1 ${operator.TaskID == null ? 'd-none' : 'd-all'}`}><RejectButton  OperatorID = {operator.OperatorID} TaskID = {operator.TaskID}  KitID = {operator.KitID}  ToolID = {operator.ToolID}/></p></td>
    <td style={{backgroundColor: `${operator.Completion > 100 ? 'yellow' : ''}` }}>
      <p className="my-0">{operator.Started}</p>
      <p className="my-0">{!(operator.Elapsed == null) ? Math.max(0, operator.Elapsed) +'/'+ operator.ExpectedTime +' '+Math.max(0,operator.Completion)+'%': ''}</p>
    </td>

    return(<></>);


return (<><p>{operator.OperatorName}</p></>);
*/
};

const MyRow2 = ({operator}) =>  {

  let { JobID } = useParams();

  var bg = "";
  var bgcolor = "white";
  var textcolor = "black";

  var OperatorSkillsBadges = operator.OperatorSkills.map((skill) => <><span className = "badge bg-secondary mx-1"><small>{skill.Skill} {skill.SkillValue}</small></span></>);
//  var os = operator.OperatorSkills;
//  os = JSON.parse(os);

//  for (var e in os) if (os[e] > 0) OperatorSkillsBadges.push(<><span className = "badge bg-secondary mx-1"><small>{e}: {os[e]}</small></span></>);

  var hasTask = operator.hasOwnProperty('task') && operator.task;
  if(hasTask && operator.task.isPaused == 1) {bgcolor = "yellow"; textcolor = "black";}
  else if(hasTask && operator.task.isWorkedOn == 1) {bgcolor = "green"; textcolor = "white";}
  else if(hasTask) {bgcolor = "#ADD8E6"; textcolor = "black";}

//  console.log(operator.OperatorID, operator.working_on_some_task)
//  console.log(operator)
  
function releaseKit(BatchID, KitID, using_kit) {
  
  if(using_kit == 1) return;
  
  if (!confirm("Rilascia Kit?")) return;

  try {

   const url = process.env.REACT_APP_BACKEND_URL;
   const api = '/api/release_kit/';

   const request = {
     BatchID: BatchID,
     KitID: KitID
   }

//   console.log(request);

   const options = {
       headers: {
         'Content-Type': 'application/json',
         'Authorization': getToken(), 
       },
     }

     axios.post(`${url}${api}`, request, options).then(
       response => {
//           console.log("RESPONSE", response);
         window.location.reload();
       }
   ).catch(
       error => {
           //setLoading(false);
           console.log(error);
       }
   );


 } catch (error) {console.log('Error fetching data: '+ error)
 } finally {
   //setLoading(false);
 }
};

function assignTask() {

  if (!confirm("Assegna task?")) return;

  try {

   const url = process.env.REACT_APP_BACKEND_URL;
   const api = '/api/jobs/ask-for-new-task/';

   const request = {
     AllJobs: false,
     jobs: [JobID],
     OperatorID: operator.OperatorID
   }

   console.log(request);

   const options = {
       headers: {
         'Content-Type': 'application/json',
         'Authorization': getToken(), 
       },
     }

     axios.post(`${url}${api}`, request, options).then(
       response => {
//           console.log("RESPONSE", response);
         window.location.reload();
       }
   ).catch(
       error => {
           //setLoading(false);
           console.log(error);
       }
   );


 } catch (error) {console.log('Error fetching data: '+ error)
 } finally {
   //setLoading(false);
 }

};

return (

  <>
    <div className='row'>
    <div className='my-1 border border-solid border-4 col-12'>
      <div className='row'>
        <div className='panel border col-12 p-1' style={{backgroundColor: "#ADD8E6"}}>
        <span className="m-1">{operator.OperatorID}</span>
        <span className="m-1"><b><big>{operator.OperatorName}</big></b></span>

        <span className="m-1">{OperatorSkillsBadges}</span>

        <span className="m-1">{operator.kits.map((kit) => {return <><span className ="badge bg-primary fst-italic mx-1" onClick={() => {releaseKit(kit.BatchID, kit.KitID, operator.using_kit)}}><small>{kit.BatchCode}/{kit.KitID}</small></span></>} )}</span>

        <span className="m-1">{operator.tools.map((tool) => {return <><span className ="badge bg-primary fst-italic"><small>{tool.ToolID} {tool.ToolName}</small></span></>} )}</span>

        <span className="m-1"><FontAwesomeIcon icon={faPlus} onClick={assignTask} style={{ cursor: 'pointer' }} /></span>

        </div>
      </div>
    {operator.assigned_tasks.map((task) => {

      var _bg = 'white'
      var _col = 'black'
      if (task.working_on_this_task) {_bg='green'; _col='white'}

      return(
      <>
      <div className='row border'>

      <div className = "panel col-3" style={{backgroundColor: _bg, color:_col}}>
        <p className="my-0"><span>{task.ProductName} ({task.ProductID})</span></p>
        <p className="my-0"><b>{task.BatchCode}</b></p>
      </div>
      <div className = "panel col-5"  style={{backgroundColor: _bg, color:_col}}>
        <p className="my-0">{<span>{task.ComponentName} ({task.ComponentID})</span>}</p>
        <p className="my-0"><b>{<span>{task.TaskQuantity} x {task.SequenceName}</span>}</b></p>
        {<p className="my-0">{task.skills.map((skill)=>{return <><span className ="badge bg-secondary fst-italic"><small>{skill.Skill}: {skill.SkillValue}</small></span></>})}</p>}
      </div>
      <div className = "panel col-2" >
        <p className={`mt-1 mb-2 ${!operator.working_on_BatchID && task.isPossible && task.kit_ok && task.tool_ok && task.isWorkedOn == 0 && task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
        <StartButton 
        OperatorID = {operator.OperatorID} 
        BatchID = {task.BatchID}  
        ComponentID = {task.ComponentID}  
        SequenceID = {task.SequenceID}  
        KitID = {task.KitID}  
        tools = {operator.tools}
        ExpectedTime = {task.ExpectedTime}
        />
        </p>
        <p className={`mt-1 mb-2 ${task.isWorkedOn == 1 && task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
        <CompleteButton 
        OperatorID = {operator.OperatorID} 
        BatchID = {task.BatchID}  
        ComponentID = {task.ComponentID}  
        SequenceID = {task.SequenceID}  
        KitID = {task.KitID}  
        tools = {operator.tools}
        ExpectedTime = {task.ExpectedTime}
        />    
        </p>
        <p className={`mt-1 mb-2 ${task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
        <RejectButton 
        OperatorID = {operator.OperatorID} 
        BatchID = {task.BatchID}  
        ComponentID = {task.ComponentID}  
        SequenceID = {task.SequenceID}  
        KitID = {task.KitID}  
        tools = {operator.tools}
        /> 
        </p>
        <p className={`mt-1 mb-2 ${task.isPossible && task.tool_ok && task.isWorkedOn == 1 && task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
        <PauseButton 
        OperatorID = {operator.OperatorID} 
        BatchID = {task.BatchID}  
        ComponentID = {task.ComponentID}  
        SequenceID = {task.SequenceID}  
        />
        </p>
        <p className={`mt-1 mb-2 ${task.isPossible && task.tool_ok && task.isWorkedOn == 1 && task.isPaused == 1 ? 'd-all' : 'd-none'}`}>
        <ResumeButton 
        OperatorID = {operator.OperatorID} 
        BatchID = {task.BatchID}  
        ComponentID = {task.ComponentID}  
        SequenceID = {task.SequenceID}  
        />
        </p>
        {<p className={`mt-1 mb-2 ${!task.tool_ok ? 'd-all' : 'd-none'}`}>{task.available_tools.map((tool) => <ToolBadge tool = {tool} OperatorID={operator.OperatorID}/>)}</p> }
        {<p className={`mt-1 mb-2 ${!task.kit_ok ? 'd-all' : 'd-none'}`}><KitBadge BatchID = {task.BatchID} BatchCode = {task.BatchCode} KitID = {task.KitID} OperatorID={operator.OperatorID} AssignedOperator={task.kit_assigned}/></p> }

      </div>

      <div className = "panel col-2" style={{backgroundColor: `${task.Completion > 100 ? 'yellow' : ''}` }}>
        <p className="my-0">{task.Started}</p>
        <p className="my-0">{task.hasOwnProperty('ActualTime_hhmm') ? task.ActualTime_hhmm + ' / ' +task.ExpectedTime_hhmm : null}</p>
        <p className="my-0">{task.hasOwnProperty('ActualTime_hhmm') ? task.Completion + '%' : null}</p>
      </div>

      </div>

      </>
    );



    })}    
      </div>
      </div>

    {/*<tr key = {operator.id}>
    <td style = {{backgroundColor: bgcolor, color: textcolor}}>
      <p className="my-0">{operator.OperatorID} {operator.OperatorName}</p>
      <p className="my-0">{OperatorSkillsBadges}</p>
    </td>
    <td>
    <p className="my-0">{hasTask && <span>{operator.task.ProductName} ({operator.task.ProductID})</span> }</p>
    <p className="my-0"><b>{hasTask && operator.task.BatchCode}</b></p>
    <p className="my-0">{operator.assigned_tasks.map((item)=>{return (<>
      <span className ="badge fst-italic"
            style = {{backgroundColor : `${item.isWorkedOn == 1 ? 'green' : 'grey'}`}}
      >
        <small>{item.BatchCode}-{item.ComponentID}-{item.SequenceID}</small>
      </span></>);})}
    </p>

    </td>
    <td>
      <p className="my-0">{operator.kits.map((kit) => {return <><span className ="badge bg-secondary fst-italic" onClick = {() => {window.open('/kits', '_blank');}} style ={{cursor: 'pointer'}}><small>{kit.BatchCode}/{kit.KitID}</small></span></>} )}</p>
      <p className="my-0">{operator.tools.map((tool) => {return <><span className ="badge bg-primary fst-italic" onClick = {() => {window.open('/tools/tool_classes/'+tool.ToolClassID, '_blank');}} style ={{cursor: 'pointer'}}><small>{tool.ToolID} {tool.ToolName}</small></span></>} )}</p>
    </td>
    <td>
    <p className="my-0">{hasTask && <span>{operator.task.ComponentName} ({operator.task.ComponentID})</span>}</p>
    <p className="my-0"><b>{hasTask && <span>{operator.task.TaskQuantity} x {operator.task.SequenceName}</span>}</b></p>
    <p className="my-0">{hasTask && operator.task.skills.map((skill)=>{return <><><span className ="badge bg-secondary fst-italic"><small>{skill.Skill}: {skill.SkillValue}</small></span></></>})}</p>
    </td>

    <td>
    <p className={`mt-1 mb-2 ${hasTask && operator.task.isPossible && operator.task.tool_ok && operator.task.isWorkedOn == 0 && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      {hasTask ?  <StartButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      KitID = {operator.task.KitID}  
      tools = {operator.tools}
      ExpectedTime = {operator.task.ExpectedTime}
      /> : null }
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isWorkedOn == 1 && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      { hasTask ? <CompleteButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      KitID = {operator.task.KitID}  
      tools = {operator.tools}
      ExpectedTime = {operator.task.ExpectedTime}
      /> : null}     
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      {hasTask ? <RejectButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      KitID = {operator.task.KitID}  
      tools = {operator.tools}
      /> : null }     
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isPossible && operator.task.tool_ok && operator.task.isWorkedOn == 1 && operator.task.isPaused == 0 ? 'd-all' : 'd-none'}`}>
      {hasTask ?  <PauseButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      /> : null }
      </p>
      <p className={`mt-1 mb-2 ${hasTask && operator.task.isPossible && operator.task.tool_ok && operator.task.isWorkedOn == 1 && operator.task.isPaused == 1 ? 'd-all' : 'd-none'}`}>
      {hasTask ?  <ResumeButton 
      OperatorID = {operator.OperatorID} 
      BatchID = {operator.task.BatchID}  
      ComponentID = {operator.task.ComponentID}  
      SequenceID = {operator.task.SequenceID}  
      /> : null }
      </p>

    {hasTask && <p className={`mt-1 mb-2 ${!operator.task.tool_ok ? 'd-all' : 'd-none'}`}>{operator.task.available_tools.map((tool) => <ToolBadge tool = {tool} OperatorID={operator.OperatorID}/>)}</p> }

    </td>
    <td style={{backgroundColor: `${hasTask && operator.task.Completion > 100 ? 'yellow' : ''}` }}>
    <p className="my-0">{hasTask &&  operator.task.Started}</p>
    <p className="my-0">{hasTask &&  operator.task.hasOwnProperty('ActualTime_hhmm') ? operator.task.ActualTime_hhmm + ' / ' +operator.task.ExpectedTime_hhmm : null}</p>
    <p className="my-0">{hasTask &&  operator.task.hasOwnProperty('ActualTime_hhmm') ? operator.task.Completion + '%' : null}</p>

    </td>

    </tr>
  */}
</>
  );
};








const OperatorsTable = () => {
  
  let { ProductID, JobID } = useParams();
//  console.log(ProductID, JobID)

//  const apiUrl = process.env.REACT_APP_API_URL;
//  const url = apiUrl + '/api/products/'+ ProductID +'/jobs/'+ JobID +'/operators/'   
  const url = `${process.env.REACT_APP_API_URL}/api/jobs/${JobID}/operators/`;
  var request = {};
  const now = new Date();
  request["Now"] = now.toLocaleString('sv');
  request["UTCoffset"] = now.getTimezoneOffset();

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };


  const [operators, setOperators] = useState([]);
  const [job, setJob] = useState({});

  const update = () => {
    fetch(url, options)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
    console.log("DATA", data);
    setOperators(data["operators"]);
    setJob(data["job"]);

  })
    .catch(rejected => {
    console.log(rejected);
  });
  } 

  useEffect(() => {
    update();
    const intervalId = setInterval(update, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
    <div className='row'>

      <div className = "panel col-3" style = {{backgroundColor: 'black', color: 'white'}}><p className="my-0">Prodotto</p><p className="my-0">Batch</p></div>
      <div className = "panel col-5" style = {{backgroundColor: 'black', color: 'white'}}><p className="my-0">Componente</p><p className="my-0">Task</p></div>
      <div className = "panel col-2" style = {{backgroundColor: 'black', color: 'white'}}>Azioni</div>
      <div className = "panel col-2" style = {{backgroundColor: 'black', color: 'white'}}><p className="my-0">Started</p><p className="my-0">%</p></div>
    
    </div>

    <div>
    {operators.map((operator) => {return (<MyRow2 operator = {operator} />); } )}
    </div>
    </>
    );


  return (
    <>
    <div>
      { job.hasOwnProperty('ProductID') ? job.ProductID : null}
      { job.hasOwnProperty('ProductName') ? job.ProductName : null}
      { job.hasOwnProperty('JobQuantity') ? job.JobQuantity : null}

    </div>

    <div>
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead><tr className="table-dark">
      <th className = "col-2">Operatore</th>
      <th className = "col-2"><p className="my-0">Prodotto</p><p className="my-0">Batch</p></th>
      <th className = "col-2"><p className="my-0">Kit</p><p className="my-0">Tool</p></th>
      <th className = "col-3"><p className="my-0">Componente</p><p className="my-0">Task</p></th>
      <th className = "col-1">Azioni</th>
      <th className = "col-2"><p className="my-0">Started</p><p className="my-0">%</p></th>
      </tr>
    </thead>
    <tbody>{operators.map((operator) => {return (<MyRow2 operator = {operator} />); } )}</tbody>
    </table>
    </div>
    </>
    );


  };

export default OperatorsTable;