import { useState, useEffect, useRef } from 'react';
//import { Badge } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
//import { useNavigate, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'bootstrap';

import TabsMenu from './TabsMenu';
import './badge-content.css';
import DictTag from '../dictionaries/DictTag';
import { getToken } from '../utils/common';
import axios from 'axios';

const SelectOperator = ({TaskID, KitID, operators}) => {

  const [parameterValue, setParameterValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    console.log(event.target.checked);
  };

  useEffect(() => {
    setInitialValue(parameterValue);
  }, []);

  var options = operators.map((item) => {
    return (<option value = {item.OperatorID}>{item.OperatorName}</option>);
    })

    var o = <select
        value={parameterValue}
        onChange={handleChange}>
        {options}
        </select>;

  const updateParameter = async () => {

    var body =  { 
        TaskID: TaskID,
        OperatorID: parameterValue,
        KitID: KitID
    };

    try {
        
        console.log(body)

        setDisabled(true);
        const response = await fetch(process.env.REACT_APP_API_URL + '/api/assign_task/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }
      setInitialValue(parameterValue);
      setDisabled(false);
    } catch (error) {
        console.log(error.message);
      setErrorMessage('Error updating parameter: ' + error.message);
    }
  };

  return (<>
    {/*<div className="d-flex align-items-center">*/}
      {o}
      {((parameterValue !== initialValue)) && (
        <button onClick={updateParameter} className="btn btn-primary mx-1">
          Assign
        </button>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    {/*</div>*/}
    </>
  );

};

const OperatorRow = (props) =>  {

  var tasks = [];

  var skills = props.skills;
  //console.log(skills)
  var CurrentTask = <></>;

  for (var id in props.assigned_tasks)  {


    var TaskID = props.assigned_tasks[id]['TaskID'];
  //  var OrderID = props.assigned_tasks[id]['OrderID'];
    var ProductID = props.assigned_tasks[id]['ProductID'];
    var Quantity = props.assigned_tasks[id]['Quantity'];
    var isPossible = props.assigned_tasks[id]['isPossible'];
    var isWorkedOn = props.assigned_tasks[id]['isWorkedOn'];
    var ExpectedTime = props.assigned_tasks[id]['ExpectedTime'];
    var Started = props.assigned_tasks[id]['Started'];
    var ComponentName = props.assigned_tasks[id]['ComponentName'];
    var SequenceName = props.assigned_tasks[id]['SequenceName'];
    var KitName = props.assigned_tasks[id]['KitName'];
    var KitID = props.assigned_tasks[id]['KitID'];
    var KitBeingUsed = props.assigned_tasks[id]['KitBeingUsed'];
//    var TaskSkills = props.assigned_tasks[id]['Skills'];
    if (id === 0) CurrentTask = <>{Quantity} x {ProductID} {ComponentName} - {SequenceName} ({TaskID})</>;
    
    tasks.push(
      <tr>
      <td>{Quantity} x {ProductID} {ComponentName} - {SequenceName} ({TaskID})</td>
      <td>{KitName} {KitID} ({KitBeingUsed})</td>
      <td>{ExpectedTime}</td>
      <td>{isPossible}</td>
      <td>{isWorkedOn}</td>
      <td>{Started}</td>
    </tr>

      );
  }

  const SkillsComponent = ({skills}) => {
      
    var tags = [];

    for (var id in skills) {
      tags.push(<span className = "badge bg-primary mx-1">{id}: {skills[id]}</span>);
    }
    return(<>{tags}</>);
  }

  
  return (
    <Accordion.Item eventKey={props.OperatorID}>
    <Accordion.Header style ={{ width: "auto"}}>
    <div className="container m-0 p-0">
    <table className="table table-striped text-start table-fixed table-sm table-responsive-sm m-0 p-0">
    <thead><tr>
      <td className = "col-2 fst-italic fs-6"><small>Operatore</small></td>
      <td className = "col-3 fst-italic fs-6">Skills</td>
      <td className = "col-1 fst-italic fs-6">Assigned Tasks</td>
      <td className = "col-6 fst-italic fs-6">Current Task</td>
    </tr></thead>
    <tbody><tr>
      <td>{props.OperatorName} ({props.OperatorID})</td>
      <td><SkillsComponent skills={skills}/></td>
      <td>{props.AssignedTasks}</td>
      <td>{CurrentTask}</td>
    </tr></tbody>
    </table>
    </div>
    </Accordion.Header>
    <Accordion.Body style ={{ backgroundColor: "grey"}}>
    <div>
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
    <tr className="table-dark">
      <th className = "col-4">Task</th>
      <th className = "col-1">Kit</th>
      <th className = "col-1">ExpectedTime</th>
      <th className = "col-1">isPossible</th>
      <th className = "col-1">isWorkedOn</th>
      <th className = "col-1">Started</th>
    </tr></thead>
    <tbody>{tasks}</tbody>
    </table>
    </div>
    </Accordion.Body>
  </Accordion.Item>  
    );

};

const TasksTable = ({tasks, operators}) =>  {
    
    var rows = [];
    var counter = 0;

    const SkillsComponent = ({skills}) => {
      
      var tags = [];

      for (var id in skills) {
        tags.push(<span className = "badge bg-primary mx-1">{id}: {skills[id]}</span>);
      }
      return(<>{tags}</>);
    }

    for (var id in tasks)  {
      counter += 1;
      var TaskID = tasks[id]['TaskID'];
//      var OrderID = tasks[id]['OrderID'];
      var ProductID = tasks[id]['ProductID'];
      var Quantity = tasks[id]['Quantity'];
      var ExpectedTime = tasks[id]['ExpectedTime'];
      var ComponentName = tasks[id]['ComponentName'];
      var SequenceName = tasks[id]['SequenceName'];
      var KitName = tasks[id]['KitName'];
      var KitID = tasks[id]['KitID'];
      var KitBeingUsed = tasks[id]['KitBeingUsed'];
      var tskills =  tasks[id]['skills'];

      var kitbg = "";
      if (KitBeingUsed === 1) kitbg = "bg-danger text-white";
  
      rows.push(
      <tr>
        <td>{Quantity} x {ProductID} {ComponentName} - {SequenceName} ({TaskID})</td>
        <td className = {kitbg}>{KitName}</td>
        <td><SkillsComponent skills = {tskills}/></td>
        <td>{ExpectedTime}</td>
        <td>{!(KitBeingUsed === 1) && (<SelectOperator TaskID = {TaskID} KitID = {KitID} operators = {operators}/>)}</td>

      </tr>
  
        );
    }
  

    return (
    <div>
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
    <tr className="table-dark">
      <th className = "col-4">Task ({counter})</th>
      <th className = "col-1">Kit</th>
      <th className = "col-2">Skills</th>
      <th className = "col-1">ExpectedTime</th>
      <th className = "col-1">AssignTo</th>
    </tr></thead>
    <tbody>{rows}</tbody>
    </table>
    </div>
  );

};

const InputComponent = ({initialValue, setExternalValue}) => {
  // State to hold the value of the input
  const [inputValue, setInputValue] = useState(initialValue);

  // Function to handle input change
  const handleChange = (event) => {
    setInputValue(event.target.value);
    setExternalValue(event.target.value);
  };

  return (
    <div>
      {/* Input component */}
      <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>
      <input
        style = {{width: '60%'}}
        type="text"
        value={inputValue} // Bind input value to state
        onChange={handleChange} // Call handleChange function on change
      />
      {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
    </div>
  );
};

const AssignOperatorModal = ({ task, operator }) => {
  const modalRef = useRef(null);

  const handleShow = () => {
    const modal = new Modal(modalRef.current);
    modal.show();
  };

  const handleHide = () => {
    const modal = Modal.getInstance(modalRef.current);
    if (modal) {
      modal.hide();
    }
  };

  function assignTask() {

     try {

      const url = process.env.REACT_APP_BACKEND_URL;
      const api = '/api/assign_task/';

      const request = {
        task: task,
        operator: operator
      }

      console.log(request);

      const options = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(), 
          },
        }

        axios.post(`${url}${api}`, request, options).then(
          response => {
//              setLoading(false);
              console.log("ASSIGN", response);
//              console.log("DATA", response.data.data);
//              setData(response.data.data);
            handleHide();
          }
      ).catch(
          error => {
              //setLoading(false);
              console.log(error);
          }
      );


    } catch (error) {console.log('Error fetching data: '+ error)
    } finally {
      //setLoading(false);
    }
  }

  
  return (
    <>
      {/* Font Awesome icon that triggers the modal */}
      <FontAwesomeIcon icon={faPlus} onClick={handleShow} style={{ cursor: 'pointer' }} />

      {/* Modal component */}
      <div className='badge-content'>
      <div ref={modalRef} className="modal fade" tabIndex="-1" aria-labelledby="assignOperatorModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="assignOperatorModalLabel" style={{ color: 'black' }}>Assegna task</h5>
              <button type="button" className="btn-close" aria-label="Close" onClick={handleHide}></button>
            </div>
            <div className="modal-body">
            <div className="modal-text fst-normal card" style={{ color: 'black' }}>
            <div className="card" style={{width: '100%'}}>
                <div className="card-body">
                  <div className = 'row'>
                    <div className = 'panel col-4 border p-2'>
                      <div className = 'myTitle'style = {{fontSize: 16}}><DictTag tag = 'Task'/></div>
                    </div>
                    <div className = 'panel col-8 border p-2'>
                      <div className = 'myBody' style = {{fontSize: 24}}>{task.SequenceName}</div>
                    </div>
                  </div>
                  <div className = 'row'>
                    <div className = 'panel col-4 border p-2'>
                      <div className = 'myTitle'style = {{fontSize: 16}}><DictTag tag = 'Component'/></div>
                    </div>
                    <div className = 'panel col-8 border p-2'>
                      <div className = 'myBody' style = {{fontSize: 24}}>{task.ComponentName}</div>
                    </div>
                  </div>

                  <div className = 'row'>
                    <div className = 'panel col-4 border p-2'>
                      <div className = 'myTitle'style = {{fontSize: 16}}><DictTag tag = 'Product'/></div>
                    </div>
                    <div className = 'panel col-8 border p-2'>
                      <div className = 'myBody' style = {{fontSize: 24}}>{task.ProductName} ({task.ProductID})</div>
                    </div>
                  </div>
                  <div className = 'row'>
                    <div className = 'panel col-4 border p-2'>
                      <div className = 'myTitle'style = {{fontSize: 16}}><DictTag tag = 'Assign to'/></div>
                    </div>
                    <div className = 'panel col-8 border p-2'>
                      <div className = 'myBody' style = {{fontSize: 24}}><a href="#" onClick= {assignTask} className="btn btn-primary">{operator.OperatorName}</a></div>
                    </div>
                  </div>
                  
                </div>
              </div>
            {/*
            <pre style={{ backgroundColor: '#f6f8fa', padding: '1em', borderRadius: '5px', overflow: 'auto' }}>
            {JSON.stringify(operator, null, 2)}
            </pre>
            <pre style={{ backgroundColor: '#f6f8fa', padding: '1em', borderRadius: '5px', overflow: 'auto' }}>
            {JSON.stringify(task, null, 2)}
            </pre>
            */}

            </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={handleHide}>Close</button>
              {/*<button type="button" className="btn btn-primary" onClick={handleHide}>Save changes</button>*/}
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

const TaskRow = ({task, filters}) => {

  var visible = true;

  if (!(filters.filterProduct == '')) if 
    (!(
      (task.ProductID.toString().toUpperCase().includes(filters.filterProduct.toUpperCase())) 
      || (task.ProductName.toString().toUpperCase().includes(filters.filterProduct.toUpperCase()))
    )) 
      visible = false;

    if (!(filters.filterBatch == '')) if 
    (!(
      (task.BatchCode.toString().toUpperCase().includes(filters.filterBatch.toUpperCase())) 
      || (task.JobID.toString().toUpperCase().includes(filters.filterBatch.toUpperCase()))
      || (task.JobCode.toString().toUpperCase().includes(filters.filterBatch.toUpperCase()))

    )) 
      visible = false;
  
      if (!(filters.filterTask == '')) if 
      (!(
        (task.ComponentName.toString().toUpperCase().includes(filters.filterTask.toUpperCase())) 
        || (task.SequenceName.toString().toUpperCase().includes(filters.filterTask.toUpperCase()))
        || (task.ComponentID.toString().toUpperCase().includes(filters.filterTask.toUpperCase()))
        || (task.SequenceID.toString().toUpperCase().includes(filters.filterTask.toUpperCase()))
        || (task.BatchCode.toString().toUpperCase().includes(filters.filterTask.toUpperCase()))  
      )) 
        visible = false;
  

    if (!visible) return <></>;

  return (
    <>
      <tr key = {task.BatchID}>
        <td>
          <p className="my-0">{task.ProductID} {(task.ProductCode == null || task.ProductCode == '') ? null: '(' + task.ProductCode +')'}</p>
          <p className="my-0">{task.ProductName}</p>
        </td>
        <td>
          <p className="my-0"><b>{task.BatchCode}</b></p>
          <p className="my-0">{task.JobID} {task.JobCode}</p>
        </td>
        <td>
        <p className="my-0"><b>{task.BatchCode}-{task.ComponentID}-{task.SequenceID}</b>
        {task.Skills.map((skill) => <><span className ="badge bg-secondary fst-italic mx-2" ><small>{skill.Skill}:{skill.SkillValue}</small></span></>)}</p>
        <p className="my-0">{task.ComponentName} - {task.SequenceName}</p>

        </td>
        <td>
          <p className="my-0">
          {task.MandatedToolName} {task.ToolClassName}
          </p>
        </td>
        <td>
        <p className="my-0">
          <span 
            className ="badge fst-italic" 
            style = {{backgroundColor : `${task.KitIsBeingUsed == 1 ? 'red' : 'green'}`}}
          ><small>{task.BatchCode}/{task.KitID}</small></span></p>
        </td>
        <td>
        <p className="my-0">
          {task.possible_operators.map((operator) => <>
          <small><span 
            className = {`btn p-1 fst-italic m-1 ${operator.isWorking == 1 ? 'border border-5 border-danger' : ''}`}
            style = {{backgroundColor : `${operator.isSkilled == 1 ? 'grey' : 'white'}`, color : `${operator.isSkilled == 1 ? 'white' : 'black'}`}}
          ><small>{operator.OperatorName} ({operator.OperatorID})</small> <AssignOperatorModal task={task} operator={operator} /></span></small></>)}
          </p>
        </td>

      </tr>
    </>
  );


};

const PossibleTasks = ({AllJobs, jobs}) => {
      
 // let { JobID } = useParams();

 const [filterProduct, setFilterProduct] = useState('');
 const [filterBatch, setFilterBatch] = useState('');
 const [filterTask, setFilterTask] = useState('');
 const [filterTool, setFilterTool] = useState('');
 const [filterKit, setFilterKit] = useState('');
 const [filterOperator, setFilterOperator] = useState('');

 const filters = {
    'filterProduct': filterProduct,
    'filterBatch': filterBatch,
    'filterTask': filterTask,
    'filterTool': filterTool,
    'filterKit': filterKit,
    'filterOperator': filterOperator,
 }

 const url = `${process.env.REACT_APP_API_URL}/api/jobs/tasks-to-assign/`;

  var request = {
    'AllJobs' : AllJobs,
    'jobs' : jobs
  };

  const now = new Date();
  request["Now"] = now.toLocaleString('sv');
  request["UTCoffset"] = now.getTimezoneOffset();

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };


  const [data, setData] = useState([]);

  const update = () => {
    fetch(url, options)
    .then((res) => {
//      console.log(res)
    return res.json();
    })
    .then((data) => {
//    console.log(data);
    setData(data);
  })
    .catch(rejected => {
    console.log(rejected);
  });
  } 


  useEffect(() => {
    update();
    const intervalId = setInterval(update, 2500);
    return () => clearInterval(intervalId);
  }, []);


 
return (
  <>

  <TabsMenu />

<div> 
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
      <tr className="table-light">
        <th className = "col-2">Product</th>
        <th className = "col-1"><p className="my-0">Batch</p><p className="my-0">Job</p></th>
        <th className = "col-4"><p className="my-0">Component</p><p className="my-0">Task</p></th>
        <th className = "col-1">Tools</th>
        <th className = "col-1">Kit</th>
        <th className = "col-2"><p className="my-0">Possible</p><p className="my-0">Operators</p></th>
      </tr>

      <tr className="table-light" >
            <th><InputComponent initialValue={''} setExternalValue={setFilterProduct}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterBatch}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilterTask}/></th>
            <th>{/*<InputComponent initialValue={''} setExternalValue={setFilterTool}/>*/}</th>
            <th>{/*<InputComponent initialValue={''} setExternalValue={setFilterKit}/>*/}</th>
            <th>{/*<InputComponent initialValue={''} setExternalValue={setFilterOperator}/>*/}</th>
      </tr>

    </thead>
    <tbody>
        {data.hasOwnProperty('tasks') && data.tasks.map((task) => <TaskRow task = {task} filters = {filters}/>)}
    </tbody>
    </table>
  </div>
  </>);
};

export default PossibleTasks;