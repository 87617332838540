import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import PersonIcon from '@mui/icons-material/Person'; // Import the icon
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { KCheckbox, Update } from '../utils/elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, IconButton, Typography,
    CircularProgress, Grid, Container, Avatar } from '@mui/material';
import LayersIcon from '@mui/icons-material/Layers';
import { KSwitchIcon } from '../utils/elements';

const CreateModal = ({ open, onClose, onCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
    //number: '',
    code: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/create/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.post(url, formData, options);
      onClose();
      onCreated();  // Refetch the user list after creation
    } catch (error) {
      console.log('Error creating user: ', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{DictValue('Create')}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            label={DictValue('Name')}
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
          {/*<TextField
            margin="dense"
            label={DictValue('Number')}
            name="number"
            type="number"
            fullWidth
            value={formData.number}
            onChange={handleChange}
            required
          />*/}
          <TextField
            margin="dense"
            label={DictValue('Code')}
            name="code"
            fullWidth
            value={formData.code}
            onChange={handleChange}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete, refetch=null }) => {
  const [visible, setVisible] = useState(true);

  const Clone = async () => {
    
    if (!confirm("Clone Product?")) return


    const url = `${process.env.REACT_APP_BACKEND_URL}/products/clone/`;
    const request = {id: item.id}
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(),
      },
    };
    await axios.post(url, request, options);
    if (refetch) refetch();

  }



  return (
    visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.username}>
         <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'row', // Align items in a row
            alignItems: 'center', // Vertically center items
            height: '100%',
            boxShadow: 3, // Adding elevation (change the number for different shadow intensity)
            backgroundColor: '#fff', // Ensure the background is white for contrast
            transition: 'box-shadow 0.3s ease-in-out', // Smooth shadow transition
            '&:hover': {
              boxShadow: 6, // Elevate more on hover
            },
          }}
        >
         {/* Link wrapped around Avatar */}
          <Link to={`/products/product/${item.id}/`} style={{ textDecoration: 'none' }}>
            <Avatar
              src={item.picture ? `${process.env.REACT_APP_API_URL}${item.picture}` : null} // Update src if you have avatar URLs
              alt={`${item.name}`}
              sx={{ width: 112, height: 112, marginRight: 2 }} // Add margin to the right of the avatar
            />
          </Link>

          {/* Details */}
          <Box sx={{ flex: 1 }}>
              <Typography variant="h6">
              <Update api="/products/change/" request={{ id: item.id }} field_type="text" field_name="name" field_value={item.name} can_change={can_change} />
            </Typography>

            <Box sx={{ marginTop: 1 }}>
  
              <Typography variant="body1">
              {DictValue('Code')}: <Update api="/products/change/" request={{ id: item.id }} field_type="text" field_name="code" field_value={item.code} can_change={can_change} />
              </Typography>

              <Typography variant="body1">
              {DictValue('Version')}: {item.version} (id: {item.id})
              </Typography>

              <KSwitchIcon
              uid_name = 'id' 
              uid_value = {item.id} 
              field_name = 'is_active'
              field_value = {item.is_active} 
              label = {DictValue("Active")}
              api = '/products/change/'
                onSuccess = {refetch}
              />

              <KCheckbox
              value = {item.is_locked} 
              label = {DictValue("Locked")}
              />

              <Typography variant="body1">
              {DictValue('Created By')}: {item.created_by}
              </Typography>

              <Typography variant="body1">
              {DictValue('Created On')}: {item.dt_created}
              </Typography>

            </Box>
            {can_delete && !item.is_locked && (
              <Delete item={item} setVisible={setVisible} />
            )}
            { /*<IconButton
              component={Link}
              to={`/products/operator/${item.id}/work-panel/`}
              sx={{ color: 'gray' }}
              aria-label="Clone"
            >
              <LayersIcon />
            {</IconButton>*/}
            {<button onClick={() => Clone()} className="btn btn-danger btn-sm mt-2">
            Clone
          </button>
          }
          </Box>
        </Box>
      </Grid>
    )
  );
};

const Delete = ({ item, setVisible }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    if (!window.confirm('Confirm delete?')) return;

    try {
      await fetchData('/products/delete/', { id: item.id }, () => {}, () => {}, setVisible);
    } catch (error) {
      setErrorMessage('Error deleting');
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const List = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
      setCanChange(checkPerm('change_product'))
      setCanAdd(checkPerm('add_product'));
      setCanDelete(checkPerm('delete_product'));
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
    }, [data]);
  

    useEffect(() => {
      checkPageAuthorization(['view_product'], navigate);
      refetch();
    }, [navigate]);
  
    const refetch = () => {
      setIsLoading(true);
      fetchData('/products/list/', {}, setData, setIsLoading);
    };
  

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.name.toLowerCase().includes(filter.toLowerCase()) ||
      (item.number?.toString().toLowerCase().includes(filter.toLowerCase()) || '') ||
      item.code.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
      <Container maxWidth="xxl"  sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              {canAdd && (
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleShowModal}>
                  {DictValue('Create')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    can_change={canChange}
                    can_delete={canDelete}
                    refetch = {refetch}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <CreateModal open={showModal} onClose={handleCloseModal} onCreated={fetch} />
      </Container>
    );
  };
  
  export default List;