import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faTrash } from '@fortawesome/free-solid-svg-icons';

const Delete = ({ManagerID}) => {

    const handleClick = () => {

        const fetchData = async () => {

            try {
    
                var request = {'request': null};
    
                const Api = process.env.REACT_APP_API_URL;   
                const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('AccessToken'),
                },
                body: JSON.stringify(request),
                };
        
    
            const response = await fetch(Api+"/api/managers/" + ManagerID + "/delete/", options);
            const data = await response.json();
            window.location.reload();


            } catch (error) {
                console.log('Error fetching data: '+ error)
            } finally {
                //setIsLoading(false);
            }
    
        };
    
        if (confirm("Confirm delete manager " + ManagerID))
            console.log("deleting", ManagerID);
            fetchData();
    };

    return(<><FontAwesomeIcon icon={faTrash} onClick={handleClick} style={{ cursor: 'pointer' }}></FontAwesomeIcon></>);

}

export default Delete;