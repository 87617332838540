import React from 'react';
import {Link, Route, Routes } from 'react-router-dom';

import Jobs from './Jobs';
import Job from './Job';
import PossibleTasks from './PossibleTasks';
import OperatorsLog from './OperatorsLog.js'
import OperatorsForecast from './OperatorsForecast.js'
import DictTag from '../dictionaries/DictTag.js';

function JobRouter() {
return (
<>
    <Link className='mx-2' to={'/jobs'}><DictTag tag = 'Jobs'/></Link>
    
    <Routes>
        <Route index element={<Jobs />} />
        <Route path="job/:JobID/*" element={<Job />} />
        <Route path="tasks/" element={<PossibleTasks AllJobs = {true}/>} />
        <Route path="log/" element={<OperatorsLog DataOptions = {{filter: null}}/>} />
        <Route path="forecast/" element={<OperatorsForecast DataOptions = {{filter: null}}/>} />
    </Routes>
</>

);

}

export default JobRouter;
