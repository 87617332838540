// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge-content {
    display: block; /* Ensure the div behaves as a block element */
    /* Reset badge styles to make it appear normal */
    background-color: yellow;
    color: initial;
    padding: 0;
    text-align: left;
    text-wrap:wrap;
    font-style: normal;
    font-weight: normal;
    font-size: 12;
  }


  .myTitle {
    font-size: 12;
    text-align: left;
    text-wrap:wrap;
    font-style: normal;
    font-weight: bold;
  }

  .myBody {
    text-align: left;
    text-wrap:wrap;
    font-style: normal;
    font-weight: bold;
    font-size: 16 !important;
    color: black;
  }`, "",{"version":3,"sources":["webpack://./src/jobs/badge-content.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,8CAA8C;IAC9D,gDAAgD;IAChD,wBAAwB;IACxB,cAAc;IACd,UAAU;IACV,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;EACf;;;EAGA;IACE,aAAa;IACb,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,wBAAwB;IACxB,YAAY;EACd","sourcesContent":[".badge-content {\n    display: block; /* Ensure the div behaves as a block element */\n    /* Reset badge styles to make it appear normal */\n    background-color: yellow;\n    color: initial;\n    padding: 0;\n    text-align: left;\n    text-wrap:wrap;\n    font-style: normal;\n    font-weight: normal;\n    font-size: 12;\n  }\n\n\n  .myTitle {\n    font-size: 12;\n    text-align: left;\n    text-wrap:wrap;\n    font-style: normal;\n    font-weight: bold;\n  }\n\n  .myBody {\n    text-align: left;\n    text-wrap:wrap;\n    font-style: normal;\n    font-weight: bold;\n    font-size: 16 !important;\n    color: black;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
