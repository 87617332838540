// Sidebar.js
import React, { useState } from 'react';
import { Offcanvas, Nav } from 'react-bootstrap';
import './Sidebar.css'; // Optional: If you need additional custom styles
import { getUser, getToken } from '../utils/common';
import { useNavigate} from 'react-router-dom';
import DictTag from '../dictionaries/DictTag';

const Sidebar = ({show, handleClose}) => {

  const visible = getToken() ? true : false;
  const user = getUser();
  const auth = user && user.auth;
  const navigate = useNavigate();

  return (
    <>
      {/*
      <button className="btn btn-primary" onClick={handleShow}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            {!visible && ( <span className="nav-link" onClick ={() => {handleClose(); navigate("/login")}}><DictTag tag= {'Login'}/></span> )}
            {auth && auth.hasOwnProperty('dictionaries') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/dictionaries")}}><DictTag tag= {'Dictionaries'}/></span> )}
            {auth && auth.hasOwnProperty('products') && ( <span className="nav-link" onClick ={() => {handleClose(); navigate("/products")}}><DictTag tag= {'Products'}/></span> )}
            {auth && auth.hasOwnProperty('operators') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/operators")}}><DictTag tag= {'Operators'}/></span> )}
            {auth && auth.hasOwnProperty('jobs') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/jobs")}}><DictTag tag= {'Jobs'}/></span> )}
            {auth && auth.hasOwnProperty('managers') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/managers")}}><DictTag tag= {'Managers'}/></span>)} 
            {auth && auth.hasOwnProperty('kits') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/kits")}}><DictTag tag= {'Kits'}/></span> )}
            {auth && auth.hasOwnProperty('tools') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/tools")}}><DictTag tag= {'Tools'}/></span> )}
            {auth && auth.hasOwnProperty('skills') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/skills")}}><DictTag tag= {'Skills'}/></span> )}
            {auth && auth.hasOwnProperty('users') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/users")}}><DictTag tag= {'Users'}/></span> )}
            {auth && auth.hasOwnProperty('args') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/args")}}><DictTag tag= {'Args'}/></span> )}
            {auth && auth.hasOwnProperty('operator_panel') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/operators/operator_panel")}}><DictTag tag= {'Work Panel'}/></span> )}
            <span className="nav-link" onClick ={() => {handleClose(); navigate("/dashboard")}}><DictTag tag= {'Dashboard'}/></span> 
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;