import React, { useEffect, useState } from 'react';

import Switch from 'react-switch';

const SwitchButton = ({ JobID, FieldName, value }) => {

    const [isChecked, setIsChecked] = useState(value);

    const updateParameter = async () => {

        var body =  { 
            JobID: JobID,
            FieldName: FieldName, 
            FieldValue: isChecked ? 0 : 1,
        };
        console.log(body);
    
        try {
    
            const Api = process.env.REACT_APP_API_URL;       
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(body),
            };
            
        const response = await fetch(Api+"/api/jobs/" + JobID +"/update/", options);
        
          const data = await response.json();
          if (!response.ok) {
            throw new Error('Failed to update parameter');
          }
          window.location.reload();
  
        } catch (error) {
            console.log(error.message);
            setErrorMessage('Error updating parameter: ' + error.message);
        }
    
    };
    

    useEffect(() => {
      setIsChecked(value);
    }, [value]);
  
    const handleChange = (checked) => {
      setIsChecked(checked);
      updateParameter();
    };
  
    return (
      <div>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          onColor="#86d3ff"
          offColor="#dcdcdc"
          checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
          uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
          handleDiameter={24}
          height={26}
          width = {80}
        />
      </div>
    );
  };

export default SwitchButton;
