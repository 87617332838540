import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import { Update, SwitchButton, KCheckbox, FilteredSelect } from '../utils/elements';

import { RequiredSkillChip, ToolclassChip } from './ComponentDetail'
import { Container, Paper, Box, Grid, Typography, Button, Avatar } from '@mui/material';

//import { PhotoCamera } from '@mui/icons-material';
import PictureUpload from '../utils/PictureUpload'; 

import  ComponentsTree  from './ComponentsTree';
import  ComponentsFlat  from './ComponentsFlat';


const fetchProductData = async (id, setData, setPictureUrl, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/products/detail/";
        const request = { 'id': id };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data)
        setData(data.data);
        setPictureUrl(process.env.REACT_APP_API_URL + data.data.picture)
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};


const Detail = () => {

  let { id } = useParams();

  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [canChange, setCanChange] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const navigate = useNavigate();

  const refetch = () => {
//    console.log("REFETCHING")
    fetchProductData(id, setProduct, setPictureUrl, setIsLoading);
  }

  useEffect(() => {
    checkPageAuthorization(['view_product'], navigate);
    refetch();
    //fetchProductData(id, setProduct, setPictureUrl, setIsLoading);
  }, [navigate]);

  useEffect(() => {
    setCanChange(checkPerm('change_product'))
    setCanDelete(checkPerm('delete_product'));
  }, []);

  const handleAvatarUpload = async (file) => {
    const backend = process.env.REACT_APP_API_URL;
    const api = "/products/upload_picture/";

    const formData = new FormData();
    formData.append('id', id);  // Pass the  ID
    formData.append('picture', file);  // Add the avatar file

    const options = {
        method: 'POST',
        headers: {
            'Authorization': getToken(),  // Include your auth token if needed
        },
        body: formData,
    };

    try {
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        //console.log("Avatar upload response:", data);
    } catch (error) {
        console.error("Error uploading picture:", error);
    }
  };

  
  return (
    product && (
      <Container maxWidth="xl" sx={{backgroundColor: 'white'}}>
        <Typography variant="h4" align="center" gutterBottom>
        {DictValue('Product Details')}
        </Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={3}>
              {/* Picture with Upload */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <PictureUpload 
                  initialImageUrl={pictureUrl} 
                  onUpload={handleAvatarUpload}
                  canChange={canChange} 
                />
                <Typography variant="h6" align="center">
                  {product.name} ({product.id})
                </Typography>
              </Grid>

              {/* Operator Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Id')}
                </Typography>
                <Typography variant="body1">{product.id}</Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Name')}
                </Typography>
                <Typography variant="body1">
                  <Update api = {"/products/change/"} request ={{id: product.id}} field_name='name' field_type='text' field_value={product.name} can_change={canChange}/>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Code')}
                </Typography>
                <Typography variant="body1">
                  <Update api = {"/products/change/"} request ={{id: product.id}} field_name='code' field_type='text' field_value={product.code} can_change={canChange}/>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Number')}
                </Typography>
                <Typography variant="body1">
                  <Update api = {"/products/change/"} request ={{id: product.id}} field_name='number' field_type='number' field_value={product.number} can_change={canChange}/>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('ETC')}
                </Typography>
                <Typography variant="body1">
                {product.expected_time_hhmm}
                </Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('isActive')}
                </Typography>
                {product && <SwitchButton api = {"/products/change/"} request ={{id: product.id}} field_name='is_active' value={product.is_active} can_change={canChange} />}
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <KCheckbox value={product.is_locked} label={'isLocked'}/>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Required toolclasses')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >

                {product?.required_toolclasses.map((toolclass) => (<ToolclassChip key={toolclass.id} toolclass={toolclass}/>))}
                </Box>  
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Required tools')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >

                {product?.required_tools.map((tool) => (<ToolclassChip key={tool.id} toolclass={tool}/>))}
                </Box>  
              </Grid>



              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Required skills')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >

                {product?.required_skills.map((skill) => (<RequiredSkillChip key = {skill.id} skill={skill}/>))}
                </Box>  
              </Grid>

  
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Components (Flat View)')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >
                  <ComponentsFlat 
                    product_id={product.id} 
                    components={product.components}
                    apiUrl={"/products/component/create/"}
                    refetch={refetch}
                    canChange = {canChange && !product.is_locked}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Components (Tree View)')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >
                  <ComponentsTree 
                    product_id={product.id} 
                    components={product.components}
                    refetch = {refetch} 
                    canChange = {canChange && !product.is_locked}

                />
                </Box>
              </Grid>


            </Grid>
          </Box>
        </Paper>
      </Container>
    )
  );
};

export default Detail;