import React from 'react'; 
import { Link, Route, Routes, useParams } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import DictTag from '../dictionaries/DictTag.js';
import { JobInfo } from './JobInfo.js';
import JobOperators from './JobOperators.js';
import JobDetail from './JobDetail.js';
import BatchDetail from '../batches/BatchDetail.js';
import JobTasksToBeAssigned from './JobTasksToBeAssigned.js'
import { Container } from '@mui/material';

/*
import Batches from '../batches/Batches.js';
import BatchTasks from '../tasks/BatchTasks.js';
import OperatorsTable from './dispatching/OperatorsTable.js'; // Import OperatorsTable component
import TasksToAssign from './dispatching/TasksToAssign.js'
*/

const Job = () => {

    let { id } = useParams();

    return (
        <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
            <span>{' >> '}</span>
            <Link className='mx-2' to={`/jobs/job/${id}`}>{id}</Link>
            <div className="my-2">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/jobs/job/${id}/info/`} eventKey="info"><DictTag tag='Job Info'/></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/jobs/job/${id}/operator-panel/`} eventKey="operators"><DictTag tag='Operators'/></Nav.Link>
                    </Nav.Item>
                    {<Nav.Item>
                        <Nav.Link as={Link} to={`/jobs/job/${id}/tasks-to-be-assigned/`} eventKey="tasks"><DictTag tag='Pending tasks'/></Nav.Link>
                    </Nav.Item>}
                </Nav>
            </div>
            <div>
                <Routes>
                    <Route index element={<JobDetail />} />
                    <Route path="info" element={<JobDetail />} />
                    <Route path="operator-panel" element={<JobOperators />} />
                    <Route path="tasks-to-be-assigned" element={<JobTasksToBeAssigned />} />

                    {/*<Route path="possible-tasks" element={<JobPossibleTasks />} />*/}
                    <Route path="batches/batch/:batch_id/" element={<BatchDetail />} />

                    {/*
                    <Route path="batches" element={<Batches />} />
                    <Route path="tasks" element={<TasksToAssign jobs = {[JobID]}/>} />
                    <Route path="batches/:BatchID/tasks/" element={<BatchTasks />} />
                    */}    
                    </Routes>
            </div>
        </Container>
    );
};

export default Job;
