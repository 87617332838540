import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSave, faCancel } from '@fortawesome/free-solid-svg-icons';

const Update = ({JobID, FieldName, FieldType, FieldValue}) =>  {

    const [parameterValue, setParameterValue] = useState(FieldValue);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState(FieldValue);
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event) => {
      setParameterValue(event.target.value);
    };
  
  
    useEffect(() => {
      setInitialValue(parameterValue);
    }, []);
  
    useEffect(() => {
    }, [disabled]);
  
    var o;
    if (!isFocused) o = <span onClick = {() => {setIsFocused(true)}}>{(FieldValue == null || FieldValue == '') ? '---' : FieldValue}</span>;
    else {
  
    if (FieldType == 'text') o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType == 'number') o = <input type="number"  step = "0.001" value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType === 'date') o =  <DatePicker
        selected={new Date(parameterValue)}
        onChange={(date) => {setParameterValue(date.toLocaleString('sv').split(' ')[0])}}
      dateFormat="dd/MM/yyyy"
      />;
    else if (FieldType == 'bool') o = (<select
          value={parameterValue}
          onChange={handleChange}>
          <option value={null}>--</option>
          <option value="1">SI</option>
          <option value="0">NO</option>
          </select>);
    }  
  
    const updateParameter = async () => {
      var body =  { 
          JobID: JobID,
          FieldName: FieldName, 
          FieldValue: parameterValue,
      };
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/jobs/" + JobID +"/update/", options);
      
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to update parameter');
        }
        setInitialValue(parameterValue);
        setDisabled(false);
        setIsFocused(false);
        window.location.reload();

      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error updating parameter: ' + error.message);
      }
  
  };
  
    return (<>
        {o}
        {((parameterValue !== initialValue)) && isFocused && (<>
            <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </>
        )}
        { isFocused && (<>
            <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </>
        )}          
        {errorMessage && <p>{errorMessage}</p>}
      </>
    );
  
}

export default Update;