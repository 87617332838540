import React, { useState, useEffect, useMemo } from 'react';
import { Container, Typography, Grid, Card, CardContent, Box, IconButton, Switch, FormControlLabel, CircularProgress } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { getToken } from '../utils/common';
import { useParams } from 'react-router-dom';
import moment from 'moment-timezone';

const getWeekDates = (startDate, isFiveDayWeek) => {
  const startOfWeek = moment(startDate).startOf('isoWeek');
  const daysToGenerate = isFiveDayWeek ? 5 : 7; // Generate 5 days (Mon-Fri) or 7 days (Mon-Sun)
  return Array.from({ length: daysToGenerate }, (_, i) => 
    startOfWeek.clone().add(i, 'days').format('YYYY-MM-DD')
  );
};

const App = () => {
  const { operator_id } = useParams();
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().startOf('isoWeek').format('YYYY-MM-DD'));
  const [isFiveDayWeek, setIsFiveDayWeek] = useState(true); // Default to 5-day week
  const [loading, setLoading] = useState(true); // Loading state for events
  const [operatorDetails, setOperatorDetails] = useState(null); // State for operator details

  const weekDates = useMemo(() => getWeekDates(selectedDate, isFiveDayWeek), [selectedDate, isFiveDayWeek]);

  // Fetch operator details
  useEffect(() => {
    const fetchOperatorDetails = async () => {
      try {
        const backend = process.env.REACT_APP_API_URL;
        const api = '/operators/details/';
        const response = await fetch(`${backend}${api}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
          body: JSON.stringify({ id: operator_id }),
        });

        if (response.ok) {
          const data = await response.json();
          setOperatorDetails(data.data); // Store operator details in state
        } else {
          console.error('Failed to fetch operator details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching operator details:', error);
      }
    };
    
    fetchOperatorDetails();
  }, [operator_id]);

  useEffect(() => {
    const fetchEvents = async () => {
      setLoading(true); // Set loading to true before fetching data
      try {
        const backend = process.env.REACT_APP_API_URL;
        const api = '/operators/actions/';
        const response = await fetch(`${backend}${api}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
          body: JSON.stringify({
            action: 'log',
            id: operator_id,
            start: weekDates[0],
            end: weekDates[weekDates.length - 1], // Last date in the week
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setEvents(data.data);
          console.log(data.data);
        } else {
          console.error('Failed to fetch events:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        setLoading(false); // Set loading to false after the fetch operation
      }
    };
    fetchEvents();
  }, [weekDates, operator_id]);

  const eventsByDay = useMemo(() => weekDates.reduce((acc, date) => {
    acc[date] = events.filter((event) => event.dt.split('T')[0] === date);
    return acc;
  }, {}), [events, weekDates]);

  const handleChangeWeek = (direction) => {
    setSelectedDate(moment(selectedDate).add(direction, 'weeks').format('YYYY-MM-DD'));
  };

  const toggleWeekType = () => {
    setIsFiveDayWeek((prev) => !prev);
  };

  return (
    <Container sx={{ mt: 4, maxWidth: 'xl' }}>
      <Typography variant="h4" gutterBottom>Events by Week</Typography>
      
      {/* Display Operator Details if fetched */}
      {operatorDetails ? (
        <Box sx={{ mb: 4 }}>
          <Typography variant="h6">{operatorDetails.name}</Typography>
          <Typography variant="body1">ID: {operatorDetails.id}</Typography>
          {/* Add more operator details as needed */}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <IconButton onClick={() => handleChangeWeek(-1)}><ArrowBackIos /></IconButton>
        <Typography variant="h6" sx={{ mx: 2 }}>
          Week of {moment(weekDates[0]).format('MMM DD')} - {moment(weekDates[weekDates.length - 1]).format('MMM DD')}
        </Typography>
        <IconButton onClick={() => handleChangeWeek(1)}><ArrowForwardIos /></IconButton>
      </Box>
      
      <Box sx={{ mb: 3 }}>
        <FormControlLabel
          control={
            <Switch 
              checked={isFiveDayWeek} 
              onChange={toggleWeekType} 
              color="primary" 
            />
          }
          label="5-Day Week"
        />
      </Box>

      {/* Loader for events */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {weekDates.map((date) => (
            <Grid item xs={12 / weekDates.length} key={date}> {/* Dynamically adjust width */}
              <Box sx={{ border: '1px solid #ccc', borderRadius: 1, p: 2 }}>
                <Typography variant="h6">{moment(date).format('ddd, MMM DD')}</Typography>
                {eventsByDay[date]?.length ? (
                  eventsByDay[date].map((event) => (
                    <Card key={event.id} sx={{ mb: 2 }}>
                      <CardContent>
                        <Typography variant="body1">
                          {moment(event.dt).format('HH:mm')}
                        </Typography>
                        <Typography variant="body1">{event.event}</Typography>
                        <Typography variant="body2" color="textSecondary">{event.task.product_name}</Typography>
                        <Typography variant="body2" color="textSecondary">{event.task.component_name}</Typography>
                        <Typography variant="body2" color="textSecondary">{event.task.sequence_name}</Typography>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">No events for this day.</Typography>
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default App;
