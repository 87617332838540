import React, { useState, useEffect } from 'react'; // Ensure React is imported before other react-related imports
import { Link, Route, Routes, useParams } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { getToken } from '../utils/common';

import ProductComponents from './ProductComponents.js';
import ProductJobs from './ProductJobs.js';
import ComponentSequence from './ComponentSequence.js';
import ComponentSequenceBatches from './ComponentSequenceBatches.js';

//import Batches from '../batches/Batches.js';
//import BatchTasks from '../tasks/BatchTasks.js';
//import Dispatching from '../jobs/dispatching/Dispatching.js';
import DictTag from '../dictionaries/DictTag.js';

const Product = () => {
    let { ProductID } = useParams();
    const [ProductName, setProductName] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const backendurl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            const url = `${backendurl}/api/products/${ProductID}/`;
            const postData = { 'ProductID': ProductID };
            const options = { headers: { 'Authorization': getToken() } };

            try {
                const response = await axios.post(url, postData, options);
                setProductName(response.data.data.ProductName);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching product:', error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [ProductID]);

    return (
        <>
            <span>{' >> '}</span>
            <Link className='mx-2' to={`/products/${ProductID}`}>{ProductName} ({ProductID})</Link>
            <div className="my-2">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/products/${ProductID}/components`} eventKey="components"><DictTag tag = 'Components'/></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/products/${ProductID}/jobs`} eventKey="jobs"><DictTag tag = 'Jobs'/></Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div>
                <Routes>
                    <Route index element={<ProductJobs />} />
                    <Route path="components" element={<ProductComponents />} />
                    <Route path="components/:ComponentID/sequence/" element={<ComponentSequence />} />
                    <Route path="components/:ComponentID/sequence/:SequenceID/batches/" element={<ComponentSequenceBatches />} />
                    <Route path="jobs" element={<ProductJobs />} />
                    {/*<Route path="jobs/:JobID/batches" element={<Batches />} />
                    <Route path="jobs/:JobID/batches/:BatchID/tasks/" element={<BatchTasks />} />
                    <Route path="jobs/:JobID/dispatching/*" element={<Dispatching />} />*/}
                </Routes>
            </div>
        </>
    );
};

export default Product;
