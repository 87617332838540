import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faX } from '@fortawesome/free-solid-svg-icons';

const CheckMark = ({ value }) => {
  
    return (
      <span>
        {value == "1" || value == 1 || value == true  ? 
        <><FontAwesomeIcon className = 'mx-1' icon={faCheckSquare} style = {{color: 'green'}}></FontAwesomeIcon></>
        :
        <><FontAwesomeIcon className = 'mx-1' icon={faX} style = {{color: 'grey'}}></FontAwesomeIcon></>
        }
        </span>
    );
};

export default CheckMark;