import React, { useState, useEffect } from 'react';
import { getToken, DictValue } from '../utils/common';
import { CircularProgress, Alert} from '@mui/material';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip, Box, IconButton, TextField
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import { useParams } from 'react-router-dom';
import { Update, SwitchButton, KCheckbox, KText, FilteredSelect } from '../utils/elements';


const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};


const fetchData = async (id, setData, setIsLoading, setError) => {
    try {
        if (setIsLoading) setIsLoading(true);
        setError(null); // Reset error state
        const backend = process.env.REACT_APP_API_URL;
        const api = "/managers/actions/";
        const request = { id:id , action: 'assignment-log' };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setData(data.data);
        console.log(data.data)

    } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'An error occurred while fetching data.');
    } finally {
        if (setIsLoading) setIsLoading(false);
    }
};



const LogEntryCard = ({ item }) => {
    const width = 2;
  
    return (
      <Card
        style={{
          marginBottom: '16px',
          border: false ? '4px solid red' : 'none',
        }}
      >
        <CardContent>
          {/* Render item details here */}
          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
          <Typography>{item.dt} {item.assignee} ({item.event})</Typography> 
          </Grid>
 
 
          {/* Accordion for JSON.stringify */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="json-content"
              id="json-header"
            >
              <Typography>Show raw JSON</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="pre" style={{ overflowX: 'auto' }}>
                {JSON.stringify(item, null, 2)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    );
  };

  
const App = () => {
    // const { id } = useParams();
    const id = 1;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        fetchData(id, setData, setIsLoading, setError);
    }, [id]);


    // Filtering logic
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
      };
    
    const filteredData = data.filter(item => 
    item.event.toLowerCase().includes(filter.toLowerCase()) || 
    item.assignee.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ padding: '16px' }}>
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '16px' }}>
                            {error}
                        </Alert>
                    )}
                <Grid item>
                <FilterInput value={filter} onChange={handleFilterChange} />
                </Grid>

                    {data.length === 0 && !error ? (
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            style={{ textAlign: 'center', marginTop: '16px' }}
                        >
                            No pending tasks.
                        </Typography>
                    ) : (
                        filteredData.map((item, index) => (
                            <LogEntryCard key={index} item={item} />
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default App;
