import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faCancel } from '@fortawesome/free-solid-svg-icons';


const Insert = ({ToolClassID}) =>  {
    const [ID, setID] = useState('');
    const [Name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    const handleIDChange = (event) => {
      setID(event.target.value);
    };
  
    const handleNameChange = (event) => {
        setName(event.target.value);
      };
      
    useEffect(() => {}, [disabled]);
  
  const insert = async () => {
      var body =  { 
          ToolID: ID,
          ToolName: Name,
          ToolClassID: ToolClassID
      };
  
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/tools/tool_classes/"+ToolClassID+"/insert/", options);
      console.log(Api+"/api/tools/tool_classes/"+ToolClassID+"/insert/")
        const data = await response.json();
//        console.log(data)  
        if (!response.ok) {
          throw new Error('Failed to insert component');
        }
        setDisabled(false);
        window.location.reload();
      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error inserting component: ' + error.message);
      }
  
  };
  
  const contentFocused = (
                    <><tr>
                        <td><input type="text"  value={ID} onChange={handleIDChange} className="mx-1" disabled={disabled}/></td>
                        <td><input type="text"  value={Name} onChange={handleNameChange} className="mx-1" disabled={disabled}/></td>
                        <td>
                        <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                        </td>
                    </tr></>);

const contentNotFocused = (<><tr>
  <td><>
      <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
  </></td>
  <td></td>
  <td></td>
</tr></>);


return (isFocused ? contentFocused : contentNotFocused);

};

export default Insert;