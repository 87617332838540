import React, { useEffect, useState } from 'react';
import { Spinner, Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel } from '@fortawesome/free-solid-svg-icons';
import { Outlet, Link } from 'react-router-dom';
import axios from 'axios'; // Import Axios library
import { useNavigate } from 'react-router-dom';
import FilterInput from '../utils/FilterInput';
import { getToken } from '../utils/common';
import DictTag from '../dictionaries/DictTag';

const InsertProduct = () =>  {

    const [ID, setID] = useState('');
    const [Name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    const handleIDChange = (event) => {
      setID(event.target.value);
    };
  
    const handleNameChange = (event) => {
        setName(event.target.value);
      };
  
      
    useEffect(() => {
    }, [disabled]);
  
  
    
    const insert = async () => {
      var body =  { 
          ProductID: ID,
          ProductName: Name,
      };
  
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/products/insert/", options);
      
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to insert component');
        }
        setDisabled(false);
        window.location.reload();
      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error inserting component: ' + error.message);
      }
  
  };
  
  const contentFocused = (<><tr>
                    <td><input type="number"  value={ID} onChange={handleIDChange} className="mx-1" disabled={disabled}/></td>
                    <td><input type="text"  value={Name} onChange={handleNameChange} className="mx-1" disabled={disabled}/></td>
                    <td></td>
                    <td></td>
                    <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                    <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </tr></>);

const contentNotFocused = (<><tr>
  <td><>
      <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
  </></td>
  <td></td>
  <td></td>
  <td></td>
  <td></td>
</tr></>);


return (isFocused ? contentFocused : contentNotFocused);
  
};
  
const DeleteProduct = ({ProductID}) => {

    const handleClick = () => {

        const fetchData = async () => {

            try {
    
                var request = {'request': null};
    
                const Api = process.env.REACT_APP_API_URL;   
                const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('AccessToken'),
                },
                body: JSON.stringify(request),
                };
        
    
            const response = await fetch(Api+"/api/products/" + ProductID + "/delete/", options);
            const data = await response.json();
            window.location.reload();


            } catch (error) {
                console.log('Error fetching data: '+ error)
            } finally {
                //setIsLoading(false);
            }
    
        };
    


        if (confirm("Confirm delete product " + ProductID))
            console.log("deleting", ProductID);
            fetchData();
        };

    return(<><FontAwesomeIcon icon={faTrash} onClick={handleClick} style={{ cursor: 'pointer' }}></FontAwesomeIcon></>);

};

const Update = ({ProductID, FieldName, FieldType, FieldValue}) =>  {

    const [parameterValue, setParameterValue] = useState(FieldValue);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState(FieldValue);
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event) => {
      setParameterValue(event.target.value);
    };
  
  
    useEffect(() => {
      setInitialValue(parameterValue);
    }, []);
  
    useEffect(() => {
    }, [disabled]);
  
    var o;
    if (!isFocused) o = <span onClick = {() => {setIsFocused(true)}}>{FieldValue}</span>;
    else {
  
    if (FieldType == 'text') o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType == 'number') o = <input type="number"  step = "0.001" value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType === 'date') o =  <DatePicker
        selected={new Date(parameterValue)}
        onChange={(date) => {setParameterValue(date.toLocaleString('sv').split(' ')[0])}}
      dateFormat="dd/MM/yyyy"
      />;
    else if (FieldType == 'bool') o = (<select
          value={parameterValue}
          onChange={handleChange}>
          <option value={null}>--</option>
          <option value="1">SI</option>
          <option value="0">NO</option>
          </select>);
    }  
  
    const updateParameter = async () => {
      var body =  { 
          ProductID: ProductID,
          FieldName: FieldName, 
          Value: parameterValue,
      };
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/products/" + ProductID +"/update/", options);
      
        const data = await response.json();
      console.log(data);
        if (!response.ok) {
          throw new Error('Failed to update parameter');
        }
        setInitialValue(parameterValue);
        setDisabled(false);
        setIsFocused(false);
        window.location.reload();

      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error updating parameter: ' + error.message);
      }
  
  };
  
    return (<>
        {o}
        {((parameterValue !== initialValue)) && isFocused && (<>
            <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </>
        )}
        { isFocused && (<>
            <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </>
        )}          
        {errorMessage && <p>{errorMessage}</p>}
      </>
    );
  
};
  
const NewJobModal = ({ ProductID }) => {

  const history = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(1); 

  const handleModal = () => {
      setShowModal(!showModal);
  };

  const handleQuantityChange = (event) => {
      setQuantity(event.target.value);
  };

  const handleSubmit = async (event) => {
      event.preventDefault();
      try {
          // Make an Axios POST request to your backend API endpoint
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/products/` + ProductID + `/newjob/`, {
              ProductID: ProductID,
              Quantity: quantity
          });
          // Check the response status
          if (response.status === 200) {
              //console.log('Job added successfully');
              // Close the modal
              setShowModal(false);
              // reload
              window.location.reload()
//              history('/products/'+ProductID+'/jobs/')
          } else {
              console.error('Failed to add job');
          }
      } catch (error) {
          console.error('Error adding job:', error);
      }
  };

  return (
      <>
          <FontAwesomeIcon className='mx-2' icon={faPlus} onClick={handleModal} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          <Modal show={showModal} onHide={handleModal}>
              <Modal.Header closeButton>
                  <Modal.Title>Add new job for product {ProductID}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form onSubmit={handleSubmit}>
                      <Form.Group controlId="quantity">
                          <Form.Label>Quantity</Form.Label>
                          <Form.Control
                              type="number"
                              value={quantity}
                              onChange={handleQuantityChange}
                          />
                      </Form.Group>
                      <Button variant="primary" type="submit">
                          Submit
                      </Button>
                  </Form>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleModal}>
                      Close
                  </Button>
              </Modal.Footer>
          </Modal>
      </>
  );
};


const Row = ({product, filters}) => {

    var visible = true;
  
    if (!(filters.field1 == '')) if 
      (!(
        (product.ProductID.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
      )) 
        visible = false;
  
    if (!(filters.field2 == '')) if 
      (!(
        (product.ProductName.toString().toUpperCase().includes(filters.field2.toUpperCase())) 
        
      )) 
        visible = false;
    
      if (!(filters.field3 == '')) if 
      (!(
        (product.ProductCode.toString().toUpperCase().includes(filters.field3.toUpperCase())) 
      )) 
        visible = false;
    
        if (!visible) return <></>;
  
    return (
        <>
        <tr>
            <td><Link to={'/products/'+product.ProductID}>{product.ProductID}</Link></td>
            <td><Update ProductID = {product.ProductID} FieldName = 'ProductName' FieldType = 'text' FieldValue = {product.ProductName} /></td>
            <td><Update ProductID = {product.ProductID} FieldName = 'ProductCode' FieldType = 'text' FieldValue = {!product.ProductCode || product.ProductCode == '' ? '---' : product.ProductCode} /></td>
            <td><Update ProductID = {product.ProductID} FieldName = 'ComponentRoot' FieldType = 'text' FieldValue = {product.ComponentRoot ? product.ComponentRoot : '-'} /></td>
            <td className="" onClick={null}>
            {product.jobs.map((job) => (
            <span key={job.JobID} className="badge bg-secondary mx-1">
                {job.JobQuantity} @ {job.Created.split(' ')[0]} (JobID: {job.JobID})
            </span>
            ))}
            </td>
            <td><NewJobModal  ProductID = {product.ProductID} /><DeleteProduct ProductID = {product.ProductID}/></td>                
        </tr>                        
        </>
);
  
  
  };
  

const ProductsList = () => {

    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [filters, setFilters] = useState({
        field1: '',
        field2: '',
        field3: '',    
      });
    
      const setFilter1 = (value) => {
        setFilters({ ...filters, field1: value });
      };
      
      const setFilter2 = (value) => {
        setFilters({ ...filters, field2: value });
      };
    
      const setFilter3 = (value) => {
        setFilters({ ...filters, field3: value });
      };


    const fetchData = async () => {

        try {

            var request = {'request': null};

            const backend = process.env.REACT_APP_API_URL;   
            const api = "/api/products/";   

            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
            };
    
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();

        setContent(<>


            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
                <tr className="table-light" >
                <th><DictTag tag = 'ProductID'/></th>
                <th><DictTag tag = 'ProductName'/></th>
                <th><DictTag tag = 'ProductCode'/></th>
                <th><DictTag tag = 'ComponentRoot'/></th>
                <th><DictTag tag = 'Jobs'/></th>
                <th><DictTag tag = 'Actions'/></th>
              </tr>

              <tr className="table-light" >
                <th><FilterInput initialValue={''} setExternalValue={setFilter1}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter2}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter3}/></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>


              </thead>
              <tbody>
                {data["data"].map((product) => <Row product = {product} filters = {filters} /> )}
                <InsertProduct />

              </tbody>

            </table>
        </>);

    } catch (error) {
        setContent('Error fetching data: '+ error)
      } finally {
        setIsLoading(false);
      }

    };
  

useEffect(() => {fetchData()}, [filters]);

var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

return (<><Outlet/>{isLoading ? loading : content }</>);
};

export default ProductsList;
