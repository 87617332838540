import React, { useEffect, useState} from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGears } from '@fortawesome/free-solid-svg-icons';
//import { faTrash, faPlus, faSave, faCancel, faCheck, faSquare, faCheckSquare, faX, faGears } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Switch from 'react-switch';
import axios from 'axios';
import QRCodePDF from '../utils/QRCodePDF';
import CheckMark from '../elements/CheckMark'
import { getToken } from '../utils/common';

const BatchSwitchButton = ({ BatchID, FieldName, value }) => {

    const [isChecked, setIsChecked] = useState(value);
    const [ErrorMessage, setErrorMessage] = useState(null);

    const updateParameter = async () => {

        var body =  { 
            BatchID: BatchID,
            FieldName: FieldName, 
            FieldValue: isChecked ? 0 : 1,
        };
        console.log(body);
    
        try {
    
            const Api = process.env.REACT_APP_API_URL;       
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(body),
            };
            
        const response = await fetch(Api+"/api/batches/" + BatchID +"/update/", options);
        
          const data = await response.json();
          if (!response.ok) {
            throw new Error('Failed to update parameter');
          }
          window.location.reload();
  
        } catch (error) {
            console.log(error.message);
            setErrorMessage('Error updating parameter: ' + error.message);
        }
    
    };
    

    useEffect(() => {
      setIsChecked(value);
    }, [value]);
  
    const handleChange = (checked) => {
      setIsChecked(checked);
      updateParameter();
    };
  
    return (
      ErrorMessage ? <p>{ErrorMessage}</p>
      :
      <div>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          onColor="#86d3ff"
          offColor="#dcdcdc"
          checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
          uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
          handleDiameter={24}
          height={26}
          width = {80}
        />
      </div>
    );
};

/*
const CheckMark = ({ value }) => {
  
    return (
      <span>
        {value ? 
        <><FontAwesomeIcon className = 'mx-1' icon={faCheckSquare} style = {{color: 'green'}}></FontAwesomeIcon></>
        :
        <><FontAwesomeIcon className = 'mx-1' icon={faX} style = {{color: 'grey'}}></FontAwesomeIcon></>
        }
        </span>
    );
};
*/

const BatchProcess = ({ JobID, BatchID }) => {
    
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleClick = (event, BatchID) => {
        event.preventDefault();

        if (!confirm("Confirm processing batch " + BatchID + " of job " + JobID)) return

        setIsLoading(true);

        const url = `${process.env.REACT_APP_API_URL}/api/jobs/` + JobID + `/batches/` + BatchID + `/process/`;
        const request = {
          BatchID: BatchID,
          } 

        axios.post(url, request).then(
          response => {
            setIsLoading(false);
          console.log(response);
            if (response.status == 200) window.location.reload();
        }).catch(error => {

          setIsLoading(false);
          setErrorMessage("Something went wrong. Please try again later." + JSON.stringify(error));
          /*
          if (error.response.status === 400) setError(error.response.data.error.message);
          else if (error.response.status === 500) setError(error.response.data.error.message);
          else setError("Something went wrong. Please try again later.");
          */
        });
    };

    return (
       isLoading ?   <><Spinner className = 'm-5' animation="border" variant="primary" /></>
      :
      <span>
        <><FontAwesomeIcon 
            className = 'mx-1' 
            icon={faGears} 
            style = {{color: 'black', cursor: 'pointer'}}
            onClick={(event) => {handleClick(event, BatchID)}}
        ></FontAwesomeIcon></>
        </span>
    );
};

const Batches = () => {

    let { ProductID, JobID } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);
  
    const fetchData = async () => {
  
      try {
  
        var request = {};
        const now = new Date();
        request["Now"] = now.toLocaleString('sv');
        request["UTCoffset"] = now.getTimezoneOffset();

        const Api = process.env.REACT_APP_API_URL;   
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
        };
    
         
//        const url = Api+"/api/jobs/" + JobID +"/batches/"
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/jobs/${JobID}/batches/`

//        console.log(url);
        const response = await fetch(url, options);
        console.log(response);
        const data = await response.json();

        console.log(data);
//        var link_to_products = "/products/";
//        var link_to_jobs = "/products/" + ProductID + "/jobs/";
        var link_to_batches = "/products/" + ProductID + "/jobs/job/" + JobID + "/batches/";
        var link_to_batches = "/jobs/job/" + JobID + "/batches/";

        setContent(
          <>
                {/* Table */}
                <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
                  {/* Table Head*/}
                  <thead>
                    <tr className="table-light" >
                    <th>BatchCode</th>
                    <th>Quantity</th>
                    <th>Processed</th>
                    <th>Active</th>
                    <th>Started/ Completed</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  {/* Table Body*/}              
                  <tbody>
                    {data["data"].map((item) => {
                      return(
                      <>
                        <tr className="table-light" >
                        <td>{item.BatchCode} {item.BatchName} <QRCodePDF Batch= {item} ProductID={ProductID}/></td>
                        <td>{item.BatchQuantity}</td>
                        <td><CheckMark value = {item.isProcessed}/> {item.isProcessed ? null : <><BatchProcess JobID = {JobID} BatchID = {item.BatchID} /></>}</td>
                        <td><BatchSwitchButton BatchID = {item.BatchID} FieldName = 'isActive' value = {item.isActive}/></td>
                        <td>
                          {item.isStarted && !item.isCompleted && <p className='my-0'>Started: {item.StartedLocal}</p>}
                          {item.isStarted && item.isCompleted && <p className='my-0'>Completed: {item.CompletedLocal}</p>}
                          {item.isStarted && !item.isCompleted && <p className='my-0'>Completed: {item.Completion + '%'} ({item.overtime > 0 ? '+': null}{item.overtime}%)</p>}
                          {item.isCompleted && <p className='my-0'>{item.UnitActualTime_HHMM} / {item.UnitExpectedTime_HHMM} ({item.overtime > 0 ? '+': null}{item.overtime}%)</p>}

                        </td>
                        <td>{item.isProcessed ? <Link to={link_to_batches+item.BatchID+"/tasks/"}>tasks</Link> : null}</td>
                        {/*
                        <SkillSelect ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentSkills={item.required_skills}/> 
                        <td><UpdateField ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}  FieldName = 'LineStation' FieldType = 'number' FieldValue = {item.LineStation}/></td>
                        <td><SelectToolClass ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentValue={item.ToolClassID} currentName={item.ToolClassName}/></td>
                        <td><SelectMandatedTool ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentValue={item.MandatedToolID} currentName={item.MandatedToolName}/></td>
                        <td><SelectPreferredOperators ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID} currentOperators={item.preferred_operators}/></td>
                        <td><Delete ProductID = {ProductID} ComponentID = {ComponentID} SequenceID = {item.SequenceID}/></td>                
                      */}
                        </tr></>);              
  
                      })}
                  </tbody>
                </table>
          </>       
        );
  
      } catch (error) {setContent('Error fetching data: '+ error)
      } finally {setIsLoading(false);}
  
    };
    
    // fetch data only on mount
    useEffect(() => {fetchData()}, []);
  
    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;
  
    return (<>{isLoading ? loading : content }</>);

};

export default Batches;
