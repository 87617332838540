import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import { Update, SwitchButton, FilteredSelect } from '../utils/elements';

import { Container, Paper, Box, Grid, Typography, Button, Avatar } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person'; // Import the icon
import IconButton from '@mui/material/IconButton';
/*
import Switch from 'react-switch';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css';
*/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCancel, faSave } from '@fortawesome/free-solid-svg-icons';
import Permissions from './Permissions';
import Groups from './Groups';
import { PhotoCamera } from '@mui/icons-material';
import PictureUpload from '../utils/PictureUpload';

/*
const AvatarUpload = ({ initialImageUrl, onUpload, canChange }) => {
  const [preview, setPreview] = useState(initialImageUrl);
  const [cameraMode, setCameraMode] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setPreview(URL.createObjectURL(file));
      onUpload(file);
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  };
  const startCamera = async () => {
    setCameraMode(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
  
    // Draw the video frame to the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Convert the canvas content to a data URL
    const dataUrl = canvas.toDataURL('image/png'); // Ensure it's PNG
  
    // Update the preview to show the captured photo
    setPreview(dataUrl);
  
    // Convert the data URL to a Blob with a proper filename
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([arrayBuffer], { type: mimeString });
  
    // Create a File object from the Blob with a proper filename and extension
    const file = new File([blob], 'avatar.png', { type: mimeString });
  
    // Stop the video stream
    video.srcObject.getTracks().forEach(track => track.stop());
  
    // Pass the File to the onUpload function
    onUpload(file);
  
    // Close the camera mode
    setCameraMode(false);
  };  
  return (
    <div style={{ textAlign: 'center' }}>
      {cameraMode ? (
        <>
          <video ref={videoRef} autoPlay style={{ width: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
          <Button
            variant="contained"
            onClick={capturePhoto}
            style={{ marginTop: '10px' }}
          >
            {DictValue('Capture Photo')}
          </Button>
        </>
      ) : (
        <>
          <Avatar
            src={preview || "/default-avatar.png"}
            alt="Operator Avatar"
            sx={{ width: 150, height: 150, margin: "0 auto" }}
          />
          {canChange && (<>
          <Button
            variant="contained"
            component="label"
            style={{ marginTop: '10px' }}
          >
            {DictValue('Upload Picture')}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Button>
          <Button
            variant="contained"
            startIcon={<PhotoCamera />}
            onClick={startCamera}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            {DictValue('Use Camera')}
          </Button></>)}
        </>
      )}
    </div>
  );
};
*/

const ResetPassword = ({ username }) => {
  const [parameterValue, setParameterValue] = useState('');
  const [initialValue, setInitialValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  const updateParameter = async () => {
    const request = {
      username: username,
      new_password: parameterValue
    };

    try {
      const backend = process.env.REACT_APP_API_URL;
      const api = "/users/reset_pwd/";
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(`${backend}${api}`, options);
      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }
//      console.log("response", response)
      // Update the initial value to reflect the successful update
      setInitialValue(parameterValue);
      setIsFocused(false);
    } catch (error) {
      setErrorMessage('Error updating parameter: ' + error.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {!isFocused ? (
        <span style={{ color: 'blue' }} onClick={() => setIsFocused(true)}> ***
        </span>
      ) : (
        <>
          {(
            <input
              type="password"
              value={parameterValue}
              onChange={handleChange}
              className="mx-1"
              disabled={disabled}
            />
          )}
 
          {parameterValue !== initialValue && (
            <FontAwesomeIcon
              className="mx-1"
              icon={faSave}
              onClick={updateParameter}
              style={{ cursor: 'pointer' }}
            />
          )}
          <FontAwesomeIcon
            className="mx-1"
            icon={faCancel}
            onClick={() => setIsFocused(false)}
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
      {errorMessage && <p style={{color: 'red'}}>{errorMessage}</p>}
    </>
  );
};

const fetchUserData = async (user_hash, setData, setPictureUrl, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/users/detail/";
        const request = { 'hash': user_hash };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data)
        setData(data.data);
        setPictureUrl(process.env.REACT_APP_API_URL + data.data.avatar)
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};

export const UserDetail = () => {

  let { UserHash } = useParams();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [canChange, setCanChange] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [canChangeUserPerms, setCanChangeUserPerms] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    checkPageAuthorization(['view_user'], navigate);
    fetchUserData(UserHash, setUser, setPictureUrl, setIsLoading);
  }, [navigate]);

  useEffect(() => {
    setCanChange(checkPerm('change_user'))
    setCanDelete(checkPerm('delete_user'));
    setCanChangeUserPerms(checkPerm('change_user_perms'));
  }, []);

  const handlePictureUpload = async (file) => {
    const backend = process.env.REACT_APP_API_URL;
    const api = "/users/upload_avatar/";

    const formData = new FormData();
    formData.append('hash', UserHash);  // Pass the user's ID
    formData.append('picture', file);  // Add the avatar file

    const options = {
        method: 'POST',
        headers: {
            'Authorization': getToken(),  // Include your auth token if needed
        },
        body: formData,
    };

    try {
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        //console.log("Avatar upload response:", data);
    } catch (error) {
        console.error("Error uploading avatar:", error);
    }
  };

  return (
    user && (
      <Container maxWidth="lg" sx={{backgroundColor: 'white'}}>
        <Typography variant="h4" align="center" gutterBottom>
        {DictValue('User Details')}
        </Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={3}>
              {/* Operator Picture with Upload */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <PictureUpload 
                  initialImageUrl={pictureUrl} 
                  onUpload={handlePictureUpload}
                  canChange={canChange} 
                />
                <Typography variant="h6" align="center">
                  {user.first_name} {user.last_name}
                </Typography>
              </Grid>

              {/* Operator Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('username')}
                </Typography>
                <Typography variant="body1">{user.username}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('EmployeeID')}
                </Typography>
                <Typography variant="body1">
                  <Update api = {"/users/change/"} request ={{username: user.username}} field_name='employee_id' field_type='text' field_value={user.employee_id} can_change={canChange}/>
                </Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('First Name')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: user.username}} field_name='first_name' field_type='text' field_value={user.first_name} can_change={canChange} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Last Name')}
                </Typography>
                <Typography variant="body1">
                <Update api = {"/users/change/"} request ={{username: user.username}} field_name='last_name' field_type='text' field_value={user.last_name} can_change={canChange} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Email')}
                </Typography>
                <Typography variant="body1">
                <Update api = {"/users/change/"} request ={{username: user.username}} field_name='email' field_type='text' field_value={user.email} can_change={canChange} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Nickname')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: user.username}} field_name='nickname' field_type='text' field_value={user.nickname} can_change={canChange} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Date of Birth')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: user.username}} field_name='date_of_birth' field_type='date' field_value={user.date_of_birth} can_change={canChange} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Date of Employment')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: user.username}} field_name='date_of_employment' field_type='date' field_value={user.date_of_employment} can_change={canChange} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Position')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: user.username}} field_name='position' field_type='text' field_value={user.position} can_change={canChange} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Department')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: user.username}} field_name='department' field_type='text' field_value={user.department} can_change={canChange} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('isActive')}
                </Typography>
                {user && <SwitchButton api = {"/users/change/"} request ={{username: user.username}} field_name='is_active' value={user.is_active} can_change={canChange} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Operator')}
                </Typography>
                <Box display="flex" alignItems="center">
                {user && user?.operator && (
                      <IconButton component={Link} to={`/operators/operator/${user.operator.id}`} color="primary">
                        <PersonIcon fontSize='large'/>
                      </IconButton>)}
                {user && <SwitchButton 
                            api = {"/operators/enable/"} 
                            request ={{username: user.username}} 
                            field_name='is_enabled' 
                            value={user?.operator?.is_enabled ? true : false} 
                            can_change={canChange} 
                            Yes = {DictValue('Enabled')}
                            No = {DictValue('Disabled')}
                            />}            
                </Box>

              </Grid>


              {checkPerm('reset_user_pwd') &&
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Reset Password')}
                </Typography>
                {user &&  <ResetPassword username={user.username} can_change={checkPerm('reset_user_pwd')} />}
              </Grid>}

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Dictionary')}
                </Typography>
                {user && <FilteredSelect 
                  options_api = {"/users/dictionaries/"} 
                  api = {"/users/change_dictionary/"} 
                  request ={{username: user.username}} 
                  current_id = {user?.dictionary_id}
                  update_api = {"/users/change_dictionary/"}  
                  option_name = {'dictionary_id'}
                  can_change={canChange} />}
              </Grid>

              {user?.hasOwnProperty('groups') && canChangeUserPerms && (<Grid item xs={12} sm={12}>
                {<Groups user={user} className= 'my-4'/>}
              </Grid>)}

              {user?.hasOwnProperty('permissions') && canChangeUserPerms && (<Grid item xs={12} sm={12}>
                {<Permissions user={user} className= 'my-4'/>}
              </Grid>)}

            </Grid>
          </Box>
        </Paper>
      </Container>
    )
  );
};
