// Sidebar.js
import React, { useState } from 'react';
import { Offcanvas, Nav } from 'react-bootstrap';
import './Sidebar.css'; // Optional: If you need additional custom styles
import { getUser, getToken, DictValue } from '../utils/common';
import { useNavigate} from 'react-router-dom';
import DictTag from '../dictionaries/DictTag';

const Sidebar = ({show, handleClose}) => {

  const visible = getToken() ? true : false;
  const user = getUser();
 
 console.log("user (sidebar)", user)

  const navigate = useNavigate();

  return (
    <>
      {/*
      <button className="btn btn-primary" onClick={handleShow}>
        <FontAwesomeIcon icon={faBars} />
      </button>
      */}
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{DictValue('Menu')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            {!visible && ( <span className="nav-link" onClick ={() => {handleClose(); navigate("/login")}}><DictTag tag= {'Login'}/></span> )}
            {user?.permissions.includes('view_dictionary') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/dictionaries")}}>{DictValue('Dictionaries')}</span> )}
            {user?.permissions.includes('view_product') && ( <span className="nav-link" onClick ={() => {handleClose(); navigate("/products")}}>{DictValue('Products')}</span> )}
            {user?.permissions.includes('view_operator') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/operators")}}>{DictValue('Operators')}</span> )}
            {user?.permissions.includes('view_job') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/jobs")}}>{DictValue('Jobs')}</span> )}
            {user?.permissions.includes('view_manager') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/managers")}}>{DictValue('Managers')}</span>)} 
            {user?.permissions.includes('kits') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/kits")}}>{DictValue('Kits')}</span> )}
            {user?.permissions.includes('view_tool') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/tools")}}>{DictValue('Tools')}</span> )}
            {user?.permissions.includes('view_skill') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/skills")}}>{DictValue('Skills')}</span> )}
            {user?.permissions.includes('view_user') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/users")}}>{DictValue('Users')}</span> )}
            {user?.permissions.includes('args') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/args")}}>{DictValue('Args')}</span> )}
            {user?.permissions.includes('view_work_panel') && (<span className="nav-link" onClick ={() => {handleClose(); navigate("/operators/operator-panel")}}>{DictValue('Work Panel')}</span> )}
            <span className="nav-link" onClick ={() => {handleClose(); navigate("/dashboard")}}>{DictValue('Dashboard')}</span> 
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;