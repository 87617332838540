import React from 'react';
import {useState, useEffect} from 'react'
import axios from 'axios';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
//import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import { getToken, removeUserSession } from './utils/common';

import PrivateRoutes from './utils/PrivateRoutes.js';
import PublicRoutes from './utils/PublicRoutes.js';

import NotFound from './pages/NotFound';
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Navbar from './pages/Navbar.js';
import Products from './products/Products.js';
import JobsRouter from './jobs/JobsRouter';
import Managers from './managers/Managers';
import Operators from './operators/Operators';
import Kits from './kits/Kits';
import SkillsList from './skills/SkillsList';
import ToolClassesList from './tools/ToolClassesList.js';
import ToolsList from './tools/ToolsList.js';
import ManagersList from './managers/ManagersList.js'
import ToolClasses from './tools/ToolClasses.js';
import Tools from './tools/Tools.js';
import UsersMain from './users/UsersMain.js';
import DictionariesGate from './dictionaries/DictionariesGate.js';
import ChangePassword from './pages/ChangePassword.js';
import Sidebar from './pages/Sidebar.js';
import Args from './args/Args.js';

import { ThemeProvider, CssBaseline } from '@mui/material';
//import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Import your theme file


function App() {
  
//const history = useNavigate();
const [authLoading, setAuthLoading] = useState(true);

const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

// Use environment variable for basename
const baseName = process.env.REACT_APP_BASENAME || '';

const serviceWorkerPath = `${baseName}service-worker.js`;

useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(serviceWorkerPath)
        .then((reg) => console.log('Service Worker registered'))
        .catch((err) => console.error('Service Worker registration failed', err));
    }
}, []);

  /*

useEffect(() => {

  const token = getToken();
  if (!token) {
    return;
  }

  const url = process.env.REACT_APP_BACKEND_URL;   
  const api = '/auth/verify/';   
  
  axios.get(`${url}${api}${token}`).then(response => {

    if (!response.hasOwnProperty("data") || !response.data.hasOwnProperty("status") || !response.data.status === "OK")
    {
      removeUserSession();
      window.location.href = '/login';
      //history('/login');
    } 
      setAuthLoading(false);
    } ).catch(error => {
//     console.log("ERROR", error);
        removeUserSession();
        setAuthLoading(false);

        window.location.href = '/login';
        //history('/login');
  });

//  setAuthLoading(false);

}, []);
if (authLoading && getToken()) {
  return <div className="content">Checking Authentication...</div>
}
*/


  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter basename={baseName.trim()}>
        <div className = "App">

        <Sidebar show = {show} handleClose={handleClose}/>   
        <Navbar show = {show} handleShow = {handleShow}/>

        {/*<div className="container-fluid mt-3">
          <div className="row">
            <div className="col">*/}
              <Routes>
                <Route path="*" element={<NotFound />} />
                {/*<Route path="/" element={<Home />} />*/}
                <Route element={<PublicRoutes />}>
                  <Route path="/" element={<Login />} />
                  <Route path="/login" element={<Login />} />
                </Route>
                <Route element={<PrivateRoutes />}>
                  <Route path="/dashboard" element={<Dashboard sidebarShow={handleShow}/>} />
                  <Route path="/operators/*" element={<Operators />} />
                  <Route path="/products/*" element = {<Products />} />
                  <Route path="/jobs/*" element={<JobsRouter />} />
                  <Route path="/kits" element={<Kits />} />
                  <Route path="/dictionaries/*" element={<DictionariesGate />} />
                  <Route path="/users/*" element={<UsersMain />} />
                  <Route path="/managers/*" element={<ManagersList />} />     
                  <Route path="/skills/" element={<SkillsList />} />     
                  <Route path="/tools/" element={<ToolClassesList />} />     
                  <Route path="/tools/toolclass/:toolclass_id/" element={<ToolsList />} />     
                  <Route path="/change-password/" element={<ChangePassword />} />     
                  <Route path="/args" element={<Args />} />

                </Route>
              </Routes>
            </div>
          {/*</div>
        </div>
      </div>*/}

    </BrowserRouter>
  </ThemeProvider>
  );
};

export default App;
