import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import PersonIcon from '@mui/icons-material/Person'; // Import the icon
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Update } from '../utils/elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, IconButton, Typography,
    CircularProgress, Grid, Container, Avatar } from '@mui/material';

    

const UserCreateModal = ({ open, onClose, onUserCreated }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    first_name: '',
    last_name: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/users/create/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.post(url, formData, options);
      onClose();
      onUserCreated();  // Refetch the user list after creation
    } catch (error) {
      console.log('Error creating user: ', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{DictValue('Create')}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            label={DictValue('Username')}
            name="username"
            fullWidth
            value={formData.username}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label={DictValue('Password')}
            name="password"
            type="password"
            fullWidth
            value={formData.password}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label={DictValue('Email')}
            name="email"
            type="email"
            fullWidth
            value={formData.email}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label={DictValue('First Name')}
            name="first_name"
            fullWidth
            value={formData.first_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label={DictValue('Last Name')}
            name="last_name"
            fullWidth
            value={formData.last_name}
            onChange={handleChange}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete }) => {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.username}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'row', // Align items in a row
            alignItems: 'center', // Vertically center items
            height: '100%',
            boxShadow: 3, // Adding elevation (change the number for different shadow intensity)
            backgroundColor: '#fff', // Ensure the background is white for contrast
            transition: 'box-shadow 0.3s ease-in-out', // Smooth shadow transition
            '&:hover': {
              boxShadow: 6, // Elevate more on hover
            },
          }}
        >
          {/* Link wrapped around Avatar */}
          <Link to={`/users/user/${item.hash}/`} style={{ textDecoration: 'none' }}>
            <Avatar
              src={item.avatar ? `${process.env.REACT_APP_API_URL}${item.avatar}` : null} // Update src if you have avatar URLs
              alt={`${item.first_name} ${item.last_name}`}
              sx={{ width: 56, height: 56, marginRight: 2 }} // Add margin to the right of the avatar
            />
          </Link>

          {/* User Details */}
          <Box sx={{ flex: 1 }}>
              <Typography variant="h6">
            <Update api="/users/change/" request={{ username: item.username }} field_type="text" field_name="first_name" field_value={item.first_name} can_change={can_change} />
                {' '}
                <Update api="/users/change/" request={{ username: item.username }} field_type="text" field_name="last_name" field_value={item.last_name} can_change={can_change} />

            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="body1">
              {item.username}
              </Typography>
              <Typography variant="body1">
                <Update api="/users/change/" request={{ username: item.username }} field_type="text" field_name="email" field_value={item.email} can_change={can_change} />
              </Typography>

            </Box>
            {item && item?.operator && (
            <IconButton component={Link} to={`/operators/operator/${item.operator.id}`} color="primary">
              <PersonIcon fontSize='large'/>
            </IconButton>)}
            {can_delete && (
              <Delete item={item} setVisible={setVisible} />
            )}

          </Box>
        </Box>
      </Grid>
    )
  );
};

const Delete = ({ item, setVisible }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    if (!window.confirm('Confirm delete?')) return;

    try {
      await fetchData('/users/delete/', { username: item.username }, () => {}, () => {}, setVisible);
    } catch (error) {
      setErrorMessage('Error deleting user');
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const UsersList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
      setCanChange(checkPerm('change_user'))
      setCanAdd(checkPerm('add_user'));
      setCanDelete(checkPerm('delete_user'));
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
      checkPageAuthorization(['view_user'], navigate);
      fetchUsers();
    }, [navigate]);
  
    const fetchUsers = () => {
      setIsLoading(true);
      fetchData('/users/list/', {}, setData, setIsLoading);
    };
  
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.username.toLowerCase().includes(filter.toLowerCase()) ||
      item.first_name.toLowerCase().includes(filter.toLowerCase()) ||
      item.last_name.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
      <Container maxWidth="xxl">
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              {canAdd && (
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleShowModal}>
                  {DictValue('Create')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.username}
                    item={item}
                    can_change={canChange}
                    can_delete={canDelete}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <UserCreateModal open={showModal} onClose={handleCloseModal} onUserCreated={fetchUsers} />
      </Container>
    );
  };
  
  export default UsersList;