import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import DictTag from '../dictionaries/DictTag';

const TabsMenu = () => { 
return  (<div className="my-2">
<Nav variant="tabs">
    <Nav.Item>
        <Nav.Link as={Link} to={`/jobs/`} eventKey="jobs"><DictTag tag = 'Jobs'/></Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link as={Link} to={`/jobs/tasks/`} eventKey="tasks"><DictTag tag = 'Tasks'/></Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link as={Link} to={`/jobs/log/`} eventKey="log"><DictTag tag = 'Log'/></Nav.Link>
    </Nav.Item>
    <Nav.Item>
        <Nav.Link as={Link} to={`/jobs/forecast/`} eventKey="forecast"><DictTag tag = 'Forecast'/></Nav.Link>
    </Nav.Item>

    </Nav>
</div>
);
}


export default TabsMenu;