import React from 'react';
import { NavLink } from 'react-router-dom';
import { getUser, getToken, DictValue } from '../utils/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import DictTag from '../dictionaries/DictTag';
import Avatar from '@mui/material/Avatar'; // Importing MUI Avatar

const Navbar = ({ handleShow }) => {
  const visible = !!getToken();
  const user = getUser();

  const navItems = [
    { permission: 'view_dictionary', path: '/dictionaries', label: DictValue('Dictionaries') },
    { permission: 'view_product', path: '/products', label: DictValue('Products') },
    { permission: 'view_operator', path: '/operators', label: DictValue('Operators') },
    { permission: 'view_job', path: '/jobs', label: DictValue('Jobs') },
    { permission: 'view_manager', path: '/managers', label: DictValue('Managers') },
    { permission: 'kits', path: '/kits', label: DictValue('Kits') },
    { permission: 'view_tool', path: '/tools', label: DictValue('Tools') },
    { permission: 'view_skill', path: '/skills', label: DictValue('Skills') },
    { permission: 'view_user', path: '/users', label: DictValue('Users') },
    { permission: 'args', path: '/args', label: DictValue('Args') },
    { permission: 'view_work_panel', path: '/operators/operator-panel', label: DictValue('Work Panel') },
  ];

  return (
    <>
      <div className="bg-light m-0 p-0" style={{ display: 'grid', gridTemplateColumns: '1fr auto', backgroundColor: 'green' }}>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="container-fluid">
              <FontAwesomeIcon className="d-block d-lg-none p-1" icon={faBars} onClick={handleShow} />

              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  {!visible && (
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/login">
                        Login
                      </NavLink>
                    </li>
                  )}
                  {visible &&
                    navItems.map(
                      (item) =>
                        user?.permissions.includes(item.permission) && (
                          <li className="nav-item" key={item.path}>
                            <NavLink className="nav-link" to={item.path}>
                              <DictTag tag={item.label} />
                            </NavLink>
                          </li>
                        )
                    )}
                </ul>
              </div>
            </div>
          </nav>
        </div>
        {visible && (
          <div className="right-div">
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container-fluid d-flex align-items-center">
                <Avatar
                  src={process.env.REACT_APP_BACKEND_URL + user.avatar} // Assuming `user.avatarUrl` contains the URL of the avatar image
                  alt={user.first_name}
                  sx={{ width: 30, height: 30, marginRight: 1 }}
                />
                <NavLink className="nav-link" to="/dashboard">
                  {user.last_name}, {user.first_name}
                </NavLink>
              </div>
            </nav>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
