import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Switch from 'react-switch';
import { getToken } from '../utils/common';
import DictTag from '../dictionaries/DictTag';
import FilterInput from '../utils/FilterInput';

const SkillSelect = ({OperatorID, currentSkills}) => {

    const [isFocused, setIsFocused] = useState(false);
    const [possibleSkills, setPossibleSkills] = useState([]);

    const fetchPossibleSkills = async () => {

      try {

        var request = {'id': null};

        const backend = process.env.REACT_APP_API_URL;   
        const api = `/api/skills/view/`
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
        };

    const response = await fetch(`${backend}${api}`, options);
    const data = await response.json();
    //console.log("DATA", data.data);
    setPossibleSkills(data.data.map((item => item['Skill'])));

} catch (error) {
    console.log(error)
  } finally {
  }

    }

    useEffect(() => {fetchPossibleSkills()}, []);

    const handleModifySkillValue = (event, Skill, value) => {
  
      event.stopPropagation();
      console.log("Modify",  Skill, " of ", OperatorID, " by ", value);
  
        const fetchData = async () => {
  
          try {
  
              var request = {
                'OperatorID': OperatorID,
                'Skill': Skill,
                'SkillChange' : value
              };
  
  
              const Api = process.env.REACT_APP_API_URL;   
              const options = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('AccessToken'),
              },
              body: JSON.stringify(request),
              };
  
  
              const response = await fetch(Api+"/api/operators/" + OperatorID +"/skill_update/", options);
  
          const data = await response.json();
          window.location.reload();
  
  
          } catch (error) {
              console.log('Error fetching data: '+ error)
          } finally {
              //setIsLoading(false);
          }
  
        };
  
        fetchData();   
  
    };
  
    const addSkill = (event) => {
  
      console.log("Add skill ", event.target.value, " to operator ", OperatorID);
  
      const fetchData = async () => {
  
        try {
  
            var request = {
              'OperatorID': OperatorID,
              'Skill': event.target.value,
              'SkillChange' : 1
            };
  
            const Api = process.env.REACT_APP_API_URL;   
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(request),
            };
  
  
        const response = await fetch(Api+"/api/operators/" + OperatorID + "/skill_update/", options);
  
        const data = await response.json();
        window.location.reload();
  
  
        } catch (error) {
            console.log('Error fetching data: '+ error)
        } finally {
            //setIsLoading(false);
        }
  
      };
   
      fetchData();   
   
    };
    
    const handleFocus = () => {
      setIsFocused(!isFocused);
    };
  
    const contentNotFocused = (
      <td onClick = {handleFocus}>
        {currentSkills ? 
          currentSkills.map((skill) => {                      
            return (
              <>
                <span className = 'badge bg-secondary mx-1'>
                  <span className = 'mx-1'>{skill.Skill}</span>
                  <span className = 'mx-2'>{skill.SkillValue}</span> 
                  <FontAwesomeIcon className = 'mx-1' icon={faCaretUp} onClick={(event) => {handleModifySkillValue(event, skill.Skill, 1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                  <FontAwesomeIcon className = 'mx-1' icon={faCaretDown} onClick={(event) => {handleModifySkillValue(event, skill.Skill, -1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                </span>
              </>);
            })
          : null }
      </td>
    );
  
    const contentFocused = (
      <>
        <td className = ''>
          <select 
            onBlur = {handleFocus}
            onChange = {(event) => addSkill(event)}
          >
            <option defaultValue>--</option>
            {possibleSkills.map((skill) => (<option value = {skill}>{skill}</option>))}
          </select>
          <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </td>
      </>
    );
  
    return( isFocused ? contentFocused : contentNotFocused );
  
};


const SelectTrainingSkills = ({OperatorID, currentSkills}) => {

  const [isFocused, setIsFocused] = useState(false);
  const [possibleSkills, setPossibleSkills] = useState([]);

  const fetchPossibleSkills = async () => {

    try {

      var request = {'id': null};

      const backend = process.env.REACT_APP_API_URL;   
      const api = `/api/skills/view/`
      const options = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
      },
      body: JSON.stringify(request),
      };

  const response = await fetch(`${backend}${api}`, options);
  const data = await response.json();
  //console.log("DATA", data.data);
  setPossibleSkills(data.data.map((item => item['Skill'])));

} catch (error) {
  console.log(error)
} finally {
}

  }

  useEffect(() => {fetchPossibleSkills()}, []);

  const handleModifySkillValue = (event, Skill, value) => {

    event.stopPropagation();
    console.log("Modify",  Skill, " of ", OperatorID, " by ", value);

      const fetchData = async () => {

        try {

            var request = {
              'OperatorID': OperatorID,
              'Skill': Skill,
              'SkillChange' : value
            };


            const Api = process.env.REACT_APP_API_URL;   
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(request),
            };

            const response = await fetch(Api+"/api/operators/" + OperatorID +"/training_skill_update/", options);

        const data = await response.json();
        window.location.reload();


        } catch (error) {
            console.log('Error fetching data: '+ error)
        } finally {
            //setIsLoading(false);
        }

      };

      fetchData();   

  };

  const addSkill = (event) => {

    console.log("Add skill ", event.target.value, " to operator ", OperatorID);

    const fetchData = async () => {

      try {

          var request = {
            'OperatorID': OperatorID,
            'Skill': event.target.value,
            'SkillChange' : 1
          };

          const Api = process.env.REACT_APP_API_URL;   
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
          };


      const response = await fetch(Api+"/api/operators/" + OperatorID + "/training_skill_update/", options);

      const data = await response.json();
      window.location.reload();


      } catch (error) {
          console.log('Error fetching data: '+ error)
      } finally {
          //setIsLoading(false);
      }

    };
 
    fetchData();   
 
  };
  
  const handleFocus = () => {
    setIsFocused(!isFocused);
  };

  const contentNotFocused = (
    <td onClick = {handleFocus}>
      {currentSkills ? 
        currentSkills.map((skill) => {                      
          return (
            <>
              <span className = 'badge bg-secondary mx-1'>
                <span className = 'mx-1'>{skill.Skill}</span>
                <span className = 'mx-2'>{skill.SkillValue}</span> 
                <FontAwesomeIcon className = 'mx-1' icon={faCaretUp} onClick={(event) => {handleModifySkillValue(event, skill.Skill, 1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
                <FontAwesomeIcon className = 'mx-1' icon={faCaretDown} onClick={(event) => {handleModifySkillValue(event, skill.Skill, -1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
              </span>
            </>);
          })
        : null }
    </td>
  );

  const contentFocused = (
    <>
      <td className = ''>
        <select 
          onBlur = {handleFocus}
          onChange = {(event) => addSkill(event)}
        >
          <option defaultValue>--</option>
          {possibleSkills.map((skill) => (<option value = {skill}>{skill}</option>))}
        </select>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </td>
    </>
  );

  return( isFocused ? contentFocused : contentNotFocused );

};


const SwitchButton = ({ OperatorID, FieldName, value }) => {

    const [isChecked, setIsChecked] = useState(value);

    const updateParameter = async () => {

        var body =  { 
            OperatorID: OperatorID,
            FieldName: FieldName, 
            FieldValue: isChecked ? 0 : 1,
        };
        console.log(body);
    
        try {
    
            const Api = process.env.REACT_APP_API_URL;       
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(body),
            };
            
        const response = await fetch(Api+"/api/operators/" + OperatorID +"/update/", options);
        
          const data = await response.json();
          if (!response.ok) {
            throw new Error('Failed to update parameter');
          }
          window.location.reload();
  
        } catch (error) {
            console.log(error.message);
            setErrorMessage('Error updating parameter: ' + error.message);
        }
    
    };
    

    useEffect(() => {
      setIsChecked(value);
    }, [value]);
  
    const handleChange = (checked) => {
      setIsChecked(checked);
      updateParameter();
    };
  
    return (
      <div>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          onColor="#86d3ff"
          offColor="#dcdcdc"
          checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
          uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
          handleDiameter={24}
          height={26}
          width = {80}
        />
      </div>
    );
};

const Insert = () => {

    const [ID, setID] = useState('');
    const [Name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleIDChange = (event) => {
        setID(event.target.value);
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    useEffect(() => {}, [disabled]);

    const insert = async () => {
        var body =  { 
            ID: ID,
            Name: Name,
        };

    //      console.log(body);

        try {
            setDisabled(true);

            const Api = process.env.REACT_APP_API_URL;   

            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(body),
            };
            
        const response = await fetch(Api+"/api/operators/insert/", options);
        
        const data = await response.json();
        if (!response.ok) {
            throw new Error('Failed to insert operator');
        }
        setDisabled(false);
        window.location.reload();
        } catch (error) {
            console.log(error.message);
        setErrorMessage('Error inserting operator: ' + error.message);
        }

    };
  
    const contentWhenFocused = (
        <><tr>
            <td><input type="number"  value={ID} onChange={handleIDChange} className="mx-1" disabled={disabled}/></td>
            <td><input type="text"  value={Name} onChange={handleNameChange} className="mx-1" disabled={disabled}/></td>
            <td></td>
            <td></td>
            <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </tr></>
    );

    const contentWhenNotFocused = (
        <><tr>
        <td><>
            <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr></>
    );


    return (isFocused ? contentWhenFocused : contentWhenNotFocused);
  
};

const Delete = ({OperatorID}) => {

    const handleClick = () => {

        const fetchData = async () => {

            try {
    
                var request = {'request': null};
    
                const Api = process.env.REACT_APP_API_URL;   
                const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('AccessToken'),
                },
                body: JSON.stringify(request),
                };
        
    
            const response = await fetch(Api+"/api/operators/" + OperatorID + "/delete/", options);
            const data = await response.json();
            window.location.reload();


            } catch (error) {
                console.log('Error fetching data: '+ error)
            } finally {
                //setIsLoading(false);
            }
    
        };
    
        if (confirm("Confirm delete operator " + OperatorID))
            console.log("deleting", OperatorID);
            fetchData();
    };

    return(<><FontAwesomeIcon icon={faTrash} onClick={handleClick} style={{ cursor: 'pointer' }}></FontAwesomeIcon></>);

};

const Update = ({OperatorID, FieldName, FieldType, FieldValue}) =>  {

    const [parameterValue, setParameterValue] = useState(FieldValue);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState(FieldValue);
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const handleChange = (event) => {
      setParameterValue(event.target.value);
    };
  
  
    useEffect(() => {
      setInitialValue(parameterValue);
    }, []);
  
    useEffect(() => {
    }, [disabled]);
  
    var o;
    if (!isFocused) o = <span onClick = {() => {setIsFocused(true)}}>{(FieldValue == null || FieldValue == '') ? '---' : FieldValue}</span>;
    else {
  
    if (FieldType == 'text') o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType == 'number') o = <input type="number"  step = "0.001" value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType === 'date') o =  <DatePicker
        selected={new Date(parameterValue)}
        onChange={(date) => {setParameterValue(date.toLocaleString('sv').split(' ')[0])}}
      dateFormat="dd/MM/yyyy"
      />;
    else if (FieldType == 'bool') o = (<select
          value={parameterValue}
          onChange={handleChange}>
          <option value={null}>--</option>
          <option value="1">SI</option>
          <option value="0">NO</option>
          </select>);
    }  
  
    const updateParameter = async () => {
      var body =  { 
          OperatorID: OperatorID,
          FieldName: FieldName, 
          FieldValue: parameterValue,
      };
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/operators/" + OperatorID +"/update/", options);
      
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to update parameter');
        }
        setInitialValue(parameterValue);
        setDisabled(false);
        setIsFocused(false);
        window.location.reload();

      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error updating parameter: ' + error.message);
      }
  
  };
  
    return (<>
        {o}
        {((parameterValue !== initialValue)) && isFocused && (<>
            <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </>
        )}
        { isFocused && (<>
            <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </>
        )}          
        {errorMessage && <p>{errorMessage}</p>}
      </>
    );
  
};
  
const OperatorsList = () => {

    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [filters, setFilters] = useState({
      field1: '',
      field2: '',
      field3: '',    
    });
  
    const setFilter1 = (value) => {
      setFilters({ ...filters, field1: value });
    };
    
    const setFilter2 = (value) => {
      setFilters({ ...filters, field2: value });
    };
  
    const setFilter3 = (value) => {
      setFilters({ ...filters, field3: value });
    };

    const fetchData = async () => {

        try {

            var request = {'request': null};

            const backend = process.env.REACT_APP_API_URL;   
            const api = "/api/operators/";   

            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
            };
    
        const response = await fetch(`${backend}${api}`, options);
        console.log(response)
        const data = await response.json();
//        console.log("DATA", data)    
        setContent(<>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
                <tr className="table-light" >
                <th><DictTag tag = 'OperatorID'/></th>
                <th><DictTag tag = 'username'/></th>
                <th><DictTag tag = 'OperatorName'/></th>
                <th><DictTag tag = 'isAvailable'/></th>
                <th><DictTag tag = 'Skills'/></th>
                <th><DictTag tag = 'TrainingSkills'/></th>
                <th><DictTag tag = 'Efficiency'/></th>
                <th><DictTag tag = 'isBusy'/></th>
                <th><DictTag tag = 'AssignedJobs'/></th>
                <th><DictTag tag = 'Details'/></th>
                <th><DictTag tag = 'Actions'/></th>
              </tr>
              <tr className="table-light" >
                <th><FilterInput initialValue={''} setExternalValue={setFilter1}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter2}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter3}/></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
                {data["data"].map((operator) => { 
//                    var link_to_operator = "/operators/" + operator.OperatorID + "/";
//                    var link_to_jobs = "/operators/" + operator.OperatorID + "/jobs/";

                  var visible = true;
                    
                  if (!(filters.field1 == '')) if 
                    (!(
                      (operator.OperatorID.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
                    )) 
                      visible = false;

                  if (!(filters.field2 == '')) if 
                    (operator.username && !(
                      (operator.username.toString().toUpperCase().includes(filters.field2.toUpperCase())) 
                        
                    ) || (operator.username == null)) 
                      visible = false;

                    if (!(filters.field3 == '')) if 
                    (!(
                      (operator.OperatorName.toString().toUpperCase().includes(filters.field3.toUpperCase())) 
                    )) 
                      visible = false;

                      if (!visible) return <></>;

                    return (
                        <>
                        <tr>
                    <td>{operator.OperatorID}</td>
                    <td><Update OperatorID = {operator.OperatorID} FieldName = 'username' FieldType = 'text' FieldValue = {operator.username} /></td>
                    <td><Update OperatorID = {operator.OperatorID} FieldName = 'OperatorName' FieldType = 'text' FieldValue = {operator.OperatorName} /></td>
                    <td><SwitchButton OperatorID = {operator.OperatorID} FieldName = 'isAvailable' value = {operator.isAvailable}/></td>
                    <SkillSelect OperatorID = {operator.OperatorID} currentSkills={operator.skill_set}/> 
                    <SelectTrainingSkills OperatorID = {operator.OperatorID} currentSkills={operator.training_skills}/> 
                    <td><Update OperatorID = {operator.OperatorID} FieldName = 'Efficiency' FieldType = 'number' FieldValue = {operator.Efficiency} /></td>
                    <td><SwitchButton OperatorID = {operator.OperatorID} FieldName = 'isBusy' value = {operator.isBusy}/></td>
                    <td></td>
                    <td>{/*<Link to={link_to_operator}>operator</Link> <Link to={link_to_jobs}>jobs</Link> */}</td>
                    <td><Delete OperatorID = {operator.OperatorID}/></td>                
                    </tr>                        
                        </>
                    );

                }

                )}
                <Insert />

              </tbody>

            </table>
        </>);

    } catch (error) {
        setContent('Error fetching data: '+ error)
      } finally {
        setIsLoading(false);
      }

    };
  
    useEffect(() => {fetchData()}, [filters]);

    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

 
return (<>{isLoading ? loading : content }</>);
};

export default OperatorsList;
