import React, { useEffect, useState } from 'react';
import { useNavigate, Link, useParams} from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData } from '../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Update } from '../utils/elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, IconButton, Typography,
    CircularProgress, Grid, Container } from '@mui/material';

    
const CreateModal = ({ open, onClose, onCreated }) => {

  let {dictionary_id} = useParams()

  const [formData, setFormData] = useState({
    dictionary_id: dictionary_id,
    tag: '',
    value: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/users/dictionary_entry_create/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.post(url, formData, options);
      onClose();
      onCreated();  // Refetch the user list after creation
    } catch (error) {
      console.log('Error creating: ', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Entry</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            label="Tag"
            name="tag"
            fullWidth
            value={formData.tag}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label="Value"
            name="value"
            fullWidth
            value={formData.value}
            onChange={handleChange}
            required
          />

        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder="Filter"
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete }) => {
    
    const [visible, setVisible] = useState(true)

    return (
        visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box sx={{ marginTop: 1 }}>
            <Typography component= "span" variant="body1">Tag: </Typography>
            <Update api = {"/users/dictionary_entry_change/"} request ={{id: item.id}} field_type="text" field_name="tag" field_value={item.tag} can_change={can_change} />
          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Typography component= "span" variant="body1">Value: </Typography>
            <Update api = {"/users/dictionary_entry_change/"} request ={{id: item.id}} field_type="text" field_name="value" field_value={item.value} can_change={can_change} />
          </Box>
          {can_delete && (       
              <Delete item={item} setVisible={setVisible} />
          )}
        </Box>
      </Grid>
      )
    );
};

const Delete = ({ item, setVisible }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    if (!window.confirm('Confirm delete?')) return;

    try {
      await fetchData('/users/dictionary_entry_delete/', { id: item.id}, () => {}, () => {}, setVisible);
    } catch (error) {
      setErrorMessage('Error deleting');
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const List = () => {

    let {dictionary_id} = useParams()

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
      setCanChange(checkPerm('change_dictionary'))
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
      checkPageAuthorization(['view_dictionary'], navigate);
      fetch();
    }, [navigate]);
  
    const fetch = () => {
      setIsLoading(true);
      fetchData('/users/dictionary_entries/', {dictionary_id:dictionary_id}, setData, setIsLoading);
    };
  
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.tag.toLowerCase().includes(filter.toLowerCase()) ||
      item.value.toLowerCase().includes(filter.toLowerCase())

    );
  
    return (
      <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              {canChange && (
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleShowModal}>
                  Create
                </Button>
              )}
            </Grid>
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    can_change={canChange}
                    can_delete={canChange}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <CreateModal open={showModal} onClose={handleCloseModal} onCreated={fetch} />
      </Container>
    );
  };
  
export default List;