import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

import Delete from '../elements/Delete';
import Update from '../elements/Update';
import Insert from './Insert';
import { getToken } from '../utils/common';
import DictTag from '../dictionaries/DictTag';
import FilterInput from '../utils/FilterInput';


const Skills = () => {

    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [filters, setFilters] = useState({
        field1: '',
        field2: '',
      });
    
      const setFilter1 = (value) => {
        setFilters({ ...filters, field1: value });
      };
      
      const setFilter2 = (value) => {
        setFilters({ ...filters, field2: value });
      };


    const fetchData = async () => {

        try {

            var request = {'id': null};

            const backend = process.env.REACT_APP_API_URL;   
            const api = `/api/skills/view/`
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
            };
    
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data);

        setContent(<>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
                <tr className="table-light" >
                <th><DictTag tag = 'SkillID' /></th>
                <th><DictTag tag = 'Skill' /></th>
                <th><DictTag tag = 'SkillName' /></th>
                <th></th>
              </tr>
              <tr className="table-light" >
                <th></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter1}/></th>
                <th><FilterInput initialValue={''} setExternalValue={setFilter2}/></th>
                <th></th>
              </tr>

              </thead>
              <tbody>
                {data["data"].map((item) => { 
                    var visible = true;
                                        
                    if (!(filters.field1 == '')) if 
                    (!(
                        (item.Skill.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
                    )) 
                        visible = false;

                    if (!(filters.field2 == '')) if 
                    (!(
                        (item.SkillName.toString().toUpperCase().includes(filters.field2.toUpperCase())) 
                    )) 
                        visible = false;

                        if (!visible) return <></>;

                    return (
                        <>
                        <tr>
                    <td>{item.SkillID}</td>
                    <td><Update ID = {item.SkillID} Table = 'skills' FieldName = 'Skill' FieldType='text' FieldValue={item.Skill} /></td>
                    <td><Update ID = {item.SkillID} Table = 'skills' FieldName = 'SkillName' FieldType='text' FieldValue={item.SkillName} /></td>
                    <td>
                        <Delete ID = {item.SkillID} target='skills'/>
                    </td>    
                    </tr>                        
                        </>
                    );

                }

                )}
                {<Insert />}

              </tbody>

            </table>
        </>);

    } catch (error) {
        setContent('Error fetching data: '+ error)
      } finally {
        setIsLoading(false);
      }

    };
  
useEffect(
    () => {fetchData()}
    , [filters]
);

    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

return (<>{isLoading ? loading : content }</>);
};

export default Skills;
