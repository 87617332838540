import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import axios from 'axios';

//import { faTrash, faPlus, faSave, faCancel, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom';

import SwitchButton from './SwitchButton';
import Delete from './Delete';
import Restart from './Restart';

import SelectAssignedJobs from './SelectAssignedJobs';


const Managers = () => {

    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const history = useNavigate();

    const fetchData = async () => {

        try {

            var request = {'ManagerID': null};

            const Api = process.env.REACT_APP_API_URL;   
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(request),
            };
    
        const response = await fetch(Api+"/api/managers/", options);
        const data = await response.json();
//        console.log(data);

        setContent(<>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
                <tr className="table-light" >
                <th>ManagerID</th>
                <th>ManagerName</th>
                <th>isActive</th>
                <th>AssignedJobs</th>
                <th>Details</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                {data["data"].map((manager) => { 
                    var link_to_managers = "/managers/";

                    return (
                        <>
                        <tr>
                    <td>{manager.ManagerID}</td>
                    <td>{manager.ManagerName}</td>
                    <td><SwitchButton ManagerID = {manager.ManagerID} FieldName = 'isActive' value = {manager.isActive}/></td>

                    <td><SelectAssignedJobs ManagerID = {manager.ManagerID} currentJobs = {manager.jobs}/></td>
                    <td></td>
                    <td>
                        <Delete ManagerID = {manager.ManagerID}/>
                        <Restart ManagerID = {manager.ManagerID}/>

                    </td>    
                    {/*

                    */}            
                    </tr>                        
                        </>
                    );

                }

                )}
                {/*<Insert />*/}

              </tbody>

            </table>
        </>);

    } catch (error) {
        setContent('Error fetching data: '+ error)
      } finally {
        setIsLoading(false);
      }

    };
  
useEffect(
    () => {fetchData()}
    , []
);

    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

return (<>{isLoading ? loading : content }</>);
};

export default Managers;
