import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import PersonIcon from '@mui/icons-material/Person'; // Import the icon
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, IconButton, Typography,
    CircularProgress, Grid, Container, Avatar } from '@mui/material';

import { Update, SwitchButton, FilteredSelect, KText, KCheckbox, KSwitchIcon } from '../utils/elements';


const CreateModal = ({ open, onClose, onCreated }) => {
  const [formData, setFormData] = useState({
    code: '',
    product_id: '',  // Select input for product_id
    quantity: '',
    batch_size: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  // Handle Product Selection from FilteredSelect
  const handleProductSelect = (productId) => {
    console.log(productId)
    setFormData({
      ...formData,
      product_id: productId
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/jobs/create/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      console.log(formData);
      await axios.post(url, formData, options);
      onClose();
      onCreated();  // Refetch the list after creation
    } catch (error) {
      setError('Error creating job. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{DictValue('Create')}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          
          {/* FilteredSelect for Product ID */}
          <FilteredSelect
            current_id={formData.product_id}
            options_api="/products/list/"
            options_request={{'is_active': true}}  
            update_api={null} 
            request={{}}  
            option_name="product_id" 
            label={DictValue('Product')}
            onChange={handleProductSelect}  // Update formData when a product is selected
          />

          <TextField
            margin="dense"
            label={DictValue('Quantity')}
            name="quantity"
            type="number"
            fullWidth
            value={formData.quantity}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label={DictValue('Batch Size')}
            name="batch_size"
            type="number"
            fullWidth
            value={formData.batch_size}
            onChange={handleChange}
            required
          />

          <TextField
            margin="dense"
            label={DictValue('Code')}
            name="code"
            fullWidth
            value={formData.code}
            onChange={handleChange}
          />

        </Box>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary" disabled={loading || !formData.product_id || !formData.quantity || !formData.batch_size}>
          {loading ? <CircularProgress size={24} /> : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
    
const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete }) => {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'row', // Align items in a row
            alignItems: 'center', // Vertically center items
            height: '100%',
            boxShadow: 3, // Adding elevation (change the number for different shadow intensity)
            backgroundColor: '#fff', // Ensure the background is white for contrast
            transition: 'box-shadow 0.3s ease-in-out', // Smooth shadow transition
            '&:hover': {
              boxShadow: 6, // Elevate more on hover
            },
          }}
        >


         {/* Link wrapped around Avatar */}
         <Link to={`/jobs/job/${item.id}/`} style={{ textDecoration: 'none' }}>
            <Avatar
              src={item.picture ? `${process.env.REACT_APP_API_URL}${item.picture}` : null} // Update src if you have avatar URLs
              alt={`${item.name}`}
              sx={{ width: 112, height: 112, marginRight: 2 }} // Add margin to the right of the avatar
            />
          </Link>


          {/* Details */}
          <Box sx={{ flex: 1 }}>


            <Typography variant="h6">
              {item.quantity} x {item.product_name}
            </Typography>

            <Box sx={{ marginTop: 1 }}>
              <Typography variant="body1">
                Created by {item.created_by} on {item.dt_created}
              </Typography>
              <Typography variant="body1">
                {DictValue('Priority')}: 
                    <Update api="/jobs/change/" 
                    request={{ id: item.id }} 
                    field_type="number" field_name="priority" 
                    field_value={item.priority} 
                    can_change={can_change} />
              </Typography>
              <KSwitchIcon
              uid_name = 'id' 
              uid_value = {item.id} 
              field_name = 'is_active'
              field_value = {item.is_active} 
              label = {DictValue("Active")}
              api = '/jobs/change/'
              
              />

              <KCheckbox
              value = {item.is_locked} 
              label = {DictValue("Locked")}
              />

            </Box>

            {!item.is_locked && can_delete && (
              <Delete item={item} setVisible={setVisible} />
            )}

          </Box>
        </Box>
      </Grid>
    )
  );
};


const Delete = ({ item, setVisible }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    if (!window.confirm('Confirm delete?')) return;

    try {
      await fetchData('/jobs/delete/', { id: item.id }, () => {}, () => {}, setVisible);
    } catch (error) {
      setErrorMessage('Error deleting');
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const List = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
      setCanChange(checkPerm('change_job'))
      setCanAdd(checkPerm('add_job'));
      setCanDelete(checkPerm('delete_job'));
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
      checkPageAuthorization(['view_job'], navigate);
      fetch();
    }, [navigate]);
  
    const fetch = () => {
      setIsLoading(true);
      fetchData('/jobs/list/', {}, setData, setIsLoading);
    };
  
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.code.toLowerCase().includes(filter.toLowerCase()) || 
      item.product.toLowerCase().includes(filter.toLowerCase()) || 
      item.id.toLowerCase().includes(filter.toLowerCase()) 
    );
  
    return (
      <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              {canAdd && (
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleShowModal}>
                  {DictValue('Create')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    can_change={canChange}
                    can_delete={canDelete}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <CreateModal open={showModal} onClose={handleCloseModal} onCreated={fetch} />
      </Container>
    );
  };
  
  export default List;