import React, { useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import { CheckCircle, Cancel, AssignmentTurnedIn, AssignmentLate } from '@mui/icons-material'; // Import additional icons
import axios from 'axios';
import { getToken } from '../utils/common';

export const KitChips = ({kits}) => {
    return (
      <Box> 
        {kits.map((item, index) => (<KitChip index = {index} data={item}/>))}
      </Box>
    );
  };
  

export const KitChip = ({ index, data: initialData }) => {
  const [data, setData] = useState(initialData);
 
  const toggleActivation = async () => {
    try {
      const backend = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${backend}/jobs/batch_actions/`, {
        batch_id: data.batch_id,
        action: 'kit_ready',
        kit_id: data.kit_id,
        value: !data.is_ready,
      },
      {
        headers: {
          'Authorization': `${getToken()}`,
          'Content-Type': 'application/json',
        }
      });

      console.log("response", response)
      if (response.status === 200) {
        console.log('Kit activation status updated successfully');
        
        setData({ ...data, is_ready: !data.is_ready });
      } else {
        console.error('Failed to update kit activation status');
      }
    } catch (error) {
      console.error('Error updating kit activation status:', error);
    }
  };

  return (
        <Chip
          key={index}
          label={`${data.batch_code}/${data.kit_id}`}
          style={{
            marginRight: 4,
            marginBottom: 4,
            border: data?.is_ready ? '2px solid green' : 'none',
            cursor: 'pointer',
          }}
          icon={
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent the chip click event from triggering
                toggleActivation();
              }}
              size="small"
            >
              {data.is_ready ? <CheckCircle sx={{ color: 'green' }} /> : <Cancel sx={{ color: 'orange' }} />}
            </IconButton>
          }
        />
  );
};

