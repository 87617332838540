import React from 'react';
import {Link, Route, Routes } from 'react-router-dom';

import Jobs from './Jobs';
import DictTag from '../dictionaries/DictTag.js';
import Job from './Job';
import { BatchInfo } from './BatchInfo.js';
import JobsList from './JobsList'

/*
import PossibleTasks from './PossibleTasks';
import OperatorsLog from './OperatorsLog.js'
import OperatorsForecast from './OperatorsForecast.js'
*/
function JobRouter() {
return (
<>
    {/*<Link className='mx-2' to={'/jobs'}><DictTag tag = 'Jobs'/></Link>*/}
    
    <Routes>
        <Route index element={<JobsList />} />
        <Route path="job/:id/*" element={<Job />} />
        <Route path="batches/:batch_id/batch_info/" element={<BatchInfo />} />
        {/*
        <Route path="tasks/" element={<PossibleTasks AllJobs = {true}/>} />
        <Route path="log/" element={<OperatorsLog DataOptions = {{filter: null}}/>} />
        <Route path="forecast/" element={<OperatorsForecast DataOptions = {{filter: null}}/>} />
        */}
    </Routes>
</>

);

}

export default JobRouter;
