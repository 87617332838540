import React, { useState } from 'react';
import { Button, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import { getToken } from '../utils/common';

const LaunchScriptButton = () => {
  const [loading, setLoading] = useState(false);
  const [output, setOutput] = useState('');
  const [error, setError] = useState('');

  const handleClick = async () => {
    setLoading(true);
    setOutput('');
    setError('');

    const backend = process.env.REACT_APP_API_URL;

    const api = "/managers/launch/";
    const request = {}; // Your request payload here (if any)
    
    // Prepare headers and options
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': getToken(), // Replace with your token fetching method
      },
    }; 
    try {

      const response = await axios.post(backend+api, request, options);      
      const { status, output, exit_code } = response.data;

      if (status === 'success') {
        setOutput(`Exit Code: ${exit_code}\nOutput: ${output}`);
      } else {
        setError('Error: Script failed to execute.');
      }
    } catch (err) {
      setError(`Error: ${err.response?.data?.message || err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} /> : 'Launch Script'}
      </Button>
      {output && (
        <Typography variant="body1" color="success.main" style={{ marginTop: '10px' }}>
          {output}
        </Typography>
      )}
      {error && (
        <Typography variant="body1" color="error" style={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}
    </div>
  );
};


const App = () => {
    return <LaunchScriptButton/>;
}

export default App;
