import React, { useState, useEffect } from 'react';
import { getToken, fetchData } from '../utils/common';
import {
  Container,
  Paper,
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  IconButton, Switch, Autocomplete
} from "@mui/material";

//import { FormControl, TextField, Select, MenuItem, CircularProgress, IconButton } from '@mui/material';

import axios from 'axios';
import { TextField, MenuItem, Select, FormControl, InputLabel, CircularProgress } from '@mui/material';

import { Save as SaveIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { it } from 'date-fns/locale'; // Import the Italian locale from date-fns
//import Autocomplete from '@mui/material/Autocomplete';


//import Switch from 'react-switch';
//import DatePicker from 'react-datepicker'
//import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faFilter } from '@fortawesome/free-solid-svg-icons';


import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const KContainer = ({ title, children }) => (
    <Container maxWidth="xl">
      <Typography variant="h4" align="center" gutterBottom>
        {title}
      </Typography>
      <Paper elevation={3}>
        <Box p={3}>
          <Grid container spacing={3}>
            {children}
          </Grid>
        </Box>
      </Paper>
    </Container>
);

export const KText = ({ label, value, sm = 6 }) => (
  <Grid item xs={12} sm={sm}>
    <Typography variant="subtitle1" color="textSecondary">
      {label}
    </Typography>
    <Typography variant="body1">
      {value}
    </Typography>
  </Grid>
);

export const KCheckbox = ({ label, value, sm = 6, color = 'blue' }) => (
    <Grid item xs={12} sm={sm}>
      <FormControlLabel
        control={<Checkbox
          checked={value}
          readOnly
          sx={{
            color: 'black',
            '&.Mui-checked': {
              color: color,
            },
          }}
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
        />}
        label={label}
      />
    </Grid>
);

export const KSwitchIcon = ({ uid_name, uid_value, field_name, field_value, label, api, icon_on=null, icon_off=null, sm = 6, onSuccess=null }) => {
    const [isChecked, setIsChecked] = useState(field_value);
  
    useEffect(() => {
      setIsChecked(field_value);
    }, [field_value]);
  
    const updateParameter = async (newValue) => {
      const request = {
        [uid_name]: uid_value,
        field_name: field_name,
        field_value: newValue,
        action: 'update_field'
      };
  
      try {
        const backend = process.env.REACT_APP_API_URL;
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${getToken()}`,
          },
          body: JSON.stringify(request),
        };
  
        const response = await fetch(`${backend}${api}`, options);
  
        if (!response.ok) {
          throw new Error("Failed to update parameter");
        }
  
        // If the API call succeeds, update the state
        setIsChecked(newValue);
        if (onSuccess) onSuccess();
      } catch (error) {
        console.log(error.message);
        // Handle error if needed
      }
    };
  
    const handleChange = () => {
      const newValue = !isChecked;
      updateParameter(newValue);
    };
  
    return (
      <Grid item xs={12} sm={sm}>
      <FormControlLabel
        control={
          <IconButton onClick={handleChange} size="small">
            {isChecked ? (icon_on ? icon_on : <CheckBoxIcon />) : (icon_off ? icon_off : <CheckBoxOutlineBlankIcon />)}
          </IconButton>
        }
        label={label}
      />
      </Grid>
    );
};

export const Update = ({ api, request, field_name, field_type, field_value, can_change }) => {
  const [parameterValue, setParameterValue] = useState(field_value || '');
  const [initialValue, setInitialValue] = useState(field_value || '');
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (data && data[field_name]) {
      setParameterValue(data[field_name]);
    }
  }, [data, field_name]);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  const updateParameter = async () => {
    setDisabled(true);
    let _request = { ...request, field_name: field_name, field_value: parameterValue };
    try {
      await fetchData(api, _request, setData, setIsFocused);
      setInitialValue(parameterValue); // Update initial value on success
      setIsFocused(false);
    } catch (error) {
      setErrorMessage('Error updating parameter: ' + error.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {!isFocused || !can_change ? (
        <span
          style={{ color: 'blue', cursor: 'pointer' }}
          onClick={() => setIsFocused(true)}
        >
          {parameterValue === null || parameterValue === '' ? '---' : 
            (field_type === 'date' 
              ? new Date(parameterValue).toLocaleDateString('it', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                })
              : parameterValue)
          }
        </span>
      ) : (
        <div>
          {field_type === 'text' && (
            <TextField
              variant="outlined"
              value={parameterValue}
              onChange={handleChange}
              disabled={disabled}
              size="small"
            />
          )}
          {field_type === 'number' && (
            <TextField
              variant="outlined"
              type="number"
              step="0.001"
              value={parameterValue}
              onChange={handleChange}
              disabled={disabled}
              size="small"
            />
          )}
          {field_type === 'date' && (
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
              <DatePicker
                value={parameterValue ? new Date(parameterValue) : null}
                onChange={(date) => setParameterValue(date.toISOString().split('T')[0])}
                format="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} size="small" />}
              />
            </LocalizationProvider>
          )}
          {field_type === 'bool' && (
            <Select
              value={parameterValue}
              onChange={handleChange}
              disabled={disabled}
              size="small"
            >
              <MenuItem value="">--</MenuItem>
              <MenuItem value="1">SI</MenuItem>
              <MenuItem value="0">NO</MenuItem>
            </Select>
          )}

          {parameterValue !== initialValue && (
            <IconButton color="primary" onClick={updateParameter}>
              <SaveIcon />
            </IconButton>
          )}
          <IconButton color="secondary" onClick={() => {
              setIsFocused(false);
              setParameterValue(initialValue);  // Reset on cancel
            }}>
            <CancelIcon />
          </IconButton>
        </div>
      )}
      {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}
    </>
  );
};

export const SwitchButton = ({ api, request, field_name, value, can_change, Yes = 'Yes', No = 'No', onChange=null }) => {
  const [isChecked, setIsChecked] = useState(value ? true : false);
  const [initialValue, setInitialValue] = useState(value ? true : false);
  const [disabled, setDisabled] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const updateParameter = async () => {
    setDisabled(true); // Disable button during the update
    let _request = { ...request, field_name: field_name, field_value: !isChecked }; // Update request with field values
    const backend = process.env.REACT_APP_API_URL;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getToken(),
      },
      body: JSON.stringify(_request),
    };

    try {
      await fetch(`${backend}${api}`, options); // API call
      setInitialValue(!isChecked); // Update initial value on success
    } catch (error) {
      setErrorMessage('Error updating parameter: ' + error.message); // Handle error
    } finally {
      setDisabled(false); // Re-enable after update
      if (!(onChange==null)) onChange();
    }
  };

  const handleChange = (event) => {
    const checked = event.target.checked;
    setIsChecked(checked); // Update local state
    if (checked !== initialValue) {
      updateParameter(); // Call update when there's a change
    }
  };

  return (
    <>
      <Switch
        checked={isChecked}
        onChange={handleChange}
        disabled={!can_change || disabled} // Disable based on can_change or loading state
        color="primary" // Set switch color to primary
        inputProps={{ 'aria-label': 'controlled' }} // Accessibility
        sx={{ transform: 'scale(1.1)' }} // Scale the switch to make it larger
      />
      {isChecked ? <Typography variant="caption" sx={{ fontSize: '1.1rem' }}>{Yes}</Typography> : <Typography variant="caption" sx={{ fontSize: '1.1rem' }}>{No}</Typography>}
      {errorMessage && <Typography color="error">{errorMessage}</Typography>} {/* Show error message */}
    </>
  );
};


export const FilteredSelect = ({ current_id, options_api, update_api, request, option_name, options_request = {}, onChange = null, label = 'Search' }) => {
  const [selectedValue, setSelectedValue] = useState(current_id || ''); // Initialize with current_id
  const [searchQuery, setSearchQuery] = useState(''); // Single input for both search and selection
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModified, setIsModified] = useState(false); // Track if selection is modified
  const [saving, setSaving] = useState(false); // Track save status

  // Fetch options from the API
  useEffect(() => {
    const fetchOptions = async () => {
      const backend = process.env.REACT_APP_API_URL;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${getToken()}`, // Assuming getToken() returns the token
        },
      };

      try {
        setLoading(true);
        const response = await axios.post(`${backend}${options_api}`, options_request, config); // Empty body in POST request
        setOptions(response.data.data); // Assuming the API returns an array of options
      } catch (err) {
        setError('Failed to load options');
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [options_api]);

  // Filter options based on search query
  const filteredOptions = options.filter(option =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    option[option_name]?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle option selection
  const handleOptionSelect = (optionId) => {
    const selectedOption = options.find(option => option.id === optionId);
    if (selectedOption) {
      setSearchQuery(selectedOption.name); // Set the search query to the selected option's name
      setSelectedValue(optionId); // Update the selected value
      console.log(optionId, current_id)
      setIsModified(optionId !== current_id); // Mark as modified if value differs from current_id
      if (!update_api) onChange(optionId);
    }
  };

  // Handle save action
  const handleSave = async () => {
    const backend = process.env.REACT_APP_API_URL;
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${getToken()}`,
      },
    };

    const updatedRequest = {
      ...request,
      selected_option: selectedValue, // Include the selected value in the request
      option_name: option_name, // Include the dynamic option_name in the request
    };

    try {
      setSaving(true);
      await axios.post(`${backend}${update_api}`, updatedRequest, config); // Send the updated request
      setIsModified(false); // Reset the modified state after saving
      if (onChange !== null) onChange();
    } catch (err) {
      console.error('Failed to save:', err);
    } finally {
      setSaving(false);
    }
  };

  return (
    <FormControl fullWidth>
      {/* Single Search & Select Input */}
      <Autocomplete
        options={filteredOptions}
        getOptionLabel={(option) => option.name}
        loading={loading}
        inputValue={searchQuery}
        onInputChange={(event, newInputValue) => setSearchQuery(newInputValue)}
        onChange={(event, newValue) => handleOptionSelect(newValue?.id || '')}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            margin="dense"
            error={Boolean(error)}
            helperText={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress size={24} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />

      {/* Show Save Icon if selection is modified */}
      {isModified && update_api && (
        <IconButton onClick={handleSave} disabled={saving} style={{ marginLeft: '8px' }}>
          {saving ? <CircularProgress size={24} /> : <SaveIcon />}
        </IconButton>
      )}
    </FormControl>
  );
};
