import React, { useState, useEffect } from 'react';
import { getToken, DictValue } from '../utils/common';
import { CircularProgress, Alert} from '@mui/material';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip, Box, IconButton, TextField
} from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// import { useParams } from 'react-router-dom';
import { Update, SwitchButton, KCheckbox, KText, FilteredSelect } from '../utils/elements';


const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};


const fetchData = async (id, setData, setIsLoading, setError) => {
    try {
        if (setIsLoading) setIsLoading(true);
        setError(null); // Reset error state
        const backend = process.env.REACT_APP_API_URL;
        const api = "/managers/actions/";
        const request = { id:id , action: 'pending-tasks' };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setData(data.data);
        console.log(data.data)

    } catch (error) {
        console.error('Error fetching data:', error);
        setError(error.message || 'An error occurred while fetching data.');
    } finally {
        if (setIsLoading) setIsLoading(false);
    }
};


const OperatorChip = ({ job, operator, onDelete=null }) => {
    
    return (
      <Chip
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {operator.first_name + ' ' + operator.last_name}
          </div>
        }
        variant="outlined"
        sx={{
          borderColor: operator.is_available && operator.assigned_tasks.length === 0 ? 'green' : 'red'
        }}
      />
    );
  };
  
  const ToolChip = ({ tool }) => {
    
    return (
      <Chip
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {tool.code + ' ' + tool.name}
          </div>
        }
        variant="outlined"
        sx={{
          borderColor: !tool.is_being_used ? 'green' : 'red'
        }}
      />
    );
  };


  const SkillChip = ({ skill }) => {
    
    return (
      <Chip
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {skill.tag + ' ' + skill.level}
          </div>
        }
        variant="outlined"
      />
    );
  };
  


const TaskCard = ({ item }) => {
    const width = 2;
  
    return (
      <Card
        style={{
          marginBottom: '16px',
          border: false ? '4px solid red' : 'none',
        }}
      >
        <CardContent>
          {/* Render item details here */}
          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            <KText label="task_id" value={item.id} sm={width} />
            <KText label="job_id" value={item.job_id} sm={width} />
            <KText label="batch_code" value={item.batch_code} sm={width} />
            <KText label="batch_progress" value={item.batch_progress+'%'} sm={width} />
            <KText label="component_progress" value={item.component_progress+'%'} sm={width} />
            <KText label="component_level" value={item.component_level} sm={width} />

          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            <KText label="Product" value={item.product_name} sm={width} />
            <KText label="Component" value={item.component_name} sm={width} />
            <KText label="Sequence" value={item.sequence_name} sm={width} />
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            <KText label="Complexity" value={item.complexity} sm={width} />
              {item.required_skills.map((skill, index) => <SkillChip key = {index} skill={skill}/>)}
              <KCheckbox label="Requires qualification" value={item.requires_qualification} sm={width} />
          </Grid>
          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            <KText label="Qualified Operators" value={item.qualified_operators.length} sm={width} />
            <KText label="Qualified Operators Index" value={item.qualified_operators_index} sm={width} />
            {item.qualified_operators.map((operator, index) => <OperatorChip key = {index} operator={operator}/>)}
          </Grid>

          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            <KCheckbox label="Requires tool" value={item.requires_tool} sm={width} />
            {item.required_tools.map((tool, index) => <ToolChip key = {index} tool={tool}/>)}
          </Grid>


          {/* Accordion for JSON.stringify */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="json-content"
              id="json-header"
            >
              <Typography>View Raw JSON</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography component="pre" style={{ overflowX: 'auto' }}>
                {JSON.stringify(item, null, 2)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </CardContent>
      </Card>
    );
  };

  
const App = () => {
    // const { id } = useParams();
    const id = 1;
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        fetchData(id, setData, setIsLoading, setError);
    }, [id]);


    // Filtering logic
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
      };
    
    const filteredData = data.filter(item => 
    item.sequence_name.toLowerCase().includes(filter.toLowerCase()) || 
    item.component_name.toLowerCase().includes(filter.toLowerCase()) ||
    item.product_name.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ padding: '16px' }}>
                    {error && (
                        <Alert severity="error" style={{ marginBottom: '16px' }}>
                            {error}
                        </Alert>
                    )}
                <Grid item>
                <FilterInput value={filter} onChange={handleFilterChange} />
                </Grid>

                    {data.length === 0 && !error ? (
                        <Typography
                            variant="h6"
                            color="textSecondary"
                            style={{ textAlign: 'center', marginTop: '16px' }}
                        >
                            No pending tasks.
                        </Typography>
                    ) : (
                        filteredData.map((item, index) => (
                            <TaskCard key={index} item={item} />
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default App;
