import React, { useEffect, useState} from 'react';

import { useNavigate } from 'react-router-dom';

import { getUser, getToken } from '../utils/common';

import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faFilter } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Switch from 'react-switch';


const Item = ({item, filters}) => {

    console.log(item);

    var visible = true;

    if (!(filters.filter1 == '')) if (!item.Argument.toString().toUpperCase().includes(filters.filter1.toString().toUpperCase())) visible = false;
    if (!(filters.filter2 == '')) if (!item.Value.toString().toUpperCase().includes(filters.filter2.toString().toUpperCase())) visible = false;

    if (!visible) return <></>;
    return (
        <>
        <tr>
            <td><Update item = {item} FieldName = 'Argument' FieldValue =  {item.Argument}/></td>
            <td><Update item = {item} FieldName = 'Value' FieldValue =  {item.Value}/></td>
            <td><Delete item = {item} /></td>
        </tr>
        </>
    );
};

const InputComponent = ({initialValue, setExternalValue}) => {
    // State to hold the value of the input
    const [inputValue, setInputValue] = useState(initialValue);
  
    // Function to handle input change
    const handleChange = (event) => {
      setInputValue(event.target.value);
      setExternalValue(event.target.value);
    };
  
    return (
      <div>
        {/* Input component */}
        <FontAwesomeIcon className = 'mx-1' icon={faFilter}></FontAwesomeIcon>
        <input
          type="text"
          value={inputValue} // Bind input value to state
          onChange={handleChange} // Call handleChange function on change
        />
        {/* Display the value of the input <p>Input value: {inputValue}</p>*/}        
      </div>
    );
};

const Insert = () =>  {

    const [Argument, setArgument] = useState('');
    const [Value, setValue] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    const handleArgumentChange = (event) => {
      setArgument(event.target.value);
    };
  
    const handleValueChange = (event) => {
        setValue(event.target.value);
      };
  
      useEffect(() => {
    }, [disabled]);
  
    
    const insert = async () => {
      var request =  { 
          Argument: Argument,
          Value: Value,
        };
  
//        console.log(request)
        const API = process.env.REACT_APP_BACKEND_URL;
        const options = {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(), 
            },
        }

        var url = `${API}/api/args/insert/`;

        axios.post(url, request, options).then(
            response => {
//                console.log(response);
                window.location.reload(); 
            }).catch(error => {
                console.log(error);
                setErrorMessage(error.message);
            });
  };
  
  const contentFocused = (
    <><tr>
        <td><input type="text"  value={Argument} onChange={handleArgumentChange} className="mx-1" disabled={disabled}/></td>
        <td><input type="text"  value={Value} onChange={handleValueChange} className="mx-1" disabled={disabled}/></td>
        <td>
            <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </td>
</tr></>);

const contentNotFocused = (<><tr>
  <td><>
      <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
  </></td>
  <td></td>
  <td></td>
</tr></>);


return (isFocused ? contentFocused : contentNotFocused);
  
};

const Delete = ({ item }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
  /* eslint-disable no-restricted-globals */
  if (!confirm('Confirm delete?')) return;
  /* eslint-enable no-restricted-globals */
    try {
        const user = getUser();
        const auth = user.auth;
          var canDelete = false;
      if (!auth.hasOwnProperty('args')) return <></>;  
      if (auth.args.includes('a') || auth.args.includes('d')) canDelete = true;
    
      if (!canDelete) return <></>;
    
      const request = {
        Argument: item.Argument
      };

      const API = process.env.REACT_APP_BACKEND_URL;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: getToken(),
        },
      };

      const url = `${API}/api/args/delete/`;

      const response = await axios.post(url, request, options);
      // Optionally, handle success response here
 //     console.log(response);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faTrash}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const Update = ({item, FieldName, FieldValue}) =>  {

  const user = getUser();
  const auth = user.auth;
  var isUpdatable = false;

  const [parameterValue, setParameterValue] = useState(FieldValue);
  const [errorMessage, setErrorMessage] = useState('');
  const [initialValue, setInitialValue] = useState(FieldValue);
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  useEffect(() => {setInitialValue(parameterValue);}, []);

  useEffect(() => {}, [disabled]);

  if (!auth.hasOwnProperty('args')) return <></>;
  
  if (auth.args.includes('a') || auth.args.includes('u')) isUpdatable = true;

  if (!isUpdatable) return <>{FieldValue}</>;


  const updateParameter = async () => {
  
    /* eslint-disable no-restricted-globals */
    if (!confirm('Confirm update?')) return;
    /* eslint-enable no-restricted-globals */
      try {
        const user = getUser();
        const auth = user.auth;
        if (!auth.hasOwnProperty('args')) return;
        if (!(auth.args.includes('a') || auth.args.includes('u'))) return;

        const request = {
          Argument: item.Argument,
          target_field: FieldName,
          target_value: parameterValue,
        };

        const API = process.env.REACT_APP_BACKEND_URL;
        const options = {
          headers: {
            'Content-Type': 'application/json',
            Authorization: getToken(),
          },
        };

        const url = `${API}/api/args/update/`;
//        console.log("request", request)
        const response = await axios.post(url, request, options);
//        console.log(response);
        window.location.reload();
      } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
    }
  }; // end update parameter  

  var o;
  if (!isFocused) o = <span  style = {{color: 'blue'}} onClick = {() => {setIsFocused(true)}}>{(FieldValue == null || FieldValue == '') ? '---' : FieldValue }</span>;
  else {

    o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>

  }
  return (<>
      {o}
      {((parameterValue !== initialValue)) && isFocused && (<>
          <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          </>
      )}
      { isFocused && (<>
          <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          </>
      )}          
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );

};

  


const Args = () => {

    const navigate = useNavigate();

    try {
      const user = getUser();
      const auth = user.auth;
      if (!auth.hasOwnProperty('args')) navigate('/dashboard');
      if (!(auth.args.includes("a") || auth.args.includes("r"))) navigate('/dashboard');
    }
    catch (error) {console.log(error); navigate('/dashboard');}

    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
  
    const [filters, setFilters] = useState({
      filter1: '',
      filter2: '',
    });
  
    const setFilter1 = (value) => {
      setFilters({ ...filters, filter1: value });
    };
    
    const setFilter2 = (value) => {
      setFilters({ ...filters, filter2: value });
    };


    const fetchData = async () => {
  
    try {

        const url = process.env.REACT_APP_BACKEND_URL;
        const api = '/api/args/get/';

        const request = {};
        const options = {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': getToken(), 
            },
          }

          axios.post(`${url}${api}`, request, options).then(
            response => {
                setLoading(false);
  //              console.log(response);
  //              console.log("DATA", response.data.data);
                setData(response.data.data);
            }
        ).catch(
            error => {
                setLoading(false);
                console.log(error);
            }
        );

  
      } catch (error) {console.log('Error fetching data: '+ error)
      } finally {setLoading(false);}
  
    };
    
    // fetch data only on mount
    useEffect(() => {fetchData()}, []);

    var content = 
    <>
        <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
            <tr className="table-light" >
            <th>
                <p className='my-0'>Argument</p>
            </th>
            <th>
                <p className='my-0'>Value</p>
            </th>
         
            <th>Actions</th>
            </tr>
            <tr className="table-light" >
            <th><InputComponent initialValue={''} setExternalValue={setFilter1}/></th>
            <th><InputComponent initialValue={''} setExternalValue={setFilter2}/></th>
            <th></th>

            </tr>
            <Insert />
            </thead>
            <tbody>{
                data.map((item)=>
                <Item 
                    item = {item} 
                    filters={filters} 
                    />
                )}
                </tbody>
        </table>

    </>;


    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;
  
    return (<>{isLoading ? loading :  content }</>);

};

export default Args;
