import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import { Update, SwitchButton, KCheckbox, FilteredSelect, KText } from '../utils/elements';

import { RequiredSkillChip, ToolclassChip } from '../products/ComponentDetail'
import { Container, Paper, Box, Grid, Typography, Button, Avatar, Chip, IconButton } from '@mui/material';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const fetchJobData = async (id, setData, setPictureUrl, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/jobs/detail/";
        const request = { 'id': id };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data)
        setData(data.data);
        setPictureUrl(process.env.REACT_APP_API_URL + data.data.picture)
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};


const OperatorChip = ({ job, operator, onDelete=null }) => {
  
  const handleDelete = async () => {
    await fetchData('/jobs/manage-operators/', {
      id: job.id,
      operator_id: operator.id,
      action: 'remove'
    }, null, null);
    onDelete();
  };


  return (
    <Chip
      key={'operator_' + operator.id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {operator.first_name + ' ' + operator.last_name}
        </div>
      }
      variant="outlined"
      onDelete={handleDelete}
      deleteIcon={<DeleteIcon />}
      sx={{
        borderColor: operator.is_available ? 'green' : 'red'
      }}
    />
  );
};

const ToolChip = ({ job, tool, onDelete=null }) => {
  
  const handleDelete = async () => {
    await fetchData('/jobs/manage-tools/', {
      id: job.id,
      tool_id: tool.id,
      action: 'remove'
    }, null, null);
    onDelete();
  };


  return (
    <Chip
      key={'tool_' + tool.id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {tool.code + ' ' + tool.name}
        </div>
      }
      variant="outlined"
      onDelete={handleDelete} // This adds the delete functionality
      deleteIcon={<DeleteIcon />} // Custom delete icon
    />
  );
};

const AvailableSkillChip = ({ skill }) => {
  

  return (
    <Chip
      key={'available_skill_chip_' + skill.id + '_' + skill.level}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {skill.tag + ' ' + skill.level + ': ' +skill.available_fte + ' FTE'}
        </div> 
      }
      variant="outlined"
    />
  );
};

const RequiredQualificationChip = ({ sequence }) => {
  

  return (
    <Chip
      key={'required_qualification_chip_' + sequence.id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {sequence.full_name ? sequence.full_name : sequence.name}
        </div> 
      }
      variant="outlined"
    />
  );
};

const Batch = ({batch, onRefetch = null}) => {

  const [loading, setLoading] = useState(false);

  // Function to handle the "Fix Tasks" API call
  const handleFixTasks = async () => {
    setLoading(true);
    const backend = process.env.REACT_APP_API_URL;
    const api = "/jobs/batches/fix-tasks/";
    const request = { 'id': batch.id };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
    };


    try {
      // Replace with your actual API URL and method
      const response = await fetch(`${backend}${api}`, options);

      if (response.ok) {
        console.log("Tasks fixed successfully");
        if (onRefetch) {
          onRefetch(); // Trigger a data refetch if onRefetch is provided
        }
//        else window.location.reload();

      } else {
        console.error("Failed to fix tasks:", response.statusText);
      }
    } catch (error) {
      console.error("Error in fixing tasks:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Accordion>
<AccordionSummary expandIcon={<ExpandMoreIcon />}>
  <Box display="flex" alignItems="center" width="100%" gap={2}>
    <Typography variant="h6" sx={{ mr: 2 }}>
      <b>{batch.name}</b>
    </Typography>

    <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {'Kit ready'}
      <SwitchButton
        api="/jobs/batches/change/"
        request={{ id: batch.id }}
        field_name="kit_ready"
        value={batch.kit_ready}
        can_change={true}
      />
    </Typography>

    <Typography variant="body1" sx={{ marginLeft: 'auto', mr: 2 }}>
      Progress: {batch.progress}%
    </Typography>

    <Typography variant="body1" sx={{ mr: 2 }}>
      <Link to={`/jobs/job/${batch.job_id}/batches/batch/${batch.id}/`} style={{ textDecoration: 'none' }}>
        {batch.code}
      </Link>
    </Typography>
  </Box>
  
</AccordionSummary>    <AccordionDetails>
        {/* Link wrapped around IconButton with Info icon */}
        <Link to={`/jobs/job/${batch.job_id}/batches/batch/${batch.id}/`} style={{ textDecoration: 'none' }}>
          <IconButton aria-label="Batch details" color="primary">
            <InfoOutlinedIcon />
          </IconButton>
        </Link>

        <Button
          variant="contained"
          color="primary"
          onClick={handleFixTasks}
          disabled={loading}
          sx={{ mt: 2 }}
        >
          {loading ? "Fixing Tasks..." : "Fix Tasks"}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}

const Detail = () => {

  let { id } = useParams();

  const [job, setJob] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [canChange, setCanChange] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const navigate = useNavigate();

  const refetch = () => {
    fetchJobData(id, setJob, setPictureUrl, setIsLoading);
  }

  useEffect(() => {
    checkPageAuthorization(['view_job'], navigate);
    refetch();
  }, [navigate]);

  useEffect(() => {
    setCanChange(checkPerm('change_job'))
    setCanDelete(checkPerm('delete_job'));
  }, []);


  
  return (
    job && (
      <Container maxWidth="xxl"  sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        {/*<Typography variant="h4" align="center" gutterBottom>
        {DictValue('Job Details')}
        </Typography>*/}
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={3}>

              <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Avatar
                src={job.picture ? `${process.env.REACT_APP_API_URL}${job.picture}` : null}
                alt={`${job.name}`}
                sx={{ width: 112, height: 112, mx: "auto" }} // Center avatar horizontally
              />
                <Typography variant="h6" align="center">
                {job.quantity} x {job.product.name} ({job.product.id})
                </Typography>
              </Grid>

              {/* Job Information */}

              <KText label={DictValue('Id')} value={job.id}/>
              <KText label={DictValue('Code')} value={job.code}/>
              <KText label={DictValue('Product Name')} value={job.product.name}/>
              <KText label={DictValue('Product Code')} value={job.product.code}/>
              <KText label={DictValue('ETC')} value={job.product.expected_time_hhmm}/>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('isActive')}
                </Typography>
                {job && <SwitchButton api = {"/jobs/change/"} request ={{id: job.id}} field_name='is_active' value={job.is_active} can_change={canChange} />}
              </Grid>
  
              <Grid item xs={12} sm={6}>
                <KCheckbox value={job.is_locked} label={'isLocked'}/>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('One operator per batch')}
                </Typography>
                {job && <SwitchButton api = {"/jobs/change/"} request ={{id: job.id}} field_name='ensure_one_operator_per_batch' value={job.ensure_one_operator_per_batch} can_change={canChange} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Auto assign tool (if only one available)')}
                </Typography>
                {job && <SwitchButton api = {"/jobs/change/"} request ={{id: job.id}} field_name='auto_assign_tool_if_only_one_available' value={job.auto_assign_tool_if_only_one_available} can_change={canChange} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Auto assign tool (if multiple available)')}
                </Typography>
                {job && <SwitchButton api = {"/jobs/change/"} request ={{id: job.id}} field_name='auto_assign_tool_if_multiple_available' value={job.auto_assign_tool_if_multiple_available} can_change={canChange} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                <Link to={`/jobs/job/${job.id}/possible-tasks/`} style={{ textDecoration: 'none' }}>
                {DictValue('Possible Tasks')}
                </Link>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                <Link to={`/jobs/job/${job.id}/operator-panel/`} style={{ textDecoration: 'none' }}>
                {DictValue('Operator panel')}
                </Link>
                </Typography>
              </Grid>

            {/* Section: selection */}

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
              {DictValue('Assigned operators')}
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                  {job && (
                      <FilteredSelect
                          options_api="/operators/list/"
                          request={{ id: job.id, action: 'add' }}
                          current_id={''}
                          update_api="/jobs/manage-operators/"
                          option_name="operator_id"
                          can_change={canChange}
                          onChange={refetch}
                          label = 'Operators'
                      />
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                  {job?.operators?.map((operator) => (
                      <OperatorChip key={operator.id} job={job} operator={operator} onDelete={refetch}/>
                  ))}
              </Grid>

              <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
              {DictValue('Assigned tools')}
                  </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                  {job && (
                      <FilteredSelect
                          options_api="/tools/list/"
                          request={{ id: job.id, action: 'add' }}
                          current_id={''}
                          update_api="/jobs/manage-tools/"
                          option_name="tool_id"
                          can_change={canChange}
                          onChange={refetch}
                          label = 'Tools'
                      />
                  )}
              </Grid>
              <Grid item xs={12} sm={6}>
                  {job?.tools?.map((tool) => (
                      <ToolChip key={'tool_' + tool.id} job={job} tool={tool} onDelete={refetch}/>
                  ))}
              </Grid>

            {/* Section: requirements*/}

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Required tools')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.product?.required_tools.map((tool) => (<ToolclassChip key={tool.id} toolclass={tool}/>))}
              </Box>  
            </Grid>


            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Required toolclasses')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.product?.required_toolclasses.map((toolclass) => (<ToolclassChip key={toolclass.id} toolclass={toolclass}/>))}
              </Box>  
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Available toolclasses')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.available_toolclasses.map((toolclass) => (<ToolclassChip key={toolclass.id} toolclass={toolclass}/>))}
              </Box>  
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Required skills')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.product?.required_skills.map((skill) => (<RequiredSkillChip key = {skill.id} skill={skill}/>))}
              </Box>  
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Available skills')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.available_skills.map((skill) => (<AvailableSkillChip key = {'available_skill'+skill.id+'-'+skill.level} skill={skill}/>))}
              </Box>  
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Required qualifications')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.product?.required_qualifications.map((sequence) => (<RequiredQualificationChip key = {'sequence_' + sequence.id} sequence={sequence}/>))}
              </Box>  
            </Grid>

            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Available qualifications')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >

              {job?.available_qualifications.map((sequence) => (<RequiredQualificationChip key = {'sequence_' + sequence.id} sequence={sequence}/>))}
              </Box>  
            </Grid>

            <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Batches')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >
                  {job.batches.map((batch) => (<Batch batch={batch}/>)) }
                </Box>
              </Grid>


            </Grid>



          </Box>
        </Paper>
      </Container>
    )
  );
};

export default Detail;