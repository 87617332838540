import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Container, Tabs, Tab, Box } from '@mui/material';

import DictTag from '../dictionaries/DictTag.js';
import ManagerDetails from './ManagerDetails.js';
import ManagerPendingTasks from './ManagerPendingTasks.js';
import ManagerAssignmentLog from './ManagerAssignmentLog.js';


const App = () => {
    const id = 1;

    const path = `/managers/manager/${id}/`;
    const basePath = `manager/${id}/`;

    const location = useLocation(); // Get the current location
    const tabs = [`${path}details/`, `${path}pending-tasks/`];

    // Determine the current tab based on the current location
    const currentTab = tabs.indexOf(location.pathname);

    return (
        <>
            <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>

            <Box sx={{ marginBottom: 2 }}>
                <Tabs
                    value={currentTab === -1 ? 0 : currentTab} // Default to the first tab if no match
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Manager navigation tabs"
                >
                    <Tab 
                        label={<DictTag tag="Manager Info" />} 
                        component={Link} 
                        to={`${path}details/`} 
                    />
                    <Tab 
                        label={<DictTag tag="Pending Tasks" />} 
                        component={Link} 
                        to={`${path}pending-tasks/`} 
                    />
                    <Tab 
                        label={<DictTag tag="Assignment Log" />} 
                        component={Link} 
                        to={`${path}assignment-log/`} 
                    />
                </Tabs>
            </Box>
            <Box>
                <Routes>
                    <Route path="manager/1/details" element={<ManagerDetails />} />
                    <Route path="manager/1/pending-tasks" element={<ManagerPendingTasks />} />
                    <Route path="manager/1/assignment-log" element={<ManagerAssignmentLog />} />
                </Routes>
            </Box>
        </Container>
        </>
    );
};

export default App;
