import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchData } from '../utils/common';
import { KContainer, KText, KCheckbox, KSwitchIcon } from '../utils/elements';
import { PlayCircle, StopCircle } from '@mui/icons-material';

import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, Grid, Chip } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { KitChips } from './KitChip';

const Component = ({item}) => {
  return (item &&
    <Accordion key={item.component_id}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <KText label= {null} value={`${item.component_name} (${item.component_id})`} sm={6}/>
      {!item.is_completed && <KCheckbox label="Possible" value={item.is_possible} sm={2} />}
      {!item.is_completed && item.is_possible && <KCheckbox label="Assigned" value={item.is_assigned} sm={2} />}
      {!item.is_completed && item.is_assigned && <KCheckbox label="Started" value={item.is_started} sm={2} />}
      {item.is_started && <KCheckbox label="Completed" value={item.is_completed} sm={2} color='green'/>}

    </AccordionSummary>
    <AccordionDetails>
      <Component component={item} />
      <Grid container spacing={2}>
        <KText label="Quantity" value={item.quantity} sm={4}/>
        <KText label="ET per unit" value={item.expected_time_per_unit_hhmm} sm={4} />
        <KText label="ET total" value={item.expected_time_hhmm} sm={4} />
      </Grid>
      <Grid item xs={12}>
          <SubcomponentsList data={item?.subcomponents} />
      </Grid>

      <Grid item xs={12} sm={12}>
      <TasksList tasks={item.tasks}/>
      </Grid>

    </AccordionDetails>
  </Accordion>
);

}

const SubcomponentsList = ({data}) => {

  return (
      <Box
      >
      {data.length > 0 && <Typography variant="subtitle1" color="textSecondary">Subcomponents</Typography>}
        {data?.map((item) => <SubcomponentChip item = {item}/>)}
      </Box>
    );
  };
  
const SubcomponentChip = ({item}) => { 
  return (<Chip
  key={item.component_id}
  label={`${item.component_name} (${item.component_id})`}
  style={{
    marginRight: 4,
    marginBottom: 4,
    border: item?.is_completed ? '2px solid green' : 'none',
    cursor: 'pointer',
  }}
  />);
};

const ComponentsList = ({components}) => {

return (
    <Box
    sx={{
      backgroundColor: '#f5f5f5', // very light grey
      border: '2px solid #ccc', // solid 2px border
      padding: '16px',
      marginBottom: '16px',
      borderRadius: '4px',
    }}
    >
    <Typography variant="subtitle1" color="textSecondary">Components</Typography>
      {components?.map((item) => <Component item = {item}/>)}
    </Box>
  );
};

const TasksList = ({tasks}) => {

return (
  <Box
  sx={{
    backgroundColor: '#f5f5f5', // very light grey
    border: '2px solid #ccc', // solid 2px border
    padding: '16px',
    marginBottom: '16px',
    borderRadius: '4px',
  }}
  >
    <Typography variant="subtitle1" color="textSecondary">Tasks</Typography>
    {tasks?.map((item) => <Task item = {item}/>)}
    </Box>
  );
};

const Task = ({item}) => {

return (item &&
  <Box>
      <Accordion key={item.sequence_id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <KText label={null} value={`[${item.sequence_id}] ${item.sequence_name}`} sm={12}/>
          {!item.is_completed && <KCheckbox label="Possible" value={item.is_possible} sm={3} />}
          {!item.is_completed && item.is_possible && <KCheckbox label="Assigned" value={item.is_assigned} sm={3} />}
          {!item.is_completed && item.is_assigned && <KCheckbox label="Started" value={item.is_started} sm={3} />}
          {item.is_started && <KCheckbox label="Completed" value={item.is_completed} sm={3} color='green'/>}

        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {item.is_assigned && <KText label="Assigned to" value={item.operator.operator_name} sm={12}/>}
            <KText label="ET per unit" value={item.expected_time_per_unit_hhmm} sm={4} />
            <KText label="ET total" value={item.expected_time_hhmm} sm={4} />
          </Grid>
        </AccordionDetails>
      </Accordion>
  </Box>
);
};

export const BatchInfo = () => {
  const { batch_id } = useParams();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchData("/jobs/batch_actions/", { batch_id: batch_id, action: 'get_info' }, setData, setIsLoading);
  }, [batch_id]);

  return (
    data && (
      <><KContainer title="Batch Details">
        <KText label="JobID" value={data.job_id} />
        <KText label="Product" value={`${data.product_name} (${data.product_id})`} />
        
        <KText label="BatchID" value={data.batch_id} />
        <KText label="BatchCode" value={data.batch_code} />
        
        <KText label="Quantity" value={data.quantity} />
        <KText label="ET per unit" value={data.expected_time_per_unit_hhmm} />
        <KText label="ET total" value={data.expected_time_hhmm} />
        <KCheckbox label="Started" value={data.is_started} />
        <KCheckbox label="Completed" value={data.is_completed} />
        <KCheckbox label="Assigned" value={data.is_assigned} />
        <KSwitchIcon 
            uid_name = 'batch_id'
            uid_value = {data.batch_id}
            field_name='is_active' 
            field_value={data.is_active}
            label='Active'
            api='/jobs/batch_actions/'
            icon_on={<PlayCircle sx={{ color: '#4CAF50' }} />}
            icon_off={<StopCircle sx={{ color: '#F44336' }} />}
          />
      <Grid item xs={12} sm={12}>
        <Typography variant="subtitle1" color="textSecondary">
          Kits
        </Typography>
        <KitChips kits={data.kits}/>    
      </Grid>
      <Grid item xs={12} sm={12}>
        <ComponentsList components={data.components}/>    
      </Grid>
      </KContainer>
      </>
  )
  );
};