import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography,
  Grid,
  ListItem, ListItemText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getToken, getUser, DictValue } from '../utils/common';

const Permissions = ({ user }) => {
  const [itemsLeft, setItemsLeft] = useState([]);
  const [itemsRight, setItemsRight] = useState([]);

  useEffect(()=>{
    
    // Helper function to extract codenames
    const extractCodenames = (permissions) => {
      return permissions.map(permission => {
        const parts = permission.split('.');
        return parts.length > 1 ? parts[1] : permission; // Extract the part after '.'
      });
    };

    const assignor = getUser();
    const assignorCodenames = extractCodenames(assignor.permissions);

    const userCodenames = extractCodenames(user.permissions)

    // Filter out codenames that are already in user.codenames
    const filteredCodenames = assignorCodenames.filter(
      (codename) => !userCodenames.includes(codename)
    );

    setItemsRight(extractCodenames(user.permissions));
    setItemsLeft(filteredCodenames);
  }, [])

  const handleOnDragEnd = async (result) => {
    if (!result.destination) return;

    const sourceList = result.source.droppableId === 'leftBox' ? itemsLeft : itemsRight;
    const destinationList = result.destination.droppableId === 'leftBox' ? itemsLeft : itemsRight;
    const setSourceList = result.source.droppableId === 'leftBox' ? setItemsLeft : setItemsRight;
    const setDestinationList = result.destination.droppableId === 'leftBox' ? setItemsLeft : setItemsRight;

    const updatedSourceList = Array.from(sourceList);
    const [movedItem] = updatedSourceList.splice(result.source.index, 1);

    if (result.source.droppableId !== result.destination.droppableId) {
      const updatedDestinationList = Array.from(destinationList);
      updatedDestinationList.splice(result.destination.index, 0, movedItem);

      setSourceList(updatedSourceList);
      setDestinationList(updatedDestinationList);

      try {
        const backend = process.env.REACT_APP_API_URL;
        const api = "/users/change_user_perms/";

        const request = {
          username: user.username,
          permission: movedItem,
          action: result.destination.droppableId === 'leftBox' ? 'remove' : 'add',
        };

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
//        console.log(response);
      } catch (error) {
        console.error('Failed to update item:', error);
      }
    } else {
      updatedSourceList.splice(result.destination.index, 0, movedItem);
      setSourceList(updatedSourceList);
    }
  };

  return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{DictValue('Permissions')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Droppable droppableId="leftBox">
                    {(provided) => (
                      <Paper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ padding: 8 }}
                        elevation={3}
                      >
                        <Typography variant="h6" gutterBottom>
                        {DictValue('Not granted')}
                        </Typography>
                        <Grid container spacing={2}>
                          {itemsLeft.map((item, index) => (
                            <Grid item xs={6} key={item}>
                              <Draggable draggableId={item} index={index}>
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      backgroundColor: 'red',
                                      color: 'white',
                                      textAlign: 'center',
                                      padding: 1,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <ListItemText primary={item} />
                                  </ListItem>
                                )}
                              </Draggable>
                            </Grid>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      </Paper>
                    )}
                  </Droppable>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Droppable droppableId="rightBox">
                    {(provided) => (
                      <Paper
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{ padding: 8 }}
                        elevation={3}
                      >
                        <Typography variant="h6" gutterBottom>
                        {DictValue('Granted')}
                        </Typography>
                        <Grid container spacing={2}>
                          {itemsRight.map((item, index) => (
                            <Grid item xs={6} key={item}>
                              <Draggable draggableId={item} index={index}>
                                {(provided) => (
                                  <ListItem
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    sx={{
                                      backgroundColor: 'green',
                                      color: 'white',
                                      textAlign: 'center',
                                      padding: 1,
                                      borderRadius: 1,
                                    }}
                                  >
                                    <ListItemText primary={item} />
                                  </ListItem>
                                )}
                              </Draggable>
                            </Grid>
                          ))}
                          {provided.placeholder}
                        </Grid>
                      </Paper>
                    )}
                  </Droppable>
                </Grid>
              </Grid>
            </DragDropContext>
        </AccordionDetails>
      </Accordion>
  );
};

export default Permissions;
