import React, { useState } from 'react';
import { setUserSession } from '../utils/common';
import axios from 'axios';
import { MD5 } from 'crypto-js';
import { Box, Button, CircularProgress, TextField, Typography, Container, Paper } from '@mui/material';

/*
const Login = () => {

  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const backendurl = process.env.REACT_APP_BACKEND_URL;   
  const api = '/auth/login/';

  const handleLogin = () => {
    setError(null);
    setLoading(true);

    axios.post(`${backendurl}${api}`, 
      { username: username.value, password: MD5(password.value).toString() }
    ).then(response => {
        setLoading(false);
        setUserSession(response.data.key, response.data); 
        window.location.reload();    
    }).catch(error => {
      setLoading(false);
      setError('Login failed. Please check your credentials.');
      console.log(error);
    });
  }
*/

const Login = () => {

  const username = useFormInput('');
  const password = useFormInput('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const backendurl = process.env.REACT_APP_BACKEND_URL;   
//  const api = '/users/login/';
  const api = '/login/';

  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios.post(`${backendurl}${api}`, 
      { username: username.value, password: password.value },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).then(response => {
        setLoading(false);
//        console.log(response)
//        console.log("data", response.data)
//        console.log("token", response.data.data.token)

        setUserSession(response.data.data.token, response.data.data); 
        window.location.reload();    
        setError('Login succesfull.');

}).catch(error => {
      setLoading(false);
      setError('Login failed. Please check your credentials.');
      console.log(error);
    });
  }


  const backgroundImageUrl = `/img/kuoyo_background.jpg`;


  return (

  <div style={{margin: '0px', backgroundSize: 'cover',  backgroundImage: `url(${backgroundImageUrl})`, height: '100vh'}}> 
  
  <Container className = 'p-5' component="main" maxWidth="xs" sx = {{backgroundColor: 'transparent'}}>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 0 }}>
        <Typography component="h1" variant="h5" align="center">
          Kuoyo 5.0
        </Typography>
        <Box component="form" sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            autoFocus
            {...username}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            {...password}
          />
          {error && <Typography color="error">{error}</Typography>}
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>
        </Box>
      </Paper>
    </Container>
  </div>
  );
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Login;
