import { React, useState, useEffect } from 'react';

import TabsMenu from './TabsMenu';
import FilterInput from '../utils/FilterInput';


const Row = ({item, filters}) => {

  var visible = true;

  if (!(filters.field1 == '')) if 
    (!(
      (item.LocalTimeFmt.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
    )) 
      visible = false;

  if (!(filters.field2 == '')) if 
    (!(
      (item.OperatorID.toString().toUpperCase().includes(filters.field2.toUpperCase())) 
      || (item.OperatorName.toString().toUpperCase().includes(filters.field2.toUpperCase()))
    )) 
      visible = false;
  
    if (!(filters.field3 == '')) if 
    (!(
      (item.event.toString().toUpperCase().includes(filters.field3.toUpperCase())) 
    )) 
      visible = false;

    if (!(filters.field4 == '')) if 
    (!(
      (item.ProductID.toString().toUpperCase().includes(filters.field4.toUpperCase())) 
      || (item.ProductName.toString().toUpperCase().includes(filters.field4.toUpperCase()))
    )) 
      visible = false;

    if (!(filters.field5 == '')) if 
    (!(
      (item.BatchCode.toString().toUpperCase().includes(filters.field5.toUpperCase())) 
      || (item.JobID.toString().toUpperCase().includes(filters.field5.toUpperCase()))
      || (item.JobCode.toString().toUpperCase().includes(filters.field5.toUpperCase()))
    )) 
      visible = false;

    if (!(filters.field6 == '')) if 
    (!(
      (item.ComponentName.toString().toUpperCase().includes(filters.field6.toUpperCase())) 
      || (item.SequenceName.toString().toUpperCase().includes(filters.field6.toUpperCase()))
    )) 
      visible = false;

      if (!visible) return <></>;

  return (
    <>
      <tr key = {item.utcTime}>
        <td>
          <p className="my-0">{item.LocalTimeFmt}</p>
        </td>
        <td>
          <p className="my-0"><b>{item.OperatorName}</b></p>
          <p className="my-0">{item.OperatorID}</p>
        </td>
        <td>
          <p className="my-0"><b>{item.event}</b></p>
        </td>
        <td>
          <p className="my-0">{item.ProductName}</p>
          <p className="my-0">{item.ProductID}</p>
        </td>
        <td>
          <p className="my-0"><b>{item.BatchCode}</b></p>
          <p className="my-0">{item.JobID} {item.JobCode}</p>
        </td>
        <td>
          <p className="my-0">{item.ComponentName}</p>
          <p className="my-0">{item.SequenceName}</p>
        </td>
        <td>
          <p className="my-0">{item.ExpectedTime ? 'ExpectedTime: ' + item.ExpectedTime.toString() : null}</p>
          <p className="my-0">{item.ActualTime ? 'ActualTime: ' + item.ActualTime.toString() : null}</p>
        </td>

      </tr>
    </>
  );


};


const OperatorsLog = ({DataOptions}) => {
 
  const api = '/api/logs/operators/'
  const url = `${process.env.REACT_APP_API_URL}${api}`;

  const [filters, setFilters] = useState({
    field1: '',
    field2: '',
    field3: '',
    field4: '',
    field5: '',
    field6: '',

  });

  const setFilter1 = (value) => {
    setFilters({ ...filters, field1: value });
  };
  
  const setFilter2 = (value) => {
    setFilters({ ...filters, field2: value });
  };

  const setFilter3 = (value) => {
    setFilters({ ...filters, field3: value });
  };

  const setFilter4 = (value) => {
    setFilters({ ...filters, field4: value });
  };

  const setFilter5 = (value) => {
    setFilters({ ...filters, field5: value });
  };

  const setFilter6 = (value) => {
    setFilters({ ...filters, field6: value });
  };
  
  var requestBody = DataOptions;
  
  const now = new Date();
  requestBody["Now"] = now.toLocaleString('sv');
  requestBody["UTCoffset"] = now.getTimezoneOffset();

  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  };


  const [data, setData] = useState([]);

  const update = () => {
    fetch(url, request)
    .then((res) => {
//      console.log(res)
    return res.json();
    })
    .then((data) => {
//    console.log(data);
    setData(data.data);
  })
    .catch(rejected => {
    console.log(rejected);
  });
  } 


  useEffect(() => {
    update();
    const intervalId = setInterval(update, 2500);
    return () => clearInterval(intervalId);
  }, []);


 
return (
  <>

  <TabsMenu />

  <div> 
    <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
    <thead>
      <tr className="table-light">
        <th className = "col-1">Datetime</th>
        <th className = "col-1">Operator</th>
        <th className = "col-1">Event</th>
        <th className = "col-1">Product</th>
        <th className = "col-1"><p className="my-0">Batch</p><p className="my-0">Job</p></th>
        <th className = "col-3"><p className="my-0">Component</p><p className="my-0">Task</p></th>
        <th className = "col-1"><p className="my-0">Times</p></th>
      </tr>

      <tr className="table-light" >
            <th><FilterInput initialValue={''} setExternalValue={setFilter1}/></th>
            <th><FilterInput initialValue={''} setExternalValue={setFilter2}/></th>
            <th><FilterInput initialValue={''} setExternalValue={setFilter3}/></th>
            <th><FilterInput initialValue={''} setExternalValue={setFilter4}/></th>
            <th><FilterInput initialValue={''} setExternalValue={setFilter5}/></th>
            <th><FilterInput initialValue={''} setExternalValue={setFilter6}/></th>
            <th></th>
      </tr>

    </thead>
    <tbody>
        {data.map((item) => <Row item = {item} filters = {filters}/>)}
    </tbody>
    </table>
  </div>
  </>);
};

export default OperatorsLog;