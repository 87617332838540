import React, { useState } from 'react';
import { Button, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import axios from 'axios';
import { getToken, DictValue } from '../utils/common';

const CreateComponentModal = ({ product_id, parent_id, api, open, onClose, onCreated }) => {
  
    const initialData = {
      name: '',
      code: '',
      product_id: product_id,
      quantity: 1,
    };
  
    const [formData, setFormData] = useState(initialData);
  
    const handleChange = (e) => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const url = `${process.env.REACT_APP_BACKEND_URL}${api}`;
        const options = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
        };
        
        const dataToSend = { ...formData, parent_id };
//        console.log('sending form with data:', dataToSend);
  
        const response = await axios.post(url, dataToSend, options);
        const newComponent = response.data; // Assume response contains the new component data
        onClose();
        onCreated(newComponent);  // Pass the new component data to onCreated
      } catch (error) {
        console.log('Error creating root component: ', error);
      }
    };
  
    return (
      <Dialog open={open} onClose={onClose}>
        {!parent_id && <DialogTitle>Create Root Component</DialogTitle>}
        {parent_id && <DialogTitle>Create SubComponent for ComponentID {parent_id}</DialogTitle>}
        <DialogContent>
          <Box component="form" onSubmit={handleSubmit}>
          <TextField
              margin="dense"
              label={DictValue("Code")}
              name="code"
              fullWidth
              value={formData.code}
              onChange={handleChange}
              required
            />
            <TextField
              margin="dense"
              label={DictValue("Name")}
              name="name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              required
            />
            {parent_id && <TextField
              margin="dense"
              label={DictValue("Quantity")}
              name="quantity"
              type="number"
              fullWidth
              value={formData.quantity}
              onChange={handleChange}
              required
            />}
  
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
          {DictValue("Cancel")}
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
          {DictValue("Create")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  
export default CreateComponentModal;