
import { React, useState } from 'react';

export const StartButton = ({OperatorID, BatchID, ComponentID, SequenceID, KitID, tools, ExpectedTime}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["KitID"] =KitID;
      request["tools"] =tools;
      request["ExpectedTime"] =ExpectedTime;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

//      console.log("REQUEST", request);

      const response = await fetch(Api+"/api/start_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
      //     console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Avvia'}
      </button>
      {/*responseData && (
        <div>
          <h2>Response Data:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      )*/}
    </div>
  );
};

export const PauseButton = ({OperatorID, BatchID, ComponentID, SequenceID}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["Event"] ='pause';

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

//      console.log("REQUEST", request);

      const response = await fetch(Api+"/api/pause_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
      //     console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Pausa'}
      </button>
    </div>
  );
};

export const ResumeButton = ({OperatorID, BatchID, ComponentID, SequenceID}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      // Perform API call

      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["Event"] ='resume';

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

//      console.log("REQUEST", request);

      const response = await fetch(Api+"/api/resume_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
      //     console.log(data)
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Riprendi'}
      </button>
    </div>
  );
};

export const CompleteButton = ({OperatorID, BatchID, ComponentID, SequenceID, KitID, tools, ExpectedTime}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {

      setIsLoading(true);
      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["tools"] =tools;
      request["ExpectedTime"] =ExpectedTime;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/complete_task/", options);
//      console.log("COMPLETED", response)
      const data = await response.json();
      setResponseData(data);
      window.location.reload();

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Completa'}
      </button>

    </div>
  );
};

export const RejectButton = ({OperatorID, BatchID, ComponentID, SequenceID, KitID, tools}) => {
  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    if (!confirm("Confermi RIFIUTO?")) return;
    try {
      setIsLoading(true);
      const request = {};
      const now = new Date();
      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] =OperatorID;
      request["BatchID"] =BatchID;
      request["ComponentID"] =ComponentID;
      request["SequenceID"] =SequenceID;
      request["KitID"] =KitID;
      request["tools"] =tools;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/reject_task/", options);
      const data = await response.json();
      setResponseData(data);
      window.location.reload();
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button onClick={fetchData} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Rifiuta'}
      </button>
      {/*responseData && (
        <div>
          <h2>Response Data:</h2>
          <pre>{JSON.stringify(responseData, null, 2)}</pre>
        </div>
      )*/}
    </div>
  );
};

export const ToolBadge = ({tool, OperatorID}) => {
  
  var bgcolor = tool.AssignedOperator ? "red" : "green";
  var textcolor = tool.AssignedOperator ? "black" : "white";

  const [responseData, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const request = {};
      const now = new Date();

      request["Now"] = now.toLocaleString('sv');
      request["UTCoffset"] = now.getTimezoneOffset();
      request["OperatorID"] = OperatorID;
      request["ToolID"] = tool.ToolID;

      const Api = process.env.REACT_APP_API_URL;
   
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
//          'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(Api+"/api/assign_tool/", options);
      const data = await response.json();
      setResponseData(data);
      //console.log(data)
      window.location.reload();

    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };


  return <>
    <span 
      className ="badge fst-italic" 
      style = {{backgroundColor: bgcolor, color: textcolor}}
      onClick={tool.AssignedOperator ? null : fetchData}    
    >
      <small>{tool.ToolID} {tool.ToolName}</small>
    </span>
  </>
};
