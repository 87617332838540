import {React, useState, useEffect} from 'react';
import { AvatarUpload } from './AvatarUpload';
import { useParams } from 'react-router-dom';
import { getToken } from '../utils/common';
import { Container, Paper, Box, Grid, Typography } from '@mui/material';
import Switch from 'react-switch';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCancel, faCaretUp, faCaretDown, faTrash, faSave } from '@fortawesome/free-solid-svg-icons';

export const SkillSelect = ({operator, is_training}) => {

  const [isFocused, setIsFocused] = useState(false);
  
  const fetchData = async (_skill_id, _value) => {

    try {

        var request = {
          'operator_id': operator.operator_id,
          'is_training': is_training,
          'skill_id': _skill_id,
          'change_skill_value_by' : _value ? _value : 0
        };


        const backend = process.env.REACT_APP_API_URL;
        const api = "/operators/skill_change/";
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);

    const data = await response.json();
//    window.location.reload();


    } catch (error) {
        console.log('Error fetching data: '+ error)
    } finally {
        //setIsLoading(false);
    }

  };

  const handleModifySkillValue = (event, skill_id, value) => {

    event.stopPropagation();
    fetchData(skill_id, value);

  };

  const addSkill = (event) => {

    const _skill_id = event.target.value;
    fetchData(_skill_id, 1);   
    window.location.reload();

  };
  
  const handleFocus = () => {
    setIsFocused(!isFocused);
  };

  const target_list = is_training ? operator.skills_in_training : operator.skills

  const SkillBadge = ({skill}) => {
    
    const [skillValue, setSkillValue] = useState(skill.skill_value)

    return (
      <>
        <span className = 'badge bg-secondary mx-1'>
          <span className = 'mx-1'>{skill.skill_tag}</span>
          <span className = 'mx-2'>{skillValue}</span> 
          <FontAwesomeIcon className = 'mx-1' icon={faCaretUp} onClick={(event) => {handleModifySkillValue(event, skill.skill_id, 1); setSkillValue(skillValue+1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
          <FontAwesomeIcon className = 'mx-1' icon={faCaretDown} onClick={(event) => {handleModifySkillValue(event, skill.skill_id, -1); if (skillValue > 0) setSkillValue(skillValue-1)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </span>
      </>);

  }

  const contentNotFocused = (
    <div onClick = {handleFocus}>
      {target_list ? 
        target_list.map((skill) => <SkillBadge key={skill.skill_id} skill = {skill}/>)
        : ' -- ' }
    </div>
  );

  const contentFocused = (
    <>
      <div className = ''>
        <select 
          onBlur = {handleFocus}
          onChange = {(event) => addSkill(event)}
        >
          <option defaultValue>--</option>
          {operator && operator.hasOwnProperty('skills_possible') && operator.skills_possible.map((skill) => (<option key = {skill.skill_id} value = {skill.skill_id}>{skill.skill_tag}</option>))}
        </select>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </div>
    </>
  );

  return( isFocused ? contentFocused : contentNotFocused );

};

export const QualificationSelect = ({operator, is_training}) => {

  const [isFocused, setIsFocused] = useState(false);
  
  const fetchData = async (_sequence_uid, action) => {

    try {

        var request = {
          'operator_id': operator.operator_id,
          'sequence_uid': _sequence_uid,
          'is_training': is_training,
          'action' : action
        };

        const backend = process.env.REACT_APP_API_URL;
        const api = "/operators/qualification_change/";
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();

    } catch (error) {
        console.log('Error fetching data: '+ error)
    } finally {
        //setIsLoading(false);
    }

  };

  const addQualification = (event) => {

    const _sequence_uid = event.target.value;
        fetchData(_sequence_uid, 'add');   
        window.location.reload();

  };
  

  const handleFocus = () => {
    setIsFocused(!isFocused);
  };

  const target_list = is_training ? operator.qualifications_in_training : operator.qualifications

  const QualificationBadge = ({sequence}) => {

    const [isVisible, setVisible] = useState(true)   
    const [isTraining, setTrainig] = useState(is_training)   
    
    const handleChangeQualification = (event, action) => {
      console.log(event, action)
      event.stopPropagation();
      fetchData(sequence.sequence_uid, action);
      if (action === 'upgrade' || action === 'downgrade') window.location.reload();
      if (action === 'remove') setVisible(false);
    };
  
    
    return ( sequence && isVisible && (
      <>
        <span className = 'badge bg-secondary mx-1'>
          <span className = 'mx-1'>{sequence.product_name}</span>
          <span className = 'mx-2'>{sequence.component_name}</span> 
          <span className = 'mx-2'>{sequence.sequence_name}</span> 
          {!isTraining && (<FontAwesomeIcon className = 'mx-1' icon={faCaretDown} onClick={(event) => {handleChangeQualification(event, 'downgrade')}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
          {isTraining && (<FontAwesomeIcon className = 'mx-1' icon={faCaretUp} onClick={(event) => {handleChangeQualification(event, 'upgrade')}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
          <FontAwesomeIcon className = 'mx-1' icon={faTrash} onClick={(event) => {handleChangeQualification(event, 'remove')}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </span>
      </>));

  }

  const contentNotFocused = (
    <div onClick = {handleFocus}>
      {target_list && target_list.length != [] ? 
        target_list.map((q) => <QualificationBadge key= {q.sequence_uid}  sequence = {q}/>)
        : ' -- ' }
    </div>
  );

  const contentFocused = (
    <>
      <div className = ''>
        <select 
          onBlur = {handleFocus}
          onChange = {(event) => addQualification(event)}
        >
          <option key = '---' defaultValue>--</option>
          {operator && operator.hasOwnProperty('qualifications_possible') && operator.qualifications_possible.map((sequence) => (<option key = {sequence.sequence_uid} value = {sequence.sequence_uid}>{sequence.sequence_uid} {sequence.product_name} {sequence.component_name} {sequence.sequence_name}</option>))}
        </select>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
      </div>
    </>
  );

  return( isFocused ? contentFocused : contentNotFocused );

};

export const SwitchButton = ({ operator_id, field_name, value }) => {

  const [isChecked, setIsChecked] = useState(value ? true : false);

  const updateParameter = async () => {

      var request =  { 
          operator_id: operator_id,
          field_name: field_name, 
          field_value: !isChecked,
      };
  
      try {
  
        const backend = process.env.REACT_APP_API_URL;
        const api = "/operators/operator_update/";
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);

        const data = await response.json();

        if (!response.ok) {
          throw new Error('Failed to update parameter');
        }
        window.location.reload();

      } catch (error) {
          console.log(error.message);
          //setErrorMessage('Error updating parameter: ' + error.message);
      }
  
  };
  
/*
  useEffect(() => {
    setIsChecked(value);
  }, [value]);
*/
  const handleChange = (checked) => {
    setIsChecked(checked);
    updateParameter();
  };

  return (
      <Switch
        onChange={handleChange}
        checked={isChecked}
        onColor="#86d3ff"
        offColor="#dcdcdc"
        checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
        uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
        handleDiameter={24}
        height={26}
        width = {80}
      />
  );
};

export const Update = ({ operator_id, field_name, field_type, field_value }) => {
  const [parameterValue, setParameterValue] = useState(field_value || '');
  const [initialValue, setInitialValue] = useState(field_value || '');
  const [disabled, setDisabled] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (event) => {
    setParameterValue(event.target.value);
  };

  const updateParameter = async () => {
    const request = {
      operator_id: operator_id,
      field_name: field_name,
      field_value: parameterValue,
    };

    try {
      const backend = process.env.REACT_APP_API_URL;
      const api = "/operators/operator_update/";
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };

      const response = await fetch(`${backend}${api}`, options);
      if (!response.ok) {
        throw new Error('Failed to update parameter');
      }

      // Update the initial value to reflect the successful update
      setInitialValue(parameterValue);
      setIsFocused(false);
    } catch (error) {
      setErrorMessage('Error updating parameter: ' + error.message);
    } finally {
      setDisabled(false);
    }
  };

  return (
    <>
      {!isFocused ? (
        <span style={{ color: 'blue' }} onClick={() => setIsFocused(true)}>
          {parameterValue === null || parameterValue === '' ? '---' : parameterValue}
        </span>
      ) : (
        <>
          {field_type === 'text' && (
            <input
              type="text"
              value={parameterValue}
              onChange={handleChange}
              className="mx-1"
              disabled={disabled}
            />
          )}
          {field_type === 'number' && (
            <input
              type="number"
              step="0.001"
              value={parameterValue}
              onChange={handleChange}
              className="mx-1"
              disabled={disabled}
            />
          )}
          {field_type === 'date' && (
            <DatePicker
              selected={parameterValue ? new Date(parameterValue) : null}
              onChange={(date) => setParameterValue(date.toISOString().split('T')[0])}
              dateFormat="dd/MM/yyyy"
            />
          )}
          {field_type === 'bool' && (
            <select value={parameterValue} onChange={handleChange} disabled={disabled}>
              <option value="">--</option>
              <option value="1">SI</option>
              <option value="0">NO</option>
            </select>
          )}

          {parameterValue !== initialValue && (
            <FontAwesomeIcon
              className="mx-1"
              icon={faSave}
              onClick={updateParameter}
              style={{ cursor: 'pointer' }}
            />
          )}
          <FontAwesomeIcon
            className="mx-1"
            icon={faCancel}
            onClick={() => setIsFocused(false)}
            style={{ cursor: 'pointer' }}
          />
        </>
      )}
      {errorMessage && <p>{errorMessage}</p>}
    </>
  );
};

const fetchOperatorData = async (operator_id, setData, setPictureUrl, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/operators/operator_info/";
        const request = { 'operator_id': operator_id };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        setData(data.data);
        setPictureUrl(process.env.REACT_APP_API_URL + data.data.pictureUrl)
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};

export const OperatorInfo = () => {

  let { OperatorID } = useParams();

  const [operator, setOperator] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');

  const handleAvatarUpload = async (file) => {
    const backend = process.env.REACT_APP_API_URL;
    const api = "/operators/upload_avatar/";
  
    const formData = new FormData();
    formData.append('operator_id', OperatorID);  // Pass the user's ID
    formData.append('picture', file);   // Add the avatar file
  
    const options = {
      method: 'POST',
      headers: {
        'Authorization': getToken(),  // Include your auth token if needed
      },
      body: formData,
    };
  
    try {
      const response = await fetch(`${backend}${api}`, options);
  
      // Check if response is OK (status code 200-299)
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Server response error:", errorText);
        return;
      }
  
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        console.log("Avatar upload response:", data);
      } else {
        const text = await response.text();
        console.error("Unexpected response format:", text);
      }
    } catch (error) {
      console.error("Error uploading avatar:", error);
    }
  };

  useEffect(() => { 
    fetchOperatorData(OperatorID, setOperator, setPictureUrl, setIsLoading);
  }, []);

  return (
    operator && (
      <Container maxWidth="md">
        <Typography variant="h4" align="center" gutterBottom>
          Operator Information
        </Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={3}>
              {/* Operator Picture with Upload */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <AvatarUpload 
                  initialImageUrl={pictureUrl} 
                  onUpload={handleAvatarUpload} 
                />
                <Typography variant="h6" align="center">
                  {operator.first_name} {operator.last_name}
                </Typography>
              </Grid>

              {/* Operator Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  OperatorID
                </Typography>
                <Typography variant="body1">{operator.operator_id}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Username
                </Typography>
                <Typography variant="body1">
                  {operator.username}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  First Name
                </Typography>
                <Typography variant="body1"><Update operator_id={operator.operator_id} field_name='first_name' field_type='text' field_value={operator.first_name} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Last Name
                </Typography>
                <Typography variant="body1">
                  <Update operator_id={operator.operator_id} field_name='last_name' field_type='text' field_value={operator.last_name} />
                </Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Email
                </Typography>
                <Typography variant="body1">
                  <Update operator_id={operator.operator_id} field_name='email' field_type='text' field_value={operator.email} />
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  NickName
                </Typography>
                <Typography variant="body1"><Update operator_id={operator.operator_id} field_name='nickname' field_type='text' field_value={operator.nickname} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Position
                </Typography>
                <Typography variant="body1"><Update operator_id={operator.operator_id} field_name='position' field_type='text' field_value={operator.position} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Department
                </Typography>
                <Typography variant="body1"><Update operator_id={operator.operator_id} field_name='department' field_type='text' field_value={operator.department} /></Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  Hourly Cost
                </Typography>
                <Update operator_id={operator.operator_id} field_name='hourly_cost' field_type='number' field_value={operator.hourly_cost} />
                </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  
                </Typography>
                <Typography variant="body1">{null}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  isAvailable
                </Typography>
                {operator && <SwitchButton operator_id={operator.operator_id} field_name='is_available' value={operator.is_available} />}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  isBusy
                </Typography>
                {operator && <SwitchButton operator_id={operator.operator_id} field_name='is_busy' value={operator.is_busy} />}
              </Grid>


              <Grid item xs={12} sm={12}>
                <Box border={1} borderColor="grey.400" borderRadius={4} p={2} mt={2}>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Skills
                  </Typography>
                  <SkillSelect operator = {operator} is_training={false}/>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box border={1} borderColor="grey.400" borderRadius={4} p={2} mt={2}>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Skills in training
                  </Typography>
                  <SkillSelect operator = {operator} is_training={true}/>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box border={1} borderColor="grey.400" borderRadius={4} p={2} mt={2}>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Qualifications
                  </Typography>
                  <QualificationSelect operator = {operator} is_training={false}/>
                </Box>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Box border={1} borderColor="grey.400" borderRadius={4} p={2} mt={2}>
                  <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                    Qualifications in training
                  </Typography>
                  <QualificationSelect operator = {operator} is_training={true}/>
                </Box>
              </Grid>



            </Grid>
          </Box>
        </Paper>
      </Container>
    )
  );
};
