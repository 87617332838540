import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { getToken, fetchData } from '../utils/common';

import {
  Box,
  Typography,
  Chip,
  Divider,
  Backdrop,
  Paper,
  Stack,
  Grid,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import {
  PlayCircle,
  StopCircle,
  PauseCircle,
  Block,
  ReportProblem,
  ExpandMore as ExpandMoreIcon,
} from '@mui/icons-material';

//import QRScanner from './QRScanner';
import { QrReader } from 'react-qr-reader';

function ConfirmDialog(props) {
  const [selection, setSelection] = useState(null); // Tracks the user's choice

  useEffect(() => {
    if (props.targetAction === 'resume' || 
        props.targetAction === 'start' ||
        props.targetAction === 'reject'      
      ) setSelection({})
//    else setSelection(null)  
  }, [props])
  

    const handleSelectionChange = (value) => {
    setSelection(value);
  };

  const handleAgree = () => {
    props.setConfirmed(true);
    props.setIsDialogOpen(false);

    // Pass the selection to TaskAction or handle it in another way
    const values = { selection };
//    console.log("SELECTION", values);
    TaskAction(props.targetTask, props.targetAction, props.refetch, values);
  };

  const handleDisagree = () => {
    props.setConfirmed(false);
    props.setIsDialogOpen(false);
  };

  return (
    <Dialog
      open={props.isDialogOpen} // Controlled by parent component
      onClose={handleDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialogMessage}
        </DialogContentText>
        {props.targetAction === "pause" && (
          <Box display="flex" flexDirection="column" gap={4} marginTop={2}>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("personal")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "personal" ? "yellow" : "grey",
                color: selection === "personal" ? "black" : "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "personal" ? "yellow" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Pausa PERSONALE
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("work")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "work" ? "orange" : "grey",
                color: selection === "work" ? "black" : "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "work" ? "orange" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Pausa di LAVORO
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("checkout")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "checkout" ? "purple" : "grey",
                color: selection === "checkout" ? "white" : "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "checkout" ? "purple" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Pausa FINE TURNO
            </Button>
          </Box>        )}

          {props.targetAction === "complete" && (
          <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("use_for_stats")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "use_for_stats" ? "green" : "grey",
                color: "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "use_for_stats" ? "darkgreen" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Tutto OK!
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("no_use_for_stats")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "no_use_for_stats" ? "orange" : "grey",
                color: "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "no_use_for_stats" ? "orange" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Ci sono stati INCONVENIENTI/ RALLENTAMENTI
            </Button>
          </Box>        )}

      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "16px" }}>
        <Button
          onClick={handleDisagree}
          sx={{
            flex: 1,
            marginRight: "8px",
            fontSize: "1.5rem", // Larger text
            fontWeight: "bold",
            padding: "12px 24px",
            backgroundColor: "lightgrey",
            color: "black",
            "&:hover": {
              backgroundColor: "darkred",
            },
            borderRadius: "8px",
          }}
        >
          Annulla
        </Button>
        <Button
          onClick={handleAgree}
          disabled={!selection}
          sx={{
            flex: 1,
            marginLeft: "8px",
            fontSize: "1.5rem", // Larger text
            fontWeight: "bold",
            padding: "12px 24px",
            backgroundColor: selection ? "blue" : "grey",
            color: "white",
            "&:hover": {
              backgroundColor: selection ? "cyan" : "darkgrey",
            },
            borderRadius: "8px",
          }}
        >
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function TaskAction (task, action, refetch, values = null) {
        
    const fetch = async () => {
      try {
  
        const request = {};
        request['operator_id'] = task.operator_id
        request['task_id'] = task.id
        request['action'] = action
        request['values'] = values
  
        console.log("REQUEST", request)
        var api = '/jobs/task-actions/'
        
          const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify(request),
        };
  
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${api}`, request, options);
//        console.log(response)
//        window.location.reload();
          refetch()

      } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
      }
    };

    fetch();
};
  
function OperatorAction (operator_id, action, refetch, values = null) {
        
  const fetch = async () => {
    try {

      const request = {};
      request['id'] = operator_id
      request['action'] = action
      request['values'] = values

      console.log("REQUEST", request)
      var api = '/operators/actions/'
      
        const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
        body: JSON.stringify(request),
      };

      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${api}`, request, options);
        refetch()

    } catch (error) {
      console.error('Error fetching data:', error);
  } finally {
    }
  };

  fetch();
};

const OperatorSection = props => 
{
    const operator = props.operator
  
  const [scanResult, setScanResult] = useState(null)
  const [scanQR, setScanQR] = useState(false)

  const playBeep = () => {
    
    const context = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = context.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(2000, context.currentTime); // Frequency in Hz
    oscillator.connect(context.destination);
    oscillator.start();
    setTimeout(() => {
      oscillator.stop();
    }, 500); // Beep duration in milliseconds
  };

  const handleScan = (result) => {
    //console.log("SCAN", result)
    if (result?.text) {
      try { 
        setScanResult(JSON.parse(result.text));
        console.log('Scan result:', result.text);
      }
      catch (error) {console.log(error)}
//      setScanResult(result.text);
//      console.log('Scan result:', result.text);
    }
  };

  useEffect(() => {
    if (scanResult) {
      if(scanResult.hasOwnProperty('BatchCode')) playBeep();
    }
  }, [scanResult]);


    return (
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {operator.name} ({operator.id})
        </AccordionSummary>
        <AccordionDetails>
            <div className = 'row'>

            {operator.working_on_TaskID && (<>
                <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Working on</small></Divider>
                <Typography textAlign='center' fontWeight='bold'>{operator.working_on_TaskID}</Typography></>)}

            <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Skills</small></Divider>
            {operator?.skills?.map((skill) => <div key = {skill.id} className="col-4 col-sm-4 col-md-3 col-lg-1 p-1 m-1 panel"><Chip key = {skill.id} label={`${skill.tag} ${skill.level}`} variant="outlined" /></div>)}

            {operator?.assigned_tools?.length > 0 && <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Attrezzature assegnate</small></Divider>}

            {operator?.assigned_tools?.length > 0 && operator?.assigned_tools?.map((tool) => <><div className="col-12 col-sm-6 col-md-4 col-lg-2 p-1 m-1 panel"><Chip key = {tool.id} label={tool.code + ' ' + tool.name} variant="outlined" /></div></>)}                
            {operator?.last_batch && <>
              <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Cesta precedente</small></Divider>
              <Typography variant="h6" textAlign='center' fontWeight='bold'>{operator.last_batch}</Typography>           
            </>}
            </div>
        </AccordionDetails>
        <AccordionDetails>
         {/*{scanQR && !scanResult && (<QrReader
            delay={1000}
            constraints={{ facingMode: 'environment' }} // Use rear camera if available
            onResult={handleScan}
            style={{ width: '100%' }}
          />)}
         {scanResult && <Paper className='p-2' elevation = {10}>{JSON.stringify(scanResult)}</Paper>}
         {scanQR && (<Button onClick={() => {setScanQR(!scanQR); setScanResult(null);}}>Reset</Button>)}
         {!scanQR && <Button onClick={() => {setScanQR(!scanQR)}}>Scan QR</Button>}
        */}  </AccordionDetails>

      </Accordion>

);

};

const ToolBadge = ({key, tool, operator_id, refetch=null}) => {
  
  var bgcolor = tool.is_being_used ? "red" : "green";
  var textcolor = tool.is_being_used ? "black" : "white";

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const onClick = () => {
      var api = "/jobs/operator-actions/"
      var request = {'action': 'assign_tool', 'operator_id': operator_id, 'tool_id': tool.id}
      fetchData(api, request, setData, setIsLoading)
      //window.location.reload()
      if (refetch) refetch()
  }

  return <>
    <span 
      key = {key}
      className ="badge fst-italic" 
      style = {{backgroundColor: bgcolor, color: textcolor}}
      onClick={tool.is_being_used ? null : onClick}    
    >
    <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>{tool.code} {tool.name}</Typography>
    </span>
  </>
};

const TaskSection = props => 
    {

        const task = props.task
        const [value, setValue] = React.useState(null);
        const [isLoading, setIsLoading] = React.useState(false);

        
        var bg = task.is_worked_on === 1 ? 'green' : '';
        if (task.is_paused) bg = 'grey';
        //if (task.is_paused) props.setPaused(true);
        if (task.is_paused) props.setPausedTask(task);

        const border = task.is_worked_on ? `2px solid  ${bg}` : '';
        const color = task.is_worked_on ? 'white' : '';
        const disabled = false
        //const disabled = (task.working_on_some_task === 1 && task.working_on_this_task === 0) ? true : false; 
        const opacity = disabled ? 'opacity-75' : '';
        //const expanded = (task.is_worked_on || (task.working_on_some_task === 0 && task.working_on_this_task === 0)) ? true : false;
        const expanded = true
//        if (task.working_on_some_task === 0 && task.working_on_this_task === 0) bg = 'cyan';

        const bg1 = '#F5F5F5' //'#DCDCDC' //'#F5F5F5'
        const elevation = 10

       function execute(action) {
//        console.log(action)
        setValue(action);
        if (action === 'pause') {
//           clearInterval(props.IntervalID);   
           props.setConfirmed(false);
          props.setIsDialogOpen(true);
           props.setTargetTask(task);
           props.setTargetAction(action);
           props.setDialogTitle("Confirm "+action+"?")
           props.setDialogMessage(`${task.product_name} > ${task.component_name} > ${task.sequence_name}`)   
          
           //           props.setPaused(true);

       }
        else if (action === 'resume')  {
//           const intervalId = setInterval(props.fetchData, 1000);
//           props.setIntervalID(intervalId)    
           props.setConfirmed(false);
           props.setIsDialogOpen(true);

           props.setTargetTask(task);
           props.setTargetAction(action);
           props.setDialogTitle("Confirm "+action+"?")
           props.setDialogMessage(`${task.product_name} > ${task.component_name} > ${task.sequence_name}`)   
 //          props.setPaused(false);

       }
        else {
           props.setConfirmed(false);
           props.setIsDialogOpen(true);
           props.setTargetTask(task);
           props.setTargetAction(action);
           props.setDialogTitle("Confirm "+action+"?")
           props.setDialogMessage(`${task.product_name} > ${task.component_name} > ${task.sequence_name}`)   
        }

       }


        return (
            <>

            <Accordion defaultExpanded={expanded} sx ={{border: `${border}`}} className={`${opacity}`}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx ={{backgroundColor: `${bg}`, color: `${color}` }}
                >
                {`Task >> ${task.id}`}
                </AccordionSummary>

                <AccordionDetails>
                  
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Articolo</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                          <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>{task.product_name}</Typography>
                          </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Cesta</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        {(<><Typography  textAlign='center' fontWeight='bold' fontSize='1.5em'>{task.batch_code}</Typography><Typography  textAlign='center' sx={{fontStyle:'italic'}} fontSize='1.0em'></Typography></>)}
                          </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Componente</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{`${task.component_name}`}</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Quantità</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{`${task.quantity}`}</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Attività</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{`${task.sequence_name}`}</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        { 
                        <><Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Attrezzatura</Paper>  
                          <Divider />
                            <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                            {task.tool_ok && props.tools?.map((tool) => <><Chip key = {tool.id} label={tool.code + ' ' + tool.name}  sx={{fontWeight:'bold', fontSize: '1.5em', border: '2px solid black'}}  variant="outlined" /></>)}
                            {!task.tool_ok && task?.required_tools?.map((tool) => <ToolBadge key= {'tool_'+tool.id} tool = {tool} operator_id={task.operator_id}/>)}
                        </Paper></>}  
                        </Stack>
                      </Paper>

                    </Stack>


                    </AccordionDetails>
       
       {/*<Divider textAlign="left" sx ={{fontStyle: 'italic'}}>Azioni</Divider>         */}

        <AccordionActions>
        <Box sx={{ flexGrow: 1, width: '100%' }} className='m-2'>
        <Grid container spacing={2}>
        {!isLoading && !disabled && task.is_possible && task.tool_ok && !task.is_worked_on && !task.is_paused && (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('start')}}>
            <PlayCircle color='success' fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Avvia</Typography>
            </Paper>
          </Grid>
        )}
        {!isLoading && !disabled && task.is_worked_on && !task.is_paused && (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('complete')}}>
            <StopCircle sx = {{color: 'purple'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Completa</Typography>
            </Paper>
          </Grid>
        )}
        {!isLoading && !disabled && !task.is_paused && (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('pause')}}>
            <PauseCircle sx = {{color: 'black'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Pausa</Typography>
            </Paper>
          </Grid>
        )}
        {!isLoading && !disabled && task.is_worked_on && task.is_paused &&  (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('resume')}}>
            <PlayCircle sx = {{color: 'orange'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Riprendi</Typography>
            </Paper>
          </Grid>
        )}
       {!isLoading && !task.is_paused &&  (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('reject')}}>
            <Block sx = {{color: 'orange'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Rifiuta</Typography>
            </Paper>
          </Grid>
        )}

      {/*!isLoading && !task.is_paused &&  (
          <Grid item xs={12}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('reportProblem')}}>
            <ReportProblem sx = {{color: 'red'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Segnala Anomalia</Typography>
            </Paper>
          </Grid>
        )*/}

        </Grid>
      </Box>


      </AccordionActions>
     
       {(!disabled && task.is_started) && <AccordionDetails>
       
        <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Tempo</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{task.actual_time_hhmm}/{task.expected_time_hhmm}</Typography>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em' sx={{color: `${task?.progress > 100 ? 'black' : ''}`, backgroundColor: `${task?.progress > 100 ? 'yellow' : ''}`, }} >{task.progress}%</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>
       
       </AccordionDetails>      
       
       
       
       
       }
     </Accordion>


            </>    
    );
    
};
    
const OperatorPanel = () => {

    let { operator_id } = useParams();

//    const navigate = useNavigate();
//    const [isLoading, setLoading] = useState(true);
    const [operator, setOperator] = useState({});
    const [error, setError] = useState(null);

    const [paused, setPaused] = React.useState(false);
    const [pausedTask, setPausedTask] = React.useState(null);
    
    const [confirmed, setConfirmed] = React.useState(true);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [dialogTitle, setDialogTitle] = React.useState('Conferma?');

    const [targetTask, setTargetTask] = React.useState({});
    const [targetAction, setTargetAction] = React.useState('');

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    /*
    const handleOpenDialog = () => {
      setIsDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setIsDialogOpen(false);
    };
  */


    const handleResume = () => {
      setPaused(false);
      TaskAction(pausedTask, 'resume', refetch)
    };

    const handleResumeOperator = () => {
      setPaused(false);
      OperatorAction(operator_id, 'resume', refetch)
    };


  const fetchData = async (id, setData, setIsLoading = null) => {

      try {
  
          if (setIsLoading !== null) setIsLoading(true)
  
          const backend = process.env.REACT_APP_API_URL;
          const api = "/operators/work-panel/";
          const request = { 'id': id };
  
          const options = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': getToken(),
              },
              body: JSON.stringify(request),
          };
  
          const response = await fetch(`${backend}${api}`, options);
          const data = await response.json();
          console.log(data)
          setData(data.data);
          return data;
  
      } catch (error) {
          return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
      } finally {
          if (setIsLoading !== null) setIsLoading(false);
      }
  };

  const refetch = async () => {
    fetchData(operator_id, setOperator);
  }

    useEffect(() => {
      let timeoutId; // Store timeout ID
  
      const fetchDataWithDelay = () => {
        refetch().then(() => {
          // Schedule the next fetch 2 seconds after the data is received
          timeoutId = setTimeout(fetchDataWithDelay, 1000);
        });
      };
  
      // Initial fetch
      fetchDataWithDelay();
  
      // Cleanup function to clear timeout when the component unmounts
      return () => {
        clearTimeout(timeoutId);
      };
    }, []); // Add dependencies to ensure the effect runs when these change
  
  
    useEffect(() => {

      if (!operator.is_checkedin) {setPaused(true);return}
      if (operator.is_paused) {setPaused(true);return}

      if (!operator?.assigned_tasks) return;
      
      const hasPausedTask = operator.assigned_tasks.some(task => task.is_paused);
      
      if (hasPausedTask !== paused) {  // Update only if there's a change
        setPaused(hasPausedTask);
      }
    }, [operator]);



//    useEffect(() => { console.log("Paused:", paused);
//    }, [paused]);

    useEffect(() => { //console.log("Confirmed");
    }, [confirmed]);

    useEffect(() => {
    }, [targetAction]);

    useEffect(() => {
    }, [targetTask]);

    if (error) {
        return <p style={{ color: 'red' }}>Error: {error}</p>;
    }
      
    var bgcolor='white';
    var textcolor='black';
    //var hasTask = operator?.task;

    if(!operator?.is_checkedin) {bgcolor = "grey"; textcolor = "black";}
    else if(operator?.is_paused) {bgcolor = "orange"; textcolor = "black";}
    else if(operator?.task?.is_paused) {bgcolor = "yellow"; textcolor = "black";}
    else if(operator?.task?.is_worked_on) {bgcolor = "green"; textcolor = "white";}
    else {bgcolor = "#ADD8E6"; textcolor = "black";}


    const PausedScreen = React.memo(() => (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        transitionDuration={0} // Disables the animation temporarily
      >
        <Box sx={{ backgroundColor: 'white' }} className='p-3'>
          <PlayCircle
            sx={{ color: 'orange' }}
            fontSize='large'
            onClick={handleResume}
          />
        </Box>
      </Backdrop>
    ));

    const OperatorPausedScreen = React.memo(({ msg }) => (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
        transitionDuration={0} // Disables the animation temporarily
      >
        {/* Parent container to stack the boxes */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Stacks children vertically
            gap: 2, // Adds spacing between children
            alignItems: 'center', // Optional: centers children horizontally
          }}
        >

          <Box sx={{ backgroundColor: 'white', color: 'black' }} className="p-3">
            {msg}
          </Box>

          <Box sx={{ backgroundColor: 'white' }} className="p-3">
            <PlayCircle
              sx={{ color: 'purple' }}
              fontSize="large"
              onClick={handleResumeOperator}
            />
          </Box>

          {operator?.assigned_tasks?.map((task) => <>
            <Box sx={{ backgroundColor: 'white', color: 'black' }} className="p-3">
            <b>{task.product_name} {task.batch_code}</b>
          </Box>
          <Box sx={{ backgroundColor: 'white', color: 'black' }} className="p-3">
          <b>{task.component_name} - {task.sequence_name}</b>
          </Box>
        
          </>)}

        </Box>
      </Backdrop>
    ));    

    const backgroundImageUrl = `/img/kuoyo_background.jpg`;

    return (
    <>
    {!confirmed && 
      <ConfirmDialog 
        targetTask = {targetTask} 
        targetAction = {targetAction} 
        confirmed={confirmed} 
        setConfirmed={setConfirmed} 
        dialogMessage={dialogMessage} 
        dialogTitle={dialogTitle} 
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        refetch={refetch}
      />}

    {(!operator.is_checkedin) && <OperatorPausedScreen msg={'FUORI TURNO'} />}
    {operator.is_checkedin && operator.is_paused && <OperatorPausedScreen msg={operator.pause_mode == 1  ? 'PAUSA PERSONALE' : operator.pause_mode == 2 ? 'PAUSA DI LAVORO' : '...'} />}

    {operator.is_checkedin && !operator.is_paused && paused && <PausedScreen />}

    {operator.is_checkedin && !operator.is_paused && !paused && <div style={{margin: '0px', backgroundSize: 'cover',  backgroundImage: `url(${backgroundImageUrl})`, height: '100vh'}}> 
      <div className="container p-2" style={{background:'transparent'}}>
          <div className='mb-2'>{<OperatorSection operator={operator}/>}</div>
            {operator?.assigned_tasks?.map((task) => 
              <TaskSection 
                  key = {`${task.id}`} 
                  task={task}
                  tools={operator.assigned_tools} 
                  setPaused={setPaused}
                  setPausedTask={setPausedTask}            
                  confirmed={confirmed}
                  setConfirmed={setConfirmed}
                  setTargetTask={setTargetTask}            
                  setTargetAction={setTargetAction}            
                  setDialogTitle={setDialogTitle}            
                  setDialogMessage={setDialogMessage} 
                  isDialogOpen={isDialogOpen}
                  setIsDialogOpen={setIsDialogOpen}
                  refetch={refetch}
            />)}
        </div>    
    </div>}    
    </>
    );



};

export default OperatorPanel;
