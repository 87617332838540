import React from 'react';
import { NavLink} from 'react-router-dom';
import { getUser, getToken } from '../utils/common';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import DictTag from '../dictionaries/DictTag';

const Navbar = ({handleShow}) => {

  const visible = getToken() ? true : false;
  const user = getUser();
  const auth = user && user.auth;

  return (
    <>
      <div className = 'bg-light m-0 p-0' style = {{display: 'grid', gridTemplateColumns: '1fr auto', backgroundColor:'green'}}>
      <div>

      <nav className="navbar navbar-expand-lg navbar-light">

        <div className="container-fluid">

        <FontAwesomeIcon className='d-block d-lg-none p-1' icon={faBars}  onClick={handleShow}/>

          {/*<NavLink className="navbar-brand" to="/">
            Kuoyo
          </NavLink>*/}

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${!visible ? 'd-block' : 'd-none'}`}
                  to="/login"
                >
                  Login
                </NavLink>
              </li>
              {visible && (
                <>
                  {false && <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/dashboard"
                    >
                      <DictTag tag= {'Dashboard'}/>
                    </NavLink>
                  </li>}
                  {auth && auth.hasOwnProperty('dictionaries') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/dictionaries"
                      >
                        <DictTag tag= {'Dictionaries'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('products') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/products"
                      >
                        <DictTag tag= {'Products'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('operators') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/operators"
                      >
                        <DictTag tag= {'Operators'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('jobs') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/jobs"
                      >
                        <DictTag tag= {'Jobs'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('managers') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/managers"
                      >
                        <DictTag tag= {'Managers'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('kits') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/kits"
                      >
                        <DictTag tag= {'Kits'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('tools') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/tools"
                      >
                        <DictTag tag= {'Tools'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('skills') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/skills"
                      >
                        <DictTag tag= {'Skills'}/>
                      </NavLink>
                    </li>
                  )}

                  {auth && auth.hasOwnProperty('users') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/users"
                      >
                        <DictTag tag= {'Users'}/>
                      </NavLink>
                    </li>
                  )}
                  {auth && auth.hasOwnProperty('args') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/args"
                      >
                        <DictTag tag= {'Args'}/>
                      </NavLink>
                    </li>
                  )}

                  {auth && auth.hasOwnProperty('operator_panel') && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link"
                        to="/operators/operator_panel"
                      >
                        <DictTag tag= {'Work Panel'}/>
                      </NavLink>
                    </li>
                  )}


                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
      </div>
      {visible && (
      <div className='right-div'>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <NavLink className="nav-link" to="/dashboard">
              {user.username}
            </NavLink>
          </div>
        </nav>      

      </div>        
      )}

      </div>
    </>
  );
};


export default Navbar;
