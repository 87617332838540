import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import { getToken } from '../utils/common';
  
const JobCloneModal = ({ job, open, onClose }) => {

//    console.log('Modal open status:', open); // Add this line
    
    const [formData, setFormData] = useState({
        username: '',
        quantity: '',
        batch_size: '',
        job: job
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/jobs/clone/`;
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getToken(),
                },
            };
            await axios.post(url, formData, options);
            onClose();  // Close the modal after submission
//            window.location.reload();  // Reload the page to reflect the new job
        } catch (error) {
            console.error('Error creating job: ', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Clone Job</DialogTitle>
            <DialogContent>
            <Typography variant="body1">{job.product_id}</Typography>
            <Typography variant="body1">{job.product_name}</Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <TextField
                        margin="dense"
                        label="Username"
                        name="username"
                        fullWidth
                        value={formData.username}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        margin="dense"
                        label="Quantity"
                        name="quantity"
                        type="number"
                        fullWidth
                        value={formData.quantity}
                        onChange={handleChange}
                        required
                    />
                    <TextField
                        margin="dense"
                        label="Batch Size"
                        name="batch_size"
                        type="number"
                        fullWidth
                        value={formData.batch_size}
                        onChange={handleChange}
                        required
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} type="submit" variant="contained" color="primary">
                    Create Job
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default JobCloneModal;
