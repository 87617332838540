import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Update } from '../utils/elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, IconButton, Typography,
    CircularProgress, Grid, Container, Avatar } from '@mui/material';

import SettingsIcon from '@mui/icons-material/Settings'; // Import an icon, e.g., settings icon
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'; // Import the work icon    
import GavelIcon from '@mui/icons-material/Gavel';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';


const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete }) => {
  const [visible, setVisible] = useState(true);

  return (
    visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
            height: '100%',
            boxShadow: 3,
            backgroundColor: '#fff',
            transition: 'box-shadow 0.3s ease-in-out',
            '&:hover': {
              boxShadow: 6,
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'left', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                src={item.avatar ? process.env.REACT_APP_API_URL + item.avatar : null}
                sx={{ width: 56, height: 56, mr: 2 }}
              />
              <Typography variant="h6" component={Link} to={`operator/${item.id}/`} sx={{ textDecoration: 'underline', color: 'blue' }}>
                {item.last_name}, {item.first_name}
              </Typography>
            </Box>
            <IconButton
              component={Link}
              to={`/operators/operator/${item.id}/work-panel/`}
              sx={{ color: 'gray' }}
              aria-label="Operator Panel"
            >
              <GavelIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={`/operators/operator/${item.id}/log/`}
              sx={{ color: 'gray' }}
              aria-label="Operator Log"
            >
              <EventNoteIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={`/operators/operator/${item.id}/time-allocation/`}
              sx={{ color: 'gray' }}
              aria-label="Operator Time Allocation"
            >
              <AccessTimeIcon />
            </IconButton>

          </Box>
          <Box sx={{ marginTop: 1 }}>
            <Typography component="span" variant="body1">
              {DictValue('operator_id')}: {item.id}
            </Typography>
          </Box>
        </Box>
      </Grid>
    )
  );
};

const List = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [canChange, setCanChange] = useState(false);
 
    useEffect(() => {
      setCanChange(checkPerm('change_operator'))
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
      checkPageAuthorization(['view_operator'], navigate);
      fetchUsers();
    }, [navigate]);
  
    const fetchUsers = () => {
      setIsLoading(true);
      fetchData('/operators/list/', {}, setData, setIsLoading);
    };
  
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.first_name.toLowerCase().includes(filter.toLowerCase()) ||
      item.last_name.toLowerCase().includes(filter.toLowerCase()) ||
      item.id.toString().toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
      <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    can_change={canChange}
                    can_delete={canChange}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  };
  
export default List;