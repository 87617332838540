import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getToken } from '../utils/common';
import { CircularProgress, Card, CardContent, Typography, Divider } from '@mui/material';

const fetchData = async (id, setData, setIsLoading) => {
    try {
        if (setIsLoading) setIsLoading(true);

        const backend = process.env.REACT_APP_API_URL;
        const api = "/jobs/actions/";
        const request = { id: id, action: 'tasks-to-be-assigned' };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        setData(data.data);
        console.log(data.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
        if (setIsLoading) setIsLoading(false);
    }
};

const App = () => {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchData(id, setData, setIsLoading);
    }, [id]);

    return (
        <div>
            {isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <div style={{ padding: '16px' }}>
                    {data.length === 0 ? (
                        <Typography variant="h6" color="textSecondary" style={{ textAlign: 'center', marginTop: '16px' }}>
                            No tasks available to assign.
                        </Typography>
                    ) : (
                        data.map((item) => (
                            <Card
                                key={item.id}
                                style={{
                                    marginBottom: '16px',
                                    border: item.qualified_operators.length === 0 ? '4px solid red' : 'none',
                                }}
                            >
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>
                                        Task #{item.id} - {item.sequence_name}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Component:</strong> {item.component_name}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Product:</strong> {item.product_name}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Batch Code:</strong> {item.batch_code}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Quantity:</strong> {item.quantity}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Expected Time:</strong> {item.expected_time_hhmm}
                                    </Typography>
                                    <Typography variant="body1" color="textSecondary">
                                        <strong>Requires Qualification:</strong> {item.requires_qualification ? 'Yes' : 'No'}
                                    </Typography>

                                    {item.required_skills.length > 0 && (
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Typography variant="subtitle1">Required Skills:</Typography>
                                            {item.required_skills.map((skill) => (
                                                <Typography key={skill.id} variant="body2">
                                                    - {skill.tag} (Level: {skill.level})
                                                </Typography>
                                            ))}
                                        </div>
                                    )}
                                    {item.qualified_operators.length > 0 && (
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Typography variant="subtitle1">Qualified Operators:</Typography>
                                            {item.qualified_operators.map((operator) => (
                                                <Typography key={operator.id} variant="body2">
                                                    - {operator.name}
                                                </Typography>
                                            ))}
                                        </div>
                                    )}

                                    {item.required_tools.length > 0 && (
                                        <div>
                                            <Divider style={{ margin: '8px 0' }} />
                                            <Typography variant="subtitle1">Required Tools:</Typography>
                                            {item.required_tools.map((tool) => (
                                                <Typography key={tool.id} variant="body2">
                                                    - {tool.name} (Code: {tool.code})
                                                </Typography>
                                            ))}
                                        </div>
                                    )}
                                </CardContent>
                            </Card>
                        ))
                    )}
                </div>
            )}
        </div>
    );
};

export default App;
