import React, { useState, useRef } from 'react';
import { Button, Avatar, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';
import { DictValue } from './common';

const CameraSelectionModal = ({ open, onClose, onCameraSelect, availableCameras }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{DictValue('Select Camera')}</DialogTitle>
      <DialogContent>
        <List>
          {availableCameras.map((camera, index) => (
            <ListItem key={camera.deviceId}>
              <ListItemButton onClick={() => onCameraSelect(camera.deviceId)}>
                <ListItemText primary={camera.label || `Camera ${index + 1}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{DictValue('Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const PictureUpload = ({ initialImageUrl, onUpload, canChange }) => {
  const [preview, setPreview] = useState(initialImageUrl);
  const [cameraMode, setCameraMode] = useState(false);
  const [availableCameras, setAvailableCameras] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setPreview(URL.createObjectURL(file));
      onUpload(file);
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  };

  const openCameraModal = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(device => device.kind === 'videoinput');
      
      // Sort devices by label in alphabetical order
      videoDevices.sort((a, b) => a.label.localeCompare(b.label));
      // Add default "user" (front-facing) camera if it's not in the list
      
      const hasUserCamera = videoDevices.some(device => device.label.toLowerCase().includes('user'));
      if (!hasUserCamera) {
        videoDevices.push({
          deviceId: 'default-user-camera',
          label: 'User (Front Camera)',  // Label for the default user camera
        });
      }

      console.log(videoDevices)

      setAvailableCameras(videoDevices);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error enumerating devices:', error);
    }
  };

  const startCamera = async (deviceId) => {
    setCameraMode(true);
    try {
      const constraints = deviceId === 'default-user-camera'
        ? { video: { facingMode: 'user' } }  // Default front camera
        : { video: { deviceId: { exact: deviceId } } };  // Selected camera

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      videoRef.current.srcObject = stream;
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL('image/png');

    setPreview(dataUrl);

    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    const file = new File([blob], 'avatar.png', { type: mimeString });

    video.srcObject.getTracks().forEach(track => track.stop());
    onUpload(file);
    setCameraMode(false);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {cameraMode ? (
        <>
          <video ref={videoRef} autoPlay style={{ width: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
          <Button variant="contained" onClick={capturePhoto} style={{ marginTop: '10px' }}>
            {DictValue('Capture Photo')}
          </Button>
        </>
      ) : (
        <>
          <Avatar
            src={preview || "/default-avatar.png"}
            alt="Operator Avatar"
            sx={{ width: 150, height: 150, margin: "0 auto" }}
          />
          {canChange && (
            <>
              <Button
                variant="contained"
                component="label"
                style={{ marginTop: '10px' }}
              >
                {DictValue('Upload Picture')}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                />
              </Button>
              <Button
                variant="contained"
                startIcon={<PhotoCamera />}
                onClick={openCameraModal}
                style={{ marginTop: '10px', marginLeft: '10px' }}
              >
                {DictValue('Use Camera')}
              </Button>
            </>
          )}
        </>
      )}

      {/* Modal for camera selection */}
      <CameraSelectionModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCameraSelect={startCamera}
        availableCameras={availableCameras}
      />
    </div>
  );
};

export default PictureUpload;
