import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { useNavigate, Link } from 'react-router-dom';


const SelectAssignedJobs = ({ ManagerID, currentJobs }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);
  
    const fetchList = async () => {
      try {
        const request = { 'ManagerID': ManagerID };
        const Api = process.env.REACT_APP_API_URL;
        const postOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
        };
        const response = await fetch(Api + "/api/jobs/open/", postOptions);
        const data = await response.json();
        console.log(data);
        const array = data["data"].map((item) => ({ value: item.JobID, label: '['+item.JobID+'] '+ item.JobQuantity+' x '+item.ProductName + ' ('+item.ProductID +')' }));
        setOptions(array);
      } catch (error) {
        console.log('Error fetching data: ' + error)
      }
    };
  
    useEffect(() => { fetchList() }, []);
  
    const changeJob = async (event, JobID, action) => {
  
      event.stopPropagation();
  
      try {
  
        const request = {
          'ManagerID': ManagerID,
          'JobID': JobID,
          'action': action
        };
  
        const Api = process.env.REACT_APP_API_URL;
        const postOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
        };
        const response = await fetch(Api + "/api/managers/" + ManagerID + "/change_assigned_job/", postOptions);
        //console.log(response)
        window.location.reload();
        // Instead of reloading page, handle response and update state accordingly
        // const data = await response.json();
        // Update state or perform other necessary actions based on the response
        setIsFocused(false); // Close select when action is performed
      } catch (error) {
        console.log('Error fetching data: ' + error)
      }
    };
  
    const handleInputChange = (inputValue) => {
      setInputValue(inputValue);
    };
  
    const handleSelectOption = (selectedOption) => {
      setSelectedOption(selectedOption);
    };
  
    return (
      <div>
        {isFocused ? (
          <div>
            <Select
              options={options}
              value={selectedOption}
              onChange={handleSelectOption}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              placeholder="Type to filter ..."
              isClearable
              isSearchable
            />
            {selectedOption && (<FontAwesomeIcon className='mx-1' icon={faSave} onClick={(event) => changeJob(event, selectedOption.value, 'add')} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
            <FontAwesomeIcon className='mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            {currentJobs.map((job) => (
              <span key={job.JobID} className="badge bg-secondary mx-1">
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faTrash}
                  onClick={(event) => changeJob(event, job.JobID, 'remove')}
                  style={{ cursor: 'pointer' }}
                />
                <span className="mx-1">{job.JobQuantity} x {job.ProductName} ({job.ProductID})</span>
              </span>
            ))}
          </div>
        ) : (
          <div onClick={() => setIsFocused(true)}>
            {currentJobs.length == 0 ? <span>---</span> :
              
              currentJobs.map((job) => (
              <div key={job.JobID} className="badge bg-secondary m-1">
                <span className="mx-1">{job.JobQuantity} x {job.ProductName} ({job.ProductID})</span>
                <span className="mx-1">[{job.JobID}]</span>
                <span className="mx-1">
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faTrash}
                    onClick={(event) => changeJob(event, job.JobID, 'remove')}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
    );
};

export default SelectAssignedJobs;
