import React, { useEffect, useState} from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel } from '@fortawesome/free-solid-svg-icons';
import { Link, useParams } from 'react-router-dom';
import DictTag from '../dictionaries/DictTag';

//import FieldUpdate from './updater.js';

//import ComponentSequence from './ComponentSequence';

const Update = ({ProductID, ComponentID, FieldName, FieldType, FieldValue}) =>  {

    const [parameterValue, setParameterValue] = useState(FieldValue);
    const [errorMessage, setErrorMessage] = useState('');
    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
  
    const handleChange = (event) => {
      setParameterValue(event.target.value);
    };
  
    useEffect(() => {
      setInitialValue(parameterValue);
    }, []);
  
    useEffect(() => {
    }, [disabled]);
  
    var o;
    if (!isFocused) o = <span onClick = {() => {setIsFocused(true)}}>{FieldValue}</span>;
    else {
    o = <></>;
    if (FieldType == 'text') o = <input type="text"  value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType == 'number') o = <input type="number"  step = "0.001" value={parameterValue} onChange={handleChange} className="mx-1" disabled={disabled}/>
    else if (FieldType === 'date') o =  <DatePicker
        selected={new Date(parameterValue)}
        onChange={(date) => {setParameterValue(date.toLocaleString('sv').split(' ')[0])}}
      dateFormat="dd/MM/yyyy"
      />;
    else if (FieldType == 'bool') o = (<select
          value={parameterValue}
          onChange={handleChange}>
          <option value={null}>--</option>
          <option value="1">SI</option>
          <option value="0">NO</option>
          </select>);
    }
  
    const updateParameter = async () => {
      var body =  { 
          ProductID: ProductID,
          ComponentID: ComponentID, 
          FieldName: FieldName,
          FieldValue: parameterValue
        };
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/products/" + ProductID + "/components/" + ComponentID + "/update/", options);
      
        const data = await response.json();
//      console.log(data);
        if (!response.ok) {
          throw new Error('Failed to update parameter');
        }
        setInitialValue(parameterValue);
        setDisabled(false);
        setIsFocused(false);
        window.location.reload();

      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error updating parameter: ' + error.message);
      }
  
  };
  
  return (<>
    {o}
    {((parameterValue !== initialValue)) && isFocused && (<>
        <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={updateParameter} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </>
    )}
    { isFocused && (<>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </>
    )}          
    {errorMessage && <p>{errorMessage}</p>}
  </>
);

  }
  
const Insert = ({ProductID}) =>  {

    const [ID, setID] = useState('');
    const [Name, setName] = useState('');
//    const [errorMessage, setErrorMessage] = useState('');
//    const [initialValue, setInitialValue] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    
    const handleIDChange = (event) => {
      setID(event.target.value);
    };
  
    const handleNameChange = (event) => {
        setName(event.target.value);
      };
      
    useEffect(() => {
    }, [disabled]);
  
    
    const insert = async () => {
      var body =  { 
          ID: ID,
          Name: Name,
      };
  
//      console.log(body);
  
      try {
          setDisabled(true);
  
          const Api = process.env.REACT_APP_API_URL;   
  
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(body),
          };
          
      const response = await fetch(Api+"/api/products/" + ProductID + "/components/insert/", options);
      
        const data = await response.json();
        if (!response.ok) {
          throw new Error('Failed to insert component');
        }
        setDisabled(false);
        window.location.reload();
      } catch (error) {
          console.log(error.message);
        setErrorMessage('Error inserting component: ' + error.message);
      }
  
  };
  
    const contentNotFocused = (<><tr>
        <td><>
            <FontAwesomeIcon icon={faPlus} onClick={() => setIsFocused(true)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            </></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
    </tr></>
    );

    const contentFocused = (<><tr>
        <td><input type="text"  value={ID} onChange={handleIDChange} className="mx-1" disabled={disabled}/></td>
        <td><input type="text"  value={Name} onChange={handleNameChange} className="mx-1" disabled={disabled}/></td>
        <td></td>
        <td></td>
        <td><>
        <FontAwesomeIcon className = 'mx-1' icon={faSave} onClick={insert} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </></td>
    </tr></>
    );

    return (isFocused ? contentFocused : contentNotFocused);
  
  }
  
const Delete = ({ProductID, ComponentID}) => {

    const handleClick = () => {

        const fetchData = async () => {

            try {
    
                var request = {'request': null};
    
                const Api = process.env.REACT_APP_API_URL;   
                const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('AccessToken'),
                },
                body: JSON.stringify(request),
                };
        
    
            const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ ComponentID +"/delete/", options);

            const data = await response.json();
            window.location.reload();


            } catch (error) {
                console.log('Error fetching data: '+ error)
            } finally {
                //setIsLoading(false);
            }
    
        };
    


        if (confirm("Confirm delete component " + ComponentID + " of product " + ProductID))
            console.log("deleting", ComponentID, ProductID);
            fetchData();
        };

    return(<><FontAwesomeIcon icon={faTrash} onClick={handleClick} style={{ cursor: 'pointer' }}></FontAwesomeIcon></>);

}

const ProductComponents = () => {

  const [content, setContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let { ProductID } = useParams();

  const MyContent = ({component, components}) => {

    const [isFocused, setIsFocused] = useState(false);

    const handleRemoveComponent = (event, subcomponent) => {

      event.stopPropagation();
//      console.log("Remove",  subcomponent.SubComponentID, " from ", ProductID, component.ComponentID);

        const fetchData = async () => {

          try {

              var request = {'request': null};

              const Api = process.env.REACT_APP_API_URL;   
              const options = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('AccessToken'),
              },
              body: JSON.stringify(request),
              };


          const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ component.ComponentID +"/subcomponents/" + subcomponent.SubComponentID +"/remove/", options);

          const data = await response.json();
          window.location.reload();


          } catch (error) {
              console.log('Error fetching data: '+ error)
          } finally {
              //setIsLoading(false);
          }

        };

        fetchData();   

    };

    const addSubComponent = (event) => {

        const fetchData = async () => {

          try {

              var request = {'request': null};

              const Api = process.env.REACT_APP_API_URL;   
              const options = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': localStorage.getItem('AccessToken'),
              },
              body: JSON.stringify(request),
              };


          const response = await fetch(Api+"/api/products/" + ProductID + "/components/"+ component.ComponentID +"/subcomponents/" + event.target.value +"/add/", options);

          const data = await response.json();
          window.location.reload();


          } catch (error) {
              console.log('Error fetching data: '+ error)
          } finally {
              //setIsLoading(false);
          }

        };

        console.log("Add subcomponent ", event.target.value, " to component ", ProductID, component.ComponentID);
        fetchData();   

    };

    const handleFocus = () => {
        setIsFocused(!isFocused);
    };
    
    const handleBlur = () => {
      setIsFocused(false);
    };

    const content = (
        <td className="" onClick={handleFocus}>
          {component.subcomponents.map((subcomponent) => (
            <span key={subcomponent.SubComponentID} className="badge bg-secondary mx-1">
              <FontAwesomeIcon
                className = "mx-1"
                icon={faTrash}
                onClick={(event) => handleRemoveComponent(event, subcomponent)}
                style={{ cursor: 'pointer' }}
              />
              {subcomponent.Quantity} x {subcomponent.SubComponentName} ({subcomponent.SubComponentID})
            </span>
          ))}
        </td>
      );

    return( isFocused ? 
      <>
        <td className = ''>
        <select onBlur = {handleFocus} onChange = {(event) => addSubComponent(event)}>
          <option defaultValue>--</option>
          {components.map((item) => (<option value = {item.ComponentID}>{item.ComponentID} {item.ComponentName}</option>))}
        </select>
        <FontAwesomeIcon className = 'mx-1' icon={faCancel} onClick={() => {setIsFocused(false)}} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
        </td>
      </>
    : content);

  }

  const fetchData = async () => {

      try {

          var request = {'request': null};

          const Api = process.env.REACT_APP_API_URL;   
          const options = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
          };
  

      const response = await fetch(Api+"/api/products/" + ProductID +"/components/", options);
      //console.log(response);
      const data = await response.json();
      console.log("DATA", data);
      //var link_to_products = "/products/";
      //var link_to_product = "/products/" + ProductID + "/components/";

      setContent(data["data"].map((item) => {return (<>
        {/*}

      <span className = 'mx-2'>{'>>'}</span>
      <Link className = 'mx-2'to={'/products/' + ProductID}>{ProductID}</Link>
      <span className = 'mx-2'>{'>>'}</span>
      <Link className = 'mx-2'to={link_to_product}>Components</Link>
      <div className = 'd-flex flex-start p-0'>
        <Link className = 'mx-2' to={link_to_products}>Products</Link> {' >> '}
        <span className = 'mx-2'>{ProductID}</span> {' >> '}
        <Link className = 'mx-2'to={link_to_product}>Components</Link>
      </div> */}
      <div className = 'mt-2'>
          <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
              <tr className="table-light" >
              <th><DictTag tag = 'ID'/></th>
              <th><DictTag tag = 'Name'/></th>
              <th><DictTag tag = 'Subcomponents'/></th>
              <th><DictTag tag = 'KitGroup'/></th>
              <th><DictTag tag = 'ExpectedTime'/></th>
              <th><DictTag tag = 'ActualTime'/></th>
              {/*<th>Details</th>*/}
              <th><DictTag tag = 'Actions'/></th>
            </tr>
            </thead>
            <tbody>
              {!item.hasOwnProperty('components') ? null : item.components.map((component) => {

//                  var link_to_sequence = "/products/" + item.ProductID + "/components/" + component.ComponentID + "/sequence/";
//                  var content = component.subcomponents.map((subcomponent) => {return (<span className = 'badge bg-secondary mx-1'>{subcomponent.Quantity} x {subcomponent.SubComponentName} ({subcomponent.SubComponentID})</span>)})
                  return <>
              <tr>
                  <td><Link to={`/products/${item.ProductID}/components/${component.ComponentID}/sequence/`}>{component.ComponentID}</Link></td>
                  <td><Update ProductID = {item.ProductID} ComponentID = {component.ComponentID}  FieldName = 'ComponentName' FieldType = 'text' FieldValue = {component.ComponentName}/></td>
                  <MyContent component = {component} components = {item.components}/>                
                  {/*<td><Link to={link_to_sequence}>sequence</Link></td>*/}
                  <td><Update ProductID = {item.ProductID} ComponentID = {component.ComponentID}  FieldName = 'KitGroup' FieldType = 'number' FieldValue = {component.KitGroup}/></td>
                  <td>{component.ExpectedTime_HHMM}</td>
                  <td>{component.ActualTime_HHMM}</td>
                  <td><Delete ProductID = {item.ProductID} ComponentID = {component.ComponentID}/></td>                
              </tr></>} )}
                  <Insert ProductID = {item.ProductID}/>
                  </tbody>
            </table>
          </div>      
      
      </>)}));

  } catch (error) {
      setContent('Error fetching data: '+ error)
    } finally {
      setIsLoading(false);
    }

  };
  
  useEffect(() => {fetchData()}, []);

  var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

  return (
  <>
    {isLoading ? loading : content }

  </>);

};

export default ProductComponents;
