import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, getToken } from '../utils/common';
import axios from 'axios';
import { Box, Container } from '@mui/system';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import PlayCircle from '@mui/icons-material/PlayCircle';
import StopCircle from '@mui/icons-material/StopCircle';
import PauseCircle from '@mui/icons-material/PauseCircle';
import Block from '@mui/icons-material/Block';
import ReportProblem from '@mui/icons-material/ReportProblem';
import Backdrop from '@mui/material/Backdrop';

import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

//import QRScanner from './QRScanner';
import { QrReader } from 'react-qr-reader';




function ConfirmDialog(props) {
        
    const [open, setOpen] = React.useState(true);
  
    const handleAgree = () => {
        setOpen(false);
        props.setConfirmed(true);
        console.log("agree")
        TaskAction (props.targetTask, props.targetAction);  
    };

    const handleDisagree = () => {
        setOpen(false);
        props.setConfirmed(true);
        console.log("disagree")

    };
    
    useEffect(() => {
    }, [props]);

    
    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleDisagree}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {props.dialogTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {props.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDisagree}>NO</Button>
            <Button onClick={handleAgree} >YES</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
};

function TaskAction (task, action) {
        
   // console.log("ACT", action)

    const fetchData = async () => {
      try {
  
        const request = {};
        const now = new Date();
        request["Now"] = now.toLocaleString('sv');
        request["UTCoffset"] = now.getTimezoneOffset();
        request["OperatorID"] =task.AssignedTo;
        request["BatchID"] =task.BatchID;
        request["ComponentID"] =task.ComponentID;
        request["SequenceID"] =task.SequenceID;
        request["KitID"] =task.KitID;
        request["tools"] =task.available_tools;
        request["ExpectedTime"] =task.ExpectedTime;
        request["Event"] = action;
  
        var api = ''

        if (action === 'complete') api = "/api/complete_task/";
        else if (action === 'start') api = "/api/start_task/";
        else if (action === 'pause') api = "/api/pause_task/";
        else if (action === 'resume') api = "/api/resume_task/";
        else if (action === 'reject') api = "/api/reject_task/";
        else return;
        
            const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify(request),
        };
  
        const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}${api}`, request, options);
//        console.log(response)
        window.location.reload();
  
      } catch (error) {
        console.error('Error fetching data:', error);
    } finally {
      }
    };

    fetchData();
};
  
const OperatorSection = props => 
{
    const operator = props.operator
//    const opSkills = operator.OperatorSkills ? JSON.parse(operator.OperatorSkills) : {}; 
//    console.log(opSkills)
  
  const [scanResult, setScanResult] = useState(null)
  const [scanQR, setScanQR] = useState(false)

  const playBeep = () => {
    
    const context = new (window.AudioContext || window.webkitAudioContext)();
    const oscillator = context.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(2000, context.currentTime); // Frequency in Hz
    oscillator.connect(context.destination);
    oscillator.start();
    setTimeout(() => {
      oscillator.stop();
    }, 500); // Beep duration in milliseconds
  };

  const handleScan = (result) => {
    //console.log("SCAN", result)
    if (result?.text) {
      try { 
        setScanResult(JSON.parse(result.text));
        console.log('Scan result:', result.text);
      }
      catch (error) {console.log(error)}
//      setScanResult(result.text);
//      console.log('Scan result:', result.text);
    }
  };

  useEffect(() => {
    if (scanResult) {
      if(scanResult.hasOwnProperty('BatchCode')) playBeep();
    }
  }, [scanResult]);


    return (
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {operator.OperatorName} ({operator.OperatorID})
        </AccordionSummary>
        <AccordionDetails>
            <div className = 'row'>

            {operator.working_on_TaskID && (<>
                <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Working on</small></Divider>
                <Typography textAlign='center' fontWeight='bold'>{operator.working_on_TaskID}</Typography></>)}

            <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Skills</small></Divider>
            {operator.OperatorSkills && operator.OperatorSkills.map((skill) => <div key = {skill.Skill} className="col-4 col-sm-4 col-md-3 col-lg-1 p-1 m-1 panel"><Chip key = {skill.Skill} label={`${skill.Skill} ${skill.SkillValue}`} variant="outlined" /></div>)}

            <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Kit assegnati</small></Divider>

                {operator.kits && operator.kits.map((kit) => <><div className="col-12 col-sm-6 col-md-4 col-lg-2 p-1 m-1 panel"><Chip key = {kit.KitID} label={kit.BatchCode + '/' + kit.KitID} variant="outlined" /></div></>)}

            <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Attrezzature assegnate</small></Divider>

                {operator.tools && operator.tools.map((tool) => <><div className="col-12 col-sm-6 col-md-4 col-lg-2 p-1 m-1 panel"><Chip key = {tool.ToolID} label={tool.ToolID + ' ' + tool.ToolName} variant="outlined" /></div></>)}                

            </div>
        </AccordionDetails>
        <AccordionDetails>
         {scanQR && !scanResult && (<QrReader
            delay={1000}
            constraints={{ facingMode: 'environment' }} // Use rear camera if available
            onResult={handleScan}
            style={{ width: '100%' }}
          />)}
         {scanResult && <Paper className='p-2' elevation = {10}>{JSON.stringify(scanResult)}</Paper>}
         {scanQR && (<Button onClick={() => {setScanQR(!scanQR); setScanResult(null);}}>Reset</Button>)}
         {!scanQR && <Button onClick={() => {setScanQR(!scanQR)}}>Scan QR</Button>}
          </AccordionDetails>

      </Accordion>

);

};


const KitBadge = ({BatchID, BatchCode, KitID, OperatorID, AssignedOperator}) => {
  
    var bgcolor = (AssignedOperator !== OperatorID) && AssignedOperator !== null ? "red" : "green";
    var textcolor = (AssignedOperator !== OperatorID) && AssignedOperator !== null ? "black" : "white";
  
  
    const [responseData, setResponseData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const fetchData = async () => {
      if (!(AssignedOperator == null)) return
      try {
        setIsLoading(true);
  
        const request = {};
        const now = new Date();
  
        request["Now"] = now.toLocaleString('sv');
        request["UTCoffset"] = now.getTimezoneOffset();
        request["OperatorID"] = OperatorID;
        request["BatchID"] = BatchID;
        request["KitID"] = KitID;
  
        const Api = process.env.REACT_APP_API_URL;
     
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
          },
          body: JSON.stringify(request),
        };
  
        const response = await fetch(Api+"/api/assign_kit/", options);
        const data = await response.json();
        setResponseData(data);
        //console.log(data)
        window.location.reload();
  
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
        <Chip 
            label={`${BatchCode}/${KitID}`} variant="outlined" 
            style = {{backgroundColor: bgcolor, color: textcolor}}
            onClick={fetchData}    
        />);
    
  };
  

const ToolBadge = ({tool, OperatorID}) => {
  
    var bgcolor = tool.AssignedOperator ? "red" : "green";
    var textcolor = tool.AssignedOperator ? "black" : "white";
  
    const [responseData, setResponseData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const fetchData = async () => {
      try {
        setIsLoading(true);
  
        const request = {};
        const now = new Date();
  
        request["Now"] = now.toLocaleString('sv');
        request["UTCoffset"] = now.getTimezoneOffset();
        request["OperatorID"] = OperatorID;
        request["ToolID"] = tool.ToolID;
  
        const Api = process.env.REACT_APP_API_URL;
     
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
  //          'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
        };
  
        const response = await fetch(Api+"/api/assign_tool/", options);
        const data = await response.json();
        setResponseData(data);
        //console.log(data)
        window.location.reload();
  
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return (
    <Chip 
        label={`${tool.ToolID} ${tool.ToolName}`} variant="outlined" 
        style = {{backgroundColor: bgcolor, color: textcolor}}
        onClick={tool.AssignedOperator ? null : fetchData}    
    />);

  };
  


const TaskSection = props => 
    {

 //       console.log("PROPS", props)
        const task = props.task
        const [value, setValue] = React.useState(null);
        const [isLoading, setIsLoading] = React.useState(false);

        var bg = task.isWorkedOn === 1 ? 'green' : '';
        if (task.isPaused === 1) bg = 'grey';
        if (task.isPaused === 1) props.setPaused(true);
        if (task.isPaused === 1) props.setPausedTask(task);

        const border = task.isWorkedOn === 1 ? `2px solid  ${bg}` : '';
        const color = task.isWorkedOn === 1 ? 'white' : '';
        const disabled = (task.working_on_some_task === 1 && task.working_on_this_task === 0) ? true : false; 
        const opacity = disabled ? 'opacity-75' : '';
        const expanded = (task.isWorkedOn === 1 || (task.working_on_some_task === 0 && task.working_on_this_task === 0)) ? true : false;
        if (task.working_on_some_task === 0 && task.working_on_this_task === 0) bg = 'cyan';

        const bg1 = '#F5F5F5' //'#DCDCDC' //'#F5F5F5'
        const elevation = 10

       function execute(action) {

        setValue(action);
        if (action === 'pause') {
           clearInterval(props.IntervalID);   
           props.setConfirmed(false);

           props.setPaused(true);
           props.setTargetTask(task);
           props.setTargetAction(action);
           props.setDialogTitle("Confirm "+action+"?")
           props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)   

       }
        else if (action === 'resume')  {
           const intervalId = setInterval(props.fetchData, 1000);
           props.setIntervalID(intervalId)    
           props.setConfirmed(false);

           props.setPaused(false);
           props.setTargetTask(task);
           props.setTargetAction(action);
           props.setDialogTitle("Confirm "+action+"?")
           props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)   

       }
        else {
           props.setConfirmed(false);
           props.setTargetTask(task);
           props.setTargetAction(action);
           props.setDialogTitle("Confirm "+action+"?")
           props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)   
        }

       }






        return (
            <>

            <Accordion defaultExpanded={expanded} sx ={{border: `${border}`}} className={`${opacity}`}>
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx ={{backgroundColor: `${bg}`, color: `${color}` }}
                >
                {`Task >> ${task.BatchCode}-${task.ComponentID}-${task.SequenceID}`}
                </AccordionSummary>

                <AccordionDetails>
                  
                    <Stack
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                    >
                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Articolo</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                          <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>{task.ProductName}</Typography>
                          </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Cesta</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        {(task.kit_ok ?  true : false) && (<><Typography  textAlign='center' fontWeight='bold' fontSize='1.5em'>{task.BatchCode}</Typography><Typography  textAlign='center' sx={{fontStyle:'italic'}} fontSize='1.0em'>Sub {task.KitID}</Typography></>)}
                        {(!task.kit_ok ? true: false) && <KitBadge BatchID = {task.BatchID} BatchCode = {task.BatchCode} KitID = {task.KitID} OperatorID={task.AssignedTo} AssignedOperator={task.kit_assigned}/>}
                          </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Componente</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{`${task.TaskQuantity} x ${task.ComponentName}`}</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Attività</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{`${task.SequenceName}`}</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>

                      <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Attrezzatura</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        {(task.tool_ok?true:false) && props.tools && props.tools.map((tool) => <><Chip key = {tool.ToolID} label={tool.ToolID + ' ' + tool.ToolName}  sx={{fontWeight:'bold', fontSize: '1.5em', border: '2px solid black'}}  variant="outlined" /></>)}
                        {(!task.tool_ok?true:false) && task.available_tools && task.available_tools.map((tool) => <ToolBadge tool = {tool} OperatorID={task.AssignedTo}/>)}
                          </Paper>  
                        </Stack>
                      </Paper>

                    </Stack>

                    {/*
                    <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Articolo</small></Divider>
                    <Typography textAlign='center' fontWeight='bold'>{task.ProductName}</Typography>

                    <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Cesta</small></Divider>
                    {(task.kit_ok ?  true : false) && <Typography textAlign='center' fontWeight='bold' sx={{color: 'white', backgroundColor:'black'}} className= 'py-2'>{task.BatchCode + '/' + task.KitID}</Typography>}
                    {(!task.kit_ok ? true: false) && <KitBadge BatchID = {task.BatchID} BatchCode = {task.BatchCode} KitID = {task.KitID} OperatorID={task.AssignedTo} AssignedOperator={task.kit_assigned}/>}
        
                    <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Componenti</small></Divider>
                    <Typography textAlign='center' fontWeight='bold' sx={{color: 'white', backgroundColor:'black'}} className= 'py-2'>{`${task.TaskQuantity} x ${task.ComponentName}`}</Typography>

                    <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Attività</small></Divider>
                    <Typography textAlign='center' fontWeight='bold' sx={{color: 'white', backgroundColor:'black'}} className= 'py-2'>{`${task.SequenceName}`}</Typography>
                      
                    <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Attrezzatura</small></Divider>
                    {(task.tool_ok?true:false) && props.tools && props.tools.map((tool) => <><div className="col-12 col-sm-6 col-md-4 col-lg-2 p-1 m-1 panel"><Chip key = {tool.ToolID} label={tool.ToolID + ' ' + tool.ToolName} variant="outlined" /></div></>)}
                    {(!task.tool_ok?true:false) && task.available_tools && task.available_tools.map((tool) => <ToolBadge tool = {tool} OperatorID={task.AssignedTo}/>)}
                  */}


                    </AccordionDetails>
       
       {/*<Divider textAlign="left" sx ={{fontStyle: 'italic'}}>Azioni</Divider>         */}

        <AccordionActions>
        <Box sx={{ flexGrow: 1, width: '100%' }} className='m-2'>
        <Grid container spacing={2}>
        {!isLoading && !disabled && task.isPossible && task.kit_ok && task.tool_ok && task.isWorkedOn === 0 && task.isPaused === 0 && (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('start')}}>
            <PlayCircle color='success' fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Avvia</Typography>
            </Paper>
          </Grid>
        )}
        {!isLoading && !disabled && task.isWorkedOn === 1 && task.isPaused === 0 && (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('complete')}}>
            <StopCircle sx = {{color: 'purple'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Completa</Typography>
            </Paper>
          </Grid>
        )}
        {!isLoading && !disabled && task.isWorkedOn === 1 && task.isPaused === 0 && (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('pause')}}>
            <PauseCircle sx = {{color: 'black'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Pausa</Typography>
            </Paper>
          </Grid>
        )}
        {!isLoading && !disabled && task.isWorkedOn === 1 && task.isPaused === 1 &&  (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('resume')}}>
            <PlayCircle sx = {{color: 'orange'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Riprendi</Typography>
            </Paper>
          </Grid>
        )}
       {!isLoading && task.isPaused === 0 &&  (
          <Grid item xs={6} sm={4}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('reject')}}>
            <Block sx = {{color: 'orange'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Rifiuta</Typography>
            </Paper>
          </Grid>
        )}

      {!isLoading && task.isPaused === 0 &&  (
          <Grid item xs={12}>
            <Paper elevation = {elevation} sx={{border: '1px solid black', textAlign: 'center'}} className='p-2' onClick={()=>{execute('reportProblem')}}>
            <ReportProblem sx = {{color: 'red'}} fontSize='large'/>
            <Typography textAlign='center' fontWeight='bold' fontSize='1.5em'>Segnala Anomalia</Typography>
            </Paper>
          </Grid>
        )}

        </Grid>
      </Box>


      </AccordionActions>
     
     {/* <AccordionActions>


       <Box sx = {{width: '100%'}}>

     <BottomNavigation
       showLabels
       value={value}
       onChange={(event, action) => {
         setValue(action);
         if (action === 'pause') {
            clearInterval(props.IntervalID);   
            props.setConfirmed(false);

            props.setPaused(true);
            props.setTargetTask(task);
            props.setTargetAction(action);
            props.setDialogTitle("Confirm "+action+"?")
            props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)   

        }
         else if (action === 'resume')  {
            const intervalId = setInterval(props.fetchData, 1000);
            props.setIntervalID(intervalId)    
            props.setConfirmed(false);

            props.setPaused(false);
            props.setTargetTask(task);
            props.setTargetAction(action);
            props.setDialogTitle("Confirm "+action+"?")
            props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)   

        }
         else {
            props.setConfirmed(false);
            props.setTargetTask(task);
            props.setTargetAction(action);
            props.setDialogTitle("Confirm "+action+"?")
            props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)   
         }
       }}
     >
        {!isLoading && !disabled && task.isPossible && task.kit_ok && task.tool_ok && task.isWorkedOn === 0 && task.isPaused === 0 && (<BottomNavigationAction label="Avvia" value = {'start'} icon={<PlayCircle color='success' fontSize='large'/>} />)}
        {!isLoading && !disabled && task.isWorkedOn === 1 && task.isPaused === 0 && (<BottomNavigationAction label="Completa" value = {'complete'} icon={<StopCircle sx = {{color: 'purple'}} fontSize='large'/>} />)}
        {!isLoading && !disabled && task.isWorkedOn === 1 && task.isPaused === 0 && (<BottomNavigationAction label="Pausa" value = {'pause'} icon={<PauseCircle sx = {{color: 'black'}}  fontSize='large'/>} />)}
        {!isLoading && !disabled && task.isWorkedOn === 1 && task.isPaused === 1 && (<BottomNavigationAction label="Riprendi" value = {'resume'} icon={<PlayCircle sx = {{color: 'orange'}} fontSize='large'/>} />)}

     </BottomNavigation>
     <Divider textAlign="left" sx ={{fontStyle: 'italic'}}></Divider>

     <BottomNavigation
       showLabels
       value={value}
       onChange={(event, action) => {
         setValue(action);
         props.setConfirmed(false);
         props.setTargetTask(task);
         props.setTargetAction(action);
         props.setDialogTitle("Confirm "+action+"?")
         props.setDialogMessage(`${task.ProductName} > ${task.ComponentName} > ${task.SequenceName}`)

        }}
     >
       {!isLoading && task.isPaused === 0 && (<BottomNavigationAction label="Rifiuta" value = {'reject'} icon={<Block sx = {{color: 'orange'}}  fontSize='large'/>} />)}
       {!isLoading && task.isPaused === 0 && (<BottomNavigationAction label="Segnala Anomalia" value = {'reportProblem'} icon={<ReportProblem sx = {{color: 'red'}}  fontSize='large'/>} />)}
     </BottomNavigation>
     
   </Box> 

       </AccordionActions>*/}

       {(!disabled && task.Started) && <AccordionDetails>
       
        <Paper elevation={elevation} sx={{backgroundColor: 'white', border:'2px solid black'}}>
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="stretch"
                          spacing={0}
                        >
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>Tempo</Paper>  
                        <Divider />
                        <Paper className='p-2' sx={{backgroundColor: bg1, textAlign: 'center'}}>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em'>{task.Elapsed_hhmm}/{task.ExpectedTime_hhmm}</Typography>
                        <Typography textAlign='center' fontWeight='bold'   fontSize='1.5em' sx={{color: `${task.Completion && task.Completion > 100 ? 'black' : ''}`, backgroundColor: `${task.Completion && task.Completion > 100 ? 'yellow' : ''}`, }} >{task.Completion}%</Typography>
                        </Paper>  
                        </Stack>
                      </Paper>
       
       {/*
       <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Avviato</small></Divider>
       <Typography textAlign='center'>{task.Started}</Typography>
       <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Trascorso</small></Divider>
       <Typography textAlign='center'>{task.Elapsed_hhmm}/{task.ExpectedTime_hhmm}</Typography>
       <Divider textAlign="left" sx ={{fontStyle: 'italic'}}><small>Completato</small></Divider>
       <Typography textAlign='center' sx={{color: `${task.Completion && task.Completion > 100 ? 'black' : ''}`, backgroundColor: `${task.Completion && task.Completion > 100 ? 'yellow' : ''}`, }} >{task.Completion}%</Typography>
       */}
       </AccordionDetails>      
       
       
       
       
       }
     </Accordion>


            </>    
    );
    
};
    
const OperatorPanel = () => {

    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(true);
    const [operator, setOperator] = useState({});
    const [error, setError] = useState(null);

    const [paused, setPaused] = React.useState(false);
    const [pausedTask, setPausedTask] = React.useState(null);
    
    const [confirmed, setConfirmed] = React.useState(true);
    const [dialogMessage, setDialogMessage] = React.useState('');
    const [dialogTitle, setDialogTitle] = React.useState('Conferma?');

    const [targetTask, setTargetTask] = React.useState({});
    const [targetAction, setTargetAction] = React.useState('');

    const [IntervalID, setIntervalID] = React.useState('');
//    console.log("TARGET", targetTask, targetAction)

    const handleResume = () => {
      setPaused(false);
      const intervalId = setInterval(fetchData, 1000);
      setIntervalID(intervalId);
      TaskAction(pausedTask, 'resume', setLoading)
    };

//    console.log("OPERATOR", operator)

    const fetchData = async () => {
        try {
            const user = getUser();
            const auth = user.auth;
            if (!auth.hasOwnProperty('operator_panel') || !(auth.operator_panel.includes("a") || auth.operator_panel.includes("r"))) {
                navigate('/dashboard');
            }

            const now = new Date();
            const request = {
                Now: now.toLocaleString('sv'),
                UTCoffset: now.getTimezoneOffset()
            };

            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': getToken()
                }
            };

            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/operator/work_panel/`, request, options);
            setOperator(response.data.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setError(error.message);
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchData();
        console.log(paused);
        clearInterval(IntervalID)
        const intervalId = setInterval(fetchData, paused ? 5000 : 1000);
        setIntervalID(intervalId)
        return () => clearInterval(IntervalID);
    }, [paused]);

    useEffect(() => {
    }, [operator]);

    useEffect(() => {
    }, [paused]);

    useEffect(() => {
    }, [confirmed]);

    useEffect(() => {
    }, [targetAction]);

    useEffect(() => {
    }, [targetTask]);

    if (error) {
        return <p style={{ color: 'red' }}>Error: {error}</p>;
    }
      
    var bgcolor='white';
    var textcolor='black';
    var hasTask = operator.hasOwnProperty('task') && operator.task;
    if(hasTask && operator.task.isPaused == 1) {bgcolor = "yellow"; textcolor = "black";}
    else if(hasTask && operator.task.isWorkedOn == 1) {bgcolor = "green"; textcolor = "white";}
    else if(hasTask) {bgcolor = "#ADD8E6"; textcolor = "black";}


    const PausedScreen = () =>
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <Box sx = {{backgroundColor: 'white'}} className='p-3'>
          <PlayCircle 
            sx = {{color: 'orange'}} 
            fontSize='large'
            onClick={handleResume}            
            />
            </Box>
        </Backdrop>
    </div>
    
    const backgroundImageUrl = `/img/kuoyo_background.jpg`;

    return (
    <>
    {!confirmed && <ConfirmDialog targetTask = {targetTask} targetAction = {targetAction} confirmed={confirmed} setConfirmed={setConfirmed} dialogMessage={dialogMessage} dialogTitle={dialogTitle}/>}
    {paused && <PausedScreen/>}
    <div style={{margin: '0px', backgroundSize: 'cover',  backgroundImage: `url(${backgroundImageUrl})`, height: '100vh'}}> 

    <div className="container p-2" style={{background:'transparent'}}>
        <div className='mb-2'>{<OperatorSection operator={operator}/>}</div>
        {operator.assigned_tasks && operator.assigned_tasks.map((task) => 
            <TaskSection 
                key = {`${task.BatchCode}-${task.ComponentID}-${task.SectionID}`} 
                task={task}
                tools={operator.tools} 
                setPaused={setPaused}
                setPausedTask={setPausedTask}            
                confirmed={confirmed}
                setConfirmed={setConfirmed}
                setTargetTask={setTargetTask}            
                setTargetAction={setTargetAction}            
                setDialogTitle={setDialogTitle}            
                setDialogMessage={setDialogMessage} 
                IntervalID={IntervalID}
                setIntervalID={setIntervalID}           
                fetchData={fetchData}
/>)}
    </div>    
    </div>    
    </>
    );



};

export default OperatorPanel;
