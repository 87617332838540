import React, { useEffect, useState } from 'react';
import { Spinner, Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';

import { faTrash, faPlus, faSave, faCancel, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';

const CreateBatchModal = ({ JobID, JobQuantity }) => {
    const [showModal, setShowModal] = useState(false);
    const [quantity, setQuantity] = useState(1); 
    const [batch_size, setBatchSize] = useState(1); 
  
    const handleModal = () => {
        setShowModal(!showModal);
    };
  
    const handleQuantityChange = (event) => {
        var _q = event.target.value;
        if (_q * batch_size > JobQuantity) console.log("Not possible")
        setQuantity(_q);
    };

    const handleBatchSizeChange = (event) => {
        var _b = event.target.value;
        if (quantity * _b > JobQuantity) console.log("Not possible")
        setBatchSize(_b);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            // Make an Axios POST request to your backend API endpoint
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/jobs/` + JobID + `/create_batches/`, {
                JobID: JobID,
                BatchSize: batch_size,
                BatchesQuantity: quantity
            });
            // Check the response status
            if (response.status === 200) {
                console.log('Batches added successfully');
                // Close the modal
                setShowModal(false);
                window.location.reload();
                
            } else {
                console.error('Failed to add batches');
            }
        } catch (error) {
            console.error('Error adding batches:', error);
        }
    };
  
    return (
        <>
            <FontAwesomeIcon className='mx-2' icon={faPlus} onClick={handleModal} style={{ cursor: 'pointer' }}></FontAwesomeIcon>
            <Modal show={showModal} onHide={handleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create new batches for job {JobID}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="batch_size">
                            <Form.Label>Batch Size</Form.Label>
                            <Form.Control
                                type="number"
                                value={batch_size}
                                onChange={handleBatchSizeChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="quantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
  };

  export default CreateBatchModal;