import React, { useEffect, useState} from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Switch from 'react-switch';
import { getToken } from '../utils/common';
import FilterInput from '../utils/FilterInput';
import DictTag from '../dictionaries/DictTag';

import CheckMark from '../elements/CheckMark'
import SelectKitAssignedOperator from './SelectKitAssignedOperator';

const KitSwitchButton = ({ BatchID, KitID, FieldName, value }) => {

    
    const [isChecked, setIsChecked] = useState(value);
    const [ErrorMessage, setErrorMessage] = useState(null);

    const updateParameter = async () => {

        var body =  { 
            BatchID: BatchID,
            KitID: KitID,
            FieldName: FieldName, 
            FieldValue: isChecked ? 0 : 1,
        };
        //console.log(body);
    
        try {
    
            const Api = process.env.REACT_APP_API_URL;       
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(body),
            };
            
        const response = await fetch(Api+"/api/batches/" + BatchID +"/kits/" + KitID +"/update/", options);
        
          const data = await response.json();
          if (!response.ok) {
            throw new Error('Failed to update parameter');
          }
          window.location.reload();
  
        } catch (error) {
            console.log(error.message);
            setErrorMessage('Error updating parameter: ' + error.message);
        }
    
    };
    

    useEffect(() => {
      setIsChecked(value);
    }, [value]);
  
    const handleChange = (checked) => {
      setIsChecked(checked);
      updateParameter();
    };
  
    return (
      ErrorMessage ? <p>{ErrorMessage}</p>
      :
      <div>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          onColor="#86d3ff"
          offColor="#dcdcdc"
          checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
          uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
          handleDiameter={24}
          height={26}
          width = {80}
        />
      </div>
    );
};

const Kits = () => {

    let { ProductID, JobID } = useParams();
    
    var isPageForJob = typeof(ProductID) !== 'undefined' && typeof(JobID) !== 'undefined';
//    console.log(isPageForJob)

    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);

    const [filters, setFilters] = useState({
      field1: '',
      field2: '',
      field3: '',    
    });
  
    const setFilter1 = (value) => {
      setFilters({ ...filters, field1: value });
    };
    
    const setFilter2 = (value) => {
      setFilters({ ...filters, field2: value });
    };
  
    const setFilter3 = (value) => {
      setFilters({ ...filters, field3: value });
    };


    const fetchData = async () => {
  
      try {
  
        var request = {

          'ProductID': ProductID,
          'JobID': JobID,        
          'isPageForJob' : isPageForJob,
        };
//        console.log(request)
        const backend = process.env.REACT_APP_API_URL;   
        const api = "/api/kits/";   

        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
        };
    
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();

        console.log(data["data"]);
        var link_to_products = "/products/";
        var link_to_jobs = "/products/" + ProductID + "/jobs/";
        var link_to_kits = "/products/" + ProductID + "/jobs/" + JobID + "/kits/";

        var header = <><div className = 'd-flex flex-start p-3'>
        <Link className = 'mx-2' to={link_to_products}><DictTag tag = 'Products'/></Link>
        {' >> '}
        <span className = 'mx-2'>{ProductID}</span>
        {' >> '}
        <Link className = 'mx-2'to={link_to_jobs}><DictTag tag = 'Jobs'/></Link>
        {' >> '}
        <span className = 'mx-2'>{JobID}</span>
        {' >> '}
        <Link className = 'mx-2'to={link_to_kits}><DictTag tag = 'Kits'/></Link>
      </div></> 

        setContent(
          <>
            {isPageForJob ?  header : null}

                <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
                  <thead>
                    <tr className="table-light" >
                    <th><DictTag tag = 'Product'/></th>
                    <th><DictTag tag = 'Job'/></th>
                    <th><DictTag tag = 'BatchCode'/></th>
                    <th><DictTag tag = 'KitID'/></th>
                    <th><DictTag tag = 'isReady'/></th>
                    <th><DictTag tag = 'Batch Completed'/></th>
                    <th><DictTag tag = 'isBeingUsed'/></th>
                    <th><DictTag tag = 'OperatorID'/></th>
                  </tr>
                  <tr className="table-light" >
                    <th><FilterInput initialValue={''} setExternalValue={setFilter1}/></th>
                    <th><FilterInput initialValue={''} setExternalValue={setFilter2}/></th>
                    <th><FilterInput initialValue={''} setExternalValue={setFilter3}/></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>

                  </thead>
                  <tbody>
                    {data["data"]["kits"].map((item) => {
                      
//                      console.log(item);

                      var visible = true;

                      if (!(filters.field1 == '')) if 
                      (!(
                        (item.ProductID.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
                        || (item.ProductName.toString().toUpperCase().includes(filters.field1.toUpperCase())) 
                      )) 
                        visible = false;

                        if (!(filters.field2 == '')) if 
                        (!(
                          (item.JobID.toString().toUpperCase().includes(filters.field2.toUpperCase())) 
                        )) 
                          visible = false;

                          if (!visible) return <></>;


                      if (!(filters.field3 == '')) if 
                        (!(
                          (item.BatchCode.toString().toUpperCase().includes(filters.field3.toUpperCase())) 
                        )) 
                          visible = false;

                          if (!visible) return <></>;

                      return(
                      <>
                        <tr className="table-light" >
                        <td>{item.ProductName} ({item.ProductID}) </td>
                        <td>{item.JobID}</td>
                        <td>{item.BatchCode} [{item.BatchID}] </td>
                        <td>{item.KitID}</td>
                        <td><KitSwitchButton BatchID = {item.BatchID} KitID = {item.KitID} FieldName = 'isReady' value = {item.isReady}/></td>
                        <td><CheckMark value = {item.BatchCompleted}/></td>
                        <td><CheckMark value = {item.isBeingUsed}/></td>
                        <td>
                          <SelectKitAssignedOperator 
                            BatchID = {item.BatchID} 
                            KitID = {item.KitID} 
                            currentOperator={{OperatorID: item.OperatorID, OperatorName:item.OperatorName}}
                            />
                        </td>
                        </tr></>);              
  
                      })}
                  </tbody>
                </table>
          </>       
        );
  
      } catch (error) {setContent('Error fetching data: '+ error)
      } finally {setIsLoading(false);}
  
    };
    
    // fetch data only on mount
    useEffect(() => {fetchData()}, [filters]);
//    useEffect(() => {}, [filters]);
  
    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;
  
    return (<>{isLoading ? loading : content }</>);

};

export default Kits;
