import CircularProgress from '@mui/material/CircularProgress';


// return the user data from the session storage
export const getUser = () => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
  
  // return the token from the session storage
  export const getToken = () => {
    return sessionStorage.getItem('token') || null;
  }
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user', JSON.stringify(user));
  }

export function checkPageAuthorization (perms, navigate) {

  for (const perm of perms) {
    try {

      const user = getUser();
      if (!user?.permissions.includes(perm)) navigate('/dashboard');
    }
    catch (error) {console.log(error); navigate('/dashboard');}
  }
}

export function checkPerm (perm) {

    try {
      const user = getUser();
      if (user?.permissions.includes(perm)) return true;
    }
    catch (error) {console.log(error); return false;}
    return false;
}


  export const Loading = () => {

    return (
      <div className="m-5">
        <CircularProgress color="primary" />
      </div>
    );
  
};
  

export const fetchData = async (api, request, setData, setIsLoading = null, setVisible=null) => {

    try {

        if (setIsLoading) setIsLoading(true);

        const backend = process.env.REACT_APP_API_URL;
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

//        console.log(options)
        const response = await fetch(`${backend}${api}`, options);
//        console.log(response)
        const data = await response.json();
        console.log("fetchData", data.data)
        setData(data.data);
        if (setVisible != null) setVisible(false);
        return({status: 'success', 'data': data.data});

    } catch (error) {
        return({status: 'error', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading) setIsLoading(false);
    }
};


export const DictValue = (tag) => {
  const user = getUser();
  const dictionary = user?.dictionary;

  // Return the tag if dictionary is null or undefined
  if (!dictionary) return tag;

  // Create a lowercase version of the tag to check in the dictionary
  const lowerCaseTag = tag.toLowerCase();

  // Fetch the value from the dictionary
  const value = dictionary[lowerCaseTag];

  // If the value exists, return it; otherwise, return the original tag
  if (value !== undefined) {
    // Check if the original tag has a capitalized first letter
    if (tag[0] === tag[0].toUpperCase()) {
      // Capitalize the first letter of the value
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value;
  }

  return tag;
};