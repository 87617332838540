import React from 'react';
import {Routes, Route, Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import DictTag from '../dictionaries/DictTag'
import {getUser} from '../utils/common.js'
import UsersAccess from './UsersAccess.js';
import UsersList from './UsersList.js';

const UsersMain = () => {

    const permission = 'users'
    const history = useNavigate();

    try {
      const user = getUser();
      if (!user?.permissions.includes(permission)) history('/dashboard');
    }
    catch (error) {console.log(error); history('/dashboard');}


    return (
        <>
            <div className="my-2 bg-white">
                <Nav variant="tabs">
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/users`} eventKey="users"><DictTag tag= {'Users'}/></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to={`/users/access/`} eventKey="access"><DictTag tag= {'Access Control'}/></Nav.Link>
                    </Nav.Item>
                </Nav>
            </div>
            <div>
               <Routes>
                   <Route index element={<UsersList />} />
                   <Route path="access/" element={<UsersAccess />} />
                </Routes>
            </div>
        </>
    );
};



export default UsersMain;