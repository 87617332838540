import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { Breadcrumbs, Typography, Container, Box } from '@mui/material';
import Users from './Users';
import { UserDetail } from './UserDetail';
import UsersAccess from './UsersAccess';
import DictTag from '../dictionaries/DictTag';
import UsersList from './UsersList';

function UsersMain() {
  const location = useLocation();
  
  // Get all pathnames by splitting the current location's pathname
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Container maxWidth="xxl" sx={{ mt: 4, backgroundColor:'white' }}>
      {/* Breadcrumbs Container */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          {/* First link (Home) */}
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            Home
          </Link>
          
          {/* Users link */}
          {pathnames.length > 0 ? (
            <Link to="/users" style={{ textDecoration: 'none', color: 'inherit' }}>
              <DictTag tag="Users" />
            </Link>
          ) : (
            <Typography color="text.primary">
              <DictTag tag="Users" />
            </Typography>
          )}

          {/* Render additional breadcrumb items for nested routes */}
          {pathnames.map((value, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`;
            const isLast = index === pathnames.length - 1;
            
            return isLast ? (
              <Typography color="text.primary" key={to}>
                {value}
              </Typography>
            ) : (
              <Link to={to} key={to} style={{ textDecoration: 'none', color: 'inherit' }}>
                {value}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Box>
      
      {/* Routes */}
      <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
        <Routes>
          <Route index element={<UsersList />} />
          <Route path="users/" element={<UsersList />} />
          {/*<Route path="access/" element={<UsersAccess />} />*/}
          <Route path="user/:UserHash/*" element={<UserDetail />} />
        </Routes>
      </Box>
    </Container>
  );
}

export default UsersMain;
