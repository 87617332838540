import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Update } from '../utils/elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, Box, IconButton, Typography,
    CircularProgress, Grid, Container } from '@mui/material';


const CreateModal = ({ open, onClose, onCreated }) => {
  const [formData, setFormData] = useState({
    name: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/users/dictionary_create/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.post(url, formData, options);
      onClose();
      onCreated();  // Refetch the user list after creation
    } catch (error) {
      console.log(DictValue('Error creating: '), error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Dictionary</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            label={DictValue('Name')}
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
        {DictValue('Cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
        {DictValue('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete }) => {
    
    const [visible, setVisible] = useState(true)

    return (
        visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Typography variant="h6" component={Link} to={`dictionary/${item.id}/`} sx={{ textDecoration: 'underline', color: 'blue' }}>
            {item.id}
          </Typography>
          <Box sx={{ marginTop: 1 }}>
            <Typography component= "span" variant="body1">{DictValue('Name')}: </Typography>
            <Update api = {"/users/dictionary_change/"} request ={{id: item.id}} field_type="text" field_name="name" field_value={item.name} can_change={can_change} />
          </Box>
          {can_delete && (       
              <Delete item={item} setVisible={setVisible} />
          )}
        </Box>
      </Grid>
      )
    );
};

const Delete = ({ item, setVisible }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    if (!window.confirm(DictValue('Confirm delete?'))) return;

    try {
      await fetchData('/users/dictionary_delete/', { id: item.id }, () => {}, () => {}, setVisible);
    } catch (error) {
      setErrorMessage(DictValue('Error deleting'));
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const List = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
      setCanChange(checkPerm('change_dictionary'))
      setCanAdd(checkPerm('add_dictionary'));
      setCanDelete(checkPerm('delete_dictionary'));
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
      checkPageAuthorization(['view_dictionary'], navigate);
      fetchUsers();
    }, [navigate]);
  
    const fetchUsers = () => {
      setIsLoading(true);
      fetchData('/users/dictionaries/', {}, setData, setIsLoading);
    };
  
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.name.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
      <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              {canAdd && (
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleShowModal}>
                  {DictValue('Create')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    can_change={canChange}
                    can_delete={canDelete}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <CreateModal open={showModal} onClose={handleCloseModal} onCreated={fetchUsers} />
      </Container>
    );
  };
  
export default List;