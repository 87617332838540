import React, { useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import { CheckCircle, Cancel, AssignmentTurnedIn, AssignmentLate } from '@mui/icons-material'; // Import additional icons
import axios from 'axios';
import { getToken } from '../utils/common';
import { useNavigate } from 'react-router-dom';

const BatchChips = ({ data: initialData }) => {
  const [data, setData] = useState(initialData);
  const navigate = useNavigate();  // Move the useNavigate hook here

  const toggleBatchActivation = async (batchId, isActive) => {
    try {
      const backend = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${backend}/jobs/batch_actions/`, {
        batch_id: batchId,
        action: 'update_field',
        field_name: 'is_active',
        field_value: !isActive,
      },
      {
        headers: {
          'Authorization': `${getToken()}`,
          'Content-Type': 'application/json',
        }
      });

      if (response.status === 200) {
        console.log('Batch activation status updated successfully');
        
        // Update the local state with the new `is_active` status
        const updatedData = data.map(item => 
          item.batch_id === batchId ? { ...item, is_active: !isActive } : item
        );
        setData(updatedData);
      } else {
        console.error('Failed to update batch activation status');
      }
    } catch (error) {
      console.error('Error updating batch activation status:', error);
    }
  };

  const handleChipClick = (batchId) => {
    // Redirect to the batch info page
    const api = `/jobs/batches/${batchId}/batch_info/`;
    navigate(api);
  };

  return (
    <Box>
      {data.map((item, index) => (
        <Chip
          key={index}
          label={`${item.batch_code} (${item.quantity})`}
          style={{
            marginRight: 4,
            marginBottom: 4,
            border: item?.is_active ? '2px solid green' : 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleChipClick(item.batch_id)}
          icon={
            <IconButton
              onClick={(e) => {
                e.stopPropagation(); // Prevent the chip click event from triggering
                toggleBatchActivation(item.batch_id, item.is_active);
              }}
              size="small"
            >
              {item.is_active ? <CheckCircle /> : <Cancel />}
            </IconButton>
          }
          deleteIcon={ // Adding the deleteIcon prop for the is_assigned status
            item.kits_ready ? (
              <AssignmentTurnedIn style={{ color: 'green' }} />
            ) : (
              <AssignmentLate style={{ color: 'orange' }} />
            )
          }          
          onDelete={() => {}}
        />
      ))}
    </Box>
  );
};

export default BatchChips;
