import React, { useState, useRef } from 'react';
import { Button, Avatar, Box } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

export const AvatarUpload = ({ initialImageUrl, onUpload }) => {
  const [preview, setPreview] = useState(initialImageUrl);
  const [cameraMode, setCameraMode] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);

  console.log("URL", initialImageUrl)

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setPreview(URL.createObjectURL(file));
      onUpload(file);
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  };
  const startCamera = async () => {
    setCameraMode(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true });
    videoRef.current.srcObject = stream;
  };

  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
  
    // Draw the video frame to the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Convert the canvas content to a data URL
    const dataUrl = canvas.toDataURL('image/png'); // Ensure it's PNG
  
    // Update the preview to show the captured photo
    setPreview(dataUrl);
  
    // Convert the data URL to a Blob with a proper filename
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([arrayBuffer], { type: mimeString });
  
    // Create a File object from the Blob with a proper filename and extension
    const file = new File([blob], 'avatar.png', { type: mimeString });
  
    // Stop the video stream
    video.srcObject.getTracks().forEach(track => track.stop());
  
    // Pass the File to the onUpload function
    onUpload(file);
  
    // Close the camera mode
    setCameraMode(false);
  };  
  return (
    <div style={{ textAlign: 'center' }}>
      {cameraMode ? (
        <>
          <video ref={videoRef} autoPlay style={{ width: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
          <Button
            variant="contained"
            onClick={capturePhoto}
            style={{ marginTop: '10px' }}
          >
            Capture Photo
          </Button>
        </>
      ) : (
        <>
          <Avatar
            src={preview || "/default-avatar.png"}
            alt="Operator Avatar"
            sx={{ width: 150, height: 150, margin: "0 auto" }}
          />
          <Button
            variant="contained"
            component="label"
            style={{ marginTop: '10px' }}
          >
            Upload Picture
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Button>
          <Button
            variant="contained"
            startIcon={<PhotoCamera />}
            onClick={startCamera}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            Use Camera
          </Button>
        </>
      )}
    </div>
  );
};




/*
import React, { useState, useEffect } from 'react';
import { Button, Avatar, Box } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';


export const AvatarUpload = ({ initialImageUrl, onUpload }) => {
  const [preview, setPreview] = useState(initialImageUrl);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Update the preview with the new image
      setPreview(URL.createObjectURL(file));
      onUpload(file); // Pass the file to the parent component's upload handler
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Avatar
        src={preview || "/default-avatar.png"}
        alt="Operator Avatar"
        sx={{ width: 150, height: 150, margin: "0 auto" }}
      />
      <Button
        variant="contained"
        component="label"
        style={{ marginTop: '10px' }}
      >
        Upload Avatar
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
          style={{ display: "none" }}
          capture="camera" // This enables camera capture on supported devices
        />
      </Button>
    </div>
  );
};


/*
export const AvatarUpload = ({ initialImageUrl, onUpload }) => {
  const [selectedImage, setSelectedImage] = useState(initialImageUrl);

  // Update selectedImage whenever initialImageUrl changes
  useEffect(() => {
    setSelectedImage(initialImageUrl);
    //console.log(initialImageUrl);
  }, [initialImageUrl]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        onUpload(file);  // Pass the selected file to the parent component
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      <Avatar
        src={selectedImage}
        sx={{ width: 150, height: 150, mb: 2 }}
      />
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="avatar-upload"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="avatar-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<PhotoCamera />}
        >
          Upload Avatar
        </Button>
      </label>
    </Box>
  );
};

*/