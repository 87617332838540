import React, { useState } from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent, CardActions, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { getToken, DictValue } from '../utils/common';
import Sequence from './Sequence';

const flattenHierarchy = (sequences) => {
  const result = [];
  const map = new Map();

  sequences.forEach(item => {
    map.set(item.id, { ...item, children: [] });
  });

  sequences.forEach(item => {
    if (item.parent_id) {
      const parent = map.get(item.parent_id);
      if (parent) {
        parent.children.push(map.get(item.id));
      }
    } else {
      result.push(map.get(item.id));
    }
  });

  const flatten = (arr) => {
    let result = [];
    arr.forEach(item => {
      result.push(item);
      if (item.children.length > 0) {
        result = result.concat(flatten(item.children));
      }
    });
    return result;
  };

  return flatten(result);
};

const CreateModal = ({ component_id, parent_id, api, open, onClose, onCreated }) => {
  const initialData = {
//    number: '',
    name: '',
    expected_time: 1,
    component_id: component_id,
  };

  const [formData, setFormData] = useState(initialData);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}${api}`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };

      const dataToSend = { ...formData, parent_id };
      const response = await axios.post(url, dataToSend, options);
      const newItem = response.data;
      onClose();
      onCreated(newItem);
    } catch (error) {
      console.log('Error creating sequence: ', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{parent_id ? `Create Child Sequence for SequenceID ${parent_id}` : 'Create Root Sequence'}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          {/*<TextField
            margin="dense"
            label={DictValue("Number")}
            name="number"
            type="number"
            fullWidth
            value={formData.number}
            onChange={handleChange}
            required
          />*/}
          <TextField
            margin="dense"
            label={DictValue("Name")}
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label={DictValue("Expected time")}
            name="expected_time"
            type="number"
            fullWidth
            value={formData.expected_time}
            onChange={handleChange}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">{DictValue("Cancel")}</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">{DictValue("Create")}</Button>
      </DialogActions>
    </Dialog>
  );
};

const ThisComponent = ({ component_id, sequences, setComponentData, canChange }) => {
  const [data, setData] = useState(flattenHierarchy(sequences) || []);
  const [currentParentId, setCurrentParentId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);

  const handleOpenModal = (parentId) => {
    setCurrentParentId(parentId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentParentId(null);
  };

  const handleCreated = async (newItem) => {
    try {
      // Refetch the sequences from the backend to ensure the latest state
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/component/detail/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      const response = await axios.post(url, { id: component_id }, options);
      const updatedData = response.data.data.sequences; // Ensure this is the correct structure
      setComponentData(response.data.data);
      setData(flattenHierarchy(updatedData)); // Update the state with the new hierarchy
    } catch (error) {
      console.log('Error fetching updated data after creation: ', error);
    }
  };

  const handleDelete = (itemId) => {
    setDeleteItemId(itemId);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/products/sequence/delete/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.delete(`${url}${deleteItemId}/`, options);
  
      // Refetch the sequences from the backend to ensure the latest state
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/products/component/detail/`, { id: component_id }, options);
      const updatedData = response.data.data.sequences; // Ensure this is the correct structure
      setComponentData(response.data.data);
      setData(flattenHierarchy(updatedData)); // Update the state with the new hierarchy
      setConfirmDialogOpen(false);
    } catch (error) {
      console.log('Error deleting: ', error);
      setConfirmDialogOpen(false);
    }
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
    setDeleteItemId(null);
  };

  return (
    <Box>
      <CreateModal
        component_id={component_id}
        parent_id={currentParentId}
        api="/products/sequence/create/"
        open={modalOpen}
        onClose={handleCloseModal}
        onCreated={handleCreated}
      />

      {canChange && data.length === 0 ? (
        <Button variant="outlined" size="small" onClick={() => handleOpenModal(null)}>
          Add Root Sequence
        </Button>
      ) : (
        <>
          {data.map((item) => <Sequence 
            key = {item.id}
            item = {item}
            handleDelete={handleDelete}
            handleOpenModal={handleOpenModal}
            canChange = {canChange}
            />)}
        </>
      )}

      <Dialog open={confirmDialogOpen} onClose={handleCloseConfirmDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this sequence?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">{DictValue('Cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="error">{DictValue('Delete')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ThisComponent;
