import React from 'react';
import { Route, Routes, useNavigate} from 'react-router-dom';
import OperatorsList from './OperatorsList.js';
import OperatorPanel from './OperatorPanel';
import OperatorPanelRoute from './OperatorPanelRoute';

import { OperatorInfo } from './OperatorInfo.js';
import OperatorDetail from './OperatorDetail.js'
import { getUser } from '../utils/common.js';
import TimeAllocationByWeek from './TimeAllocationByWeek.js'
import EventsLogByWeek from './EventsLogByWeek.js'

const Operators = () => {

    /*
    const permission = 'operators'
    const history = useNavigate();

    try {
      const user = getUser();
      if (!user?.permissions.includes(permission)) history('/dashboard');
    }
    catch (error) {console.log(error); history('/dashboard');}
    */
    return (
    <>

        {/*<h3 className = "mb-3">Operators</h3> */}
    
        <div>    
        <Routes>
            <Route index element={<OperatorsList />} />
            <Route path="operator-panel" element={<OperatorPanelRoute />} /> 
            <Route path="operator/:operator_id" element={<OperatorDetail />} /> 
            <Route path="operator/:operator_id/work-panel/" element={<OperatorPanel />} /> 
            <Route path="operator/:operator_id/log/" element={<EventsLogByWeek />} /> 
            <Route path="operator/:operator_id/time-allocation/" element={<TimeAllocationByWeek />} /> 
            <Route path="*" element={<OperatorsList />} /> 
        </Routes>
        </div>
    </>
    );
};

export default Operators;