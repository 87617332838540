import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Update } from '../utils/elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField,  
    Box, IconButton, Typography,
    CircularProgress, Grid, Container } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info'; // Assuming you want an info icon for detail


const CreateModal = ({ open, onClose, onCreated }) => {

  let {toolclass_id} = useParams()

  const [formData, setFormData] = useState({
    code: '',
    name: '',
    toolclass_id: toolclass_id
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/tools/create/`;
      const options = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': getToken(),
        },
      };
      await axios.post(url, formData, options);
      onClose();
      onCreated();  // Refetch the user list after creation
    } catch (error) {
      console.log(DictValue('Error creating: '), error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{DictValue("Create")} {DictValue("Tool")}</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            margin="dense"
            label={DictValue('Code')}
            name="code"
            fullWidth
            value={formData.code}
            onChange={handleChange}
            required
          />
          <TextField
            margin="dense"
            label={DictValue('Name')}
            name="name"
            fullWidth
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
        {DictValue('Cancel')}
        </Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
        {DictValue('Create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FilterInput = ({ value, onChange }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton>
          <FilterListIcon />
        </IconButton>
        <TextField
          size="small"
          variant="outlined"
          value={value}
          onChange={onChange}
          placeholder={DictValue('Filter')}
        />
      </Box>
    );
};

const Item = ({ item, can_change, can_delete }) => {
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate(); // Hook to navigate to other routes

  const handleDetailClick = () => {
    navigate(`/tools/tool/${item.id}/`); // Redirects to the detail view of the item
  };

  return (
    visible && (
      <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
        <Box
          sx={{
            border: '1px solid',
            borderRadius: 2,
            padding: 2,
            display: 'flex',
            flexDirection: 'column', // Align items in a row
            alignItems: 'laeft', // Vertically center items
            height: '100%',
            boxShadow: 3, // Adding elevation (change the number for different shadow intensity)
            backgroundColor: '#fff', // Ensure the background is white for contrast
            transition: 'box-shadow 0.3s ease-in-out', // Smooth shadow transition
            '&:hover': {
              boxShadow: 6, // Elevate more on hover
            },
          }}
        >

          <Box sx={{ marginTop: 1 }}>


            <Typography component="span" variant="body1">
              {DictValue("Name")}:
            </Typography>
            <Update 
              api="/tools/change/"
              request={{ id: item.id }}
              field_type="text"
              field_name="name"
              field_value={item.name}
              can_change={can_change} 
            />
            <br/>
            <Typography component="span" variant="body1">
              {DictValue("Code")}:
            </Typography>
            <Update 
              api="/tools/change/"
              request={{ id: item.id }}
              field_type="text"
              field_name="code"
              field_value={item.code}
              can_change={can_change} 
            />
            <br/>
          <Typography component="span" variant="subtitle1">
              Toolclass: {item.toolclass.name}
            </Typography>
            <br/>
            <Typography component="p" variant="body1">
              ID: {item.id}
            </Typography>
            <br/>

            <Typography component="span" variant="subtitle1">
              UsedBy: {item.operator_name}
            </Typography>


          </Box>
          <Box sx={{ marginTop: 'auto' }}>
            {false && <IconButton onClick={handleDetailClick}>
              <InfoIcon sx={{color: 'blue'}} /> {/* Detail icon button */}
            </IconButton>}
            {can_delete && <Delete item={item} setVisible={setVisible} />}
          </Box>
        </Box>
      </Grid>
    )
  );
};


const Delete = ({ item, setVisible }) => {
  
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = async () => {
    if (!window.confirm(DictValue('Confirm delete?'))) return;

    try {
      await fetchData('/tools/delete/', { id: item.id }, () => {}, () => {}, setVisible);
    } catch (error) {
      setErrorMessage(DictValue('Error deleting'));
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      {errorMessage && <div>{errorMessage}</div>}
    </>
  );
};

const List = () => {

    let {toolclass_id} = useParams()
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [canChange, setCanChange] = useState(false);
    const [canAdd, setCanAdd] = useState(false);
    const [canDelete, setCanDelete] = useState(false);

    useEffect(() => {
      setCanChange(checkPerm('change_tool'))
      setCanAdd(checkPerm('add_tool'));
      setCanDelete(checkPerm('delete_tool'));
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
      checkPageAuthorization(['view_tool'], navigate);
      fetch();
    }, [navigate]);
  
    const fetch = () => {
      setIsLoading(true);
      fetchData('/tools/toolclass/tools/', {toolclass_id: toolclass_id}, setData, setIsLoading);
    };
  
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    
    // Filtering logic
    const handleFilterChange = (e) => {
      setFilter(e.target.value);
    };
  
    const filteredData = data.filter(item => 
      item.name.toLowerCase().includes(filter.toLowerCase()) ||
      item.code.toLowerCase().includes(filter.toLowerCase())
    );
  
    return (
      <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs={12} container spacing={2} alignItems="center">
            <Grid item>
              {canAdd && (
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleShowModal}>
                  {DictValue('Create')}
                </Button>
              )}
            </Grid>
            <Grid item>
              <FilterInput value={filter} onChange={handleFilterChange} />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {isLoading ? (
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', padding: '1em' }}>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : (
                filteredData.map((item) => (
                  <Item
                    key={item.id}
                    item={item}
                    can_change={canChange}
                    can_delete={canDelete}
                  />
                ))
              )}
            </Grid>
          </Grid>
        </Grid>
        <CreateModal open={showModal} onClose={handleCloseModal} onCreated={fetch} />
      </Container>
    );
  };
  
export default List;