import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUser, getToken, DictValue } from '../utils/common';


const RedirectToPage = () => {
  const navigate = useNavigate();
  const user = getUser();

  console.log("OPERATOR_ID", user.operator?.id)

  useEffect(() => {
    navigate("/operators/operator/"+user.operator.id+"/work-panel/");
  }, [navigate]);

  return null; // Nothing is rendered
};

export default RedirectToPage;