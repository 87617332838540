import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const SelectManager = ({ JobID, currentManagerID, currentManagerName }) => {
    const [options, setOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    const fetchList = async () => {
      try {
        const request = { 'request': null };
        const Api = process.env.REACT_APP_API_URL;
        const postOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
        };
        const response = await fetch(Api + "/api/managers/", postOptions);
        const data = await response.json();
        const array = data["data"].map((item) => ({ value: item.ManagerID, label: item.ManagerName + ' [' + item.ManagerID + ']' }));
        setOptions(array);
      } catch (error) {
        console.log('Error fetching data: ' + error)
      }
    };
  
    useEffect(() => { fetchList() }, []);
  
    const changeManager = async (event, ManagerID, action) => {
  
      event.stopPropagation();
  
      try {
  
        const request = {
          'JobID': JobID,
          'ManagerID': ManagerID,
          'action': action
        };
  
        const Api = process.env.REACT_APP_API_URL;
        const postOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
          },
          body: JSON.stringify(request),
        };
        const response = await fetch(Api + "/api/jobs/" + JobID + "/change_manager/", postOptions);
//        window.location.reload();
        // Instead of reloading page, handle response and update state accordingly
        // const data = await response.json();
        // Update state or perform other necessary actions based on the response
        setIsFocused(false);
        window.location.reload(); // Close select when action is performed
      } catch (error) {
        console.log('Error fetching data: ' + error)
      }
    };
  
    const handleInputChange = (inputValue) => {
      setInputValue(inputValue);
    };
  
    const handleSelectOption = (selectedOption) => {
      setSelectedOption(selectedOption);
    };
  
    return (
      <div>
        {isFocused ? (
          <div>
            <Select
              options={options}
              value={selectedOption}
              onChange={handleSelectOption}
              onInputChange={handleInputChange}
              inputValue={inputValue}
              placeholder="Type to filter ..."
              isClearable
              isSearchable
            />
            {selectedOption && (<FontAwesomeIcon className='mx-1' icon={faSave} onClick={(event) => changeManager(event, selectedOption.value, 'add')} style={{ cursor: 'pointer' }}></FontAwesomeIcon>)}
            <FontAwesomeIcon className='mx-1' icon={faCancel} onClick={() => setIsFocused(false)} style={{ cursor: 'pointer' }}></FontAwesomeIcon>

          </div>
        ) : (
          <div onClick={() => setIsFocused(true)}>
            {!currentManagerID ? <span>---</span> :
              
              <div key={currentManagerID} className="badge bg-secondary m-1">
                <span className="mx-1">{currentManagerName}</span>
                <span className="mx-1">[{currentManagerID}]</span>
                <span className="mx-1">
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faTrash}
                    onClick={(event) => changeManager(event, currentManagerID, 'remove')}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
              </div>
            }
          </div>
        )}
      </div>
    );
};

export default SelectManager;
