import React from 'react';
import {Link, Route, Routes } from 'react-router-dom';

import ProductsList from './ProductsList';
import Product from './Product';
import DictTag from '../dictionaries/DictTag';

function Products() {
return (
<>
<Link className='mx-2' to={'/products'}><DictTag tag = 'Products'/></Link>
    
<Routes>
    <Route index element={<ProductsList />} />
    <Route path=":ProductID/*" element={<Product />} />
</Routes>

</>

);

}

export default Products;
