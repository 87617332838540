import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import SwitchButton from '../jobs/SwitchButton';
import Delete from '../jobs/Delete';
import Update from '../jobs/Update';
import CreateBatchModal from '../jobs/CreateBatchModal';
import SelectAssignedOperators from '../jobs/SelectAssignedOperators';
import SelectManager from '../jobs/SelectManager';

  
const ProductJobs = () => {

    let { ProductID } = useParams();

    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {

        try {

            var request = {'ProductID': ProductID};

            const Api = process.env.REACT_APP_API_URL;   
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(request),
            };
    
        const response = await fetch(Api+"/api/jobs/", options);
        const data = await response.json();

        setContent(<>

            <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
            <thead>
                <tr className="table-light" >
                <th>JobID</th>
                <th>Quantity/ Product</th>
                <th>isActive</th>
                <th>isCompleted</th>
                <th>Priority</th>
                <th>Manager</th>
                <th>AssignedOperators</th>
                <th>Batches</th>
                <th>Created/ Completed</th>
                <th>Details</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
                {data["data"].map((job) => { 
//                    var link_to_batches = "/products/" + job.ProductID + "/jobs/" + job.JobID + "/batches/";
                    var link_to_batches = "/jobs/job/" + job.JobID + "/batches/";

                    return (
                        <>
                        <tr>
                    <td><Link to={`/jobs/job/${job.JobID}`}>{job.JobID}</Link></td>
                    <td>{job.JobQuantity} x {job.ProductName} ({job.ProductID})</td>
                    <td><SwitchButton JobID = {job.JobID} FieldName = 'isActive' value = {job.isActive}/></td>
                    <td><SwitchButton JobID = {job.JobID} FieldName = 'isCompleted' value = {job.isCompleted}/></td>
                    <td><Update JobID = {job.JobID} FieldName = 'Priority' FieldType = 'number' FieldValue = {job.Priority} /></td>
                    <td><SelectManager JobID = {job.JobID} currentManagerID = {job.ManagerID} currentManagerName = {job.ManagerName}/></td>
                    <td><SelectAssignedOperators JobID = {job.JobID} currentOperators = {job.operators}/></td>
                    <td>{job.batches.map((batch) => (
                            <span key={batch.BatchID} className="badge bg-secondary mx-1">
                            {batch.BatchCode}{' => '}{batch.BatchQuantity} 
                            </span>
                        ))}</td>
                    <td>{job.Created}</td>
                    <td><Link to={link_to_batches}>batches</Link></td>
                    <td>
                        <CreateBatchModal JobID = {job.JobID} JobQuantity = {job.JobQuantity}/>
                        <Delete JobID = {job.JobID}/>
                    </td>                
                    </tr>                        
                        </>
                    );

                }

                )}
                {/*<Insert />*/}

              </tbody>

            </table>
        </>);

    } catch (error) {
        setContent('Error fetching data: '+ error)
      } finally {
        setIsLoading(false);
      }

    };
  
useEffect(
    () => {fetchData()}
    , []
);

    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;

return (<>{isLoading ? loading : content }</>);
};

export default ProductJobs;
