import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { 
  Box, 
  Grid, 
  Typography, 
  Chip, 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, DialogContentText,
  DialogTitle, 
  TextField, 
  IconButton 
} from '@mui/material';
import {
  Gavel as GavelIcon,
  EventNote as EventNoteIcon,
  AccessTime as AccessTimeIcon,
  Layers as LayersIcon,
  BugReport as BugReportIcon
} from '@mui/icons-material';

import { fetchData, DictValue } from '../utils/common';


const OperatorSkillsBadges = ({ skills }) => {
  return skills?.map((skill, index) => ( <SkillBadge key= {index} skill={skill} />));
};

export const SkillBadge = ({ key, skill }) => {
    return( 
      <Chip
        key={key}
        label={`${skill.tag} ${skill.level} ${skill.is_training ? 'T' : ''}`}
        color="secondary"
        size="small"
        sx={{ margin: '0.25rem' }}
      />);
};



function ConfirmDialog(props) {

  const [selection, setSelection] = useState(null); // Tracks the user's choice

  useEffect(() => {
    if (["resume", "start", "reject"].includes(props.targetAction)) {
      setSelection({});
    }
  }, [props]);
  

  const handleSelectionChange = (value) => { setSelection(value);};

  const handleAgree = () => {
  //  props.setConfirmed(true);
    props.setIsDialogOpen(false);
    props.onConfirm(selection);
    // Pass the selection to TaskAction or handle it in another way
//    const values = { selection };
//    console.log("SELECTION", values);
 //   TaskAction(props.targetTask, props.targetAction, props.refetch, values);
  };

  const handleDisagree = () => {
//    props.setConfirmed(false);
    props.setIsDialogOpen(false);
  };

  return (
    <Dialog
      open={props.isDialogOpen} // Controlled by parent component
      onClose={handleDisagree}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{props.dialogTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.dialogMessage}
        </DialogContentText>
        {props.targetAction === "pause" && (
          <Box display="flex" flexDirection="column" gap={4} marginTop={2}>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("personal")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "personal" ? "yellow" : "grey",
                color: selection === "personal" ? "black" : "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "personal" ? "yellow" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Pausa PERSONALE
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("work")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "work" ? "orange" : "grey",
                color: selection === "work" ? "black" : "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "work" ? "orange" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Pausa di LAVORO
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("checkout")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "checkout" ? "purple" : "grey",
                color: selection === "checkout" ? "white" : "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "checkout" ? "purple" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Pausa FINE TURNO
            </Button>
          </Box>        )}

          {props.targetAction === "complete" && (
          <Box display="flex" flexDirection="column" gap={2} marginTop={2}>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("use_for_stats")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "use_for_stats" ? "green" : "grey",
                color: "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "use_for_stats" ? "darkgreen" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Tutto OK!
            </Button>
            <Button
              variant="contained"
              onClick={() => handleSelectionChange("no_use_for_stats")}
              sx={{
                borderRadius: "8px",
                backgroundColor: selection === "no_use_for_stats" ? "orange" : "grey",
                color: "white",
                fontSize: "1.5rem", // Larger text
                fontWeight: "bold", // Optional: makes text bold
                "&:hover": {
                  backgroundColor: selection === "no_use_for_stats" ? "orange" : "darkgrey",
                },
                textTransform: "none",
              }}
            >
              Ci sono stati INCONVENIENTI/ RALLENTAMENTI
            </Button>
          </Box>        )}

      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between", padding: "16px" }}>
        <Button
          onClick={handleDisagree}
          sx={{
            flex: 1,
            marginRight: "8px",
            fontSize: "1.5rem", // Larger text
            fontWeight: "bold",
            padding: "12px 24px",
            backgroundColor: "lightgrey",
            color: "black",
            "&:hover": {
              backgroundColor: "darkred",
            },
            borderRadius: "8px",
          }}
        >
          Annulla
        </Button>
        <Button
          onClick={handleAgree}
          disabled={!selection}
          sx={{
            flex: 1,
            marginLeft: "8px",
            fontSize: "1.5rem", // Larger text
            fontWeight: "bold",
            padding: "12px 24px",
            backgroundColor: selection ? "blue" : "grey",
            color: "white",
            "&:hover": {
              backgroundColor: selection ? "cyan" : "darkgrey",
            },
            borderRadius: "8px",
          }}
        >
          Conferma
        </Button>
      </DialogActions>
    </Dialog>
  );
};



export const TaskOperatorButton = ({ operator_id, task_id, action, tag, refetch }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
//  const [confirmed, setConfirmed] = useState(false);

  const handleClick = () => { setIsDialogOpen(true); };// Open the dialog when the button is clicked

  const handleAction = (selection) => {
    const api = "/jobs/task-actions/";
    const request = {
      action: action,
      task_id: task_id,
      operator_id: operator_id,
      values: {selection: selection}, // Include selection data if applicable
    };
//    console.log(request)
    fetchData(api, request, (data) => {
      setIsDialogOpen(false);
      refetch();
//      setConfirmed(false); // Reset confirmation after handling
      refetch(); // Refresh data after the action
    });
  };

  return (
    <div>
      <button onClick={handleClick}>
        {tag}
      </button>
      <ConfirmDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
//        setConfirmed={setConfirmed}
        dialogTitle="Confirm Action"
        dialogMessage="Are you sure you want to perform this action?"
        targetAction={action}
        targetTask={task_id}
//        refetch={refetch}
        onConfirm={handleAction} // Pass the handler for confirmed actions
      />
    </div>
  );
};



export const OperatorButton = ({operator_id, action, tag, refetch}) => {

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function click () {
    
      const api = '/operators/actions/';
      const request = {
          action: action,
          id: operator_id,
      };
      fetchData(api, request, setData, setIsLoading);
    };
  
  return (
    <div>
      <button onClick={click} disabled={isLoading}>
        {isLoading ? 'Loading...' : tag}
      </button>
    </div>
  );
};

export const ToolBadge = ({key, tool, operator_id, refetch}) => {
  
    var bgcolor = tool.is_being_used ? "red" : "green";
    var textcolor = tool.is_being_used ? "black" : "white";
  
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const onClick = () => {
        var api = "/jobs/operator-actions/"
        var request = {'action': 'assign_tool', 'operator_id': operator_id, 'tool_id': tool.id}
//        console.log("£££££££££££", request)
        fetchData(api, request, setData, setIsLoading)
        //window.location.reload()
        refetch()
    }
  
    return <>
      <span 
        key = {key}
        className ="badge fst-italic" 
        style = {{backgroundColor: bgcolor, color: textcolor}}
        onClick={tool.is_being_used ? null : onClick}    
      >
        <small>{tool.code} {tool.name}</small>
      </span>
    </>
};
  
const FlawModal = ({ task, open, onClose, onSubmit }) => {
  const [description, setDescription] = useState('');
  const [flawedCount, setFlawedCount] = useState(1); // Default to 1

  const handleSubmit = () => {
    onSubmit(description, flawedCount);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Report Flaw</DialogTitle>
      <DialogContent>
        {/* Non-modifiable fields */}
        <TextField
          margin="dense"
          label="Batch Code"
          fullWidth
          variant="outlined"
          value={task.batch_code}
          InputProps={{ readOnly: true }}
        />
        <TextField
          margin="dense"
          label="Product Name"
          fullWidth
          variant="outlined"
          value={task.product_name}
          InputProps={{ readOnly: true }}
        />
        <TextField
          margin="dense"
          label="Component Name"
          fullWidth
          variant="outlined"
          value={task.component_name}
          InputProps={{ readOnly: true }}
        />

        {/* Modifiable fields */}
        <TextField
          margin="dense"
          label="Number of Flawed Components"
          type="number"
          fullWidth
          variant="outlined"
          value={flawedCount}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (value >= 1 && value <= task.quantity) {
              setFlawedCount(value);
            }
          }}
          InputProps={{ inputProps: { min: 1, max: task.quantity } }}
        />
        <TextField
          autoFocus
          margin="dense"
          label="Description of Flaw"
          fullWidth
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Description"
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Task = ({instance, refetch}) => {

    var _bg = 'white'
    var _col = 'black'
    const [showFlawModal, setShowFlawModal] = useState(false);

    const handleFlawSubmit = (description, flawedCount) => {
      // Logic to handle flaw submission, e.g., API call
//      console.log(`Flaw description: ${description}, Flawed components: ${flawedCount}`);

      var api = "/jobs/batches/report-flaw/"
      var request = {'task_id': instance.id, 'quantity': flawedCount, 'description': description }
  //    console.log("£££££££££££", request)
      fetchData(api, request, null, null)
      //window.location.reload()
      refetch()

    };
    
    if (instance?.is_worked_on) {_bg='green'; _col='white'}
    if (instance?.is_paused) {_bg='lightgrey'; _col='black'}

    return(<>

    <div className='row border'>

    <div className = "panel col-3" style={{backgroundColor: _bg, color:_col}}>
      <p className="my-0"><span>{instance.product_name}</span></p>
      <p className="my-0">
            <b>{instance.batch_code} ({instance.batch_progress}%)</b>
            <IconButton
              component={Link}
              to={`/jobs/job/${instance.job_id}/batches/batch/${instance.batch_id}/`} 
              sx={{ color: _bg === 'green' ? 'white' : 'gray' }}
              aria-label="Batch Detail"
            >
              <LayersIcon />
            </IconButton>
      </p>

      {!instance.is_flawed && <><button onClick={() => setShowFlawModal(true)} className="btn btn-danger btn-sm mt-2">
        Report Flaw
      </button>      {/* Flaw Modal */}
      <FlawModal
        open={showFlawModal}
        onClose={() => setShowFlawModal(false)}
        onSubmit={handleFlawSubmit}
        task = {instance}
      /></>}
    </div>
  
    <div className = "panel col-5"  style={{backgroundColor: _bg, color:_col}}>
      <p className="my-0">
        {<span>{instance.component_name} ({instance.component_progress}%)</span>}
        {instance.is_flawed && <IconButton
              component={Link}
              to={`/jobs/job/${instance.job_id}/batches/batch/${instance.batch_id}/`}
              sx={{ color: 'red' }}
              aria-label="Batch Detail"
            >
              <BugReport />
            </IconButton>}
      </p>
      <p className="my-0"><b>{<span>{instance.quantity} x {instance.sequence_name}</span>}</b></p>
      {<p className="my-0">{instance.required_skills?.map((skill)=>{return <><span className ="badge bg-secondary fst-italic"><small>{skill.tag}: {skill.level}</small></span></>})}</p>}
      {<p className="my-0">{instance.required_tools?.map((tool)=>{return <><span className ="badge bg-secondary fst-italic"><small>{tool.name}</small></span></>})}</p>}

    </div>
    <div className = "panel col-2" >
      <p className={`mt-1 mb-2`}>
      {!instance.operator_is_working && instance.is_possible && instance.tool_ok && !instance.is_worked_on && !instance.is_paused &&
      <TaskOperatorButton task_id = {instance.task_id} operator_id={instance.operator_id} action='start' tag='Avvia' refetch={refetch}/> }
      </p>
      <p className={`mt-1 mb-2`}>
      {instance.is_worked_on && !instance.is_paused &&
      <TaskOperatorButton task_id = {instance.task_id} operator_id={instance.operator_id} action='complete' tag='Completa' refetch={refetch}/> }
      </p>

      <p className={`mt-1 mb-2`}>
      {!instance.is_paused && <TaskOperatorButton task_id = {instance.task_id} operator_id={instance.operator_id} action='reject' tag='Rifiuta' refetch={refetch}/>}
      </p>

      <p className={`mt-1 mb-2`}>
      {/*instance.is_possible && instance.tool_ok && instance.is_worked_on && !instance.is_paused && <TaskOperatorButton task_id = {instance.task_id} operator_id={instance.operator_id} action='pause' tag='Pausa' refetch={refetch}/>*/}
      {!instance.is_paused && <TaskOperatorButton task_id = {instance.task_id} operator_id={instance.operator_id} action='pause' tag='Pausa' refetch={refetch}/>}
      </p>

      <p className={`mt-1 mb-2`}>
      {instance.is_possible && instance.tool_ok && instance.is_worked_on && instance.is_paused && <TaskOperatorButton task_id = {instance.task_id} operator_id={instance.operator_id} action='resume' tag='Riprendi' refetch={refetch}/>}
      </p>
        
      {!instance?.tool_ok && <p className={`mt-1 mb-2`}>{instance.required_tools?.map((tool, index) => <ToolBadge key={index} tool = {tool} operator_id={instance.operator_id} refetch={refetch}/>)}</p> }
      {/*<p className={`mt-1 mb-2`}><KitBadge kit = {instance.kit} operator_id={instance.operator_id}/></p> */}
    </div> 

    <div className = "panel col-2" style={{backgroundColor: `${instance.progress > 100 ? 'yellow' : ''}` }}>
      <p className="my-0">{instance?.dt_started}</p>
      <p className="my-0">{instance?.actual_time_hhmm ? instance.actual_time_hhmm + ' / ' +instance.expected_time_hhmm : null}</p>
      <p className="my-0">{instance?.progress ? instance.progress + '%' : null}</p>
    </div>

    </div>
    </>);
};

const Operator = ({ instance, refetch }) => {

  var bgcolor = "#ADD8E6"
  var textcolor = 'black'
  if(!instance?.is_checkedin) {bgcolor = "lightgrey"; textcolor = "black";}
  else if(instance?.is_paused) {bgcolor = "orange"; textcolor = "black";}
  else {bgcolor = "#ADD8E6"; textcolor = "black";}


  return (
    <>
      <Grid container spacing={1} sx={{ border: 2, borderColor: 'primary.main', padding: 1, my: 1 }}>
        <Grid item xs={12} sx={{ backgroundColor: bgcolor, color: textcolor, padding: 1 }}>
          
          {/* Left-aligned row for ID, Name, IconButton */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            <Typography variant="body1" sx={{ margin: 1 }}>
              {instance.id}
            </Typography>
            <Typography variant="h6" component="span" sx={{ margin: 1 }}>
              {instance.name}
            </Typography>
            <IconButton
              component={Link}
              to={`/operators/operator/${instance.id}/work-panel/`}
              sx={{ color: 'gray' }}
              aria-label="Operator Panel"
            >
              <GavelIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={`/operators/operator/${instance.id}/log/`}
              sx={{ color: 'gray' }}
              aria-label="Operator Log"
            >
              <EventNoteIcon />
            </IconButton>
            <IconButton
              component={Link}
              to={`/operators/operator/${instance.id}/time-allocation/`}
              sx={{ color: 'gray' }}
              aria-label="Operator Time Allocation"
            >
              <AccessTimeIcon />
            </IconButton>


          {/* Operator Skills Badges */}
          <Box sx={{ margin: 1 }}>
            <OperatorSkillsBadges skills={instance.skills} />
          </Box>

          {/* Left-aligned row for assigned_kits and assigned_tools */}
          <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', gap: 1, margin: 1 }}>
            {/* Uncomment and update releaseKit if assigned_kits are needed */}
            {/* instance.assigned_kits?.map((kit, index) => (
              <Chip
                key={index}
                label={`${kit.batch_code}/${kit.kit_id}`}
                color="primary"
                variant="outlined"
                size="small"
                sx={{ fontStyle: 'italic' }}
                onClick={() => releaseKit(kit.batch_id, kit.kit_id, instance.using_kit)}
              />
            )) */}
            {instance.assigned_tools?.map((tool, index) => (
              <Chip
                key={index}
                label={`${tool.code} ${tool.name}`}
                color="primary"
                variant="outlined"
                size="small"
                sx={{ fontStyle: 'italic' }}
              />
            ))}
            {!instance.is_checkedin && <Typography variant="h6" component="span" sx={{ margin: 1 }}>{DictValue('FUORI TURNO')}</Typography>}
            {instance.is_paused && instance.pause_mode == 1 && <Typography variant="h6" component="span" sx={{ margin: 1 }}>{DictValue('PAUSA PERSONALE')}</Typography>}
            {instance.is_paused && instance.pause_mode == 2 && <Typography variant="h6" component="span" sx={{ margin: 1 }}>{DictValue('PAUSA DI LAVORO')}</Typography>}
            {(!instance.is_checkedin || instance.is_paused) && instance.assigned_tasks.length == 0 && <OperatorButton operator_id={instance.id} tag="Riprendi" action="resume"/>}  
          </Box>
          </Box>

        </Grid>
      </Grid>

      {/* Assigned tasks */}
      {instance?.assigned_tasks?.map((taskInstance) => (
        <Task key={taskInstance.id} instance={taskInstance} refetch={refetch} />
      ))}
    </>
  );
};

const Content = ({ data, refetch }) => {
  return (
    <>
      {data?.map((instance, index) => (
        <Operator key={index} instance={instance} refetch={refetch}/>
      ))}
    </>
  );
};

const JobOperators = () => {
  let { id } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const api = '/jobs/operator-actions/';
  const request = {
    action: 'list',
    job_id: id,
  };

  const refetch = () => {
    fetchData(api, request, setData, setIsLoading)
  }
  
  useEffect(() => {
    let timeoutId; // Store timeout ID

    const fetchDataWithDelay = () => {
      fetchData(api, request, setData, setIsLoading).then(() => {
        // Schedule the next fetch 2 seconds after the data is received
        timeoutId = setTimeout(fetchDataWithDelay, 2000);
      });
    };

    // Initial fetch
    fetchDataWithDelay();

    // Cleanup function to clear timeout when the component unmounts
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // Add dependencies to ensure the effect runs when these change


 return (
    <>
      {<Content data={data} refetch={refetch}/>}
    </>
  );
};

export default JobOperators;
