import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import { Update, SwitchButton, KCheckbox } from '../utils/elements';

import { Container, Paper, Box, Grid, Typography, Chip } from '@mui/material';

import { PhotoCamera } from '@mui/icons-material';
import PictureUpload from '../utils/PictureUpload'; 

import  Sequences  from './Sequences';
import { KContainer, KText } from '../utils/elements';

/*
const PictureUpload = ({ initialImageUrl, onUpload, canChange }) => {
  const [preview, setPreview] = useState(initialImageUrl);
  const [cameraMode, setCameraMode] = useState(false);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setPreview(URL.createObjectURL(file));
      onUpload(file);
    } else {
      console.error("Invalid file type. Please upload a PNG or JPEG image.");
    }
  };

  const startCamera = async () => {
    setCameraMode(true);
    try {
      // Try to start with the rear camera (environment) using 'ideal'
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: { ideal: 'environment' } } // Prefer rear camera
      });
      videoRef.current.srcObject = stream;
      console.log("Using rear camera");
  
    } catch (error) {
      console.warn("Rear camera not available, switching to front camera:", error);
      try {
        // Fallback to the front camera (user)
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: 'user' } // Request front camera
        });
        videoRef.current.srcObject = stream;
        console.log("Using front camera");
  
      } catch (frontError) {
        console.error("Error accessing the front camera:", frontError);
      }
    }
  };


  const capturePhoto = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
  
    // Draw the video frame to the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Convert the canvas content to a data URL
    const dataUrl = canvas.toDataURL('image/png'); // Ensure it's PNG
  
    // Update the preview to show the captured photo
    setPreview(dataUrl);
  
    // Convert the data URL to a Blob with a proper filename
    const byteString = atob(dataUrl.split(',')[1]);
    const mimeString = dataUrl.split(',')[0].split(':')[1].split(';')[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
  
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
  
    const blob = new Blob([arrayBuffer], { type: mimeString });
  
    // Create a File object from the Blob with a proper filename and extension
    const file = new File([blob], 'avatar.png', { type: mimeString });
  
    // Stop the video stream
    video.srcObject.getTracks().forEach(track => track.stop());
  
    // Pass the File to the onUpload function
    onUpload(file);
  
    // Close the camera mode
    setCameraMode(false);
  };  
  return (
    <div style={{ textAlign: 'center' }}>
      {cameraMode ? (
        <>
          <video ref={videoRef} autoPlay style={{ width: '100%' }} />
          <canvas ref={canvasRef} style={{ display: 'none' }} width="640" height="480" />
          <Button
            variant="contained"
            onClick={capturePhoto}
            style={{ marginTop: '10px' }}
          >
            {DictValue('Capture Photo')}
          </Button>
        </>
      ) : (
        <>
          <Avatar
            src={preview || "/default-avatar.png"}
            alt="Product"
            sx={{ width: 150, height: 150, margin: "0 auto" }}
          />
          {canChange && (<>
          <Button
            variant="contained"
            component="label"
            style={{ marginTop: '10px' }}
          >
            {DictValue('Upload Picture')}
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </Button>
          <Button
            variant="contained"
            startIcon={<PhotoCamera />}
            onClick={startCamera}
            style={{ marginTop: '10px', marginLeft: '10px' }}
          >
            {DictValue('Use Camera')}
          </Button></>)}
        </>
      )}
    </div>
  );
};
*/

const fetchThisData = async (id, setData, setPictureUrl, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/products/component/detail/";
        const request = { 'id': id };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data)
        setData(data.data);
        setPictureUrl(process.env.REACT_APP_API_URL + data.data.picture)
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};


export const ToolclassChip = ({toolclass}) => {
    
  return <Chip
      key = {toolclass.id}
      label={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {toolclass.name}
    </div>
  }
  variant="outlined"
/>
}; 

export const ToolChip = ({tool}) => {
    
  return <Chip
      key = {tool.id}
      label={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {tool.name}
    </div>
  }
  variant="outlined"
/>
}; 



export const RequiredSkillChip = ({skill}) => {
    
  return <Chip
      key = {skill.id}
      label={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {skill.name} {skill.level}{':'} {skill.expected_time_hhmm}
    </div>
  }
  variant="outlined"
/>
}; 



const Detail = () => {

  let { id } = useParams();

  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pictureUrl, setPictureUrl] = useState('');
  const [canChange, setCanChange] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    checkPageAuthorization(['view_product'], navigate);
    fetchThisData(id, setItem, setPictureUrl, setIsLoading);
  }, [navigate]);

  useEffect(() => {
    setCanChange(checkPerm('change_product'))
    setCanDelete(checkPerm('delete_product'));
  }, []);

  const handlePictureUpload = async (file) => {

    const backend = process.env.REACT_APP_API_URL;
    const api = "/products/component/upload_picture/";

    const formData = new FormData();
    formData.append('id', id);  // Pass the  ID
    formData.append('picture', file);  // Add the avatar file

    const options = {
        method: 'POST',
        headers: {
            'Authorization': getToken(),  // Include your auth token if needed
        },
        body: formData,
    };

    try {
        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        //console.log("Avatar upload response:", data);
    } catch (error) {
        console.error("Error uploading picture:", error);
    }
  };

  
  return (
    item && (
      <Container maxWidth="xl">
        <Typography variant="h4" align="center" gutterBottom>
        {DictValue('Component Details')}
        </Typography>
        <Paper elevation={3}>
          <Box p={3}>

          <Grid container spacing={3} sx={{marginBottom: 2}}>
              {/* Picture with Upload */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Typography variant="h4" align="center">
                  {item.name}
                </Typography>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              {/* Picture with Upload */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <PictureUpload 
                  initialImageUrl={pictureUrl} 
                  onUpload={handlePictureUpload}
                  canChange={canChange} 
                />
              </Grid>

              {/* Operator Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Id')}
                </Typography>
                <Typography variant="body1">{item.id}</Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Name')}
                </Typography>
                <Typography variant="body1">
                  <Update api = {"/products/component/change/"} request ={{id: item.id}} field_name='name' field_type='text' field_value={item.name} can_change={canChange}/>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Code')}
                </Typography>
                <Typography variant="body1">
                  <Update api = {"/products/component/change/"} request ={{id: item.id}} field_name='code' field_type='text' field_value={item.code} can_change={canChange}/>
                </Typography>
              </Grid>

              <KText label={DictValue('Expected time')} value={item.expected_time_hhmm}/>
              <KCheckbox label={DictValue('isLocked')} value={item.is_locked}/>
              
              
              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Required toolclasses')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >

                {item?.required_toolclasses.map((toolclass) => (<ToolclassChip toolclass={toolclass}/>))}
                </Box>  
              </Grid>

              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Required tools')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >

                {item?.required_tools.map((tool) => (<ToolChip tool={tool}/>))}
                </Box>  
              </Grid>


              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Required skills')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >

                {item?.required_skills.map((skill) => (<RequiredSkillChip skill={skill}/>))}
                </Box>  
              </Grid>


              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Sequences')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >
                  {<Sequences 
                    component_id={item.id} 
                    sequences={item.sequences} 
                    setComponentData={setItem}
                    canChange = {canChange && !item.is_locked}
                  />}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    )
  );
};

export default Detail;