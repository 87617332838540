import React from 'react';
import {Link, Route, Routes } from 'react-router-dom';

import ProductsList from './ProductsList';
import ProductDetail from './ProductDetail';
import ComponentDetail from './ComponentDetail';
import DictTag from '../dictionaries/DictTag';

function Products() {
return (
<>
{/*<Link className='mx-2' to={'/products'}><DictTag tag = 'Products'/></Link>*/}
    
<Routes>
    <Route index element={<ProductsList />} />
    <Route path="product/:id/*" element={<ProductDetail />} />
    <Route path="component/:id/*" element={<ComponentDetail />} />
</Routes>

</>

);

}

export default Products;
