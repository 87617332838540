import React, { useEffect, useState} from 'react';
import { Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faSave, faCancel, faCheck, faSquare, faCheckSquare, faX, faGears } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Switch from 'react-switch';
import axios from 'axios';

import CheckMark from '../elements/CheckMark'

const TaskSwitchButton = ({ BatchID, FieldName, value }) => {

    const [isChecked, setIsChecked] = useState(value);
    const [ErrorMessage, setErrorMessage] = useState(null);

    const updateParameter = async () => {

        var body =  { 
            BatchID: BatchID,
            FieldName: FieldName, 
            FieldValue: isChecked ? 0 : 1,
        };
        console.log(body);
    
        try {
    
            const Api = process.env.REACT_APP_API_URL;       
            const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('AccessToken'),
            },
            body: JSON.stringify(body),
            };
            
        const response = await fetch(Api+"/api/batches/" + BatchID +"/update/", options);
        
          const data = await response.json();
          if (!response.ok) {
            throw new Error('Failed to update parameter');
          }
          window.location.reload();
  
        } catch (error) {
            console.log(error.message);
            setErrorMessage('Error updating parameter: ' + error.message);
        }
    
    };
    

    useEffect(() => {
      setIsChecked(value);
    }, [value]);
  
    const handleChange = (checked) => {
      setIsChecked(checked);
      updateParameter();
    };
  
    return (
      ErrorMessage ? <p>{ErrorMessage}</p>
      :
      <div>
        <Switch
          onChange={handleChange}
          checked={isChecked}
          onColor="#86d3ff"
          offColor="#dcdcdc"
          checkedIcon={<span className="p-1" style={{ marginLeft: '8px' }}>Yes</span>}
          uncheckedIcon={<span className="p-1" style={{ marginLeft: '2px' }}>No</span>}
          handleDiameter={24}
          height={26}
          width = {80}
        />
      </div>
    );
};


const ComponentCard = ({component}) => {

const hasTasks = component.tasks.length == 0 ? false : true;
console.log(component, hasTasks);


const component_info =     <>
<table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
<thead>
<tr className="table-light" >
  <th>Kit</th>
  <th>Times</th>
  <th>isPossible</th>
  <th>isCompleted</th>
</tr>
</thead>
<tbody>
<tr>


  <td>{component.KitID}</td>
  <td>
    {Boolean(!component.isCompleted) && <p className='my-0'>E: {component.ExpectedTime_HHMM}</p>}
    {Boolean(component.isCompleted) && <p className='my-0'>A: {component.ActualTime_HHMM}/{component.ExpectedTime_HHMM} ({component.overtime>0?'+':null}{component.overtime}%)</p>}
  </td>
  <td><CheckMark value = {component.isPossible}/></td>
  <td><CheckMark value = {component.isCompleted}/></td>

</tr>
</tbody>
</table>

</>;                              

return ( <>{component_info}</>);


} ; 

const TasksCard = ({tasks}) => {

  const hasTasks = tasks.length == 0 ? false : true;
  
  const tasks_info = <>
  <table className="table table-bordered table-striped text-start table-fixed table-sm table-responsive-sm">
  
  <thead>
  <tr className="table-light" >
  <th>Sequence</th>
  <th>Skills</th>
  <th>Times</th>
  <th>isPossible</th>
  <th>AssignedTo</th>
  <th>isWorkedOn</th>
  <th>isCompleted</th>
  </tr>
  </thead>
  
  <tbody>
  
  {tasks.map((task) => {
    return(
    <>
      <tr className="table-light">
      <td>{task.TaskQuantity} x {task.SequenceName} ({task.SequenceID})</td>
      <td>{task.skills.map((skill) => {return <><span className = "badge bg-secondary mx-1"><small>{skill.Skill}: {skill.SkillValue}</small></span></>})}</td>
      <td>
        {Boolean(task.isCompleted) && <p className= 'my-0'>A: {task.ActualTime_HHMM}/{task.ExpectedTime_HHMM} ({task.overtime>0 ? '+':null} {task.overtime}%)</p> }
        {Boolean(!task.isCompleted) && <p className= 'my-0'>E: {task.ExpectedTime_HHMM}</p> }

        </td>
      <td><CheckMark value = {task.isPossible}/></td>
      <td><CheckMark value = {task.AssignedTo == null ? 0 : 1}/>{task.OperatorName}</td>
      <td><CheckMark value = {task.isWorkedOn}/></td>
      <td><CheckMark value = {task.isCompleted}/></td>
      </tr></>);              
  
    })}
  </tbody>
  </table>
  
  </>;
  
  
  return ( <>{tasks_info}</>);
  
  
  } ; 
  


const BatchTasks = () => {

    let { ProductID, JobID, BatchID } = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);
    const Api = process.env.REACT_APP_API_URL;   
  
    const url = Api+"/api/jobs/" + JobID +"/batches/" + BatchID +"/tasks/";
//    console.log(url);

    const fetchData = async () => {
  
      try {
  
        var request = {'request': null};
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('AccessToken'),
        },
        body: JSON.stringify(request),
        };
    
        const response = await fetch(Api+"/api/jobs/" + JobID +"/batches/" + BatchID +"/tasks/", options);
        const data = await response.json();

//        console.log(data);
        var link_to_products = "/products/";
        var link_to_jobs = "/products/" + ProductID + "/jobs/";
        var link_to_batches = "/products/" + ProductID + "/jobs/" + JobID + "/batches/";
        var link_to_tasks = "/products/" + ProductID + "/jobs/" + JobID + "/batches/" + BatchID + "/tasks/";


        setContent(
          <>

              {data["data"]["components"].map((component) => {

                      const isCompleted = (component.isCompleted == 1 || component.isCompleted == '1' || component.isCompleted == true) ? true : false;
                      const isPossible = (component.isPossible == 1 || component.isPossible == '1' || component.isPossible == true) ? true : false;

                      var backgroundColor = isPossible ?  "white" : "#f5f5f5"
                      if (isCompleted) backgroundColor =  "#D0F0C0"                    
                      var borderColor = isPossible ? "blue" : "red"
                      if (isCompleted) borderColor = "green"
  
                      return(
                      <div className="container-fluid">

                          <div className="row my-2 py-1" style={{ backgroundColor: backgroundColor, margin: '10px', padding: '5px', borderWidth: '3px', borderColor: borderColor, borderStyle: 'solid' }}>
                            <h5>{component.Quantity} x {component.ComponentName} ({component.ComponentID})</h5>
                            <p>{component.predecessors.map((item) => {return <span className = "badge mx-1" style = {{backgroundColor : `${item.isCompleted == 0 ? 'red' : 'green'}`, color : `${item.isCompleted == 0 ? 'black' : 'white'}`}}><small>{item.ComponentName} ({item.ComponentID})</small></span>})}</p>
                          <div className="col-4 margin-0 padding-0">
                            <div>
                              <div>
                              {/*<h5 className="card-title">{component.Quantity} x {component.ComponentName} ({component.ComponentID})</h5>
                                <p className="card-text">This is the content of card 1.</p>*/}
                                {<ComponentCard component={component}/>}
                              </div>
                            </div>
                          </div>
                          <div className="col-8 margin-0 padding-0">
                            <div>
                              <div>
                                {/*<h5 className="card-title"></h5>
                                <p className="card-text"></p>*/}
                                {(<TasksCard tasks={component.tasks}/>)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                        );              
  
                      })}



          </>       
        );
  
      } catch (error) {setContent('Error fetching data: '+ error)
      } finally {setIsLoading(false);}
  
    };
    
    // fetch data only on mount
    useEffect(() => {fetchData()}, []);
  
    var loading = <><Spinner className = 'm-5' animation="border" variant="primary" /></>;
  
    return (<>{isLoading ? loading : content }</>);

};

export default BatchTasks;
