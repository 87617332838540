import React, { useRef, useState } from 'react';
import { Box } from '@mui/material';
import QRCode from 'qrcode.react';
import jsPDF from 'jspdf';
import QrCode2Icon from '@mui/icons-material/QrCode2';

const QRCodePDF = ({ Batch, ProductID }) => {
  const [renderQR, setRenderQR] = useState(false);
  const qrCodeRef = useRef();

  const generateAndOpenPDF = () => {
    // Ensure the QR code is rendered
    setRenderQR(true);
    // Wait a bit for the QR code to be rendered in the DOM
    setTimeout(() => {
      // Capture the QR code as a data URL
      const qrCanvas = qrCodeRef.current.querySelector('canvas');
      if (!qrCanvas) {
        console.error('QR Code canvas not found');
        return;
      }

      const qrDataURL = qrCanvas.toDataURL('image/png');

      // Create a new jsPDF instance
      const doc = new jsPDF();

      // Add QR code to PDF
      doc.addImage(qrDataURL, 'PNG', 10, 10, 50, 50); // Adjust position and size

      // Add other content to PDF
      doc.setFontSize(20);
      doc.text(`Cesta`, 70, 15); // Adjust position
      doc.setFontSize(40);
      doc.text(`${Batch.BatchCode}`, 70, 30); // Adjust position
      doc.setFontSize(10);
      doc.text(`JobID: ${Batch.JobID}`, 10, 100); // Adjust position
      doc.text(`Quantity: ${Batch.BatchQuantity}`, 10, 115); // Adjust position
      doc.text(`ProductID: ${ProductID}`, 10, 130); // Adjust position
    
      // Open PDF in a new window
      const pdfBlob = doc.output('blob');
      const pdfURL = URL.createObjectURL(pdfBlob);
      window.open(pdfURL);

      // Optionally, hide QR code again after PDF generation
      setRenderQR(false);
    }, 100); // Adjust timeout as needed
  };

  const payload = JSON.stringify({BatchCode: Batch.BatchCode})
  return (
    <>
        <span onClick={generateAndOpenPDF}>
          <QrCode2Icon fontSize="large" />
        </span>
        {renderQR && (
          <Box
            ref={qrCodeRef}
            style={{ position: 'absolute', top: -9999, left: -9999, visibility: 'hidden' }}
          >
            <QRCode value={payload} size={256}/>
          </Box>
        )}
    </>
  );
};

export default QRCodePDF;
