import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { getToken, checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import { Update, SwitchButton, KCheckbox, KText, FilteredSelect } from '../utils/elements';

import { Container, Paper, Box, Grid, Typography, Button, Avatar, Chip } from '@mui/material';

import {Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { Table, TableBody, TableCell, TableRow, TableHead} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const fetchBatchData = async (id, setData, setIsLoading = null) => {

    try {

        if (setIsLoading !== null) setIsLoading(true)

        const backend = process.env.REACT_APP_API_URL;
        const api = "/jobs/batches/detail/";
        const request = { 'id': id };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getToken(),
            },
            body: JSON.stringify(request),
        };

        const response = await fetch(`${backend}${api}`, options);
        const data = await response.json();
        console.log(data)
        setData(data.data);
        setPictureUrl(process.env.REACT_APP_API_URL + data.data.picture)
        return data;

    } catch (error) {
        return({status: 'ERROR', message: 'Error fetching data: ' + error.message});
    } finally {
        if (setIsLoading !== null) setIsLoading(false);
    }
};




const Component = ({ component, onRefetch = null }) => {

  const { name, is_completed, is_started, is_possible, expected_time_hhmm, progress, actual_time_hhmm, subcomponents, tasks, quantity } = component;
  const width = 3;
  const [loading, setLoading] = useState(false);



  // Function to handle the "Fix Tasks" API call
  const handleFixTasks = async () => {
    setLoading(true);
    const backend = process.env.REACT_APP_API_URL;
    const api = "/jobs/batches/components/fix-tasks/";
    const request = { 'id': component.id };

    const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': getToken(),
        },
        body: JSON.stringify(request),
    };


    try {
      // Replace with your actual API URL and method
      const response = await fetch(`${backend}${api}`, options);

      if (response.ok) {
        console.log("Tasks fixed successfully");
        if (onRefetch) {
          onRefetch(); // Trigger a data refetch if onRefetch is provided
        }
//        else window.location.reload();

      } else {
        console.error("Failed to fix tasks:", response.statusText);
      }
    } catch (error) {
      console.error("Error in fixing tasks:", error);
    } finally {
      setLoading(false);
    }
  };


  const ComponentChip = ({ component }) => (
    <Chip
      key={'component_' + component.id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {component.name}
        </div>
      }
      variant="outlined"
      sx={{
        borderColor: component.is_completed ? 'green' : 'red',
      }}
    />
  );

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography
            variant="h6"
            sx={{
              color: is_completed ? 'white' : 'inherit',
              backgroundColor: is_completed ? 'green' : 'transparent',
              padding: is_completed ? '4px 8px' : '0',
              borderRadius: '4px',
            }}
          >
            <b>{name} (x{quantity})</b>
          </Typography>

          <Box mt={1} display="flex" alignItems="start">
            {!is_started && <KCheckbox label={DictValue('isPossible')} value={is_possible} />}
            {!is_completed && is_possible && <KCheckbox label={DictValue('isStarted')} value={is_started} />}
            {is_started && <KCheckbox label={DictValue('isCompleted')} value={is_completed} />}
          </Box>

          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            <KText label="ETC" value={expected_time_hhmm} sm={width} />
            <KText label="Progress" value={progress} sm={width} />
            <KText label="AT" value={actual_time_hhmm} sm={width} />

          </Grid>

          <Box mt={1}>
            {subcomponents.map((subcomponent) => (
              <ComponentChip key={subcomponent.id} component={subcomponent} />
            ))}
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {tasks.map((task) => (
          <Task key={task.id} task={task} />
        ))}
      </AccordionDetails>
      <Button
            variant="contained"
            color="primary"
            onClick={handleFixTasks}
            disabled={loading}
            sx={{ mt: 2 }}
          >
            {loading ? "Fixing Tasks..." : "Fix Tasks"}
      </Button>
    </Accordion>
  );
};


const Task = ({ task }) => {
  
  const width = 3


  const OperatorChip = ({operator}) => {

    return (
      <Chip
        key={'operator_' + operator.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {operator.name}
          </div>
        }
        variant="outlined"
/*        sx={{
          borderColor: operator.tasks.length > 0 ? 'red' : 'green'
        }}*/
        />
    );


  }


  const ToolChip = ({tool}) => {

    return (
      <Chip
//        key={'tool_' + tool.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {tool.name}
          </div>
        }
        variant="outlined"
/*        sx={{
          borderColor: operator.tasks.length > 0 ? 'red' : 'green'
        }}*/
        />
    );


  }

  const LogEntry = ({ entry }) => {
    return (
      <tr>
        <TableCell>{entry.dt}</TableCell>
        <TableCell>{entry.event}</TableCell>
        <TableCell>{JSON.stringify(entry.infos)}</TableCell>
      </tr>
    );
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="h6">
            <b>{task.sequence_name}</b>
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Typography variant="h6" gutterBottom>
            <b>{task.name}</b>
          </Typography>
          
          <Grid container spacing={1} justifyContent="flex-start">
            {!task.is_started && !task.is_completed && <KCheckbox label={DictValue('isPossible')} value={task.is_possible} sm={width} />}
            {task.is_possible && !task.is_completed && <KCheckbox label={DictValue('isAssigned')} value={task.is_assigned} sm={width} />}
            {task.is_assigned && !task.is_completed && <KCheckbox label={DictValue('isStarted')} value={task.is_started} sm={width} />}
            {task.is_started && <KCheckbox label={DictValue('isCompleted')} value={task.is_completed} sm={width} />}

          </Grid>
          
          <Grid container spacing={1} justifyContent="flex-start" sx={{ mt: 1 }}>
            {task.is_assigned && <KText label="AssignedTo" value={task.operator.name} sm={width} />}
            <KText label="ETC" value={task.expected_time_hhmm} sm={width} />
            {task.is_started && <KText label="AT" value={task.actual_time_hhmm} sm={width} />}
            <KText label="ID" value={task.id} sm={width} />
            <KText label="Parent" value={task.parent_id} sm={width} />

          </Grid>

          {!task.is_started && <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textSecondary">
              {DictValue('Qualified operators')}
            </Typography>
            <Box 
              sx={{ 
                border: '1px solid', 
                borderColor: 'grey.400', // Adjust the color as needed
                borderRadius: 1, // Optional: for rounded corners
                padding: 2 // Adjust padding as needed
              }}
            >

            {task?.qualified_operators.map((operator) => (<OperatorChip key={'operator_'+operator.id} operator={operator}/>))}
            </Box>  
          </Grid>}

          {!task.is_started && task.requires_tool && <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="textSecondary">
              {DictValue('Required tools')}
            </Typography>
            <Box 
              sx={{ 
                border: '1px solid', 
                borderColor: 'grey.400', // Adjust the color as needed
                borderRadius: 1, // Optional: for rounded corners
                padding: 2 // Adjust padding as needed
              }}
            >

            {task?.required_tools.map((tool) => (<ToolChip key={'tool_'+tool.id} tool={tool}/>))}
            </Box>  
          </Grid>}

          {true && (
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Log')}
              </Typography>
              <Box 
                sx={{ 
                  border: '1px solid', 
                  borderColor: 'grey.400', // Adjust the color as needed
                  borderRadius: 1, // Optional: for rounded corners
                  padding: 2 // Adjust padding as needed
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date/Time</TableCell>
                      <TableCell>Action</TableCell>
                      <TableCell>Info</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {task?.log.map((entry) => (
                      <LogEntry key={'logentry_' + entry.id} entry={entry} />
                    ))}
                  </TableBody>
                </Table>
              </Box>  
            </Grid>
          )}


        </Box>
      </AccordionDetails>
    </Accordion>
  );
};



const Detail = () => {
  let { id, batch_id } = useParams();
  console.log(id, batch_id);
  const [batch, setBatch] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canChange, setCanChange] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const navigate = useNavigate();

  const refetch = async () => {
    try {
      await fetchBatchData(batch_id, setBatch, setIsLoading);
    } catch (error) {
      console.error("Failed to fetch batch data:", error);
    }
  };

  useEffect(() => {
    checkPageAuthorization(['view_job'], navigate);
    refetch();
  }, [navigate, id, batch_id]);  // Ensure refetch on id or batch_id change

  useEffect(() => {
    setCanChange(checkPerm('change_job'));
    setCanDelete(checkPerm('delete_job'));
  }, []);

  if (isLoading) {
    return <Typography variant="h6" align="center">Loading...</Typography>;
  }

  return (
    batch && (
      <Container maxWidth="xl">
        <Typography variant="h4" align="center" gutterBottom>
          {DictValue('Batch Details')}
        </Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={3}>
              {/* Operator Information */}
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Id')}
                </Typography>
                <Typography variant="body1">{batch.id}</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Batch Code')}
                </Typography>
                <Typography variant="body1">
                  {batch.code}
                </Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Batch Name')}
                </Typography>
                <Typography variant="body1">
                  {batch.name}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Quantity')}
                </Typography>
                <Typography variant="body1">
                  {batch.quantity}
                </Typography>
              </Grid>


              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('isActive')}
                </Typography>
                <SwitchButton
                  api="/jobs/batches/change/"
                  request={{ id: batch.id }}
                  field_name="is_active"
                  value={batch.is_active}
                  can_change={canChange}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('Kit Ready')}
                </Typography>
                <SwitchButton
                  api="/jobs/batches/change/"
                  request={{ id: batch.id }}
                  field_name="kit_ready"
                  value={batch.kit_ready}
                  can_change={canChange}
                />
              </Grid>


              <Grid item xs={12} sm={12}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Components')}
                </Typography>
                <Box 
                  sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                  }}
                >
                  {batch.components.map((component) => (<Component component={component}/>))}
                </Box>
              </Grid>



            </Grid>
          </Box>
        </Paper>
      </Container>
    )
  );
};

export default Detail;