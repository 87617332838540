import { React, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { checkPageAuthorization, checkPerm, fetchData, DictValue } from '../utils/common';
import { Update, SwitchButton, FilteredSelect, KText, KCheckbox } from '../utils/elements';

import { Container, Paper, Box, Grid, Typography, Button, Avatar } from '@mui/material';

import Chip from '@mui/material/Chip';

import PersonIcon from '@mui/icons-material/Person'; // Import the icon
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';

const SkillChip = ({operator, skill, onChange}) => {
    
  const [data, setData] = useState(skill)
  
  const handleIncrease = async () => {
      fetchData('/operators/manage-skills/', {id: operator.id, is_training: false, skill_id: skill.id, action: 'increase'}, setData, null)
      onChange(); 

    };
  
    const handleDecrease = async () => {
      fetchData('/operators/manage-skills/', {id: operator.id, is_training: false, skill_id: skill.id, action: 'decrease'}, setData, null)
      onChange(); 

    };



  return data.level>0 && (<Chip
      key = {data.id}
      label={
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {data.name + ' ' + data.level}
      <IconButton color="primary" onClick={handleIncrease} size="small">
        <ArrowUpwardIcon />
      </IconButton>
      <IconButton color="secondary" onClick={handleDecrease} size="small">
        <ArrowDownwardIcon />
      </IconButton>
    </div>
  }
  variant="outlined"
/>)
}; 


const TrainingSkillChip = ({ operator, skill, onChange = null }) => {
  const [data, setData] = useState(skill);
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    setData(skill); // Update data when skill prop changes
  }, [skill]);

  const handleDelete = async () => {
    try {
      await fetchData('/operators/manage-skills/', {
        id: operator.id,
        is_training: true,
        skill_id: skill.id,
        action: 'delete'
      }, setData, null);
      setVisible(false);
      if (onChange) {
        onChange(); // Call onChange if it's defined
      }
    } catch (error) {
      console.error("Error deleting skill:", error);
    }
  };

  return (
    data.level > 0 && visible && (
      <Chip
        key={data.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {`${data.name} ${data.level}`}
            <IconButton color="primary" onClick={handleDelete} size="small">
              <DeleteIcon />
            </IconButton>
          </div>
        }
        variant="outlined"
      />
    )
  );
};

const QualificationChip = ({ operator, qualification, onChange = null }) => {
  const [data, setData] = useState(qualification);
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    setData(qualification); // Update data when skill prop changes
  }, [qualification]);

  const handleDelete = async () => {
    try {
      await fetchData('/operators/manage-qualifications/', {
        id: operator.id,
        sequence_id: qualification.id,
        action: 'remove'
      }, setData, null);
      setVisible(false);
      if (onChange) {
        onChange(); // Call onChange if it's defined
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  return (
    visible && (
      <Chip
        key={data.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {`${data.name}`}
            <IconButton color="primary" onClick={handleDelete} size="small">
              <DeleteIcon />
            </IconButton>
          </div>
        }
        variant="outlined"
      />
    )
  );
};


const Detail = () => {

  let { operator_id } = useParams();

  const [operator, setOperator] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [canChange, setCanChange] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    checkPageAuthorization(['view_operator'], navigate);
    fetchData('/operators/details/', {'id':operator_id}, setOperator, setIsLoading);
  }, [navigate]);

  useEffect(() => {
    setCanChange(checkPerm('change_operator'))
  }, []);

  const refetch = () => {
    console.log("refetching");
    
    // Add a delay of 1 second (1000 milliseconds)
    setTimeout(async () => {
      const response = await fetchData('/operators/details/', { 'id': operator_id }, setOperator, setIsLoading);
      
      if (response && response.data) {
        // Explicitly set operator to force re-render if response differs
        console.log("new data", response.data);
        setOperator(response.data);
      }
    }, 1000); // Adjust the delay as needed
  };

  return (
    operator && (
      <Container maxWidth="xxl" sx={{mt:4, paddingBottom: 2, backgroundColor: 'white'}}>
        <Typography variant="h4" align="center" gutterBottom>
        {DictValue('Operator Details')}
        </Typography>
        <Paper elevation={3}>
          <Box p={3}>
            <Grid container spacing={3} sx={{ marginBottom: 4 }}>
              {/* Operator Picture with Upload */}
              <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar
                          src={operator.avatar ? process.env.REACT_APP_API_URL + operator.avatar : null} 
                          sx={{ width: 56, height: 56, mr: 2 }} // Adjust size and margin as needed
                      />
                <Typography variant="h6" align="center">
                  {operator.first_name} {operator.last_name}
                </Typography>

                  </Box>
              </Grid>

              {/* Operator Information */}
              <KText label={DictValue('Username')} value ={operator.username} />
              <KText label={DictValue('EmployeeID')} value ={operator.employee_id} />

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                {DictValue('Nickname')}
                </Typography>
                <Typography variant="body1"><Update api = {"/users/change/"} request ={{username: operator.username}} field_name='nickname' field_type='text' field_value={operator.nickname} can_change={checkPerm('system.change_user')} /></Typography>
              </Grid>

              <KCheckbox label={DictValue('isActive')} value ={operator.is_active} />

              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" color="textSecondary">
                  {DictValue('User')}
                </Typography>
                <Box display="flex" alignItems="center">
                {operator && operator?.hash && (
                      <IconButton component={Link} to={`/users/user/${operator.hash}`} color="primary">
                        <PersonIcon fontSize='large'/>
                      </IconButton>)}
                </Box>

              </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 4 }}>
                  <Grid item xs={12} sm={12}>
                  <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                  {DictValue('Skills')}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      {operator && (
                          <FilteredSelect
                              options_api="/skills/list/"
                              request={{ id: operator.id, is_training: false, action: 'add' }}
                              current_id={''}
                              update_api="/operators/manage-skills/"
                              option_name="operator_id"
                              can_change={true}
                              onChange={refetch}
                              label = 'Skills'
                          />
                      )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      {operator?.skills.map((skill) => (
                          <SkillChip key = {skill.id} operator={operator} skill={skill} onChange = {refetch}/>
                      ))}
                  </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 4 }}>
                  <Grid item xs={12} sm={12}>
                  <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                  {DictValue('Training Skills')}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      {operator && (
                          <FilteredSelect
                              options_api="/skills/list/"
                              request={{ id: operator.id, is_training: true, action: 'train' }}
                              current_id={''}
                              update_api="/operators/manage-skills/"
                              option_name="operator_id"
                              can_change={true}
                              onChange={refetch}
                              label = 'Skills'
                          />
                      )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      {operator?.training_skills.map((skill) => (
                          <TrainingSkillChip key = {skill.id} operator={operator} skill={skill}/>
                      ))}
                  </Grid>
              </Grid>

              <Grid container sx={{ marginBottom: 4 }}>
                  <Grid item xs={12} sm={12}>
                  <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                  {DictValue('Qualifications')}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      {operator && (
                          <FilteredSelect
                              options_api="/operators/manage-qualifications/"
                              options_request={{ id: operator.id, action: 'available_qualifications' }}
                              update_api="/operators/manage-qualifications/"
                              request={{ id: operator.id, action: 'qualify' }}
                              current_id={''}
                              option_name="sequence_id"
                              can_change={true}
                              onChange={refetch}
                              label = 'Qualifications'
                          />
                      )}
                  </Grid>
                  {<Grid item xs={12} sm={6}>
                      {operator?.qualifications.map((qualification) => (
                          <QualificationChip key = {qualification.id} operator={operator} qualification={qualification}/>
                      ))}
                  </Grid>}
              </Grid>

              <Grid container sx={{ marginBottom: 4 }}>
                  <Grid item xs={12} sm={12}>
                  <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                  {DictValue('Training qualifications')}
                      </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                      {operator && (
                          <FilteredSelect
                              options_api="/operators/manage-qualifications/"
                              options_request={{ id: operator.id, action: 'available_qualifications' }}
                              update_api="/operators/manage-qualifications/"
                              request={{ id: operator.id, action: 'train' }}
                              current_id={''}
                              option_name="sequence_id"
                              can_change={true}
                              onChange={refetch}
                              label = 'Qualifications'
                          />
                      )}
                  </Grid>
                  {<Grid item xs={12} sm={6}>
                      {operator?.training_qualifications.map((qualification) => (
                          <QualificationChip key = {qualification.id} operator={operator} qualification={qualification}/>
                      ))}
                  </Grid>}
              </Grid>


          </Box>
        </Paper>
      </Container>
    )
  );
};

export default Detail;