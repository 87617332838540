// theme.js
import { createTheme } from '@mui/material/styles';

// Determine background color based on the environment variable
const environment = process.env.REACT_APP_ENVIRONMENT || ''; // Default to empty if undefined
console.log("ENVIRONMENT", environment)
let backgroundColor;

if (environment === 'dev') {
  backgroundColor = 'yellow';
} else if (environment === 'prod') {
  backgroundColor = 'white';
} else {
  backgroundColor = 'pink';
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xxl: 1920, // Custom breakpoint
    },
  },
  palette: {
    background: {
      default: backgroundColor, // Sets the default background color
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: backgroundColor, // Sets background for the body globally
          margin: 0, // Ensure no default margin
          padding: 0,
        },
      },
    },
  },
});

export default theme;