import React, { useState, useEffect, useRef } from 'react';
import { Grid, Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Card, CardContent, CardActions, IconButton } from '@mui/material';
import axios from 'axios';
import { getToken, DictValue, fetchData } from '../utils/common';
import { Update, SwitchButton, FilteredSelect, KText } from '../utils/elements';
import Chip from '@mui/material/Chip';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DeleteIcon from '@mui/icons-material/Delete';
import {Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VideoUpload from './VideoUpload';
import { Table, TableBody, TableCell, TableRow, TableHead} from '@mui/material';

const SkillChip = ({sequence, skill}) => {
    
    const [data, setData] = useState(skill)
    
    const handleIncrease = async () => {
        fetchData('/products/sequence/manage-required-skills/', {id: sequence.id, skill_id: skill.id, action: 'increase'}, setData, null)
    };
    
      const handleDecrease = async () => {
        fetchData('/products/sequence/manage-required-skills/', {id: sequence.id, skill_id: skill.id, action: 'decrease'}, setData, null)
      };



    return data.level>0 && (<Chip
        key = {data.id}
        label={
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {data.tag + ' ' + data.level}
        <IconButton color="primary" onClick={handleIncrease} size="small">
          <ArrowUpwardIcon />
        </IconButton>
        <IconButton color="secondary" onClick={handleDecrease} size="small">
          <ArrowDownwardIcon />
        </IconButton>
      </div>
    }
    variant="outlined"
  />)
}; 

const OperatorChip = ({ sequence, operator, onDelete=null }) => {
  
  const handleDelete = async () => {
    await fetchData('/operators/manage-qualifications/', {
      sequence_id: sequence.id,
      id: operator.id,
      action: 'remove',
    }, null, null);
    onDelete();
  };


  return (
    <Chip
      key={operator.id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {operator.first_name + ' ' + operator.last_name}
        </div>
      }
      variant="outlined"
      onDelete={handleDelete} // This adds the delete functionality
      deleteIcon={<DeleteIcon />} // Custom delete icon
    />
  );
};

const ToolClassChip = ({ sequence, toolclass, onDelete=null }) => {
  
    const handleDelete = async () => {
      await fetchData('/products/sequence/manage-required-toolclass/', {
        id: sequence.id,
        toolclass_id: toolclass.id,
        action: 'remove',
      }, null, null);
      onDelete();
    };
  
  
    return (
      <Chip
        key={toolclass.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {toolclass.name}
          </div>
        }
        variant="outlined"
        onDelete={handleDelete} // This adds the delete functionality
        deleteIcon={<DeleteIcon />} // Custom delete icon
      />
    );
};
  
const ToolChip = ({ sequence, tool, onDelete=null }) => {
  
    const handleDelete = async () => {
      await fetchData('/products/sequence/manage-required-tool/', {
        id: sequence.id,
        tool_id: tool.id,
        action: 'remove',
      }, null, null);
      onDelete();
    };
  
  
    return (
      <Chip
        key={tool.id}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {tool.name}
          </div>
        }
        variant="outlined"
        onDelete={handleDelete} // This adds the delete functionality
        deleteIcon={<DeleteIcon />} // Custom delete icon
      />
    );
};


const OLDVideoUpload = ({ sequenceId, onUploadComplete }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState(null);
    const [showVideo, setShowVideo] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunks = useRef([]);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        if (!selectedFile) {
            console.log("No file selected")
            return;
        }
    
        // Convert Blob to a File with a filename
        const file = new File([selectedFile], 'recorded-video.mp4', { type: 'video/mp4' });
    
        const formData = new FormData();
        formData.append('video', file);
        formData.append('sequence_id', sequenceId);
    
        setUploading(true);
        setError(null);
    
        try {
            const backend = process.env.REACT_APP_API_URL;
            const api = '/products/sequence/upload-video/'

            const response = await axios.post(`${backend}${api}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${getToken()}`,
                },
            });
    
            setUploading(false);
            onUploadComplete(response.data.video_url); // Notify parent with the uploaded video URL
        } catch (error) {
            setUploading(false);
            setError('Video upload failed. Please try again.');
        }
    };

    const startRecording = async () => {
        try {
          setShowVideo(true);
      
          let stream;
      
          // Try to get the rear camera (environment) first
          try {
            stream = await navigator.mediaDevices.getUserMedia({
              video: { facingMode: { exact: 'environment' } }
            });
            console.log("Using rear camera (environment)");
          } catch (error) {
            console.warn("Rear camera not available, switching to front camera:", error);
            // Fallback to the front camera (user)
            stream = await navigator.mediaDevices.getUserMedia({
              video: { facingMode: 'user' }
            });
            console.log("Using front camera (user)");
          }
      
          videoRef.current.srcObject = stream;
      
          // Initialize MediaRecorder
          mediaRecorderRef.current = new MediaRecorder(stream);
      
          // Set up event handlers
          mediaRecorderRef.current.ondataavailable = (e) => {
            console.log('Data available:', e.data);
            if (e.data.size > 0) {
              chunks.current.push(e.data);
              saveRecording();
            }
          };
      
          // Optionally handle when the recording stops
          /*
          mediaRecorderRef.current.onstop = () => {
            const blob = new Blob(chunks.current, { type: 'video/mp4' });
            console.log('Blob created:', blob);
            setSelectedFile(blob);
            console.log("VideoURL", URL.createObjectURL(blob));
            setVideoURL(URL.createObjectURL(blob));
            chunks.current = []; // Clear chunks for next recording
          };
          */
      
          console.log(mediaRecorderRef.current, mediaRecorderRef.current.state);
      
          // Stop the recording if already in progress
          if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            console.log('Stopping current recording before starting a new one.');
            stopRecording(); // Ensure you stop the current recording
          }
      
          // Start recording
          console.log('Before start:', mediaRecorderRef.current.state);
          mediaRecorderRef.current.start();
          console.log('After start:', mediaRecorderRef.current.state);
          setIsRecording(true);
      
        } catch (err) {
          console.error('Error accessing webcam:', err);
          setError('Unable to access webcam');
        }
      };
      
    const saveRecording = async () => {

            // Process recorded data
            if (chunks.current.length > 0) {
                const blob = new Blob(chunks.current, { type: 'video/mp4' });
                console.log('Blob created:', blob);
    
                // Convert Blob to File
                const file = new File([blob], 'recorded-video.mp4', { type: 'video/mp4' });
    
                // Upload video
                const formData = new FormData();
                formData.append('video', file);
                formData.append('sequence_id', sequenceId); // Assuming you need to associate this with a sequence
    
                console.log("FORM", formData)

                try {
                    const backend = process.env.REACT_APP_API_URL;
                    const response = await axios.post(`${backend}/products/sequence/upload-video/`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `${getToken()}`,
                        },
                    });
                    console.log('Upload successful:', response.data);
//                    setVideoUrl(response.data.video_url); // Update video URL state
                } catch (error) {
                    console.error('Video upload failed:', error);
                    setError('Video upload failed. Please try again.');
                }
                
                chunks.current = []; // Clear chunks for the next recording
            } else {
                console.error('No data chunks available');
            }

    }

    const stopRecording = async () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
            videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
            mediaRecorderRef.current = null; // Reset MediaRecorder
            videoRef.current.srcObject = null; // Clear video source
            setIsRecording(false);
    
        }
    };
    
/*    
    const uploadVideo = async (file) => {
        const formData = new FormData();
        formData.append('video', file);
        formData.append('sequence_id', sequenceId);
    
        setUploading(true);
        setError(null);
    
        try {
            const backend = process.env.REACT_APP_API_URL;
            const api = '/products/sequence/upload-video/'

            const response = await axios.post(`${backend}${api}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `${getToken()}`,
                },
            });
    
            setUploading(false);
            onUploadComplete(response.data.video_url); // Notify parent with the uploaded video URL
        } catch (error) {
            setUploading(false);
            setError('Video upload failed. Please try again.');
        }
    };
*/

    return (
        <Box>
  
            {<><input type="file" accept="video/*" capture="camcorder" onChange={handleFileChange} />
            <Button variant="contained" color="primary" onClick={handleUpload} disabled={uploading}>
                {uploading ? 'Uploading...' : 'Upload Video'}
            </Button></>}
            {error && <Typography color="error">{error}</Typography>}

            <Box mt={2}>
                {showVideo && <video ref={videoRef} width="100%" autoPlay muted></video>}
                {isRecording ? (
                    <Button variant="contained" color="secondary" onClick={stopRecording}>
                        Stop Recording
                    </Button>
                ) : (
                    <Button variant="contained" color="primary" onClick={startRecording}>
                        Start Recording
                    </Button>
                )}
            </Box>

            {videoURL && (
                <Box mt={2}>
                    <Typography>Recorded Video:</Typography>
                    <video width="100%" controls src={videoURL}></video>
                </Box>
            )}
        </Box>
    );
};


const TaskEntry = ({ task }) => {
    return (
      <tr>
        <TableCell>{task.dt_completed}</TableCell>
        <TableCell>{task.batch_code}</TableCell>
        <TableCell>{task.quantity}</TableCell>
        <TableCell>{task.operator.name}</TableCell>
        <TableCell>{task.expected_time_per_unit_hhmm}</TableCell>
        <TableCell>{task.actual_time_per_unit_hhmm}</TableCell>
        <TableCell>{task.deviation}</TableCell>
        <TableCell>
            <SwitchButton
                  api="/jobs/task-actions/"
                  request={{ task_id: task.id, action: 'change' }}
                  field_name="use_for_stats"
                  value={task.use_for_stats}
                  can_change={true}
            />
            {task.use_for_stats}</TableCell>
      </tr>
    );
  };

const Sequence = ({ item, handleDelete, handleOpenModal, canChange }) => {

    const [data, setData] = useState(item);
    const [videoUrl, setVideoUrl] = useState(process.env.REACT_APP_API_URL + item.video || ''); // New state for video URL

    const refetch = () => {
//        console.log("refetching");
        fetchData('/products/sequence/detail/', { id: item.id }, setData, null, null);
    };

    // Log data after it's updated
    useEffect(() => {
        console.log("Data updated:", data);
    }, [data]);

    const handleVideoUploadComplete = (url) => {
        setVideoUrl(url); // Update video URL state
        refetch(); // Optionally refetch data to update UI
    };


    return (
        <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center" width="100%">
        <Typography variant="h6"><b>
            {data.name}
        </b></Typography>
        <Typography variant="body1" sx={{ marginLeft: 'auto' }}>
            ETC: {data.expected_time_hhmm}
        </Typography>
        </Box>
        </AccordionSummary>
        <AccordionDetails>

        <Card key={data.id} sx={{ marginBottom: 2, width: '100%' }}>
            <CardContent>

            <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12} sm={12}>

                <Box display="flex" flexDirection="column" width="100%">
                    <Grid container spacing={2} alignItems="center">
                        <KText label='id' value = {data.id} sm={2}/>
                        <KText label='Parent Id' value = {data.parent_id ? data.parent_id : '-'} sm={2}/>
                        <KText label='Child Id' value = {data.child_id ? data.child_id : '-'} sm={2}/>
                        <KText label='Average Time' value = {data.average_time_hhmm} sm={2}/>
                        <KText label='Expected Time' value = {data.expected_time_hhmm} sm={2}/>

                    </Grid>
                </Box>
                </Grid>
            </Grid>

                <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12} sm={12}>

                <Box display="flex" flexDirection="column" width="100%">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                            <Typography color="textSecondary">
                                <b>Expected Time (seconds):
                                <Update
                                    api='/products/sequence/change/'
                                    request={{ id: data.id }}
                                    field_name='expected_time'
                                    field_type='number'
                                    field_value={data.expected_time}
                                    can_change={true}
                                /></b>
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography color="textSecondary">
                                <b>Name:
                                <Update
                                    api='/products/sequence/change/'
                                    request={{ id: data.id }}
                                    field_name='name'
                                    field_type='text'
                                    field_value={data.name}
                                    can_change={true}
                                /></b>
                            </Typography>
                        </Grid>

                    </Grid>
                </Box>
                </Grid>
                </Grid>

                <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12} sm={12}>
                    <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                    {DictValue('Skills')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {data && (
                            <FilteredSelect
                                options_api="/skills/list/"
                                api="/products/sequence/manage-required_skills/"
                                request={{ id: data.id, action: 'add' }}
                                current_id={data?.id}
                                update_api="/products/sequence/manage-required-skills/"
                                option_name="skill_id"
                                can_change={true}
                                onChange={refetch}
                                label = 'Skills'
                            />
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {data?.required_skills.map((skill) => (
                            <SkillChip key = {skill.id} sequence={data} skill={skill} />
                        ))}
                    </Grid>
                </Grid>

                <Grid container spacing={1} sx={{ marginBottom: 4 }}>

                    <Grid item xs={12} sm={12}>

                        <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                            Requires qualification:
                        <SwitchButton 
                                api='/products/sequence/change/'
                                request={{ id: item.id }}
                                field_name='requires_qualification'
                                value={item.requires_qualification}
                                can_change={true}
                                onChange = {refetch}                
                        />
                        </Typography>

                    </Grid>                    

                        {data.requires_qualification && (<><Grid item xs={12} sm={12}>
                            <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                            {DictValue('Qualified operators')}
                            </Typography>
                            </Grid>                    
                            <Grid item xs={12} sm={6}>
                                {data && (
                                    <FilteredSelect
                                        options_api="/operators/manage-qualifications/"
                                        options_request={{'action': 'operators_available_for_sequence', 'sequence_id': data.id}}
                                        update_api="/operators/manage-qualifications/"
                                        request={{ sequence_id: data.id, action: 'qualify_operator_for_sequence', 'is_training':false  }}
                                        current_id={data?.id}
                                        option_name="operator_id"
                                        can_change={true}
                                        onChange={refetch}
                                        label = 'Operators'
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {data?.qualified_operators.map((operator) => (
                                    <OperatorChip key = {operator.id} sequence={data} operator={operator} onDelete={refetch} />
                                ))}
                            </Grid></>
                        )}

                        {data.requires_qualification && (<><Grid item xs={12} sm={12}>
                            <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                            {DictValue('Training operators')}
                            </Typography>
                            </Grid>                    
                            <Grid item xs={12} sm={6}>
                                {data && (
                                    <FilteredSelect
                                        options_api="/operators/manage-qualifications/"
                                        options_request={{'action': 'operators_available_for_sequence', 'sequence_id': data.id}}
                                        update_api="/operators/manage-qualifications/"
                                        request={{ sequence_id: data.id, action: 'qualify_operator_for_sequence', 'is_training':true  }}
                                        current_id={data?.id}
                                        option_name="operator_id"
                                        can_change={true}
                                        onChange={refetch}
                                        label = 'Operators'
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                {data?.training_operators.map((operator) => (
                                    <OperatorChip key = {operator.id} sequence={data} operator={operator} onDelete={refetch} />
                                ))}
                            </Grid></>
                        )}




                </Grid>



                <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12} sm={12}>
                    <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                    {DictValue('Required toolclass')}
                        </Typography>
                        </Grid>                    
                        {!data?.required_toolclass && <Grid item xs={12} sm={6}>
                            {data && (
                                <FilteredSelect
                                    options_api="/tools/toolclass/list/"
                                    //options_request={data.required_skills}
                                    update_api="/products/sequence/manage-required-toolclass/"
                                    request={{ id: data.id, resource: 'toolclass', action: 'add' }}
                                    current_id={data?.id}
                                    option_name="toolclass_id"
                                    can_change={true}
                                    onChange={refetch}
                                    label = {DictValue('Toolclasses')}
                                />
                            )}
                        </Grid>}
                        <Grid item xs={12} sm={6}>
                            {data?.required_toolclass && <ToolClassChip sequence={data} toolclass={data.required_toolclass} onDelete={refetch} />}
                    </Grid>
                </Grid>

                <Grid container sx={{ marginBottom: 4 }}>
                    <Grid item xs={12} sm={12}>
                    <Typography variant = 'body1' sx={{fontWeight: 'bold'}} color="textSecondary">
                    {DictValue('Required tool')}
                        </Typography>
                        </Grid>                    
                        {!data?.required_tool && <Grid item xs={12} sm={6}>
                            {data && (
                                <FilteredSelect
                                    options_api="/tools/list/"
                                    update_api="/products/sequence/manage-required-tool/"
                                    request={{ id: data.id, resource: 'tool', action: 'add' }}
                                    current_id={data?.id}
                                    option_name="tool_id"
                                    can_change={true}
                                    onChange={refetch}
                                    label = {DictValue('Tools')}
                                />
                            )}
                        </Grid>}
                        <Grid item xs={12} sm={6}>
                            {data?.required_tool && <ToolChip sequence={data} tool={data.required_tool} onDelete={refetch} />}
                    </Grid>
                </Grid>


                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Video</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                {/* Display the video if it exists */}
                {videoUrl && (
                    <Box sx={{ mt: 2 }}>
                        <video width="100%" controls>
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </Box>
                )}
                {/* Video upload functionality */}
                <VideoUpload sequenceId={data.id} onUploadComplete={handleVideoUploadComplete} />


                </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>Tasks</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                    </AccordionDetails>
                </Accordion>

                {true && (
                <Grid item xs={12} sm={12} sx={{ marginTop: 2 }}>
                <Typography variant="subtitle1" color="textSecondary">
                    {DictValue('Tasks')}
                </Typography>
                <Box 
                    sx={{ 
                    border: '1px solid', 
                    borderColor: 'grey.400', // Adjust the color as needed
                    borderRadius: 1, // Optional: for rounded corners
                    padding: 2 // Adjust padding as needed
                    }}
                >
                    <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell>Completed</TableCell>
                        <TableCell>Batch</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>Operator</TableCell>
                        <TableCell>ET per unit</TableCell>
                        <TableCell>AT per unit</TableCell>
                        <TableCell>Deviation</TableCell>
                        <TableCell>Use for stats</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {item?.tasks?.map((task) => (
                        <TaskEntry key={'task_' + task.id} task={task} />
                        ))}
                    </TableBody>
                    </Table>
                </Box>  
                </Grid>
            )}

            </CardContent>
            <CardActions>
                {canChange && <Button size="small" color="primary" onClick={() => handleOpenModal(data.id)}>
                    Add Child
                </Button>}
                {canChange && <Button size="small" color="primary" onClick={() => handleDelete(data.id)}>
                    Delete Sequence
                </Button>}


                {/*<IconButton color="error" onClick={() => handleDelete(data.id)}>
                    <DeleteIcon />
                </IconButton>*/}
            </CardActions>
        </Card>
        </AccordionDetails>
                </Accordion>

    );
};

export default Sequence;